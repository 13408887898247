import React from 'react';
import { Link } from 'react-router-dom';
import { ConctactLink, IconSCTwo } from './styles';
import Logo from 'app/components/Menu/site-logo.svg';
import Icon2Ico from './icons/socials/Icon2.svg';
import Icon3Ico from './icons/socials/Icon3.svg';
import useTranslation, { useLocaleId } from '../../hooks/useTranslation';
import { Box } from '@material-ui/system';
import { Container } from '@mui/material';
import { configSiteName, configContactEmail, configLegalName, configPhone, configPhoneMask, configLegalAddress, configFacebookUrl, configInstagramUrl, } from 'app/util/siteConfig';
import AnpcImage from './assets/anpc_sal.png';
import EuSolImage from './assets/eu_sol.png';
import Multibanner from './assets/multibanner.svg';
import { Typography } from '@material-ui/core';
var FooterSection = function () {
    var t = useTranslation();
    var locale = useLocaleId();
    var fromtToRoutes = [
        {
            from: { name: 'Chisinau', code: 'KIV' },
            to: { name: 'Bucuresti', code: 'BUH' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Chisinau', code: 'KIV' },
        },
        {
            from: { name: 'Londra', code: 'LON' },
            to: { name: 'Bucuresti', code: 'BUH' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Londra', code: 'LON' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Berlin', code: 'BER' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Madrid', code: 'MAD' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Paris', code: 'PAR' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Lisabona', code: 'LIS' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Roma', code: 'ROM' },
        },
        {
            from: { name: 'Madrid', code: 'MAD' },
            to: { name: 'Bucuresti', code: 'BUH' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Milano', code: 'MIL' },
        },
        {
            from: { name: 'Roma', code: 'ROM' },
            to: { name: 'Bucuresti', code: 'BUH' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Bruxelles', code: 'BRU' },
        },
        {
            from: { name: 'Paris', code: 'PAR' },
            to: { name: 'Bucuresti', code: 'BUH' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Barcelona', code: 'BCN' },
        },
        {
            from: { name: 'Berlin', code: 'BER' },
            to: { name: 'Bucuresti', code: 'BUH' },
        },
        {
            from: { name: 'Milano', code: 'MIL' },
            to: { name: 'Bucuresti', code: 'BUH' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Hamburg', code: 'HAM' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Frankfurt', code: 'FRA' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Atena', code: 'ATH' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Tel Aviv', code: 'TLV' },
        },
        {
            from: { name: 'Dublin', code: 'DUB' },
            to: { name: 'Bucuresti', code: 'BUH' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Praga', code: 'PRG' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Santander', code: 'SDR' },
        },
        {
            from: { name: 'Bologna', code: 'BLQ' },
            to: { name: 'Bucuresti', code: 'BUH' },
        },
        {
            from: { name: 'Catania', code: 'CTA' },
            to: { name: 'Bucuresti', code: 'BUH' },
        },
        {
            from: { name: 'Bucuresti', code: 'BUH' },
            to: { name: 'Amsterdam', code: 'AMS' },
        },
        {
            from: { name: 'Lisabona', code: 'LIS' },
            to: { name: 'Bucuresti', code: 'BUH' },
        },
    ];
    var links = [
        {
            title: configSiteName,
            items: [
                {
                    title: 'footer.about',
                    link: "/" + locale + "/despre-noi",
                },
                {
                    title: 'footer.terms',
                    link: "/" + locale + "/termeni-si-conditii",
                },
                {
                    title: 'footer.contacts',
                    link: "/" + locale + "/contacte",
                },
                {
                    title: 'footer.privacy',
                    link: "/" + locale + "/politica-de-confidentialitate",
                },
                {
                    title: 'footer.iata',
                    link: "/" + locale + "/iata-terms-and-conditions",
                },
                {
                    title: 'footer.cookies',
                    link: "/" + locale + "/de-ce-cookie",
                },
            ],
        },
        {
            title: 'footer.services',
            items: [
                // {
                //   title: 'footer.tickets',
                //   link: '/',
                // },
                // {
                //   type: 'external',
                //   title: 'footer.hotels',
                //   link: 'https://hoteluri.zbor24.ro/',
                // },
                // {
                //   type: 'external',
                //   title: 'footer.transfer',
                //   link: 'https://zbor24.gettransfer.com/en',
                // },
                {
                    type: 'external',
                    title: 'footer.insurances',
                    link: 'https://www.asigurari.ro/',
                },
                {
                    title: 'footer.groupOffer',
                    link: "/" + locale + "/group-offer",
                },
                {
                    title: 'corporate.list.item9.title',
                    link: "/" + locale + "/conexiuni-inteligente",
                },
                {
                    title: 'menuButtons.warranty',
                    link: "/" + locale + "/guarantee",
                },
            ],
        },
        {
            title: 'footer.more',
            items: [
                {
                    title: 'menuButtons.bonus',
                    link: "/" + locale + "/program-de-bonus",
                },
                {
                    title: 'footer.faq',
                    link: "/" + locale + "/intrebari-frecvente",
                },
                {
                    title: 'footer.specialNeeds',
                    link: "/" + locale + "/special-needs-rules",
                },
                {
                    title: 'footer.whatsCheckin',
                    link: "/" + locale + "/check-in",
                },
                {
                    title: 'footer.checkin',
                    link: "/" + locale + "/check-in-online",
                },
                {
                    title: 'footer.blog',
                    link: "/" + locale + "/blog",
                },
                {
                    title: 'menuButtons.career',
                    link: "/" + locale + "/cariera",
                },
            ],
        },
        {
            title: 'footer.utilInfo',
            items: [
                {
                    title: 'footer.countries',
                    link: "/" + locale + "/bilete-avion-catre-tari",
                },
                {
                    title: 'footer.cities',
                    link: "/" + locale + "/bilete-avion",
                },
                {
                    title: 'filters.airports',
                    link: "/" + locale + "/aeroporturi",
                },
                {
                    title: 'filters.airlines',
                    link: "/" + locale + "/companii-aeriene",
                },
                {
                    title: 'footer.planes',
                    link: "/" + locale + "/wiki/aeronave",
                },
                {
                    title: 'footer.alliances',
                    link: "/" + locale + "/wiki/aliante",
                },
                {
                    title: 'footer.safety_assessment_criteria',
                    link: "/" + locale + "/criterii-de-evaluare-a-sigurantei-pentru-companii-aeriene",
                },
                {
                    title: 'footer.evaluation_criteria',
                    link: "/" + locale + "/criterii-de-evaluare-a-serviciilor-complete-oferite-de-transportator",
                },
            ],
        },
    ];
    return (React.createElement(Box, { sx: {
            backgroundColor: '#fff',
            padding: {
                xs: '32px 16px 24px',
                md: '64px 0 48px 0',
            },
            margin: '50px 0 0 0',
            color: '#828282',
            zIndex: '2',
        } },
        React.createElement(Container, { maxWidth: "xl", sx: {
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                gap: { xs: '0', md: '32px 0' },
                padding: '0 0 32px 0',
            } },
            React.createElement(Box, { sx: {
                    width: { xs: '100%', md: 'auto' },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                } },
                React.createElement(Box, { sx: {
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'row', md: 'column' },
                        marginBottom: '48px',
                    } },
                    React.createElement(Box, { component: 'img', src: Logo, alt: configSiteName, sx: {
                            width: { xs: '130px', md: '200px' },
                            margin: { xs: '0', md: '0 0 48px 0' },
                        } }),
                    React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                        React.createElement(Box, { sx: {
                                margin: {
                                    xs: '0 0 6px 0',
                                    md: '0 0 16px 0',
                                },
                            } },
                            React.createElement(ConctactLink, { href: "mailto:" + configContactEmail }, configContactEmail)),
                        React.createElement(Box, { sx: {
                                display: 'flex',
                                alignItems: 'center',
                                gap: '16px',
                                margin: {
                                    xs: '0',
                                    md: '0 0 32px 0',
                                },
                            } },
                            React.createElement(ConctactLink, { href: "tel:" + configPhone }, configPhoneMask)))),
                React.createElement(Box, { sx: {
                        display: 'flex',
                        gap: '8px',
                        margin: {
                            xs: '0 0 48px 0',
                            md: '0',
                        },
                    } },
                    React.createElement("a", { href: configFacebookUrl, target: "_blank", rel: "noreferrer" },
                        React.createElement(IconSCTwo, { src: Icon3Ico, alt: "Facebook" })),
                    React.createElement("a", { href: configInstagramUrl, target: "_blank", rel: "noreferrer" },
                        React.createElement(IconSCTwo, { src: Icon2Ico, alt: "Instagram" })))),
            React.createElement(Box, { sx: {
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: {
                        xs: '48px 16px',
                        md: '30px',
                    },
                } }, links.map(function (col, key) { return (React.createElement(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: {
                        xs: 'calc(50% - 8px)',
                        md: '165px',
                    },
                }, key: key },
                React.createElement(Box, { sx: {
                        fontWeight: 700,
                        fontSize: '18px',
                        lineHeight: '120%',
                        color: 'primary.dark',
                        margin: {
                            xs: '0 0 8px 0',
                            md: '0 0 4px 0',
                        },
                    } }, t(col.title)),
                col.items.map(function (item, key) {
                    return item.type === 'external' ? (React.createElement("a", { href: item.link, target: "_blank", rel: "noreferrer", style: {
                            display: 'inline-block',
                            fontSize: '14px',
                            lineHeight: '140%',
                            color: '#828282',
                            textDecoration: 'none',
                            padding: '4px 0',
                        }, key: key }, t(item.title))) : (React.createElement(Link, { to: item.link, style: {
                            display: 'inline-block',
                            fontSize: '14px',
                            lineHeight: '140%',
                            color: '#828282',
                            textDecoration: 'none',
                            padding: '4px 0',
                        }, key: key }, t(item.title)));
                }))); }))),
        React.createElement(Container, { sx: {
                justifyContent: 'center',
                padding: '8px 0',
                borderTop: '1px solid #E3F2FD',
            } },
            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'center', padding: '8px' } },
                React.createElement(Typography, { variant: "h5", color: "primary.dark" }, "Cele mai c\u0103utate destina\u021Bii")),
            React.createElement(Container, { maxWidth: "xl", sx: {
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', md: 'repeat(4, 1fr)' },
                    gap: '4px 16px',
                } }, fromtToRoutes.map(function (route, index) { return (React.createElement(Box, { key: index, component: Link, to: "/ro/bilete-avion/din-" + route.from.name + "-" + route.from.code + "/spre-" + route.to.name + "-" + route.to.code, sx: {
                    padding: '4px 0',
                } },
                route.from.name,
                " \u2192 ",
                route.to.name)); }))),
        React.createElement(Container, { maxWidth: "xl", sx: {
                position: 'relative',
                padding: {
                    xs: '16px 0 0',
                    md: '24px 16px 0',
                },
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: '0',
                    left: {
                        xs: '0',
                        md: '24px',
                    },
                    width: {
                        xs: '100%',
                        md: 'calc(100% - 48px)',
                    },
                    height: '1px',
                    borderTop: '1px solid #E3F2FD',
                },
            } },
            React.createElement(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    margin: '0 0 32px 0',
                } },
                React.createElement(Box, { sx: {
                        display: 'flex',
                        gap: '8px',
                        margin: {
                            xs: '0 0 16px 0',
                            md: '0',
                        },
                        width: {
                            xs: '100%',
                            md: '41%',
                        },
                    } },
                    React.createElement("a", { href: "https://anpc.ro/ce-este-sal/", target: "_blank", rel: "noreferrer", style: { width: '57%' } },
                        React.createElement("img", { src: AnpcImage, style: { width: '100%' } })),
                    React.createElement("a", { href: "https://ec.europa.eu/consumers/odr", target: "_blank", rel: "noreferrer", style: { width: '43%' } },
                        React.createElement("img", { src: EuSolImage, style: { width: '100%' } }))),
                React.createElement(Box, { sx: {
                        width: {
                            xs: '100%',
                            md: '48%',
                        },
                    } },
                    React.createElement("img", { src: Multibanner, alt: "", style: { width: '100%' } }))),
            React.createElement(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: {
                        xs: '24px 0',
                        md: '0',
                    },
                    flexWrap: 'wrap',
                    fontSize: '12px',
                    lineHeight: '140%',
                    opacity: '0.5',
                    margin: '0 0 32px 0',
                } },
                React.createElement(Box, { sx: {
                        width: {
                            xs: '100%',
                            md: 'calc(50% - 16px)',
                        },
                    } }, configLegalAddress),
                React.createElement(Box, { sx: {
                        width: {
                            xs: '100%',
                            md: 'calc(50% - 16px)',
                        },
                    } }, "IBAN RON : RO19BACX0000001124019000 | IBAN EURO: RO89BACX0000001124019001 Unicredit Tiriac Bank, Bucharest SWIFT: BACXROBU")),
            React.createElement(Box, { sx: {
                    fontSize: '14px',
                    lineHeight: '140%',
                } }, t('footer.copyright', { configLegalName: configLegalName })))));
};
export default FooterSection;
