var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TextField } from '@material-ui/core';
import React, { useCallback } from 'react';
var LatinLettersField = function (props) {
    var onChange = useCallback(function (e) {
        e.stopPropagation();
        e.preventDefault();
        var value = e.target.value || '';
        props.onChange(value.replace(/[^a-zăîâșşțţ|\s|\.|-]/gi, '').toUpperCase());
    }, []);
    return React.createElement(TextField, __assign({}, props, { onChange: onChange }));
};
export default LatinLettersField;
