var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React from 'react';
import useTranslation, { useDateFormat, useLocaleId, } from '../../hooks/useTranslation';
import { useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
var BackButton = function (_a) {
    var searchArgs = _a.searchArgs;
    var format = useDateFormat();
    var history = useHistory();
    var location = useLocation();
    var hasBack = location.key;
    var path = '';
    var t = useTranslation();
    var locale = useLocaleId();
    if (searchArgs.__typename === 'SearchOnewayArguments') {
        var sourceString = searchArgs.owItinerary.source.map(function (s) {
            return s;
        });
        var destinationString = searchArgs.owItinerary.destination.map(function (s) {
            return s;
        });
        var departureString = searchArgs.owItinerary.departureDate.value
            ? searchArgs.owItinerary.departureDate.value
            : searchArgs.owItinerary.departureDate.range.start + "," + searchArgs.owItinerary.departureDate.range.end;
        path = "/" + locale + "/search/" + sourceString + "/" + destinationString + "/" + departureString + "/-/?passengers=" + searchArgs.passengers.adults + "_" + searchArgs.passengers.children + "_" + searchArgs.passengers.infants;
    }
    else if (searchArgs.__typename === 'SearchReturnArguments') {
        var sourceString = searchArgs.returnItinerary.source.map(function (s) {
            return s;
        });
        var destinationString = searchArgs.returnItinerary.destination.map(function (s) {
            return s;
        });
        var departureString = searchArgs.returnItinerary.departureDate.value
            ? searchArgs.returnItinerary.departureDate.value
            : searchArgs.returnItinerary.departureDate.range.start + "," + searchArgs.returnItinerary.departureDate.range.end;
        var returnString = '';
        if (searchArgs.returnItinerary.arrivalDate.value) {
            returnString = searchArgs.returnItinerary.arrivalDate.value;
        }
        else if (searchArgs.returnItinerary.arrivalDate.range) {
            returnString = searchArgs.returnItinerary.arrivalDate.range.start + "," + searchArgs.returnItinerary.arrivalDate.range.end;
        }
        else if (searchArgs.returnItinerary.arrivalDate.duration) {
            returnString = "d" + searchArgs.returnItinerary.arrivalDate.duration.start + "," + searchArgs.returnItinerary.arrivalDate.duration.end;
        }
        path = "/" + locale + "/search/" + sourceString + "/" + destinationString + "/" + departureString + "/" + returnString + "/?passengers=" + searchArgs.passengers.adults + "_" + searchArgs.passengers.children + "_" + searchArgs.passengers.infants;
    }
    var handleBackClick = function () {
        if (hasBack) {
            history.go(-1);
        }
        else {
            history.push(path);
        }
    };
    var StyledButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 170px;\n    height: 33px;\n    text-align: center;\n    padding: 0px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: #1976d2;\n    color: white;\n    border-radius: 4px;\n    cursor: pointer;\n\n    transition: 0.2s ease;\n\n    &:hover {\n      background: ", ";\n    }\n  "], ["\n    width: 170px;\n    height: 33px;\n    text-align: center;\n    padding: 0px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: #1976d2;\n    color: white;\n    border-radius: 4px;\n    cursor: pointer;\n\n    transition: 0.2s ease;\n\n    &:hover {\n      background: ", ";\n    }\n  "])), function (p) { return p.theme.palette.primary.dark; });
    return (React.createElement(StyledButton, { onClick: function () { return handleBackClick(); } },
        React.createElement(ArrowBackIosIcon, { sx: { fontSize: '12px', mr: '8px' } }),
        React.createElement(Typography, { variant: "body2" }, t('booking.backToSearch'))));
};
export default BackButton;
var templateObject_1;
