var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { After, ensureReady } from '@jaredpalmer/after';
import routes from './routes';
import { createStore } from './redux/store';
import { Provider } from 'react-redux';
import { createMuiTheme } from './components/theme';
import { ApolloClient, ApolloLink, ApolloProvider, concat, HttpLink, InMemoryCache, } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core';
import { QueryParamProvider } from 'use-query-params';
import { runtimeConfig } from './config';
import { CacheProvider } from '@emotion/react';
import { LOCALE_LOCAL_STORAGE_KEY, LocaleProvider, } from './hooks/useTranslation';
import { AuthProvider, JWT_TOKEN_KEY } from './hooks/useAuth';
import { relayStylePagination } from '@apollo/client/utilities';
import { CookieBanner } from 'app/components/CookieBanner';
import createEmotionCache from 'app/shared/createEmotionCache';
import CssBaseline from '@mui/material/CssBaseline';
import { AnalyticsProvider } from './hooks/useAnalytics';
// @ts-ignore
var storeState = window._APP_.state || {};
// @ts-ignore
delete window._APP_.state;
var store = createStore(storeState).store;
var httpLink = new HttpLink({ uri: runtimeConfig.apiUrl });
var authMiddleware = new ApolloLink(function (operation, forward) {
    // add the authorization to the headers
    operation.setContext(function (_a) {
        var _b = _a.headers, headers = _b === void 0 ? {} : _b;
        return ({
            headers: __assign(__assign({}, headers), { Authorization: localStorage.getItem(JWT_TOKEN_KEY)
                    ? "" + localStorage.getItem(JWT_TOKEN_KEY)
                    : null, Locale: localStorage.getItem(LOCALE_LOCAL_STORAGE_KEY) }),
        });
    });
    return forward(operation);
});
var client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    passengers: relayStylePagination(),
                },
            },
        },
    }),
    link: concat(authMiddleware, httpLink),
});
var theme = createMuiTheme();
var cache = createEmotionCache();
function renderApp() {
    ensureReady(routes).then(function (data) {
        render(React.createElement(ApolloProvider, { client: client },
            React.createElement(BrowserRouter, null,
                React.createElement(QueryParamProvider, { ReactRouterRoute: Route },
                    React.createElement(CacheProvider, { value: cache },
                        React.createElement(ThemeProvider, { theme: theme },
                            React.createElement(Provider, { store: store },
                                React.createElement(AuthProvider, null,
                                    React.createElement(AnalyticsProvider, null,
                                        React.createElement(LocaleProvider, null,
                                            React.createElement(CssBaseline, null),
                                            React.createElement(After, { data: data, routes: routes, transitionBehavior: "instant" }),
                                            React.createElement(CookieBanner, null)))))))))), document.getElementById('root'));
    });
}
renderApp();
if (module.hot) {
    module.hot.accept('./routes', renderApp);
}
