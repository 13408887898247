var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var Stroller = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.79688 11.0003L5.00868 9.34092L5.2247 9.80611L4.46258 10.16H3.43745C2.97337 10.16 2.59717 9.78381 2.59717 9.31974C2.59717 8.85566 2.97337 8.47946 3.43745 8.47946H5.34975C5.45968 8.47946 5.55979 8.54274 5.60696 8.64204L6.328 10.16H17.7222C18.1862 10.16 18.568 10.5377 18.5101 10.9982C18.3563 12.2205 17.8712 13.3428 17.1508 14.269C17.7558 14.8068 18.1423 15.5882 18.1423 16.4621C18.1423 18.0838 16.8231 19.4031 15.2013 19.4031C13.7224 19.4031 12.4956 18.3107 12.294 16.8822H10.5462C10.3445 18.3107 9.11772 19.4031 7.63883 19.4031C6.0171 19.4031 4.69786 18.0838 4.69786 16.4621C4.69786 15.5305 5.12991 14.6982 5.80907 14.1598C5.94468 14.0523 6.09013 13.9566 6.24397 13.874C6.21731 13.8335 6.16409 13.7334 6.09215 13.591C5.6427 12.7013 4.46258 10.16 4.46258 10.16L5.2247 9.80611L5.29297 9.9529C5.33665 10.0467 5.39929 10.1811 5.47507 10.3434C5.62667 10.6679 5.83069 11.1035 6.0406 11.5479C6.25075 11.9928 6.46578 12.4445 6.63973 12.8021C6.72688 12.9813 6.80224 13.1338 6.8608 13.2489C6.90571 13.3371 6.93141 13.3845 6.9418 13.4037C6.94716 13.4136 6.94845 13.416 6.9462 13.4126L7.45071 14.1803L6.64114 14.6145C5.98112 14.9685 5.53814 15.6637 5.53814 16.4621C5.53814 17.6198 6.48117 18.5628 7.63883 18.5628C8.69462 18.5628 9.57041 17.7831 9.71417 16.7648L9.81621 16.042H13.024L13.126 16.7648C13.2698 17.7831 14.1456 18.5628 15.2013 18.5628C16.359 18.5628 17.302 17.6198 17.302 16.4621C17.302 15.8425 17.0291 15.285 16.5925 14.897L16.0044 14.3742L16.4875 13.7531C17.0972 12.9692 17.5134 12.0263 17.6619 11.0003H5.79688ZM12.6805 9.31974H17.7222C18.1862 9.31974 18.568 8.94165 18.5107 8.48112C18.1324 5.43796 15.722 3.02756 12.6788 2.64926C12.2183 2.59201 11.8402 2.97372 11.8402 3.43779V8.47946C11.8402 8.94353 12.2164 9.31974 12.6805 9.31974ZM12.6805 3.49718V8.47946H17.6628C17.2951 5.90301 15.2569 3.86486 12.6805 3.49718Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.63873 18.5632C8.79891 18.5632 9.73942 17.6226 9.73942 16.4625C9.73942 15.3023 8.79891 14.3618 7.63873 14.3618C6.47854 14.3618 5.53803 15.3023 5.53803 16.4625C5.53803 17.6226 6.47854 18.5632 7.63873 18.5632ZM7.63873 19.4034C9.26298 19.4034 10.5797 18.0867 10.5797 16.4625C10.5797 14.8382 9.26298 13.5215 7.63873 13.5215C6.01447 13.5215 4.69775 14.8382 4.69775 16.4625C4.69775 18.0867 6.01447 19.4034 7.63873 19.4034Z", fill: "currentColor" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.2012 18.5632C16.3614 18.5632 17.3019 17.6226 17.3019 16.4625C17.3019 15.3023 16.3614 14.3618 15.2012 14.3618C14.041 14.3618 13.1005 15.3023 13.1005 16.4625C13.1005 17.6226 14.041 18.5632 15.2012 18.5632ZM15.2012 19.4034C16.8255 19.4034 18.1422 18.0867 18.1422 16.4625C18.1422 14.8382 16.8255 13.5215 15.2012 13.5215C13.577 13.5215 12.2603 14.8382 12.2603 16.4625C12.2603 18.0867 13.577 19.4034 15.2012 19.4034Z", fill: "currentColor" })))); };
export default Stroller;
