var mapCurrencySymbolByCode = {
    'EUR': '€',
};
var defaultCurrencySymbol = mapCurrencySymbolByCode.EUR;
var getCurrencySymbol = function (code) {
    if (!code)
        return defaultCurrencySymbol;
    return mapCurrencySymbolByCode[code] || defaultCurrencySymbol;
};
export default getCurrencySymbol;
