var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import { PristineButton } from '../../ui/buttons';
import { css } from '@emotion/react';
export var FlightRowRoot = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  position: relative;\n\n  ", " {\n    display: block;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  position: relative;\n\n  ", " {\n    display: block;\n  }\n"])), function (p) { return p.theme.breakpoints.down('sm'); });
export var FlightRowSwitchDirections = styled(IconButton)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 26px;\n  height: 26px;\n\n  ", "\n\n  z-index: 2;\n  border: 1px solid ", ";\n  color: ", ";\n  background-color: white;\n\n  ", " {\n    &:hover {\n      background-color: white;\n      transform: scale(1.1);\n    }\n  }\n\n  cursor: pointer;\n\n  ", " {\n    background-color: ", ";\n    border: 1px solid white;\n    color: white;\n    position: relative;\n\n    ", "\n    &:focus {\n      background-color: ", ";\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 26px;\n  height: 26px;\n\n  ", "\n\n  z-index: 2;\n  border: 1px solid ", ";\n  color: ", ";\n  background-color: white;\n\n  ", " {\n    &:hover {\n      background-color: white;\n      transform: scale(1.1);\n    }\n  }\n\n  cursor: pointer;\n\n  ", " {\n    background-color: ", ";\n    border: 1px solid white;\n    color: white;\n    position: relative;\n\n    ", "\n    &:focus {\n      background-color: ", ";\n    }\n  }\n"])), function (_a) {
    var inline = _a.inline;
    return !inline && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      margin: -9px;\n    "], ["\n      margin: -9px;\n    "])));
}, function (p) { return p.theme.palette.text.primary; }, function (p) { return p.theme.palette.text.primary; }, function (p) { return p.theme.breakpoints.up('sm'); }, function (p) { return p.theme.breakpoints.down('sm'); }, function (p) { return p.theme.palette.primary.dark; }, function (_a) {
    var inline = _a.inline;
    return !inline && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        left: 94%;\n      "], ["\n        left: 94%;\n      "])));
}, function (p) { return p.theme.palette.primary.main; });
export var FlightRowDeleteButton = styled(PristineButton)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  position: absolute;\n  left: -20px;\n  top: 50%;\n  transform: translate(0, -50%);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: transform 0.4s;\n  padding: 0;\n  cursor: pointer;\n  color: ", ";\n\n  ", " {\n    position: relative;\n    z-index: 1;\n    top: 0;\n    border-radius: 50%;\n    height: 26px;\n    width: 26px;\n    top: 0;\n    right: 0px;\n    left: initial;\n    transform: translate(0, 0);\n    color: white;\n  }\n  svg {\n    width: 100%;\n    height: 100%;\n  }\n"], ["\n  width: 16px;\n  height: 16px;\n  position: absolute;\n  left: -20px;\n  top: 50%;\n  transform: translate(0, -50%);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: transform 0.4s;\n  padding: 0;\n  cursor: pointer;\n  color: ", ";\n\n  ", " {\n    position: relative;\n    z-index: 1;\n    top: 0;\n    border-radius: 50%;\n    height: 26px;\n    width: 26px;\n    top: 0;\n    right: 0px;\n    left: initial;\n    transform: translate(0, 0);\n    color: white;\n  }\n  svg {\n    width: 100%;\n    height: 100%;\n  }\n"])), function (p) { return p.theme.palette.text.primary; }, function (p) { return p.theme.breakpoints.down('sm'); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
