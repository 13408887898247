var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Cookies from 'js-cookie';
import React from 'react';
import { configUtmObjKey } from 'app/util/siteConfig';
export var UTM_OBJ_KEY = configUtmObjKey;
var defaultState = {
    ga: '',
    utmTerm: undefined,
    utmCampaign: undefined,
    utmMedium: undefined,
    utmSource: undefined,
};
export var AnalyticsContext = React.createContext({
    utmData: defaultState,
    clearAnalyticsContext: function () { },
    setAnalyticsContext: function (newAnalytics) { },
});
export var AnalyticsProvider = function (_a) {
    var children = _a.children;
    var ga = Cookies.get('_ga');
    var _b = React.useState(__assign(__assign({}, defaultState), { ga: ga })), utmData = _b[0], setUtmData = _b[1];
    var setAnalyticsContext = React.useCallback(function (newData) {
        localStorage.setItem(UTM_OBJ_KEY, JSON.stringify(__assign({ ga: ga }, newData)));
        setUtmData(__assign({ ga: ga }, newData));
    }, []);
    var clearAnalyticsContext = React.useCallback(function () {
        localStorage.setItem(UTM_OBJ_KEY, JSON.stringify({ ga: ga }));
        setUtmData({ ga: ga || defaultState.ga });
    }, []);
    var value = React.useMemo(function () {
        return { setAnalyticsContext: setAnalyticsContext, clearAnalyticsContext: clearAnalyticsContext, utmData: utmData };
    }, [setAnalyticsContext, clearAnalyticsContext, utmData]);
    return (React.createElement(AnalyticsContext.Provider, { value: value }, children));
};
