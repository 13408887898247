import { Stack, Typography } from '@material-ui/core';
import { TrendingFlat } from '@material-ui/icons';
import React from 'react';
import SwapHoriz from '../../assets/icons/SwapHoriz';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { parseISO } from 'date-fns';
import { useDateFormat } from 'app/hooks/useTranslation';
import { Box } from '@material-ui/system';
import { useHistory } from 'react-router-dom';
var BookingTopSectionMobile = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    var result = _a.result;
    var history = useHistory();
    var format = useDateFormat();
    return (React.createElement(Stack, { sx: {
            width: '100%',
            backgroundColor: 'primary.dark',
            p: 3,
            mb: 4,
        }, color: "background.default" }, ((_b = result.search) === null || _b === void 0 ? void 0 : _b.__typename) !== 'SearchMultipleArguments' ? (React.createElement(Stack, { direction: "row", alignItems: 'center' },
        React.createElement(Box, { sx: {
                width: '22px',
                height: '22px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '90%',
                border: '1px solid white',
                mr: 2,
            }, onClick: function () { return history.go(-1); } },
            React.createElement(ArrowBackIcon, { sx: { fontSize: '14px' } })),
        React.createElement(Stack, null,
            React.createElement(Stack, { direction: "row" },
                React.createElement(Typography, { color: "background.default" }, (_d = (_c = result === null || result === void 0 ? void 0 : result.itinerary) === null || _c === void 0 ? void 0 : _c.sector[0].segments[0].segment.source.station.city) === null || _d === void 0 ? void 0 : _d.name),
                ((_e = result.search) === null || _e === void 0 ? void 0 : _e.__typename) !== 'SearchOnewayArguments' ? (React.createElement(SwapHoriz, { viewBox: "-3 -5 24 24", fill: "white", sx: { color: 'background.default', mx: 1 } })) : (React.createElement(TrendingFlat, { sx: { color: 'background.default', mx: '11px' } })),
                React.createElement(Typography, { color: "background.default" }, (_h = (_f = result.itinerary) === null || _f === void 0 ? void 0 : _f.sector[0].segments[((_g = result.itinerary) === null || _g === void 0 ? void 0 : _g.sector[0].segments.length) - 1].segment.destination.station.city) === null || _h === void 0 ? void 0 : _h.name)),
            React.createElement(Stack, { direction: "row" },
                React.createElement(Typography, { variant: "subtitle1" },
                    format(parseISO((_j = result.itinerary) === null || _j === void 0 ? void 0 : _j.sector[0].segments[0].segment.source.localTime), 'EEEEEE, dd MMMM'),
                    ((_k = result.search) === null || _k === void 0 ? void 0 : _k.__typename) !== 'SearchOnewayArguments'
                        ? ' - '
                        : '',
                    ((_l = result.search) === null || _l === void 0 ? void 0 : _l.__typename) !== 'SearchOnewayArguments' &&
                        format(parseISO((_m = result.itinerary) === null || _m === void 0 ? void 0 : _m.sector[((_o = result.itinerary) === null || _o === void 0 ? void 0 : _o.sector.length) - 1].segments[0].segment.source.localTime), 'EEEEEE, dd MMMM')),
                React.createElement(Typography, { variant: "subtitle1" }, "\u00A0/\u00A0"),
                React.createElement(Typography, { variant: "subtitle1" }, (_p = result.passengers) === null || _p === void 0 ? void 0 :
                    _p.passenger.length,
                    "\u00A0",
                    result.passengers && ((_q = result.passengers) === null || _q === void 0 ? void 0 : _q.passenger.length) > 1
                        ? 'passengers'
                        : 'passenger'))))) : (React.createElement(Stack, { direction: "row" },
        React.createElement(Stack, null, (_r = result.itinerary) === null || _r === void 0 ? void 0 : _r.sector.map(function (item) {
            var _a, _b;
            return (React.createElement(Stack, null,
                React.createElement(Stack, { direction: "row" },
                    React.createElement(Typography, { color: "background.default" }, (_a = item.segments[0].segment.source.station.city) === null || _a === void 0 ? void 0 : _a.name),
                    React.createElement(TrendingFlat, { sx: { color: 'background.default', mx: '11px' } }),
                    React.createElement(Typography, { color: "background.default" }, (_b = item.segments[item.segments.length - 1].segment
                        .destination.station.city) === null || _b === void 0 ? void 0 : _b.name)),
                React.createElement(Typography, { variant: "subtitle1" }, format(parseISO(item.segments[0].segment.source.localTime), 'EEEEEE, dd MMMM'))));
        })),
        React.createElement(Stack, { ml: 2 }, (_s = result.passengers) === null || _s === void 0 ? void 0 :
            _s.passenger.length,
            "\u00A0",
            result.passengers && ((_t = result.passengers) === null || _t === void 0 ? void 0 : _t.passenger.length) > 1
                ? 'passengers'
                : 'passenger')))));
};
export default BookingTopSectionMobile;
