var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var People = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M14.5358 16.7142V14.9999C14.5358 14.0906 14.1746 13.2185 13.5316 12.5756C12.8886 11.9326 12.0166 11.5714 11.1072 11.5714H4.2501C3.34079 11.5714 2.46872 11.9326 1.82574 12.5756C1.18276 13.2185 0.821533 14.0906 0.821533 14.9999V16.7142M19.6787 16.7142V14.9999C19.6781 14.2403 19.4253 13.5023 18.9598 12.9019C18.4944 12.3015 17.8428 11.8727 17.1072 11.6828M13.6787 1.39707C14.4162 1.5859 15.0699 2.01482 15.5367 2.6162C16.0035 3.21758 16.2568 3.95721 16.2568 4.7185C16.2568 5.47979 16.0035 6.21943 15.5367 6.82081C15.0699 7.42219 14.4162 7.8511 13.6787 8.03993M11.1072 4.71422C11.1072 6.60776 9.57222 8.14279 7.67868 8.14279C5.78513 8.14279 4.2501 6.60776 4.2501 4.71422C4.2501 2.82067 5.78513 1.28564 7.67868 1.28564C9.57222 1.28564 11.1072 2.82067 11.1072 4.71422Z", stroke: "#002171", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default People;
