var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
/**
 *
 * LoginSocialGoogle
 *
 */
import React, { forwardRef, memo, useCallback, useImperativeHandle, useLayoutEffect, useState, } from 'react';
// const SCOPE = ''
// const JS_SRC = 'https://apis.google.com/js/api.js'
var JS_SRC = 'https://accounts.google.com/gsi/client';
var SCRIPT_ID = 'google-login';
var isServer = typeof window === 'undefined';
var _window = !isServer && window;
var LoginSocialGoogle = forwardRef(function (_a, ref) {
    var client_id = _a.client_id, _b = _a.scope, scope = _b === void 0 ? 'email profile' : _b, _c = _a.prompt, prompt = _c === void 0 ? 'select_account' : _c, ux_mode = _a.ux_mode, _d = _a.className, className = _d === void 0 ? '' : _d, _e = _a.login_hint, login_hint = _e === void 0 ? '' : _e, _f = _a.access_type, access_type = _f === void 0 ? 'online' : _f, onLogoutFailure = _a.onLogoutFailure, onLogoutSuccess = _a.onLogoutSuccess, onLoginStart = _a.onLoginStart, onReject = _a.onReject, onResolve = _a.onResolve, _g = _a.redirect_uri, redirect_uri = _g === void 0 ? '/' : _g, _h = _a.cookie_policy, cookie_policy = _h === void 0 ? 'single_host_origin' : _h, _j = _a.hosted_domain, hosted_domain = _j === void 0 ? '' : _j, _k = _a.discoveryDocs, discoveryDocs = _k === void 0 ? '' : _k, children = _a.children, _l = _a.fetch_basic_profile, fetch_basic_profile = _l === void 0 ? true : _l;
    var _m = useState(false), isLogged = _m[0], setIsLogged = _m[1];
    var _o = useState(false), isSdkLoaded = _o[0], setIsSdkLoaded = _o[1];
    var _p = useState(false), isProcessing = _p[0], setIsProcessing = _p[1];
    var _q = useState(null), instance = _q[0], setInstance = _q[1];
    var _r = useState(''), token = _r[0], setToken = _r[1];
    useLayoutEffect(function () {
        !isServer && !isSdkLoaded && load();
    }, [isSdkLoaded]);
    var checkIsExistsSDKScript = useCallback(function () {
        return !!document.getElementById(SCRIPT_ID);
    }, []);
    var insertScriptGoogle = useCallback(function (d, s, id, jsSrc, cb) {
        if (s === void 0) { s = 'script'; }
        var ggScriptTag = d.createElement(s);
        ggScriptTag.id = id;
        ggScriptTag.src = jsSrc;
        ggScriptTag.async = true;
        ggScriptTag.defer = true;
        var scriptNode = document.getElementsByTagName('script')[0];
        scriptNode &&
            scriptNode.parentNode &&
            scriptNode.parentNode.insertBefore(ggScriptTag, scriptNode);
        ggScriptTag.onload = cb;
    }, []);
    var handleResponse = useCallback(function (res) {
        setIsLogged(true);
        setIsProcessing(false);
        setToken(res === null || res === void 0 ? void 0 : res.access_token);
        // _window.google.accounts.id.initialize({
        //   client_id,
        //   auto_select: true,
        //   callback: (value: any) =>
        //     console.log('🚀 ~ file: index.tsx ~ line 113 ~ value', value)
        // })
        // _window.google.accounts.id.prompt()
        var data = res;
        onResolve({
            provider: 'google',
            data: data,
        });
    }, [onResolve]);
    var load = useCallback(function () {
        if (checkIsExistsSDKScript()) {
            setIsSdkLoaded(true);
        }
        else {
            insertScriptGoogle(document, 'script', SCRIPT_ID, JS_SRC, function () {
                var params = {
                    client_id: client_id,
                    cookie_policy: cookie_policy,
                    login_hint: login_hint,
                    hosted_domain: hosted_domain,
                    fetch_basic_profile: fetch_basic_profile,
                    discoveryDocs: discoveryDocs,
                    ux_mode: ux_mode,
                    redirect_uri: redirect_uri,
                    access_type: access_type,
                    scope: scope,
                    immediate: true,
                    prompt: prompt,
                };
                var client = _window.google.accounts.oauth2.initTokenClient(__assign(__assign({}, params), { callback: handleResponse }));
                setInstance(client);
                setIsSdkLoaded(true);
            });
        }
    }, [
        checkIsExistsSDKScript,
        insertScriptGoogle,
        client_id,
        prompt,
        cookie_policy,
        login_hint,
        hosted_domain,
        fetch_basic_profile,
        discoveryDocs,
        ux_mode,
        redirect_uri,
        access_type,
        scope,
        handleResponse,
    ]);
    var loginGoogle = useCallback(function () {
        if (isProcessing || !isSdkLoaded)
            return;
        setIsProcessing(true);
        if (!_window.google) {
            setIsProcessing(false);
            load();
            onReject("Google SDK isn't loaded!");
        }
        else {
            onLoginStart && onLoginStart();
            if (instance)
                instance.requestAccessToken();
        }
    }, [instance, isProcessing, isSdkLoaded, load, onLoginStart, onReject]);
    useImperativeHandle(ref, function () { return ({
        onLogout: function () {
            if (isLogged && token) {
                setIsLogged(false);
                var auth2 = _window.google.accounts.oauth2;
                auth2.revoke(token, function (done) {
                    if (done.error) {
                        console.log(done.error);
                        onLogoutFailure && onLogoutFailure();
                    }
                    else
                        onLogoutSuccess && onLogoutSuccess();
                });
            }
            else {
                console.log('You must login before logout.');
            }
        },
    }); });
    return (React.createElement("div", { className: className, style: { width: '100%' }, onClick: loginGoogle }, children));
});
export default memo(LoginSocialGoogle);
