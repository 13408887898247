var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import SvgIcon from '@mui/material/SvgIcon';
var HighlightOff = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M7.86624 5.38368L4.63376 8.61617M4.63376 5.38368L7.86624 8.61617M10.2906 11.0405C8.05904 13.2721 4.44096 13.2721 2.20939 11.0405C-0.0221778 8.80896 -0.022178 5.19088 2.20939 2.95931C4.44096 0.727744 8.05904 0.727744 10.2906 2.95931C12.5222 5.19088 12.5222 8.80896 10.2906 11.0405Z", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default HighlightOff;
