var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Alert, Box, Button, ClickAwayListener, List, ListItem, Paper, Popper, Stack, SwipeableDrawer, Typography, useMediaQuery, } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TopDestinationsPopularityEnum, useGetNearbyPlacesQuery, useGetPlacesQuery, useGetPopularPlacesQuery, } from '../../../generated/graphql';
import useTranslation, { useLocaleId } from '../../../hooks/useTranslation';
import { AirportInput } from './AirportInput';
import AirportOptionItem from './AirportOptionItem';
import { AirportSelectLoading } from './elements';
import Clear from '../../../assets/icons/Clear';
import { useDispatch } from 'react-redux';
import { setNearbyPlaces } from 'app/redux/slices/flightSearch.slice';
var AirportSelect = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var value = _a.value, onChange = _a.onChange, _l = _a.placeholder, placeholder = _l === void 0 ? '' : _l, label = _a.label, type = _a.type, initialDepartureAirport = _a.initialDepartureAirport, autoFocus = _a.autoFocus, flightError = _a.flightError, wide = _a.wide, popularDestinationValue = _a.popularDestinationValue, errorText = _a.errorText, onFocus = _a.onFocus;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); }, {
        noSsr: true,
    });
    var dispatch = useDispatch();
    var locale = useLocaleId();
    var boxRef = useRef(null);
    var _m = useState(null), anchorEl = _m[0], setAnchorEl = _m[1];
    var _o = useState(false), isOpen = _o[0], setIsOpen = _o[1];
    var _p = useState([]), prevValue = _p[0], setPrevValue = _p[1];
    useEffect(function () {
        setPrevValue(value);
    }, [isOpen]);
    var limitReached = value.length >= 3;
    var handleChange = useCallback(function (options) {
        var newOptions = options.length > 1
            ? options.filter(function (option) { return option.code !== 'anywhere'; })
            : options;
        onChange(newOptions);
    }, [onChange]);
    var _q = useState(''), inputValue = _q[0], setInputValue = _q[1];
    var nearbyValue = null;
    var popularValue = {};
    popularDestinationValue
        ? (popularValue = {
            code: popularDestinationValue.code,
            popularity: TopDestinationsPopularityEnum.Searches,
        })
        : {};
    value[0] ? (nearbyValue = { code: value[0].code }) : null;
    var _r = useGetPlacesQuery({
        variables: { term: inputValue },
        skip: inputValue.length < 3,
    }), data = _r.data, loading = _r.loading, error = _r.error;
    var popularPlacesResponce = useGetPopularPlacesQuery({
        variables: { topDestinations: popularValue },
        skip: type === 'departure',
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        ssr: false,
    });
    var _s = useState(initialDepartureAirport), allowAutofilling = _s[0], setAllowAutoFilling = _s[1];
    var nearByPlacesResponce = useGetNearbyPlacesQuery({
        variables: { nearbyCities: nearbyValue },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        ssr: false,
        skip: type === 'arrival',
        onCompleted: function (_a) {
            var _b;
            var nearbyPlaces = _a.nearbyPlaces;
            if ((nearbyPlaces === null || nearbyPlaces === void 0 ? void 0 : nearbyPlaces.__typename) === 'PlaceConnection' &&
                allowAutofilling &&
                value.length === 0) {
                var tempPlaces_1 = [];
                (_b = nearbyPlaces.edges) === null || _b === void 0 ? void 0 : _b.forEach(function (placeEdge, index) {
                    var _a;
                    if (index < 3) {
                        tempPlaces_1.push({
                            name: ((placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.__typename) === 'Station'
                                ? (_a = placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.city) === null || _a === void 0 ? void 0 : _a.name
                                : placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.name),
                            code: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.code,
                        });
                    }
                });
                setAllowAutoFilling(false);
                dispatch(setNearbyPlaces(tempPlaces_1));
                handleChange(tempPlaces_1.slice(0, 1));
            }
        },
    });
    var wrapperRef = useRef();
    useEffect(function () {
        if (value.length > 0) {
            setAllowAutoFilling(false);
        }
    }, []);
    var purgeInputValue = function () {
        setInputValue('');
    };
    var closeDropdown = function () {
        setIsOpen(false);
    };
    var t = useTranslation();
    var anywhereItem = {
        name: t('airportItem.anywhere'),
        code: 'anywhere',
    };
    var list = loading ? (React.createElement(AirportSelectLoading, null, "Loading...")) : (React.createElement(List, { sx: {
            maxHeight: { md: 400, xs: 'calc(100% - 190px)' },
            overflow: 'auto',
        } },
        type === 'arrival' &&
            !value.find(function (el) { return el.code === 'anywhere'; }) &&
            !inputValue &&
            value.length === 0 && (React.createElement(AirportOptionItem, { name: anywhereItem.name, code: anywhereItem.code, type: "anywhere", clickHandler: function () {
                if (!limitReached) {
                    onChange([
                        {
                            name: anywhereItem.name,
                            code: anywhereItem.code,
                        },
                    ]);
                }
            }, closeDropdown: closeDropdown, purgeInputValue: purgeInputValue, isMain: true })),
        !inputValue.length && value.length ? (React.createElement(React.Fragment, null,
            React.createElement(ListItem, { sx: { backgroundColor: '#fbfbfb', color: 'primary.dark' } },
                React.createElement(Typography, null, t('airportSelect.alreadyIncluded'))),
            value.map(function (val, key) { return (React.createElement(AirportOptionItem, { key: val.code, name: val.name, code: val.code, clickHandler: function () {
                    handleChange(value.filter(function (v) { return v.code !== val.code; }));
                }, closeDropdown: closeDropdown, purgeInputValue: purgeInputValue, included: true, isMain: true })); }))) : null,
        !inputValue.length ? (React.createElement(React.Fragment, null,
            type === 'departure' && (React.createElement(React.Fragment, null,
                React.createElement(ListItem, { sx: { backgroundColor: '#fbfbfb' } },
                    React.createElement(Typography, null,
                        t('airportSelect.addNearBy'),
                        ' ',
                        value.length ? value[0].name : t('airportSelect.yourself'))),
                ((_c = (_b = nearByPlacesResponce.data) === null || _b === void 0 ? void 0 : _b.nearbyPlaces) === null || _c === void 0 ? void 0 : _c.__typename) ===
                    'PlaceConnection' &&
                    ((_d = nearByPlacesResponce.data.nearbyPlaces.edges) === null || _d === void 0 ? void 0 : _d.reduce(function (result, placeEdge, placeIndex) {
                        var _a, _b, _c;
                        var index = result.indexCounter++;
                        result.places.push(React.createElement(AirportOptionItem, { clickHandler: function () {
                                var _a;
                                if (!limitReached) {
                                    handleChange(__spreadArray(__spreadArray([], value, true), [
                                        {
                                            name: ((placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.__typename) === 'Station'
                                                ? (_a = placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.city) === null || _a === void 0 ? void 0 : _a.name
                                                : placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.name),
                                            code: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.code,
                                        },
                                    ], false));
                                }
                            }, closeDropdown: closeDropdown, purgeInputValue: purgeInputValue, name: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.name, code: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.code, 
                            /* @ts-ignore */
                            city: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.city, 
                            /* @ts-ignore */
                            country: (_a = placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.city) === null || _a === void 0 ? void 0 : _a.country, type: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.__typename, isMain: true }));
                        (placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.__typename) === 'City' &&
                            ((_c = (_b = placeEdge.node.stations) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.forEach(function (station, si) {
                                var index = result.indexCounter++;
                                result.places.push(React.createElement(AirportOptionItem, { clickHandler: function () {
                                        var _a;
                                        if (!limitReached) {
                                            handleChange(__spreadArray(__spreadArray([], value, true), [
                                                {
                                                    name: ((placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.__typename) ===
                                                        'Station'
                                                        ? ((_a = placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.city) === null || _a === void 0 ? void 0 : _a.name) +
                                                            ' ' +
                                                            (placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.code)
                                                        : placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.name),
                                                    code: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.code,
                                                },
                                            ], false));
                                        }
                                    }, closeDropdown: closeDropdown, purgeInputValue: purgeInputValue, name: station === null || station === void 0 ? void 0 : station.node.name, code: station === null || station === void 0 ? void 0 : station.node.code, city: station === null || station === void 0 ? void 0 : station.node.city, type: ((station === null || station === void 0 ? void 0 : station.node.__typename) ||
                                        (station === null || station === void 0 ? void 0 : station.node.type)) }));
                            }));
                        return result;
                    }, {
                        places: [],
                        indexCounter: 0,
                    }).places))),
            type === 'arrival' && (React.createElement(React.Fragment, null,
                React.createElement(ListItem, { sx: { backgroundColor: '#fbfbfb' } },
                    React.createElement(Typography, { sx: { color: 'primary.dark' } }, t('airportSelect.popularDestinations'))),
                ((_f = (_e = popularPlacesResponce.data) === null || _e === void 0 ? void 0 : _e.popularPlaces) === null || _f === void 0 ? void 0 : _f.__typename) ===
                    'PlaceConnection' &&
                    ((_g = popularPlacesResponce.data.popularPlaces.edges) === null || _g === void 0 ? void 0 : _g.filter(function (place) { return !value.map(function (v) { return v.code; }).includes(place.node.code); }).reduce(function (result, placeEdge, placeIndex) {
                        var index = result.indexCounter++;
                        (placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.__typename) !== 'Station'
                            ? result.places.push(React.createElement(AirportOptionItem, { clickHandler: function () {
                                    if (!limitReached) {
                                        handleChange(__spreadArray(__spreadArray([], value, true), [
                                            {
                                                /* @ts-ignore */
                                                name: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.name,
                                                /* @ts-ignore */
                                                code: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.code,
                                            },
                                        ], false));
                                    }
                                }, closeDropdown: closeDropdown, purgeInputValue: purgeInputValue, name: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.name, code: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.code, 
                                /* @ts-ignore */
                                city: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.city, 
                                /* @ts-ignore */
                                country: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.country, type: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.__typename, isMain: true }))
                            : result.places.push(React.createElement(AirportOptionItem, { clickHandler: function () {
                                    if (!limitReached) {
                                        handleChange(__spreadArray(__spreadArray([], value, true), [
                                            {
                                                /* @ts-ignore */
                                                name: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.city.name,
                                                /* @ts-ignore */
                                                code: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.code,
                                            },
                                        ], false));
                                    }
                                }, closeDropdown: closeDropdown, purgeInputValue: purgeInputValue, name: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.name, code: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.code, 
                                /* @ts-ignore */
                                city: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.city, 
                                /* @ts-ignore */
                                country: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.country, type: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.__typename, isMain: true }));
                        return result;
                    }, {
                        places: [],
                        indexCounter: 0,
                    }).places))))) : null,
        ((_h = data === null || data === void 0 ? void 0 : data.places) === null || _h === void 0 ? void 0 : _h.__typename) === 'AppError' && (React.createElement(React.Fragment, null,
            React.createElement(Alert, { severity: "error", sx: { maxWidth: 512 } },
                React.createElement(Box, null, t('airportSelect.notFound'))))),
        ((_j = data === null || data === void 0 ? void 0 : data.places) === null || _j === void 0 ? void 0 : _j.__typename) === 'PlaceConnection' &&
            ((_k = data.places.edges) === null || _k === void 0 ? void 0 : _k.reduce(function (result, placeEdge, placeIndex) {
                var _a, _b, _c;
                var index = result.indexCounter++;
                result.places.push(React.createElement(AirportOptionItem, { clickHandler: function () {
                        var _a;
                        if (!limitReached) {
                            handleChange(__spreadArray(__spreadArray([], value, true), [
                                {
                                    name: ((placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.__typename) === 'Station'
                                        ? (_a = placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.city) === null || _a === void 0 ? void 0 : _a.name
                                        : placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.name),
                                    code: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.code,
                                },
                            ], false));
                        }
                    }, closeDropdown: closeDropdown, purgeInputValue: purgeInputValue, name: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.name, code: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.code, 
                    /* @ts-ignore */
                    city: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.city, country: 
                    /* @ts-ignore */
                    ((placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.country) ||
                        (
                        /* @ts-ignore */
                        (_a = placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.city) === null || _a === void 0 ? void 0 : _a.country)), type: placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.__typename, isMain: true }));
                (placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.__typename) === 'City' &&
                    ((_c = (_b = placeEdge.node.stations) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.forEach(function (station, si) {
                        var _a;
                        var index = result.indexCounter++;
                        result.places.push(React.createElement(AirportOptionItem, { clickHandler: function () {
                                var _a;
                                if (!limitReached) {
                                    handleChange(__spreadArray(__spreadArray([], value, true), [
                                        {
                                            name: ((((_a = station === null || station === void 0 ? void 0 : station.node.city) === null || _a === void 0 ? void 0 : _a.name) ||
                                                (placeEdge === null || placeEdge === void 0 ? void 0 : placeEdge.node.name)) +
                                                ' ' +
                                                (station === null || station === void 0 ? void 0 : station.node.code)),
                                            code: station === null || station === void 0 ? void 0 : station.node.code,
                                        },
                                    ], false));
                                }
                            }, closeDropdown: closeDropdown, purgeInputValue: purgeInputValue, name: station === null || station === void 0 ? void 0 : station.node.name, code: station === null || station === void 0 ? void 0 : station.node.code, city: station === null || station === void 0 ? void 0 : station.node.city, country: (_a = station === null || station === void 0 ? void 0 : station.node.city) === null || _a === void 0 ? void 0 : _a.country, type: station === null || station === void 0 ? void 0 : station.node.type }));
                    }));
                return result;
            }, { places: [], indexCounter: 0 }).places)));
    var mouseDownRef = useRef(false);
    return (React.createElement("div", { ref: wrapperRef, style: { position: 'relative', flex: 1 } },
        React.createElement(AirportInput, { label: label, value: value, wide: wide, flightError: flightError, onChange: handleChange, autoFocus: autoFocus, inputText: inputValue, onInputTextChange: setInputValue, placeholder: value.length > 1 ? '' : placeholder, type: type, onMouseDown: function (e) {
                mouseDownRef.current = true;
            }, onFocus: onFocus ||
                (function () {
                    var _a;
                    if (!isOpen) {
                        // @ts-ignore
                        ((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.blur) && document.activeElement.blur();
                        setAnchorEl(wrapperRef.current);
                        setIsOpen(true);
                    }
                }) }),
        React.createElement(Stack, { sx: {
                position: { xs: 'relative', md: 'absolute' },
                fontSize: 10,
                color: 'red',
            } }, errorText),
        isMobile ? (React.createElement(SwipeableDrawer, { anchor: "bottom", open: isOpen, onOpen: function () { return setIsOpen(true); }, onClose: function () {
                purgeInputValue();
                setIsOpen(false);
            } },
            React.createElement(Stack, { sx: { height: 'calc(100vh - 100px)' } },
                React.createElement(Stack, { justifyContent: "space-between", alignItems: "center", sx: {
                        backgroundColor: 'primary.dark',
                        height: 70,
                    } },
                    React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", sx: { width: '100%', padding: '16px 16px' } },
                        React.createElement(Typography, { sx: {
                                fontWeight: 600,
                                fontSize: '1em',
                                color: 'common.white',
                            } }, type === 'arrival'
                            ? t('airportSelect.selectReturn')
                            : t('airportSelect.selectDeparture')),
                        React.createElement(Clear, { viewBox: "-12 -7 24 24", onClick: function () {
                                purgeInputValue();
                                setIsOpen(false);
                            }, sx: { color: 'common.white' } })),
                    React.createElement(Box, { sx: {
                            width: '100%',
                            backgroundColor: 'common.white',
                            height: '35px',
                            borderRadius: '12px 12px 0 0',
                            display: 'flex',
                            justifyContent: 'center',
                        } },
                        React.createElement("div", { style: {
                                width: '30px',
                                height: '4px',
                                backgroundColor: 'grey',
                                marginTop: '8px',
                                borderRadius: 21,
                            } }))),
                React.createElement(AirportInput, { label: label, value: value, onChange: handleChange, isOpen: isOpen, autoFocus: true, inputText: inputValue, onInputTextChange: setInputValue, placeholder: value.length > 1 ? '' : placeholder }),
                React.createElement(Box, { sx: {
                        padding: '0 0 0 24px',
                        margin: '-8px 0 0 0',
                        fontSize: '12px',
                        color: 'text.secondary',
                    } }, t('airportSelect.max.3.destinations')),
                list,
                React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", sx: {
                        position: 'fixed',
                        bottom: '0.5rem',
                        width: '100%',
                        backgroundColor: 'common.white',
                    } },
                    React.createElement(Button, { sx: {
                            p: 1,
                            mx: 0.5,
                            borderRadius: '3px',
                            backgroundColor: '#f2f2f2',
                            color: 'text.primary',
                            width: '100%',
                            height: 46,
                        }, onClick: function () {
                            handleChange(prevValue);
                            closeDropdown();
                            purgeInputValue();
                        } }, t('common.cancel')),
                    React.createElement(Button, { sx: {
                            p: 1,
                            mx: 0.5,
                            borderRadius: '3px',
                            backgroundColor: 'primary.main',
                            color: 'common.white',
                            width: '100%',
                            height: 46,
                        }, onClick: function () {
                            purgeInputValue();
                            closeDropdown();
                        } }, t('common.apply')))))) : (React.createElement(Popper, { anchorEl: anchorEl, placement: "bottom-start", popperOptions: { modifiers: [{ name: 'flip', enabled: false }] }, open: isOpen, style: {
                zIndex: 1001,
            } }, isOpen ? (React.createElement(ClickAwayListener, { onClickAway: function (e) {
                if (mouseDownRef.current) {
                    mouseDownRef.current = false;
                }
                else {
                    setAnchorEl(null);
                    setIsOpen(false);
                    purgeInputValue();
                }
            } },
            React.createElement(Paper, { sx: {
                    p: 1,
                    top: -57,
                    position: 'relative',
                    left: -9,
                    border: '1px solid #1976D2',
                    boxShadow: '0px 40px 30px -30px rgba(0, 0, 0, 0.1)',
                    borderRadius: '4px',
                } },
                React.createElement(Stack, null,
                    React.createElement(AirportInput, { label: label, isOpen: isOpen, value: value, wide: wide, onChange: handleChange, autoFocus: true, inputText: inputValue, onInputTextChange: setInputValue, placeholder: value.length > 1 ? '' : placeholder }),
                    React.createElement(Box, { sx: {
                            padding: '0 0 0 16px',
                            fontSize: '12px',
                            color: 'text.secondary',
                        } }, t('airportSelect.max.3.destinations')),
                    list)))) : null))));
};
export default AirportSelect;
