import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useMediaQuery } from '@material-ui/core';
var FlightCardSkeleton = function (_a) {
    var noPrice = _a.noPrice;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    return (React.createElement(Stack, { direction: 'row', width: isMobile ? 'calc(100% - 32px)' : '100%', mx: isMobile ? 2 : 0, height: isMobile ? '156px' : '189px', mt: 2, sx: {
            boxShadow: '0px 2px 10px rgba(25, 1, 52, 0.12)',
            borderRadius: '4px',
        } }, !isMobile ? (React.createElement(React.Fragment, null,
        React.createElement(Stack, { width: noPrice ? '100%' : '70%', sx: {
                borderRight: noPrice ? 'none' : '1px solid rgb(227, 242, 253)',
            } },
            React.createElement(Stack, { direction: 'row', sx: {
                    justifyContent: 'space-between',
                    borderBottom: '1px solid rgb(227, 242, 253)',
                    padding: '12px',
                } },
                React.createElement(Skeleton, { variant: "rectangular", width: '30%' }),
                React.createElement(Skeleton, { variant: "rectangular", width: '30%' })),
            React.createElement(Stack, { direction: 'row', height: '100%', sx: { alignItems: 'center', padding: '12px' } },
                React.createElement(Skeleton, { variant: "rectangular", width: '100%', height: '68px' }))),
        !noPrice && (React.createElement(Stack, { width: '30%', sx: { padding: '16px' } },
            React.createElement(Skeleton, { variant: "rectangular", width: '100%', height: '100%' }))))) : (React.createElement(Stack, { width: '100%', height: '100%' },
        React.createElement(Stack, { width: '100%', height: '100%', sx: {
                padding: '12px 8px',
                borderBottom: '1px solid rgb(227, 242, 253)',
            } },
            React.createElement(Skeleton, { variant: "rectangular", width: '100%', height: '100%' })),
        React.createElement(Stack, { width: '100%', height: '100%', sx: { padding: '12px 8px' } },
            React.createElement(Skeleton, { variant: "rectangular", width: '100%', height: '100%' }))))));
};
export default FlightCardSkeleton;
