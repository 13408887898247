var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { Box } from '@material-ui/core';
import useTranslation from 'app/hooks/useTranslation';
import cardFront from 'app/assets/illustrations/bank-card-front.svg';
import cardBack from 'app/assets/illustrations/bank-card-back.svg';
var CardContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  perspective: 1000px;\n  transform-style: preserve-3d;\n  transition: transform 0.6s;\n\n  ", ";\n"], ["\n  width: 100%;\n  perspective: 1000px;\n  transform-style: preserve-3d;\n  transition: transform 0.6s;\n\n  ", ";\n"])), function (_a) {
    var reversed = _a.reversed;
    return reversed && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      transform: rotateY(180deg);\n    "], ["\n      transform: rotateY(180deg);\n    "])));
});
var BankCard = function (_a) {
    var watchCardValues = _a.watchCardValues, reversed = _a.reversed;
    var t = useTranslation();
    return (React.createElement(CardContainer, { reversed: reversed }, reversed ? (React.createElement(Box, { sx: {
            backfaceVisibility: 'hidden',
            transformStyle: 'preserve-3d',
            transition: 'transform 0.6s',
            transform: reversed ? 'rotateY(180deg)' : 'rotateY(0deg)',
        } },
        React.createElement(Box, { component: 'img', src: cardBack, sx: { width: '100%' } }),
        React.createElement(Box, { sx: {
                position: 'absolute',
                top: '50%',
                right: { xs: '7%', md: '32px' },
                transform: {
                    xs: 'translate(0, calc(-50% - 36%))',
                    md: 'translate(0, calc(-50% - 12px))',
                },
                transformStyle: 'preserve-3d',
                fontSize: { xs: '12px', md: 'initial' },
            } }, watchCardValues && watchCardValues.cardCvv
            ? watchCardValues.cardCvv
            : '001'))) : (React.createElement(Box, { sx: {
            position: 'relative',
        } },
        React.createElement(Box, { component: 'img', src: cardFront, sx: { width: '100%' } }),
        React.createElement(Box, { sx: {
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '100%',
                color: '#fff',
                padding: { xs: '16px 26px', md: '24px 32px' },
            } },
            React.createElement(Box, { sx: { margin: { xs: '0 0 6px 0', md: '0 0 18px 0' } } },
                React.createElement(Box, { sx: {
                        letterSpacing: '1px',
                        fontSize: '12px',
                        lineHeight: { xs: '1.2', md: '1.4' },
                        opacity: '0.6',
                    } }, t('bankcard.placeholder.number')),
                React.createElement(Box, { sx: {
                        fontWeight: { xs: '400', md: '600' },
                        fontSize: { xs: '14px', md: '20px' },
                        letterSpacing: '2px',
                    } }, watchCardValues && watchCardValues.cardNumber
                    ? watchCardValues.cardNumber
                        .replace(/\W/gi, '')
                        .replace(/(.{4})/g, '$1 ')
                    : '0000 0000 0000 0000')),
            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                React.createElement(Box, { sx: { maxWidth: '70%' } },
                    React.createElement(Box, { sx: {
                            letterSpacing: '1px',
                            fontSize: '12px',
                            lineHeight: { xs: '1.2', md: '1.4' },
                            opacity: '0.6',
                        } }, t('bankcard.placeholder.cardholder')),
                    React.createElement(Box, { sx: {
                            fontSize: { xs: '12px', md: '16px' },
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        } }, watchCardValues && watchCardValues.cardHolder
                        ? watchCardValues.cardHolder
                        : t('bankcard.placeholder.name'))),
                React.createElement(Box, null,
                    React.createElement(Box, { sx: {
                            letterSpacing: '1px',
                            fontSize: '12px',
                            lineHeight: { xs: '1.2', md: '1.4' },
                            opacity: '0.6',
                        } }, t('bankcard.placeholder.valid')),
                    React.createElement(Box, { sx: { fontSize: { xs: '12px', md: '16px' } } },
                        watchCardValues && watchCardValues.cardExpireMonth
                            ? watchCardValues.cardExpireMonth
                            : '01',
                        "/",
                        watchCardValues && watchCardValues.cardExpireYear
                            ? watchCardValues.cardExpireYear
                            : '24'))))))));
};
export default BankCard;
var templateObject_1, templateObject_2;
