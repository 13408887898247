var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var Trolley = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_15390_64178)" },
            React.createElement("path", { d: "M13.2502 5.49967V5.99967H13.7502H15.5835C16.3157 5.99967 16.9168 6.60082 16.9168 7.33301V17.4163C16.9168 18.1485 16.3157 18.7497 15.5835 18.7497H15.0835V19.2497C15.0835 19.4777 14.8949 19.6663 14.6668 19.6663C14.4388 19.6663 14.2502 19.4777 14.2502 19.2497V18.7497H13.7502H8.25016H7.75016V19.2497C7.75016 19.4777 7.56152 19.6663 7.3335 19.6663C7.10547 19.6663 6.91683 19.4777 6.91683 19.2497V18.7497H6.41683C5.68464 18.7497 5.0835 18.1485 5.0835 17.4163V7.33301C5.0835 6.60082 5.68464 5.99967 6.41683 5.99967H8.25016H8.75016V5.49967V2.74967C8.75016 2.52165 8.93881 2.33301 9.16683 2.33301H12.8335C13.0615 2.33301 13.2502 2.52165 13.2502 2.74967V5.49967Z", stroke: "#002171" }),
            React.createElement("path", { d: "M13.75 5.95801H8.25", stroke: "#002171" }),
            React.createElement("path", { d: "M8.25 16.5L8.25 8.25", stroke: "#002171", strokeLinecap: "round" }),
            React.createElement("path", { d: "M11 16.5L11 8.25", stroke: "#002171", strokeLinecap: "round" }),
            React.createElement("path", { d: "M13.75 16.5L13.75 8.25", stroke: "#002171", strokeLinecap: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_15390_64178" },
                React.createElement("rect", { width: "22", height: "22", fill: "white" })))))); };
export default Trolley;
