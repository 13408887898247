import React from 'react';
import { Stack, SwipeableDrawer, Typography, useTheme, } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Box } from '@material-ui/system';
export var MobileDrawer = function (_a) {
    var onClose = _a.onClose, open = _a.open, title = _a.title, backdropOpacity = _a.backdropOpacity, children = _a.children, _b = _a.height, height = _b === void 0 ? 'calc(100vh - 100px)' : _b;
    var theme = useTheme();
    return (React.createElement(React.Fragment, null,
        React.createElement(SwipeableDrawer, { open: open, anchor: "bottom", onOpen: function () { }, onClose: onClose, PaperProps: {
                sx: {
                    backgroundColor: 'transparent',
                    boxShadow: '0px -4px 10px rgba(1, 21, 52, 0.16)',
                    height: height,
                },
            }, BackdropProps: {
                sx: {
                    opacity: backdropOpacity ? backdropOpacity + "!important" : 1,
                },
            } },
            React.createElement(Stack, { sx: {
                    height: '100%',
                    backgroundColor: 'primary.dark',
                } },
                title && (React.createElement(React.Fragment, null,
                    React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", sx: {
                            padding: '16px',
                        } },
                        React.createElement(Typography, { sx: {
                                fontWeight: 600,
                                fontSize: '1em',
                                color: 'common.white',
                            } }, title),
                        React.createElement(Clear, { onClick: onClose, sx: { color: 'common.white' } })))),
                React.createElement(Box, { sx: {
                        width: '100%',
                        backgroundColor: 'common.white',
                        height: '35px',
                        borderRadius: '12px 12px 0 0',
                        display: 'flex',
                        justifyContent: 'center',
                    } },
                    React.createElement("div", { style: {
                            width: '30px',
                            height: '4px',
                            backgroundColor: 'grey',
                            marginTop: '8px',
                            borderRadius: 21,
                        } })),
                React.createElement(Stack, { height: 1, sx: {
                        maxHeight: '100%',
                        overflowY: 'scroll',
                        padding: '0 8px 8px',
                        backgroundColor: 'common.white',
                    } }, children)))));
};
