import { Card, Stack, Typography, useMediaQuery, Button, Link, } from '@material-ui/core';
import useTranslation, { useLocaleId } from 'app/hooks/useTranslation';
import React from 'react';
import Backpack from '../../assets/icons/Backpack';
import Briefcase from '../../assets/icons/Briefcase';
import Check from '../../assets/icons/Check';
import Close from '../../assets/icons/Close';
import Trolley from '../../assets/icons/Trolley';
var BookingBaggage = function (_a) {
    var baggageInformation = _a.baggageInformation;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var iconMap = {
        personal_item: React.createElement(Backpack, null),
        cabin_bag: React.createElement(Trolley, null),
        hold_baggage: React.createElement(Briefcase, null),
    };
    var handleScroll = function () {
        var passenger = document.querySelector('.passenger');
        if (passenger) {
            return passenger.scrollIntoView({ behavior: 'smooth' });
        }
    };
    var t = useTranslation();
    var locale = useLocaleId();
    return (React.createElement(Stack, null,
        React.createElement(Card, { sx: { my: 4, px: 3, py: 4, mx: { xs: 2, md: 0 } } },
            React.createElement(Typography, { variant: "h5", color: "primary.main", sx: {
                    fontWeight: 400,
                    lineHeight: '26px',
                    color: function (theme) { return theme.palette.primary.dark; },
                } },
                t('importantBaggageInfo.title'),
                ":"),
            React.createElement(Typography, { sx: {
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: function (theme) { return theme.palette.text.disabled; },
                } }, t('importantBaggageInfo.description')),
            baggageInformation.map(function (binfo, index) { return (React.createElement(Stack, { spacing: 1, key: index, sx: { mt: '20px' } },
                binfo.route && (React.createElement(Typography, { color: "primary.dark", sx: { my: 1, fontWeight: 600 } }, binfo.route.map(function (routeItem) { return (React.createElement(React.Fragment, null, routeItem.map(function (item, index) { return (React.createElement(React.Fragment, null,
                    item,
                    index !== routeItem.length - 1 && ' - ')); }))); }))),
                binfo.free && (React.createElement(Stack, null, binfo.free.map(function (baggageGroup, key) { return (React.createElement(Stack, { direction: isMobile ? 'column' : 'row', sx: { color: function (theme) { return theme.palette.primary.dark; } }, key: key },
                    React.createElement(Stack, { direction: "row", width: '180px' },
                        iconMap[baggageGroup === null || baggageGroup === void 0 ? void 0 : baggageGroup.type],
                        React.createElement(Typography, { fontSize: "14px", ml: 0.5 }, t("booking." + (baggageGroup === null || baggageGroup === void 0 ? void 0 : baggageGroup.type)))),
                    React.createElement(Stack, { direction: "row", sx: {
                            width: { xs: 'initial', md: 'calc(100% - 180px)' },
                            marginLeft: { xs: 3, md: 0 },
                        } },
                        React.createElement(Check, { viewBox: "-4 -4 19 19", sx: { mr: 1 } }),
                        React.createElement(Typography, { fontSize: "14px" }, t('importantBaggageInfo.free'))))); }))),
                binfo.paid && (React.createElement(Stack, { spacing: 1, sx: { color: 'primary.dark' } }, binfo.paid.map(function (baggageGroup, key) { return (React.createElement(Stack, { direction: isMobile ? 'column' : 'row', key: key },
                    React.createElement(Stack, { direction: "row", width: '180px' },
                        iconMap[baggageGroup === null || baggageGroup === void 0 ? void 0 : baggageGroup.type],
                        React.createElement(Typography, { fontSize: "14px", ml: 0.5 }, t("booking." + (baggageGroup === null || baggageGroup === void 0 ? void 0 : baggageGroup.type)))),
                    React.createElement(Stack, { direction: "row", sx: {
                            alignItems: 'flex-start',
                            width: { xs: 'initial', md: 'calc(100% - 180px)' },
                            marginLeft: { xs: 3, md: 0 },
                        } },
                        React.createElement(Close, { viewBox: "-4 -4 19 19", stroke: "red", sx: { mr: 1 } }),
                        React.createElement(Typography, { fontSize: "14px" },
                            t('importantBaggageInfo.notIncluded'),
                            ","),
                        React.createElement(Button, { sx: { padding: '4px 8px' }, onClick: function (e) { return handleScroll(); } }, t('importantBaggageInfo.viewOptions'))))); }))),
                binfo.forbidden && (React.createElement(Stack, { sx: { color: 'primary.dark' } }, binfo.forbidden.map(function (baggageGroup, key) { return (React.createElement(Stack, { direction: isMobile ? 'column' : 'row', key: key },
                    React.createElement(Stack, { direction: "row", width: '180px' },
                        iconMap[baggageGroup === null || baggageGroup === void 0 ? void 0 : baggageGroup.type],
                        React.createElement(Typography, { fontSize: "14px", ml: 0.5 }, t("booking." + (baggageGroup === null || baggageGroup === void 0 ? void 0 : baggageGroup.type)))),
                    React.createElement(Stack, { direction: "row", sx: {
                            width: { xs: 'initial', md: 'calc(100% - 180px)' },
                            marginLeft: { xs: 3, md: 0 },
                        } },
                        React.createElement(Close, { viewBox: "-4 -4 19 19", stroke: "red", sx: { mr: 1 } }),
                        React.createElement(Stack, { direction: "row", sx: { display: 'inline' } },
                            React.createElement(Typography, { fontSize: "14px", sx: { display: 'inline' } },
                                t('importantBaggageInfo.forbiddenString'),
                                ' '),
                            React.createElement(Link, { sx: { ml: 0.5, display: 'inline', fontSize: '14px' }, href: "/" + locale + "/forbiddenBaggage", target: "_blank" }, t('importantBaggageInfo.viewDetails')))))); }))))); }))));
};
export default BookingBaggage;
