var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Slider from 'react-slick';
import styled from '@emotion/styled';
import first from './assets/1.svg';
import second from './assets/2.svg';
import third from './assets/3.svg';
import dashedLine from './assets/dashed-line.svg';
import { configSiteName } from '../../util/siteConfig';
import useTranslation from '../../hooks/useTranslation';
var StyledSlider = styled(Slider)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 48px 0 0 0;\n  width: 100%;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-position: center 10%;\n  .slick-dots {\n    bottom: -36px;\n    li {\n      margin: 0;\n      button:before {\n        font-size: 12px;\n        color: #1976d2;\n      }\n    }\n  }\n  @media (max-width: 768px) {\n    width: calc(100% + 32px);\n    margin: 48px -16px 0;\n  }\n"], ["\n  margin: 48px 0 0 0;\n  width: 100%;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-position: center 10%;\n  .slick-dots {\n    bottom: -36px;\n    li {\n      margin: 0;\n      button:before {\n        font-size: 12px;\n        color: #1976d2;\n      }\n    }\n  }\n  @media (max-width: 768px) {\n    width: calc(100% + 32px);\n    margin: 48px -16px 0;\n  }\n"])), dashedLine);
var SliderBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  padding: 0 42px;\n  @media (max-width: 1024px) {\n    padding: 0 16px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  padding: 0 42px;\n  @media (max-width: 1024px) {\n    padding: 0 16px;\n  }\n"])));
var WelcomeSection = function () {
    var t = useTranslation();
    var data = [
        {
            img: first,
            title: t('simpleAndFast.list1.title'),
            desc: t('simpleAndFast.list1.desc', { configSiteName: configSiteName }),
        },
        {
            img: second,
            title: t('simpleAndFast.list2.title'),
            desc: t('simpleAndFast.list2.desc'),
        },
        {
            img: third,
            title: t('simpleAndFast.list3.title'),
            desc: t('simpleAndFast.list3.desc'),
        },
    ];
    var sliderSettings = {
        dots: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (React.createElement(Box, { sx: {
            padding: {
                xs: '64px 16px',
                md: '80px 16px',
            },
        } },
        React.createElement(Typography, { variant: "h2", sx: {
                margin: '0 0 8px 0',
                textAlign: 'center',
                color: function (theme) { return theme.palette.primary.dark; },
            } }, t('simpleAndFast.title')),
        React.createElement(Typography, { sx: {
                fontSize: '16px',
                color: '#828282',
                textAlign: 'center',
            } }, t('simpleAndFast.subtitle')),
        React.createElement(StyledSlider, __assign({}, sliderSettings), data.map(function (item, key) { return (React.createElement(SliderBox, { key: key },
            React.createElement(Box, { component: 'img', src: item.img, sx: {
                    width: { xs: '160px', md: 'auto' },
                    display: 'inline-block',
                    margin: '0 auto 16px',
                }, alt: configSiteName }),
            React.createElement(Typography, { sx: {
                    fontWeight: 600,
                    fontSize: '20px',
                    lineHeight: '28px',
                    margin: '0 0 8px 0',
                    color: function (theme) { return theme.palette.primary.dark; },
                } }, item.title),
            React.createElement(Typography, { sx: {
                    fontSize: '14px',
                    lineHeight: '160%',
                    color: '#828282',
                } }, item.desc))); }))));
};
export default WelcomeSection;
var templateObject_1, templateObject_2;
