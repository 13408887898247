var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Card, Checkbox, Dialog, FormControlLabel, Grid, Stack, TextField, Typography, } from '@material-ui/core';
import styled from '@emotion/styled';
import { Box } from '@material-ui/system';
import { useNoEmailLazyQuery } from 'app/generated/graphql';
import { AuthContext } from 'app/hooks/useAuth';
import useTranslation from 'app/hooks/useTranslation';
import React, { useContext, useEffect, useState } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Controller, useFormContext } from 'react-hook-form';
import SocialLogin from '../User/SocialLogin/SocialLogin';
import { configPhone, configPhoneMask } from 'app/util/siteConfig';
import chevron from '../../assets/illustrations/chevron-up-black.svg';
var ContactForm = function (_a) {
    var bookingId = _a.bookingId;
    var control = useFormContext().control;
    var _b = useState('md'), countryCode = _b[0], setCountryCode = _b[1];
    var t = useTranslation();
    var authData = useContext(AuthContext).authData;
    var _c = useState(false), isNoEmailChecked = _c[0], setIsNoEmailChecked = _c[1];
    var _d = useState(false), isLoginOpen = _d[0], setIsLoginOpen = _d[1];
    var _e = useNoEmailLazyQuery({
        fetchPolicy: 'network-only',
        variables: { id: bookingId, check: isNoEmailChecked },
    }), noEmailQuery = _e[0], noEmailResult = _e[1];
    useEffect(function () {
        fetch('https://ipapi.co/json/')
            .then(function (res) { return res.json(); })
            .then(function (response) {
            setCountryCode(response.country_code);
        })
            .catch(function (data) {
            console.error('Request failed: ', data);
        });
    }, []);
    var handleNoEmailChange = function (event) {
        setIsNoEmailChecked(event.target.checked);
        noEmailQuery();
        if (event.target.checked) {
            setIsLoginOpen(true);
        }
    };
    var StyledMuiPhoneNumber = styled(MuiPhoneNumber)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .MuiPhoneNumber-flagButton {\n      width: 36px;\n      height: 16px;\n      margin: 12px 0 0 -4px;\n      svg {\n        width: 50%;\n      }\n      &:before {\n        content: '';\n        width: 50%;\n        height: 100%;\n        background: url(", ");\n        background-size: 100%;\n        background-position: left center;\n      }\n    }\n  "], ["\n    .MuiPhoneNumber-flagButton {\n      width: 36px;\n      height: 16px;\n      margin: 12px 0 0 -4px;\n      svg {\n        width: 50%;\n      }\n      &:before {\n        content: '';\n        width: 50%;\n        height: 100%;\n        background: url(", ");\n        background-size: 100%;\n        background-position: left center;\n      }\n    }\n  "])), chevron);
    return (React.createElement(Card, { sx: {
            mx: { lg: 'unset', xs: 2 },
            mt: '1.25rem',
            p: 2,
        } },
        React.createElement(Stack, { direction: "row", mt: 1, mb: 1.25 },
            React.createElement(Typography, { variant: "body2", sx: { color: 'primary.dark' } }, t('booking.contactInfo'))),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { md: 6, xs: 12, item: true },
                React.createElement(Controller, { name: "form.customer.email", control: control, rules: {
                        required: t('formErrors.requiredField'),
                    }, render: function (_a) {
                        var _b, _c;
                        var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                        return (React.createElement(TextField, __assign({}, field, { value: field.value, disabled: formState.isSubmitting || isNoEmailChecked, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "email", variant: "outlined", label: "Email" })));
                    } }),
                !authData.user && (React.createElement(FormControlLabel, { label: React.createElement(Typography, { fontSize: '12px', lineHeight: '16px', color: '#808080' }, t('noEmail.label')), control: React.createElement(Checkbox, { checked: isNoEmailChecked, onChange: handleNoEmailChange }) }))),
            React.createElement(Grid, { md: 6, xs: 12, item: true },
                React.createElement(Controller, { name: "form.customer.phone", control: control, rules: {
                        required: t('formErrors.requiredField'),
                    }, render: function (_a) {
                        var _b, _c;
                        var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                        return (React.createElement(StyledMuiPhoneNumber, __assign({}, field, { value: field.value, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, InputLabelProps: { shrink: false }, sx: { width: '100%' }, variant: "outlined", label: t('form.phone'), type: "tel", defaultCountry: countryCode.toLowerCase(), preferredCountries: ['it', 'fr', 'ro', 'md', 'gb', 'ie'], autoFormat: false })));
                    } }))),
        React.createElement(Dialog, { open: isLoginOpen, onClose: function () { return setIsLoginOpen(false); } },
            React.createElement(Stack, { sx: { p: 2, display: 'inline' }, justifyContent: "center", alignItems: "center" },
                t('noEmail.string'),
                React.createElement("br", null),
                React.createElement("a", { style: { display: 'inline' }, href: "tel:" + configPhone }, configPhoneMask),
                React.createElement(Box, { mt: 2 },
                    React.createElement(SocialLogin, null))))));
};
export default ContactForm;
var templateObject_1;
