var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ClickAwayListener, InputAdornment, Popper, Stack, SwipeableDrawer, TextField, useMediaQuery, useTheme, Typography, Box, } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { capitalize, range } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import MaskedInput from 'react-text-mask';
import CalendarToday from '../../assets/icons/CalendarToday';
import useTranslation, { useDateFormat, useLocaleId, } from '../../hooks/useTranslation';
function TextMaskCustom(props) {
    var t = useTranslation();
    var inputRef = props.inputRef, other = __rest(props, ["inputRef"]);
    return (React.createElement(MaskedInput, __assign({}, other, { guide: false, type: "tel", placeholder: t('placeholder.date'), mask: [
            /[0-3]/,
            /[0-9]/,
            '.',
            /[0-1]/,
            /[0-9]/,
            '.',
            /[1-2]/,
            /[0|9]/,
            /[0-9]/,
            /[0-9]/,
        ], placeholderChar: 'x', showMask: true })));
}
var Picker = function (props) {
    var t = useTranslation();
    var _a = (props.value || '').split('.'), initialDay = _a[0], initialMonth = _a[1], initialYear = _a[2];
    var locale = useLocaleId();
    var _b = useState(0), slide = _b[0], setSlide = _b[1];
    var days = range(1, 32).map(function (n) { return n.toString().padStart(2, '0'); });
    var months = range(1, 13).map(function (n) { return n.toString().padStart(2, '0'); });
    var format = useDateFormat();
    var years = useMemo(function () {
        return range(props.minYear || 1900, props.maxYear ? props.maxYear + 1 : 2100).map(function (n) { return n.toString(); });
    }, []);
    if (props.reverseYears) {
        years = years.reverse();
    }
    var monthNames = useMemo(function () {
        return months.reduce(function (acc, val, i) {
            var date = new Date();
            date.setDate(1);
            date.setMonth(i);
            acc[val] = capitalize(format(date, 'MMMM'));
            return acc;
        }, {});
    }, []);
    var _c = useState(initialDay), day = _c[0], setDay = _c[1];
    var _d = useState(initialMonth), month = _d[0], setMonth = _d[1];
    var _e = useState(initialYear), year = _e[0], setYear = _e[1];
    var handleChangeDay = function (e) {
        setDay(e.target.getAttribute('data-value'));
        props.onChange(e.target.getAttribute('data-value'), month, year);
        setSlide(1);
    };
    var handleChangeMonth = function (e) {
        setMonth(e.target.getAttribute('data-value'));
        props.onChange(day, e.target.getAttribute('data-value'), year);
        setSlide(2);
    };
    var handleChangeYear = function (e) {
        setYear(e.target.getAttribute('data-value'));
        props.onChange(day, month, e.target.getAttribute('data-value'));
        props.onClose();
    };
    var titleMap = [
        t('common.choose_the_day'),
        t('common.choose_the_month'),
        t('common.choose_the_year'),
    ];
    return (React.createElement(Stack, { alignItems: "center", sx: {
            my: 1,
            justifyContent: { xs: 'flex-start', md: 'center' },
            backgroundColor: 'background.paper',
            borderBottom: { xs: 'none', md: '1px solid' },
            width: { xs: '100%', md: 400 },
            height: { xs: '100%', md: 290 },
            borderRadius: '4px',
            zIndex: 1500,
            overflow: 'auto',
        } },
        React.createElement(Stack, { justifyContent: "center", alignItems: "center", sx: { pt: 2, fontSize: 12, color: '#828282' } }, titleMap[slide]),
        React.createElement(Stack, { direction: "row", sx: {
                flexWrap: 'wrap',
                maxWidth: slide === 2 ? 300 : 255,
                overflowY: slide === 0 ? 'hidden' : 'auto',
            } },
            slide === 0
                ? days.map(function (d) { return (React.createElement(Stack, { key: d, onClick: handleChangeDay, justifyContent: "center", alignItems: "center", sx: {
                        width: 25,
                        height: 25,
                        fontSize: '0.9rem',
                        m: 1,
                        borderRadius: '4px',
                        cursor: 'pointer',
                        color: d === initialDay ? 'common.white' : 'text.primary',
                        backgroundColor: d === initialDay ? 'primary.main' : 'inherit',
                    }, "data-value": d }, d)); })
                : null,
            slide === 1
                ? months.map(function (m) { return (React.createElement(Stack, { key: m, justifyContent: "center", alignItems: "center", onClick: handleChangeMonth, sx: {
                        color: m === initialMonth ? 'common.white' : 'text.primary',
                        backgroundColor: m === initialMonth ? 'primary.main' : 'inherit',
                        width: 110,
                        height: 36,
                        display: 'flex',
                        borderRadius: '4px',
                        m: 1,
                        textAlign: 'center',
                        cursor: 'pointer',
                    }, "data-value": m }, monthNames[m])); })
                : null,
            slide === 2
                ? years.map(function (y) { return (React.createElement(Stack, { key: y, justifyContent: "center", alignItems: "center", onClick: handleChangeYear, sx: {
                        color: y === initialYear ? 'common.white' : 'text.primary',
                        backgroundColor: y === initialYear ? 'primary.main' : 'inherit',
                        width: '60px',
                        height: '36px',
                        fontSize: 14,
                        borderRadius: '4px',
                        lineHeight: '16px',
                        my: 1,
                        textAlign: 'center',
                        cursor: 'pointer',
                    }, "data-value": y }, y)); })
                : null)));
};
export var MaskedDateField = function (props) {
    var t = useTranslation();
    var onPickerChange = useCallback(function (day, month, year) {
        props.onChange({ target: { value: [day, month, year].join('.') } });
    }, [props.onChange]);
    var theme = useTheme();
    var smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var _a = React.useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var _b = useState(false), showPicker = _b[0], setShowPicker = _b[1];
    var _c = useState(false), focusedInput = _c[0], setFocusedInput = _c[1];
    var handlePopoverOpen = function (event) {
        setAnchorEl(!anchorEl ? event.currentTarget : null);
        if (smallScreen) {
            setShowPicker(true);
        }
    };
    var handlePopoverClose = function () {
        setShowPicker(false);
        setAnchorEl(null);
    };
    var handlePopoverClick = function (event) {
        event.nativeEvent.stopImmediatePropagation();
        event.stopPropagation();
    };
    var isOpen = Boolean(anchorEl);
    return smallScreen ? (React.createElement(React.Fragment, null,
        React.createElement(TextField, __assign({}, props, { error: !!props.error, helperText: props.helperText, fullWidth: true, variant: "outlined", InputLabelProps: { shrink: true }, InputProps: {
                notched: false,
                inputComponent: TextMaskCustom,
                endAdornment: (React.createElement(InputAdornment, { position: "end", onClick: handlePopoverOpen, sx: { cursor: 'pointer' } },
                    React.createElement(CalendarToday, null))),
            } })),
        React.createElement(SwipeableDrawer, { onClick: handlePopoverClick, onOpen: function () { }, onClose: handlePopoverClose, open: isOpen, style: { zIndex: 2000 }, anchor: "bottom" },
            React.createElement(Stack, { sx: { height: 'calc(100vh - 100px)' } },
                React.createElement(Stack, { justifyContent: "space-between", alignItems: "center", sx: {
                        backgroundColor: 'primary.dark',
                    } },
                    React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", sx: { width: '100%', padding: '16px 16px' } },
                        React.createElement(Typography, { sx: {
                                maxWidth: 'calc(100% - 48px)',
                                fontWeight: 600,
                                fontSize: '1em',
                                color: 'common.white',
                            } }, t('common.selectTheDate')),
                        React.createElement(Clear, { onClick: function () { return handlePopoverClose(); }, sx: { color: 'common.white' } })),
                    React.createElement(Box, { sx: {
                            width: '100%',
                            backgroundColor: 'common.white',
                            height: '35px',
                            borderRadius: '12px 12px 0 0',
                            display: 'flex',
                            justifyContent: 'center',
                        } },
                        React.createElement("div", { style: {
                                width: '30px',
                                height: '4px',
                                backgroundColor: 'grey',
                                marginTop: '8px',
                                borderRadius: 21,
                            } }))),
                React.createElement(TextField, __assign({}, props, { sx: { mx: 1, px: 1 }, error: !!props.error, helperText: props.helperText, variant: "outlined", InputLabelProps: { shrink: true }, InputProps: {
                        notched: false,
                        readOnly: true,
                    } })),
                React.createElement(Picker, { minYear: props.minYear, maxYear: props.maxYear, reverseYears: props.reverseYears, onChange: onPickerChange, onClose: handlePopoverClose, value: props.value }))))) : (React.createElement(React.Fragment, null,
        React.createElement(ClickAwayListener, { onClickAway: function () {
                handlePopoverClose();
                setFocusedInput(false);
            } },
            React.createElement(TextField, __assign({}, props, { error: !!props.error, helperText: props.helperText, fullWidth: true, variant: "outlined", InputLabelProps: { shrink: true }, focused: isOpen || focusedInput, onClick: function () { return setFocusedInput(true); }, InputProps: {
                    notched: false,
                    inputComponent: TextMaskCustom,
                    endAdornment: (React.createElement(InputAdornment, { position: "end", onClick: handlePopoverOpen, sx: { cursor: 'pointer' } },
                        React.createElement(CalendarToday, null))),
                } }))),
        React.createElement(Popper, { onClick: handlePopoverClick, open: isOpen, anchorEl: anchorEl === null || anchorEl === void 0 ? void 0 : anchorEl.parentElement, style: { zIndex: 2000 } },
            React.createElement(Picker, { minYear: props.minYear, maxYear: props.maxYear, reverseYears: props.reverseYears, onChange: onPickerChange, onClose: handlePopoverClose, value: props.value }))));
};
