import React from 'react';
import useTranslation from 'app/hooks/useTranslation';
import blob from '../assets/illustrations/phone-img.png';
import { Button, Stack, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
var NotFoundComponent = function () {
    var t = useTranslation();
    return (React.createElement(Stack, { sx: {
            width: {
                xs: '100%',
                md: '60vw',
                backgroundImage: "url(" + blob + ")",
                backgroundSize: 'contain',
                minHeight: 500,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            },
        }, justifyContent: "center", alignItems: "center" },
        React.createElement(Typography, { sx: { fontSize: 24, lineHeight: '100%', paddingBottom: '16px' } }, t('errorPage.title')),
        React.createElement(Typography, { sx: {
                fontSize: 100,
                fontWeight: 700,
                lineHeight: '100%',
                paddingBottom: '16px',
            }, color: "GrayText" }, "404"),
        React.createElement(Typography, { sx: {
                fontSize: 24,
                lineHeight: '100%',
                maxWidth: '320px',
                textAlign: 'center',
                paddingBottom: '16px',
            } }, t('errorPage.desc')),
        React.createElement(Button, { LinkComponent: "a", href: "/", sx: { height: '48px', width: 240, marginTop: '32px' }, color: "error", variant: "contained" },
            React.createElement(ChevronRight, { sx: { mr: 1 } }),
            t('common.toTheMain'))));
};
export default NotFoundComponent;
