import React, { useEffect, useState } from 'react';
import InputRange from 'react-input-range';
import { DurationOption, DurationSliderRoot } from './elements';
import useTranslation from '../../../../hooks/useTranslation';
import { Box, Button, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
var DurationSlider = function (_a) {
    var value = _a.value, onChange = _a.onChange, onCancel = _a.onCancel, closeDrawer = _a.closeDrawer;
    var _b = useState('nights'), activeOption = _b[0], setActiveOption = _b[1];
    var optionMultiplier = {
        nights: 1,
        weeks: 7,
        months: 30,
    };
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _c = useState({
        min: value[0] * optionMultiplier[activeOption],
        max: value[1] * optionMultiplier[activeOption],
    }), intermediateValue = _c[0], setIntermediateValue = _c[1];
    var min = value[0];
    var max = value[1];
    useEffect(function () {
        if (min > 45 || max > 45) {
            if (min % 7 === 0 && max % 7 === 0) {
                setActiveOption('weeks');
                setIntermediateValue({ min: min / 7, max: max / 7 });
            }
            else if (min % 30 === 0 && max % 30 === 0) {
                setActiveOption('months');
                setIntermediateValue({ min: min / 30, max: max / 30 });
            }
        }
    }, [min, max]);
    var handleOptionChange = function (option) {
        setActiveOption(option);
        setIntermediateValue({
            min: 2,
            max: 5,
        });
    };
    var options = ['nights', 'weeks', 'months'];
    var maxValueMap = {
        nights: 45,
        weeks: 7,
        months: 12,
    };
    var t = useTranslation();
    return (React.createElement(DurationSliderRoot, null,
        React.createElement(Stack, { direction: "row", sx: { width: '100%', mt: 1, mb: 3, fontSize: '12px' }, spacing: 2 }, options.map(function (o) { return (React.createElement(DurationOption, { onClick: function () { return handleOptionChange(o); }, selected: activeOption === o }, t("durationOptions." + o))); })),
        React.createElement(Stack, { direction: "row", sx: { mb: 2, width: '100%' } },
            React.createElement(Typography, { color: "text.secondary" },
                t('durationStay.label'),
                "\u00A0"),
            ' ',
            React.createElement(Typography, { color: "text.primary" },
                t('duration.from').toLowerCase(),
                " ",
                intermediateValue.min,
                ' ',
                t('duration.to'),
                " ",
                intermediateValue.max,
                ' ',
                t("durationOptions." + activeOption).toLowerCase())),
        React.createElement(Stack, { direction: "row", sx: { width: '100%', position: 'relative' } },
            React.createElement(Box, { sx: { top: '1em', color: 'primary.dark', position: 'relative' } }, "0"),
            React.createElement(InputRange, { value: intermediateValue, allowSameValues: true, onChange: function (v) { return setIntermediateValue(v); }, minValue: 0, maxValue: maxValueMap[activeOption], formatLabel: function () { return ''; } }),
            React.createElement(Box, { sx: { top: '1em', color: 'primary.dark', position: 'relative' } }, maxValueMap[activeOption])),
        isMobile ? (React.createElement(Stack, { direction: "row", alignItems: "center", sx: {
                position: 'fixed',
                bottom: '0.5rem',
                justifyContent: { xs: 'space-between', md: 'flex-end' },
                width: '100%',
                backgroundColor: 'common.white',
            } },
            React.createElement(Button, { sx: {
                    p: 1,
                    mx: 0.5,
                    borderRadius: '3px',
                    backgroundColor: '#f2f2f2',
                    color: 'text.primary',
                    width: '100%',
                    height: 46,
                }, onClick: function () {
                    onCancel && onCancel();
                    closeDrawer && closeDrawer();
                } }, t('common.cancel')),
            React.createElement(Button, { sx: {
                    p: 1,
                    mx: 0.5,
                    borderRadius: '3px',
                    display: 'flex',
                    backgroundColor: 'primary.main',
                    color: 'common.white',
                    alignSelf: 'flex-end',
                    width: '100%',
                    height: 46,
                }, onClick: function () {
                    onChange([
                        intermediateValue.min * optionMultiplier[activeOption],
                        intermediateValue.max * optionMultiplier[activeOption],
                    ]);
                    closeDrawer && closeDrawer();
                } }, t('common.apply')))) : (React.createElement(Button, { sx: {
                position: 'fixed',
                bottom: 0,
                right: 0,
                borderRadius: '0px',
                display: 'flex',
                backgroundColor: 'primary.main',
                color: 'common.white',
                alignSelf: 'flex-end',
                width: '25%',
                height: 48,
                '&:hover': {
                    backgroundColor: '#1c72c7',
                    color: 'text.primary',
                },
            }, onClick: function () {
                onChange([
                    intermediateValue.min * optionMultiplier[activeOption],
                    intermediateValue.max * optionMultiplier[activeOption],
                ]);
                closeDrawer && closeDrawer();
            } },
            React.createElement(ChevronRight, { viewBox: "0 -3 32 32" }),
            " ",
            t('common.apply')))));
};
export default DurationSlider;
