var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Box, Container } from '@material-ui/core';
import MobileMenu from 'app/components/Menu/MobileMenu';
import DesktopMenu from 'app/components/Menu/DesktopMenu';
var Header = function (_a) {
    var isMobile = _a.isMobile, mobileMenuProps = _a.mobileMenuProps, desktopMenuProps = _a.desktopMenuProps, paletteMode = _a.paletteMode, _b = _a.bottomShadow, bottomShadow = _b === void 0 ? true : _b;
    return (React.createElement(Box, { sx: {
            backgroundColor: isMobile
                ? function (theme) { return theme.palette.primary.dark; }
                : '#fff',
            boxShadow: bottomShadow
                ? '0px 8px 32px rgba(0, 85, 104, 0.1);'
                : 'none',
        } },
        React.createElement(Container, { disableGutters: !isMobile }, isMobile ? (React.createElement(MobileMenu, __assign({}, mobileMenuProps))) : (React.createElement(DesktopMenu, __assign({ paletteMode: paletteMode, navigationType: "buttons" }, desktopMenuProps))))));
};
export default Header;
