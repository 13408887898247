var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { add, addMonths, addYears, format as fnsFormat, getDay, getMonth, getYear, isSameYear, parseISO, set, startOfDay, startOfMonth, } from 'date-fns';
import useTranslation, { useDateFormat, useLocaleId, } from '../../../../hooks/useTranslation';
import { CloseOverlay, RangePickerAbsoluteContainer, RangePickerCalendars, RangePickerPopularOption, RangePickerPopularOptions, RangePickerRoot, RangePickerTimeInput, RangePickerTimeInputs, } from './elements';
import MonthView from '../MonthView/MonthView';
import PickerModeSelect from '../PickerModeSelect/PickerModeSelect';
import DurationSlider from '../DurationSlider/DurationSlider';
import { Button, Divider, Stack, SwipeableDrawer, TextField, Typography, useMediaQuery, } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Box } from '@material-ui/system';
import { WeekDayName, WeekDayNamesContainer } from '../MonthView/elements';
// const dateTextFormat = 'yyyy/MM/dd HH:mm:ss';
var dateTextFormat = 'dd MMM';
var mobileDateTextFormat = 'dd LLL, iiiiii';
var parseTimePart = function (part) {
    var number = Number.parseInt(part, 10);
    if (Number.isNaN(number)) {
        return 0;
    }
    return number;
};
var popularOptions = [
    {
        text: 'Last day',
        duration: {
            days: -1,
        },
    },
    {
        text: 'Last 3 days',
        duration: {
            days: -3,
        },
    },
    {
        text: 'Last 30 days',
        duration: {
            days: -30,
        },
    },
    {
        text: 'Last quarter',
        duration: {
            months: -3,
        },
    },
];
var RangePicker = function (_a) {
    var value = _a.value, _b = _a.otherValues, otherValues = _b === void 0 ? [] : _b, onChange = _a.onChange, minDate = _a.minDate, maxDate = _a.maxDate, timePickersEnabled = _a.timePickersEnabled, popularOptionsEnabled = _a.popularOptionsEnabled, singleDatePick = _a.singleDatePick, showDurationPicker = _a.showDurationPicker, startAdornment = _a.startAdornment, secondaryRange = _a.secondaryRange, _c = _a.groupedCalendarItems, groupedCalendarItems = _c === void 0 ? {} : _c, duration = _a.duration, needsUpdateConfirmation = _a.needsUpdateConfirmation, secondary = _a.secondary, onDurationCancel = _a.onDurationCancel, onDurationChange = _a.onDurationChange, endAdornment = _a.endAdornment, onConfirm = _a.onConfirm, _d = _a.alignCalendars, alignCalendars = _d === void 0 ? 'right' : _d;
    var _e = useState(false), calendarsVisible = _e[0], setCalendarsVisible = _e[1];
    var _f = useState(null), hoveredDate = _f[0], setHoveredDate = _f[1];
    var _g = useState('00:00:00'), startTime = _g[0], setStartTime = _g[1];
    var _h = useState('00:00:00'), endTime = _h[0], setEndTime = _h[1];
    var _j = useState(false), isDataChange = _j[0], setIsDataChange = _j[1];
    var _k = useState(add(new Date(), { months: -1 })), visibleRangeRefDate = _k[0], setVisibleRangeRefDate = _k[1];
    var t = useTranslation();
    var _l = useState([null, null]), potentialValues = _l[0], setPotentialValues = _l[1];
    var locale = useLocaleId();
    var daysProperties = useMemo(function () {
        var ret = __assign({}, groupedCalendarItems);
        otherValues.forEach(function (otherValue) {
            var value = Array.isArray(otherValue.value)
                ? otherValue.value[0]
                : otherValue.value;
            if (value === undefined) {
                return;
            }
            var range = Array.isArray(otherValue.value)
                ? [parseISO(otherValue.value[0]), parseISO(otherValue.value[1])]
                : [parseISO(otherValue.value), parseISO(otherValue.value)];
            var day = range[0];
            var index = 0;
            do {
                var key = fnsFormat(day, 'yyyy-MM-dd');
                ret[key] = __assign(__assign({}, ret[key]), { type: otherValue.type, label: otherValue.type === 'other'
                        ? t('datepicker.marks.other') + ' ' + otherValue.index
                        : t('datepicker.marks.' + otherValue.type), isRangeStart: day === range[0], isRangeEnd: day >= range[1], isMidRangeStartOfWeek: day > range[0] && getDay(day) === 1, isMidRangeEndOfWeek: day < range[1] && getDay(day) === 0 });
                day = add(day, { days: 1 });
            } while (day <= range[1]);
        });
        return ret;
    }, [otherValues, groupedCalendarItems]);
    var _m = useState('single'), pickerMode = _m[0], setPickerMode = _m[1];
    var _o = useState(false), syncRequested = _o[0], setSyncRequested = _o[1];
    var syncWithValue = useCallback(function () {
        setSyncRequested(true);
    }, []);
    useEffect(function () {
        if (syncRequested) {
            if (potentialValues[0] && potentialValues[1]) {
                var startTimeParts = startTime.split(':').map(parseTimePart);
                var endTimeParts = endTime.split(':').map(parseTimePart);
                onChange([
                    set(potentialValues[0], {
                        hours: startTimeParts[0],
                        minutes: startTimeParts[1],
                        seconds: startTimeParts[2],
                    }),
                    set(potentialValues[1], {
                        hours: endTimeParts[0],
                        minutes: endTimeParts[1],
                        seconds: endTimeParts[2],
                    }),
                ]);
                if (!needsUpdateConfirmation) {
                    setCalendarsVisible(false);
                }
            }
            setSyncRequested(false);
            setIsDataChange(true);
        }
    }, [
        endTime,
        needsUpdateConfirmation,
        onChange,
        onConfirm,
        pickerMode,
        potentialValues,
        startTime,
        syncRequested,
    ]);
    useEffect(function () {
        if (isDataChange && pickerMode === 'single') {
            if (onConfirm)
                onConfirm();
            setCalendarsVisible(false);
            setIsDataChange(false);
        }
    }, [isDataChange, onConfirm, pickerMode, syncRequested]);
    var handleStartTimeChange = useCallback(function (e) {
        setStartTime(e.target.value);
        syncWithValue();
    }, [syncWithValue]);
    var handleEndTimeChange = useCallback(function (e) {
        setEndTime(e.target.value);
        syncWithValue();
    }, [syncWithValue]);
    var handleChange = useCallback(function (val) {
        if (pickerMode === 'single') {
            setPotentialValues([val[0], val[0]]);
        }
        else {
            setPotentialValues(val);
        }
        syncWithValue();
    }, [syncWithValue, pickerMode]);
    var prevMonthDate = useMemo(function () { return addMonths(startOfMonth(visibleRangeRefDate), -1); }, [visibleRangeRefDate]);
    var nextMonthDate = useMemo(function () { return addMonths(startOfMonth(visibleRangeRefDate), 1); }, [visibleRangeRefDate]);
    var handlePrevMonth = useCallback(function () {
        setVisibleRangeRefDate(prevMonthDate);
    }, [prevMonthDate]);
    var handleNextMonth = useCallback(function () {
        setVisibleRangeRefDate(nextMonthDate);
    }, [nextMonthDate]);
    var format = useDateFormat();
    var initIntermediateState = useCallback(function (value) {
        var startDate = (value === null || value === void 0 ? void 0 : value[0]) || null;
        var endDate = (value === null || value === void 0 ? void 0 : value[1]) || null;
        setPickerMode(duration ? 'duration' : singleDatePick ? 'single' : 'range');
        setPotentialValues([
            startDate ? startOfDay(startDate) : null,
            endDate ? startOfDay(endDate) : null,
        ]);
        setVisibleRangeRefDate(startDate || add(new Date(), { months: -1 }));
        !duration &&
            setStartTime(startDate ? format(startDate, 'HH:mm:ss') : '00:00:00');
        !duration &&
            setEndTime(endDate ? format(endDate, 'HH:mm:ss') : '00:00:00');
    }, [singleDatePick, duration]);
    var showCalendars = useCallback(function (e) {
        // e.stopPropagation();
        // e.nativeEvent.stopImmediatePropagation(); // because react 16
        var _a;
        // if (!calendarsVisible) {
        //   setCalendarsVisible(true);
        //   initIntermediateState(value);
        //   const handleClose = () => {
        //     document.removeEventListener('click', handleClose);
        //     setCalendarsVisible(false);
        //   };
        //   document.addEventListener('click', handleClose);
        // }
        // return false;
        if (!calendarsVisible) {
            // @ts-ignore
            ((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.blur) && document.activeElement.blur();
            setCalendarsVisible(true);
            initIntermediateState(value);
        }
    }, [calendarsVisible, initIntermediateState, value]);
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var dateFormat = isMobile ? mobileDateTextFormat : dateTextFormat;
    var inputValueText = useMemo(function () {
        var durationText = 'nights';
        var multiplier = 1;
        if (duration) {
            if (duration[0] > 45 || duration[1] > 45) {
                if (duration[0] % 7 === 0 && duration[1] % 7 === 0) {
                    durationText = 'weeks';
                    multiplier = 7;
                }
                else if (duration[0] % 30 === 0 && duration[1] % 30 === 0) {
                    durationText = 'months';
                    multiplier = 30;
                }
            }
            return duration[0] / multiplier + " -  " + duration[1] / multiplier + " " + t("durationOptions." + durationText).toLowerCase();
        }
        if ((value === null || value === void 0 ? void 0 : value[0]) && (value === null || value === void 0 ? void 0 : value[1])) {
            if (singleDatePick) {
                return "" + format(value[0], dateFormat);
            }
            return format(value[0], dateFormat) + " - " + format(value[1], dateFormat);
        }
        return '';
    }, [value, singleDatePick, dateFormat, duration]);
    var handlePopularOption = useCallback(function (duration) {
        var value = [add(new Date(), duration), new Date()];
        onChange(value);
        initIntermediateState(value);
    }, [initIntermediateState, onChange]);
    var handleClear = useCallback(function (e) {
        if (value) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            onChange(null);
            initIntermediateState(null);
        }
    }, [initIntermediateState, onChange, value]);
    var minYear = useMemo(function () { return getYear(minDate || new Date()); }, [minDate]);
    var maxYear = useMemo(function () { return getYear(maxDate || addYears(new Date(), 1)); }, [maxDate]);
    var minMonth = useMemo(function () { return getMonth(minDate || new Date()); }, [minDate]);
    var maxMonth = useMemo(function () {
        return getMonth(maxDate || addYears(new Date(), 1)) + (maxYear - minYear) * 12;
    }, [maxDate, maxYear, minYear]);
    var rangeOfSeven = [0, 1, 2, 3, 4, 5, 6];
    var weekDayLabels = {
        ro: ['Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sâ', 'Du'],
        en: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    };
    var monthOfSelectedValue = (value === null || value === void 0 ? void 0 : value[0]) ? getMonth(value[0]) : undefined;
    var mobileView = (React.createElement(SwipeableDrawer, { open: calendarsVisible, onOpen: function () { return console.log('open'); }, onClose: function () { return setCalendarsVisible(false); }, anchor: "bottom" },
        React.createElement(Stack, { sx: { height: 'calc(100vh - 100px)' } },
            React.createElement(Stack, { justifyContent: "space-between", alignItems: "center", sx: {
                    backgroundColor: 'primary.dark',
                    height: 70,
                } },
                React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", sx: { width: '100%', padding: '16px 16px' } },
                    React.createElement(Typography, { sx: {
                            fontWeight: 600,
                            fontSize: '1em',
                            color: 'common.white',
                        } }, secondary
                        ? t('rangePicker.selectReturn')
                        : t('rangePicker.selectDeparture')),
                    React.createElement(Clear, { onClick: function () { return setCalendarsVisible(false); }, sx: { color: 'common.white' } })),
                React.createElement(Box, { sx: {
                        width: '100%',
                        backgroundColor: 'common.white',
                        height: '35px',
                        borderRadius: '12px 12px 0 0',
                        flexShrink: 0,
                        display: 'flex',
                        justifyContent: 'center',
                    } },
                    React.createElement("div", { style: {
                            width: '30px',
                            height: '4px',
                            backgroundColor: 'grey',
                            marginTop: '8px',
                            borderRadius: 21,
                        } })),
                React.createElement(PickerModeSelect, { value: pickerMode, onChange: setPickerMode, showDurationOption: showDurationPicker }),
                pickerMode !== 'duration' && (React.createElement(React.Fragment, null,
                    React.createElement(WeekDayNamesContainer, null, rangeOfSeven.map(function (dayNumber) { return (React.createElement(WeekDayName, { key: dayNumber }, weekDayLabels[locale][dayNumber])); })),
                    React.createElement(Divider, { variant: "fullWidth", sx: { width: '100%' } }))),
                timePickersEnabled && (React.createElement(RangePickerTimeInputs, null,
                    React.createElement(RangePickerTimeInput, null,
                        React.createElement("input", { type: "time", step: 1, value: startTime, onChange: handleStartTimeChange })),
                    React.createElement(RangePickerTimeInput, null,
                        React.createElement("input", { type: "time", step: 1, value: endTime, onChange: handleEndTimeChange })))),
                React.createElement(Stack, { sx: {
                        maxHeight: 'calc(100vh - 290px)',
                        overflow: 'scroll',
                        flexShrink: 0,
                        width: '100%',
                        mb: '10px',
                        py: 1,
                    } }, pickerMode === 'duration' ? (React.createElement(React.Fragment, null,
                    React.createElement(DurationSlider, { value: duration || [3, 10], onCancel: function () {
                            onDurationCancel && onDurationCancel();
                            // setCalendarsVisible(false);
                        }, closeDrawer: function () { return setCalendarsVisible(false); }, onChange: function (d) {
                            // setCalendarsVisible(false);
                            onDurationChange === null || onDurationChange === void 0 ? void 0 : onDurationChange(d);
                        } }))) : (new Array(maxMonth - minMonth + 1)
                    .fill(null)
                    .map(function (_, i) { return (React.createElement(MonthView, { key: i, secondaryRange: secondaryRange, navigationDirection: "backward", initialDate: addMonths(minDate || new Date(), i), onPrevMonth: handlePrevMonth, isMonthOfSelectedValue: monthOfSelectedValue ===
                        getMonth(addMonths(minDate || new Date(), i)) &&
                        isSameYear((value === null || value === void 0 ? void 0 : value[0]) || new Date(), addMonths(minDate || new Date(), i)), minDate: minDate, maxDate: maxDate, hoveredDate: hoveredDate, onDateHover: setHoveredDate, isRange: true, value: potentialValues, onChange: handleChange, daysProperties: daysProperties, setPickerMode: setPickerMode, setPotentialValues: setPotentialValues, syncWithValue: syncWithValue })); }))),
                pickerMode !== 'duration' && (React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", sx: {
                        width: '100%',
                        position: 'fixed',
                        bottom: '8px',
                        zIndex: 4,
                    } },
                    React.createElement(Button, { sx: {
                            p: 1,
                            mx: 0.5,
                            borderRadius: '3px',
                            backgroundColor: '#f2f2f2',
                            color: 'text.primary',
                            width: '100%',
                            height: 46,
                        }, onClick: function () { return setCalendarsVisible(false); } }, t('common.cancel')),
                    pickerMode !== 'single' && (React.createElement(Button, { disabled: potentialValues.filter(Boolean).length < 2, variant: "contained", sx: {
                            p: 1,
                            mx: 0.5,
                            borderRadius: '3px',
                            color: 'common.white',
                            width: '100%',
                            height: 46,
                            '&.Mui-disabled': { backgroundColor: '#d5d5d5' },
                        }, onClick: function () {
                            onConfirm && onConfirm();
                            setCalendarsVisible(false);
                        } }, t('common.apply')))))))));
    return (React.createElement(RangePickerRoot, { onClick: showCalendars },
        React.createElement(TextField, { type: "text", sx: isMobile
                ? {
                    width: '100%',
                    backgroundColor: 'common.white',
                    borderRadius: '0.25em',
                    boxShadow: { xs: '0px 2px 10px 0px #1901341F', md: 'unset' },
                }
                : {}, InputProps: {
                readOnly: true,
                endAdornment: endAdornment,
                startAdornment: startAdornment,
                sx: {
                    '> .MuiOutlinedInput-input': {
                        pt: '14px',
                        pb: '14px',
                        fontSize: '14px',
                        textTransform: 'capitalize',
                        color: function (theme) { return theme.palette.primary.dark; },
                        fontWeight: { xs: '600', sm: '400' },
                    },
                    '> .MuiOutlinedInput-notchedOutline': isMobile
                        ? {
                            border: { xs: 'none' },
                        }
                        : {},
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: calendarsVisible ? '2px' : '1px',
                        borderColor: calendarsVisible
                            ? 'primary.main'
                            : 'rgba(0, 0, 0, 0.23)',
                    },
                },
            }, value: inputValueText, onFocus: showCalendars }),
        calendarsVisible && (React.createElement(React.Fragment, null,
            React.createElement(CloseOverlay, { onClick: function () { return setCalendarsVisible(false); } }),
            isMobile ? (mobileView) : pickerMode === 'duration' ? (React.createElement(RangePickerAbsoluteContainer, { secondary: secondary, align: alignCalendars },
                React.createElement(DurationSlider, { value: duration || [3, 10], onCancel: function () {
                        onDurationCancel && onDurationCancel();
                        setCalendarsVisible(false);
                    }, onChange: function (d) {
                        setCalendarsVisible(false);
                        onDurationChange === null || onDurationChange === void 0 ? void 0 : onDurationChange(d);
                    } }),
                React.createElement(PickerModeSelect, { value: pickerMode, onChange: setPickerMode, onConfirm: function () {
                        onConfirm && onConfirm();
                        setCalendarsVisible(false);
                    }, showDurationOption: showDurationPicker }))) : (React.createElement(RangePickerAbsoluteContainer, { secondary: secondary, align: alignCalendars },
                popularOptionsEnabled && (React.createElement(RangePickerPopularOptions, null, popularOptions.map(function (option) { return (React.createElement(RangePickerPopularOption, { key: option.text, tabIndex: 0, onClick: function () { return handlePopularOption(option.duration); } }, option.text)); }))),
                React.createElement(RangePickerCalendars, { style: isMobile
                        ? { overflowY: 'auto', height: 'calc(100vh - 106px)' }
                        : {} },
                    React.createElement(MonthView, { secondaryRange: secondaryRange, navigationDirection: "backward", initialDate: visibleRangeRefDate, onPrevMonth: handlePrevMonth, minDate: minDate, maxDate: maxDate, hoveredDate: hoveredDate, onDateHover: setHoveredDate, isRange: true, value: potentialValues, onChange: handleChange, daysProperties: daysProperties, setPickerMode: setPickerMode }),
                    React.createElement(MonthView, { secondaryRange: secondaryRange, navigationDirection: "forward", initialDate: nextMonthDate, onNextMonth: handleNextMonth, minDate: minDate, maxDate: maxDate, hoveredDate: hoveredDate, onDateHover: setHoveredDate, isRange: true, value: potentialValues, onChange: handleChange, daysProperties: daysProperties, setPickerMode: setPickerMode })),
                React.createElement(PickerModeSelect, { value: pickerMode, onChange: setPickerMode, onConfirm: function () {
                        onConfirm && onConfirm();
                        setCalendarsVisible(false);
                    }, showDurationOption: showDurationPicker }),
                timePickersEnabled && (React.createElement(RangePickerTimeInputs, null,
                    React.createElement(RangePickerTimeInput, null,
                        React.createElement("input", { type: "time", step: 1, value: startTime, onChange: handleStartTimeChange })),
                    React.createElement(RangePickerTimeInput, null,
                        React.createElement("input", { type: "time", step: 1, value: endTime, onChange: handleEndTimeChange }))))))))));
};
export default RangePicker;
