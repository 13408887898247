var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Alert, AlertTitle, Box, Button, CircularProgress, Container, Dialog, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import Layout from 'app/components/Layout';
import { format, isBefore, parseISO, startOfToday } from 'date-fns';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { BooleanParam, DelimitedArrayParam, DelimitedNumericArrayParam, NumericObjectParam, StringParam, useQueryParams, withDefault, } from 'use-query-params';
import noItinerary from 'app/assets/illustrations/NotFoundItinerary.svg';
import { Filters } from 'app/components/Filters/Filters';
import FlightCard from 'app/components/FlightCard/FlightCard';
import FlightCardSkeleton from 'app/components/FlightCard/FlightCardSkeleton';
import FlightSearchForm from 'app/components/FlightSearchForm/SearchForm/FlightSearchForm';
import { RecommendedItineraries } from 'app/components/RecommendedItineraries/RecommendedItineraries';
import { Sorter } from 'app/components/ResultsSorter/Sorter';
import { CabinClassType, DepartureDateType, FlightPartType, FlightType, ItinerariesSortByInput, ReturnDateType, useOneWayLazyQuery, useRecommendedOneWayQuery, useRecommendedTwoWayQuery, useRoundTripLazyQuery, useSearchValuesQuery, } from 'app/generated/graphql';
import { internalDateFormat, setInitialState, } from 'app/redux/slices/flightSearch.slice';
import useTranslation from 'app/hooks/useTranslation';
import GroupedCards from 'app/components/FlightCard/GroupedItineraries';
import { GroupedFilters } from 'app/components/Filters/GroupedFilters';
import ProgressBar from 'app/components/ui/ProgressBar';
function SearchResults() {
    var _a, _b, _c, _d, _e, _f, _g;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var t = useTranslation();
    var _h = useState(false), isDialogShown = _h[0], setIsDialogShown = _h[1];
    var params = useParams();
    var _j = useQueryParams({
        passengers: withDefault(DelimitedNumericArrayParam, [1]),
        airlines: withDefault(DelimitedArrayParam, []),
        airports: withDefault(DelimitedArrayParam, []),
        price: withDefault(DelimitedArrayParam, ['0', '100000']),
        waitTime: withDefault(DelimitedNumericArrayParam, undefined),
        flightType: withDefault(DelimitedArrayParam, [
            FlightType.Lowcost,
            FlightType.Regular,
            FlightType.Wise,
        ]),
        cabinclass: withDefault(StringParam, 'economy'),
        stopovers: withDefault(DelimitedNumericArrayParam, []),
        noNight: withDefault(BooleanParam, false),
        depTime: withDefault(NumericObjectParam, null),
        arrTime: withDefault(NumericObjectParam, null),
        noAirportChange: withDefault(BooleanParam, false),
        nigthsInDestination: withDefault(DelimitedNumericArrayParam, undefined),
        dayInWeek: withDefault(NumericObjectParam, null),
        sortBy: withDefault(StringParam, ItinerariesSortByInput.Recommendation),
    }), queryParams = _j[0], setQueryParams = _j[1];
    var cabinClass = {
        economy: CabinClassType.Economy,
        business: CabinClassType.Business,
        businessEconomy: CabinClassType.PremiumEconomy,
    };
    var departureDate = (_a = params === null || params === void 0 ? void 0 : params.departureDate) === null || _a === void 0 ? void 0 : _a.split(',');
    var returnDate = (_b = params === null || params === void 0 ? void 0 : params.returnDate) === null || _b === void 0 ? void 0 : _b.split(',');
    var durationStay = ((_c = params === null || params === void 0 ? void 0 : params.returnDate) === null || _c === void 0 ? void 0 : _c.startsWith('d'))
        ? params.returnDate.slice(1).split(',')
        : null;
    var queryPicker = params.returnDate === '-' ? 'oneWay' : 'roundTrip';
    if (isBefore(parseISO(departureDate[0]), new Date())) {
        departureDate[0] = format(new Date(), internalDateFormat);
    }
    var searchFormValuesResult = useSearchValuesQuery({
        variables: {
            input: {
                cabinClass: cabinClass[queryParams.cabinclass],
                itineraries: [
                    {
                        source: params.departureValues,
                        destination: params.returnValues,
                        departureDate: departureDate.join(','),
                        returnDate: params.returnDate,
                    },
                ],
                passengers: {
                    adults: queryParams.passengers[0],
                    children: queryParams.passengers[1],
                    infants: queryParams.passengers[2],
                },
            },
        },
    });
    var dispatch = useDispatch();
    var anywhereItem = {
        name: t('airportItem.anywhere'),
        code: 'anywhere',
    };
    useEffect(function () {
        var _a, _b, _c;
        if (searchFormValuesResult.data &&
            ((_a = searchFormValuesResult.data.searchFormValues) === null || _a === void 0 ? void 0 : _a.__typename) ===
                'SearchFormValues') {
            var itineraries_1 = ((_c = (_b = searchFormValuesResult === null || searchFormValuesResult === void 0 ? void 0 : searchFormValuesResult.data) === null || _b === void 0 ? void 0 : _b.searchFormValues) === null || _c === void 0 ? void 0 : _c.itineraries) || [];
            var flights_1 = [
                {
                    departureAirportOptions: [],
                    arrivalAirportOptions: [],
                    date: format(startOfToday(), internalDateFormat),
                },
                null,
                null,
            ];
            var passengerCount = {
                adult: (searchFormValuesResult.data.searchFormValues.__typename ===
                    'SearchFormValues' &&
                    searchFormValuesResult.data.searchFormValues.passengers.adults) ||
                    1,
                child: (searchFormValuesResult.data.searchFormValues.__typename ===
                    'SearchFormValues' &&
                    searchFormValuesResult.data.searchFormValues.passengers.children) ||
                    0,
                infant: (searchFormValuesResult.data.searchFormValues.__typename ===
                    'SearchFormValues' &&
                    searchFormValuesResult.data.searchFormValues.passengers.infants) ||
                    0,
            };
            itineraries_1.forEach(function (itin, i) {
                var _a, _b, _c, _d, _e, _f;
                var flight = {
                    arrivalAirportOptions: itin.destination.map(function (destination) {
                        return {
                            code: destination.code,
                            name: destination.__typename === 'Station'
                                ? destination.city.name
                                : destination.name,
                        };
                    }),
                    departureAirportOptions: itin.source.map(function (source) {
                        return {
                            code: source.code,
                            name: source.__typename === 'Station'
                                ? source.city.name
                                : source.name,
                        };
                    }),
                    date: itin.departureDate.value || [
                        (_a = itin.departureDate.range) === null || _a === void 0 ? void 0 : _a.start,
                        (_b = itin.departureDate.range) === null || _b === void 0 ? void 0 : _b.end,
                    ],
                };
                var returnFlight = null;
                if (itin.__typename === 'SearchFormReturnItinerary') {
                    returnFlight = {
                        arrivalAirportOptions: itin.source.map(function (source) {
                            return {
                                code: source.code,
                                name: source.__typename === 'Station'
                                    ? source.city.name
                                    : source.name,
                            };
                        }),
                        departureAirportOptions: itin.destination.map(function (destination) {
                            return {
                                code: destination.code,
                                name: destination.__typename === 'Station'
                                    ? destination.city.name
                                    : destination.name,
                            };
                        }),
                        date: itin.returnDate.duration
                            ? itin.departureDate.value || [
                                (_c = itin.departureDate.range) === null || _c === void 0 ? void 0 : _c.start,
                                (_d = itin.departureDate.range) === null || _d === void 0 ? void 0 : _d.end,
                            ]
                            : itin.returnDate.value || [
                                (_e = itin.returnDate.range) === null || _e === void 0 ? void 0 : _e.start,
                                (_f = itin.returnDate.range) === null || _f === void 0 ? void 0 : _f.end,
                            ],
                    };
                }
                if (params.returnValues.includes('anywhere')) {
                    flight.arrivalAirportOptions.push(anywhereItem);
                    if (returnFlight) {
                        returnFlight.departureAirportOptions.push(anywhereItem);
                    }
                }
                flights_1 = [flight, returnFlight, null];
            });
            var payload = {
                type: params.returnDate === '-' ? 'oneWay' : 'twoWay',
                focusReturnDate: false,
                //@ts-ignore
                flightClass: queryParams.cabinclass,
                passengerCount: passengerCount,
                stayDuration: durationStay
                    ? [parseInt(durationStay[0]), parseInt(durationStay[1])]
                    : null,
                flights: flights_1,
            };
            dispatch(setInitialState(payload));
        }
    }, [searchFormValuesResult]);
    var itinerarySearch = queryPicker === 'oneWay'
        ? {
            source: (_d = params === null || params === void 0 ? void 0 : params.departureValues) === null || _d === void 0 ? void 0 : _d.split(','),
            destination: (_e = params === null || params === void 0 ? void 0 : params.returnValues) === null || _e === void 0 ? void 0 : _e.split(','),
            departureDate: {
                type: departureDate.length > 1
                    ? DepartureDateType.Range
                    : DepartureDateType.Specific,
                value: departureDate[0],
                range: departureDate.length > 1
                    ? { start: departureDate[0], end: departureDate[1] }
                    : {},
            },
        }
        : {
            source: (_f = params === null || params === void 0 ? void 0 : params.departureValues) === null || _f === void 0 ? void 0 : _f.split(','),
            destination: (_g = params === null || params === void 0 ? void 0 : params.returnValues) === null || _g === void 0 ? void 0 : _g.split(','),
            departureDate: {
                type: departureDate.length > 1
                    ? DepartureDateType.Range
                    : DepartureDateType.Specific,
                value: departureDate[0],
                range: departureDate.length > 1
                    ? { start: departureDate[0], end: departureDate[1] }
                    : {},
            },
            returnDate: durationStay
                ? {
                    type: ReturnDateType.Duration,
                    duration: {
                        start: parseInt(durationStay[0]),
                        end: parseInt(durationStay[1]),
                    },
                }
                : {
                    type: returnDate.length > 1
                        ? ReturnDateType.Range
                        : ReturnDateType.Specific,
                    value: returnDate.length > 1 ? returnDate[0] : params === null || params === void 0 ? void 0 : params.returnDate,
                    range: returnDate.length > 1
                        ? { start: returnDate[0], end: returnDate[1] }
                        : {},
                },
        };
    var search = {
        itinerary: itinerarySearch,
        passengers: {
            adults: queryParams.passengers[0],
            children: queryParams.passengers[1] || 0,
            infants: queryParams.passengers[2] || 0,
        },
        cabinClass: cabinClass[queryParams.cabinclass],
    };
    var searchIdRef = useRef(null);
    var stopoverCount = queryParams.stopovers && queryParams.stopovers.length > 0
        ? queryParams.stopovers.map(function (s) { return s; })
        : null;
    var noNight = queryParams.noNight;
    var noAirportChange = queryParams.noAirportChange;
    var departureTimeFilter = [];
    var arrivalTimeFilter = [];
    var dayInWeekFilter = [];
    useEffect(function () {
        if (queryParams.depTime) {
            if (Object.keys(queryParams.depTime).includes('outstart' || 'outend')) {
                if (queryParams.depTime.outstart || queryParams.depTime.outend) {
                    departureTimeFilter.push({
                        part: FlightPartType.Out,
                        range: __assign(__assign({}, (queryParams.depTime.outstart && {
                            start: queryParams.depTime.outstart,
                        })), (queryParams.depTime.outend && {
                            end: queryParams.depTime.outend,
                        })),
                    });
                }
            }
            if (Object.keys(queryParams.depTime).includes('instart' || 'inend')) {
                if (queryParams.depTime.instart || queryParams.depTime.inend) {
                    departureTimeFilter.push({
                        part: FlightPartType.In,
                        range: __assign(__assign({}, (queryParams.depTime.instart && {
                            start: queryParams.depTime.instart,
                        })), (queryParams.depTime.inend && {
                            end: queryParams.depTime.inend,
                        })),
                    });
                }
            }
        }
    }, [queryParams.depTime, departureTimeFilter]);
    useEffect(function () {
        if (queryParams.arrTime) {
            if (Object.keys(queryParams.arrTime).includes('outstart' || 'outend')) {
                if (queryParams.arrTime.outstart || queryParams.arrTime.outend) {
                    arrivalTimeFilter.push({
                        part: FlightPartType.Out,
                        range: __assign(__assign({}, (queryParams.arrTime.outstart && {
                            start: queryParams.arrTime.outstart,
                        })), (queryParams.arrTime.outend && {
                            end: queryParams.arrTime.outend,
                        })),
                    });
                }
            }
            if (Object.keys(queryParams.arrTime).includes('instart' || 'inend')) {
                if (queryParams.arrTime.instart || queryParams.arrTime.inend) {
                    arrivalTimeFilter.push({
                        part: FlightPartType.In,
                        range: __assign(__assign({}, (queryParams.arrTime.instart && {
                            start: queryParams.arrTime.instart,
                        })), (queryParams.arrTime.inend && {
                            end: queryParams.arrTime.inend,
                        })),
                    });
                }
            }
        }
    }, [queryParams.arrTime, arrivalTimeFilter]);
    var stopover = {
        //@ts-ignore
        stopover: stopoverCount ? stopoverCount : undefined,
        excludeStopNight: noNight || undefined,
        excludeChangeAirport: noAirportChange || undefined,
        waitTimeRange: queryParams.waitTime
            ? {
                start: queryParams.waitTime[0],
                end: queryParams.waitTime[1],
            }
            : undefined,
    };
    var filter = {
        size: 15,
        offset: 0,
        airlines: queryParams.airlines,
        price: { start: queryParams.price[0], end: queryParams.price[1] },
        airports: queryParams.airports,
        departureTime: departureTimeFilter,
        arrivalTime: arrivalTimeFilter,
        stopover: stopover,
        flightType: queryParams.flightType,
        searchId: searchIdRef.current,
        nightInDestination: queryParams.nigthsInDestination
            ? {
                start: queryParams.nigthsInDestination[0],
                end: queryParams.nigthsInDestination[1],
            }
            : undefined,
    };
    var _k = useOneWayLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    }), onewayItinerariesQuery = _k[0], onewayItineraries = _k[1];
    var _l = useRoundTripLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    }), returnItinerariesQuery = _l[0], returnItineraries = _l[1];
    var _m = useOneWayLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    }), onewayOffsetItinerariesQuery = _m[0], onewayOffsetItineraries = _m[1];
    var _o = useRoundTripLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    }), returnOffsetItinerariesQuery = _o[0], returnOffsetItineraries = _o[1];
    var _p = useState(15), offset = _p[0], setOffset = _p[1];
    var recommendedOneWayResults = useRecommendedOneWayQuery({
        variables: {
            search: search,
        },
        skip: queryPicker === 'roundTrip',
    });
    var recommendedTwoWayResults = useRecommendedTwoWayQuery({
        variables: {
            search: search,
        },
        skip: queryPicker === 'oneWay',
    });
    var recommendedOneWay = get(recommendedOneWayResults.data, 'recommendedOnewayItineraries.itineraries');
    var recommendedTwoWay = get(recommendedTwoWayResults.data, 'recommendedReturnItineraries.itineraries');
    var itineraries = queryPicker === 'oneWay'
        ? get(onewayItineraries.data, 'onewayItineraries.itineraries')
        : get(returnItineraries.data, 'returnItineraries.itineraries');
    var offsetItineraries = queryPicker === 'oneWay'
        ? get(onewayOffsetItineraries.data, 'onewayItineraries.itineraries')
        : get(returnOffsetItineraries.data, 'returnItineraries.itineraries');
    var metadata = queryPicker === 'oneWay'
        ? get(onewayItineraries.data, 'onewayItineraries.metadata')
        : get(returnItineraries.data, 'returnItineraries.metadata');
    var groupedMetadata = queryPicker === 'oneWay'
        ? get(onewayItineraries.data, 'onewayItineraries.groupedMeta')
        : get(returnItineraries.data, 'returnItineraries.groupedMeta');
    useEffect(function () {
        if (metadata && metadata.searchId) {
            searchIdRef.current = metadata.searchId;
        }
        else if (groupedMetadata && groupedMetadata.searchId) {
            searchIdRef.current = groupedMetadata.searchId;
        }
    }, [metadata, groupedMetadata]);
    var recommendedItinerariesLoading = recommendedOneWayResults.loading || recommendedTwoWayResults.loading;
    var recommendedItineraries = queryPicker === 'oneWay' ? recommendedOneWay : recommendedTwoWay;
    var groupedItineraries = queryPicker === 'oneWay'
        ? get(onewayItineraries.data, 'onewayItineraries.groupedItineraries')
        : get(returnItineraries.data, 'returnItineraries.groupedItineraries');
    var _q = useState(metadata), lastMetadata = _q[0], setLastMetadata = _q[1];
    var _r = useState(itineraries), lastItineraries = _r[0], setLastItineraries = _r[1];
    var _s = useState(groupedMetadata), lastGroupedMetadata = _s[0], setLastGroupedMetadata = _s[1];
    var _t = useState(groupedItineraries), lastGroupedItineraries = _t[0], setLastGrouped = _t[1];
    var _u = useState(recommendedItineraries), lastRecommendedItineraries = _u[0], setLastRecommendedItineraries = _u[1];
    useEffect(function () {
        metadata && setLastMetadata(metadata);
        if (lastGroupedMetadata) {
            setLastGroupedMetadata(null);
        }
    }, [metadata]);
    useEffect(function () {
        groupedMetadata && setLastGroupedMetadata(groupedMetadata);
        if (metadata) {
            setLastMetadata(null);
        }
    }, [groupedMetadata]);
    useEffect(function () {
        if (itineraries) {
            setLastItineraries(itineraries);
            setOffset(itineraries.length);
        }
        if (lastGroupedItineraries) {
            setLastGrouped(null);
        }
    }, [itineraries]);
    useEffect(function () {
        groupedItineraries && setLastGrouped(groupedItineraries);
        if (lastItineraries) {
            setLastItineraries(null);
        }
        if (lastRecommendedItineraries) {
            setLastRecommendedItineraries(null);
        }
    }, [groupedItineraries]);
    useEffect(function () {
        recommendedItineraries &&
            setLastRecommendedItineraries(recommendedItineraries);
    }, [recommendedItineraries]);
    var loading = queryPicker === 'oneWay'
        ? onewayItineraries.loading
        : returnItineraries.loading;
    useEffect(function () {
        if (loading) {
            setOffset(15);
        }
    }, [loading]);
    var searchQueryResult = queryPicker === 'oneWay'
        ? get(onewayItineraries.data, 'onewayItineraries')
        : get(returnItineraries.data, 'returnItineraries');
    var validationErrors = (searchQueryResult === null || searchQueryResult === void 0 ? void 0 : searchQueryResult.__typename) === 'ValidationErrors' ||
        (searchQueryResult === null || searchQueryResult === void 0 ? void 0 : searchQueryResult.__typename) === 'AppError';
    var searchError = queryPicker === 'oneWay'
        ? onewayItineraries.error
        : returnItineraries.error;
    useEffect(function () {
        return function () {
            searchIdRef.current = null;
        };
    }, []);
    useEffect(function () {
        queryPicker === 'oneWay'
            ? onewayItineraries.refetch &&
                onewayItineraries.refetch({
                    filter: filter,
                    options: { sortBy: queryParams.sortBy },
                })
            : returnItineraries.refetch &&
                returnItineraries.refetch({
                    filter: filter,
                    options: { sortBy: queryParams.sortBy },
                });
    }, [queryParams]);
    useEffect(function () {
        queryPicker === 'oneWay'
            ? onewayItinerariesQuery({
                variables: {
                    search: search,
                    filter: filter,
                    options: { sortBy: queryParams.sortBy },
                },
                // fetchPolicy: 'no-cache',
            })
            : returnItinerariesQuery({
                variables: {
                    search: search,
                    filter: filter,
                    options: { sortBy: queryParams.sortBy },
                },
                // fetchPolicy: 'no-cache',
            });
    }, [params]);
    var fetchMoreOneWay = onewayItineraries.fetchMore;
    var fetchMoreReturn = returnItineraries.fetchMore;
    var onFetchMoreOneWay = function () {
        fetchMoreOneWay &&
            fetchMoreOneWay({
                variables: {
                    filter: __assign(__assign({}, filter), { offset: itineraries.length, size: 10 }),
                },
                //@ts-ignore
                updateQuery: function (previousResult, _a) {
                    var fetchMoreResult = _a.fetchMoreResult;
                    return __assign(__assign({}, previousResult), { 
                        // Add the new matches data to the end of the old matches data.
                        onewayItineraries: __assign(__assign({}, fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.onewayItineraries), { itineraries: __spreadArray(__spreadArray([], previousResult.onewayItineraries.itineraries, true), fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.onewayItineraries.itineraries, true) }) });
                },
            });
    };
    var onFetchMoreReturn = function () {
        fetchMoreReturn &&
            fetchMoreReturn({
                variables: {
                    filter: __assign(__assign({}, filter), { offset: itineraries.length, size: 10 }),
                },
                //@ts-ignore
                updateQuery: function (previousQueryResult, _a) {
                    var fetchMoreResult = _a.fetchMoreResult;
                    return __assign(__assign({}, previousQueryResult), { returnItineraries: __assign(__assign({}, fetchMoreResult), { 
                            //@ts-ignore
                            metadata: fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.returnItineraries.metadata, itineraries: __spreadArray(__spreadArray([], previousQueryResult.returnItineraries.itineraries, true), fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.returnItineraries.itineraries, true) }) });
                },
            });
    };
    var handleFetchMoreClick = function () {
        setOffset(offset + 10);
        queryPicker === 'oneWay'
            ? onewayOffsetItinerariesQuery({
                variables: {
                    search: search,
                    filter: __assign(__assign({}, filter), { size: 10, offset: offset }),
                    options: { sortBy: queryParams.sortBy },
                },
            })
            : returnOffsetItinerariesQuery({
                variables: {
                    search: search,
                    filter: __assign(__assign({}, filter), { size: 10, offset: offset }),
                    options: { sortBy: queryParams.sortBy },
                },
            });
    };
    useEffect(function () {
        offsetItineraries &&
            lastItineraries &&
            setLastItineraries(__spreadArray(__spreadArray([], lastItineraries, true), offsetItineraries, true));
    }, [offsetItineraries]);
    var noItineraries = (React.createElement(Stack, { justifyContent: "center", alignItems: "center", sx: { maxWidth: '90vw' } },
        React.createElement(Box, { component: "img", src: noItinerary, sx: { overflow: 'hidden', maxWidth: '100%' } }),
        React.createElement(Typography, { variant: "h5", color: "primary.main", textAlign: "center" },
            t('results.notFound'),
            ' '),
        React.createElement(Typography, { variant: "h6", textAlign: "center" }, t('results.adjustSearch'))));
    useEffect(function () {
        if (typeof document === 'undefined') {
            return;
        }
        var expiredSessionTimeout = setTimeout(function () {
            if (!loading && lastItineraries) {
                setIsDialogShown(true);
            }
        }, 1000 * 60 * 15);
        return function () { return clearTimeout(expiredSessionTimeout); };
    }, [loading, lastItineraries]);
    return (React.createElement(Layout, { HeaderProps: {
            paletteMode: isMobile ? 'dark' : 'white',
            mobileMenuProps: { navVisible: false },
        } },
        React.createElement(Box, { sx: {
                backgroundColor: isMobile
                    ? function (theme) { return theme.palette.primary.dark; }
                    : null,
                mt: { md: 5 },
                pb: { xs: 2 },
            } },
            React.createElement(Container, null,
                React.createElement(FlightSearchForm, { passengers: queryParams.passengers, searchIdRef: searchIdRef, compactForm: true }),
                loading && React.createElement(ProgressBar, null))),
        React.createElement(Box, { sx: {
                width: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                mt: 2,
            } },
            React.createElement(Container, null,
                searchError && (React.createElement(Stack, { justifyContent: "center", alignItems: "center", alignSelf: "center", sx: { maxWidth: '90vw', p: 2 } },
                    React.createElement(Box, { component: "img", src: noItinerary, sx: { overflow: 'hidden', maxWidth: '100%' } }),
                    React.createElement(Typography, { variant: "h5", color: "primary.main", textAlign: "center" }, t('results.sysError')))),
                validationErrors && (React.createElement(Stack, { justifyContent: "center", alignItems: "center", alignSelf: "center", sx: { maxWidth: '90vw', p: 2 } },
                    React.createElement(Box, { component: "img", src: noItinerary, sx: { overflow: 'hidden', maxWidth: '100%' } }),
                    React.createElement(Typography, { variant: "h5", color: "primary.main", textAlign: "center" }, t('results.validationError')))),
                !isMobile && (React.createElement(RecommendedItineraries, { recommendedItineraries: lastRecommendedItineraries ? lastRecommendedItineraries : [], searchIdRef: searchIdRef, isLoading: recommendedItinerariesLoading })),
                !loading && lastItineraries && lastItineraries.length === 0 ? (React.createElement(Stack, { direction: "row", justifyContent: isMobile ? 'center' : 'space-between' },
                    lastItineraries && !isMobile && (React.createElement(Stack, null,
                        React.createElement(Filters, { metadata: lastMetadata && lastMetadata, values: queryParams, onChange: setQueryParams, isLoading: loading }))),
                    React.createElement(Stack, { justifyContent: "center", direction: "row", sx: { flex: 1 } }, noItineraries))) : (!searchError &&
                    !validationErrors && (React.createElement(Stack, { direction: "row", justifyContent: isMobile ? 'center' : 'space-between' },
                    !loading && lastItineraries && !isMobile && (React.createElement(React.Fragment, null,
                        React.createElement(Filters, { metadata: lastMetadata, values: queryParams, onChange: setQueryParams, isLoading: loading }))),
                    React.createElement(React.Fragment, null,
                        React.createElement(Stack, { sx: {
                                width: '100%',
                                pl: { md: 2 },
                            } },
                            !lastGroupedItineraries && (React.createElement(Sorter, { sortBy: queryParams.sortBy, values: queryParams, sorterPrices: lastMetadata && lastMetadata.minimumPriceSortType, isLoading: loading, onChange: setQueryParams })),
                            loading ||
                                (!lastItineraries && !lastGroupedItineraries) ? (React.createElement(Stack, null, new Array(6).fill(0).map(function (el, key) {
                                return React.createElement(FlightCardSkeleton, { key: key });
                            }))) : (React.createElement(React.Fragment, null, lastItineraries === null || lastItineraries === void 0 ? void 0 :
                                lastItineraries.map(function (i, index) { return (React.createElement(React.Fragment, { key: index },
                                    React.createElement(FlightCard, { key: index, itinerary: i, itinerarySearch: itinerarySearch, departureDateType: itinerarySearch.departureDate.type, passengers: queryParams.passengers }),
                                    isMobile &&
                                        lastItineraries.length >= 3 &&
                                        index === 2 && (React.createElement(Box, { sx: { mt: { xs: 2, sm: 0 } } },
                                        React.createElement(RecommendedItineraries, { searchIdRef: searchIdRef, recommendedItineraries: lastRecommendedItineraries
                                                ? lastRecommendedItineraries
                                                : [], isLoading: recommendedItinerariesLoading }))))); }),
                                !!(lastItineraries === null || lastItineraries === void 0 ? void 0 : lastItineraries.length) &&
                                    isMobile &&
                                    (lastItineraries.length < 3 ||
                                        lastItineraries.length > 5) && (React.createElement(Box, { sx: { mt: { xs: 2, sm: 0 } } },
                                    React.createElement(RecommendedItineraries, { searchIdRef: searchIdRef, recommendedItineraries: lastRecommendedItineraries
                                            ? lastRecommendedItineraries
                                            : [], isLoading: recommendedItinerariesLoading }))))),
                            !loading && lastGroupedItineraries && (React.createElement(React.Fragment, null,
                                lastGroupedMetadata && (React.createElement(GroupedFilters, { metadata: lastGroupedMetadata, values: queryParams, onChange: setQueryParams, isLoading: loading })),
                                React.createElement(GroupedCards, { groupedItineraries: lastGroupedItineraries, searchIdRef: searchIdRef }))),
                            React.createElement(Stack, { sx: { mt: 3, alignItems: 'center' } }, lastItineraries &&
                                lastMetadata &&
                                lastItineraries.length < lastMetadata.itinerariesCount ? (React.createElement(Button, { variant: "contained", color: "primary", sx: {
                                    p: 2,
                                }, onClick: handleFetchMoreClick }, onewayOffsetItineraries.loading ||
                                returnOffsetItineraries.loading ? (React.createElement(CircularProgress, null)) : (t('fetchMore.text')))) : null)))))))),
        !loading &&
            lastItineraries &&
            isMobile &&
            !searchError &&
            !validationErrors && (React.createElement(Filters, { metadata: lastMetadata, values: queryParams, onChange: setQueryParams, isLoading: loading })),
        React.createElement(Dialog, { open: isDialogShown, onClose: function () { } },
            React.createElement(Alert, { sx: {
                    py: 2,
                    px: '1.75rem',
                    '& .MuiAlert-icon': {
                        color: 'primary.main',
                    },
                }, severity: "info" },
                React.createElement(AlertTitle, { sx: { fontSize: '16px' } }, t('bonus.warning')),
                React.createElement(Typography, { variant: "body1" }, t('modal.search_results_have_expired')),
                React.createElement(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: '8px' } },
                    React.createElement(Button, { variant: "contained", onClick: function () { return window.location.reload(); }, sx: { margin: '8px 0 0 0' } }, t('common.refresh')),
                    React.createElement(Button, { variant: "contained", onClick: function () { return window.open('/', '_self'); }, sx: { margin: '8px 0 0 0' } }, t('common.toTheMain')))))));
}
export default SearchResults;
