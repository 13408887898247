var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Collapse, Divider, FormControl, FormControlLabel, Radio, Stack, Typography, } from '@material-ui/core';
import { Box } from '@material-ui/system';
import Visa from 'app/assets/icons/Visa.svg';
import MasterCard from 'app/assets/icons/MasterCard.svg';
import { find } from 'lodash';
import ErrorHelperText from 'app/components/Mui/Error/ErrorHelperText';
import useTranslation from 'app/hooks/useTranslation';
import { configContactEmail } from 'app/util/siteConfig';
import CardForm from './CardForm';
var PaymentMethod = function (_a) {
    var method = _a.method, paymentButtonRef = _a.paymentButtonRef, baseCurrency = _a.baseCurrency;
    var _b = useFormContext(), control = _b.control, watch = _b.watch, errors = _b.formState.errors, setValue = _b.setValue;
    var t = useTranslation();
    var watchPaymentMethod = watch('methodHandle', 'card_direct');
    var watchCurrency = watch('currency', baseCurrency.code);
    var watchGatewayId = watch('gatewayId');
    var handleGateway = function (gatewayId, callback) {
        var _a, _b;
        if (gatewayId === watchGatewayId) {
            var y = (_a = paymentButtonRef.current) === null || _a === void 0 ? void 0 : _a.offsetTop;
            (_b = paymentButtonRef.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
        callback(gatewayId);
    };
    useEffect(function () {
        var _a;
        if (method.gateways && watchPaymentMethod === 'card_redirect') {
            var defaultGatewayId = (_a = find(method.gateways, function (gateway) { return gateway.isDefault; })) === null || _a === void 0 ? void 0 : _a.id;
            if (defaultGatewayId) {
                setValue('gatewayId', defaultGatewayId);
            }
        }
        else if (method.cardGateway &&
            method.cardGateway.id &&
            watchPaymentMethod === 'card_direct') {
            setValue('gatewayId', method.cardGateway.id);
        }
    }, [method.gateways, watchPaymentMethod]);
    return (React.createElement(Stack, { sx: __assign({ border: method.methodHandle === watchPaymentMethod
                ? '1px solid #1976D2'
                : '1px solid #bdbdbd', borderRadius: 1, py: 1, px: 2, mt: 2 }, (!!(errors === null || errors === void 0 ? void 0 : errors.methodHandle) && {
            borderColor: 'error.main',
        })) },
        React.createElement(Controller, { control: control, name: "methodHandle", render: function (_a) {
                var _b = _a.field, onChange = _b.onChange, value = _b.value, fieldState = _a.fieldState;
                return (React.createElement(FormControlLabel, { value: method.methodHandle, control: React.createElement(Radio, { checked: value === method.methodHandle, onChange: onChange, sx: __assign({}, (fieldState.error && {
                            color: 'error.main',
                        })) }), label: React.createElement(Stack, { sx: {
                            width: '100%',
                        }, direction: "row" },
                        React.createElement(Box, { component: "img", src: method.icon, sx: { ml: 1, mr: 1 } }),
                        React.createElement(Typography, { variant: "body2", sx: { color: 'primary.dark' } }, method.name),
                        method.gateways && (React.createElement(Stack, { direction: "row", sx: { position: 'absolute', right: '1rem' } },
                            React.createElement(Box, { component: "img", src: Visa, mr: 1 }),
                            React.createElement(Box, { component: "img", src: MasterCard })))) }));
            } }),
        watchPaymentMethod === 'card_direct' &&
            watchPaymentMethod === method.methodHandle && React.createElement(CardForm, null),
        method.gateways && watchPaymentMethod === 'card_redirect' && (React.createElement(Collapse, { in: true },
            React.createElement(Divider, { sx: { my: 2 } }),
            React.createElement(FormControl, { component: "fieldset", name: "gatewayId" },
                React.createElement(Typography, { sx: { fontWeight: 500, fontSize: '12px' } }, t('payment.selectProvider')),
                (errors === null || errors === void 0 ? void 0 : errors.gatewayId) && (React.createElement(ErrorHelperText, { sx: { mt: 2 } }, t('payment.selectOption'))),
                React.createElement(Stack, { direction: { xs: 'column', sm: 'row' }, justifyContent: "flex-start", alignItems: "center" }, method.gateways
                    .filter(function (iss) { var _a; return (_a = iss.currencies) === null || _a === void 0 ? void 0 : _a.some(function (c) { return c.code === watchCurrency; }); })
                    .map(function (iss, index) { return (React.createElement(Controller, { key: index, control: control, rules: { required: true }, name: "gatewayId", render: function (_a) {
                        var onChange = _a.field.onChange, fieldState = _a.fieldState;
                        return (React.createElement(React.Fragment, null,
                            React.createElement(Stack, { key: index, role: "button", sx: __assign({ m: 2, ml: { md: 0 }, p: 2, border: '1px solid', borderColor: iss.id === watchGatewayId
                                        ? 'primary.main'
                                        : '#bdbdbd', cursor: 'pointer', boxShadow: iss.id === watchGatewayId
                                        ? 'none'
                                        : '0px 2px 10px rgba(25, 1, 52, 0.12)', background: iss.id === watchGatewayId
                                        ? 'linear-gradient(0deg, rgba(25, 119, 210, 0.07), rgba(25, 119, 210, 0.07))'
                                        : 'unset', borderRadius: 1, textAlign: 'center', fontSize: 14, alignItems: 'center', justifyContent: 'center', overflow: 'hidden', width: '100%', maxWidth: { md: '250px' } }, (fieldState.error && {
                                    borderColor: 'error.main',
                                })), onClick: function () { return handleGateway(iss.id, onChange); } }, iss.imageUrl ? (React.createElement(Stack, { justifyContent: "center", alignItems: "center" },
                                React.createElement(Box, { sx: {
                                        height: { xs: '28px', sm: '38px' },
                                    }, component: "img", src: iss.imageUrl }))) : (iss.name))));
                    } })); }))),
            method.description && (React.createElement(React.Fragment, null,
                React.createElement(Stack, null, method.description
                    .filter(function (desc) {
                    return desc.currencyCode === watchCurrency;
                })
                    .map(function (desc) { return (React.createElement(Typography, { variant: "body2", color: "text.secondary" },
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: desc.body } }))); })))))),
        method.type === 'BANK_TRANSFER' &&
            watchPaymentMethod === 'bank_transfer' && (React.createElement(Collapse, { in: true },
            React.createElement(Divider, { sx: { my: 2 } }),
            React.createElement(Box, { sx: {
                    margin: {
                        xs: '0 0 24px 0',
                        md: '0 0 30px 0',
                    },
                } },
                React.createElement(Box, { sx: {
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '160%',
                        color: '#003352',
                        margin: '0 0 14px 0',
                    } }, t('payment.bankTransfer')),
                React.createElement(Box, { sx: {
                        fontSize: '14px',
                        lineHeight: '120%',
                        letterSpacing: '0.01em',
                        color: '#828282',
                    } },
                    React.createElement("p", null, t('payment.bankTransfer.P1')),
                    React.createElement("p", null, "\u00A0"),
                    React.createElement("p", null, t('payment.bankTransfer.P2')))),
            React.createElement(Box, { sx: {
                    margin: '0 0 16px 0',
                } },
                React.createElement(Box, { sx: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        gap: '16px',
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '160%',
                        color: '#003352',
                        margin: '0 0 14px 0',
                    } }, t('payment.cashDeposit')),
                React.createElement(Box, { sx: {
                        fontSize: '14px',
                        lineHeight: '120%',
                        letterSpacing: '0.01em',
                        color: '#828282',
                    } },
                    React.createElement("p", null, t('payment.cashDeposit.P1')),
                    React.createElement("p", null, "\u00A0"),
                    React.createElement("p", null, t('payment.cashDeposit.P2')))),
            React.createElement(Box, { sx: {
                    fontSize: '14px',
                    lineHeight: '120%',
                    background: '#E4F5FF',
                    borderRadius: '8px',
                    padding: { xs: '16px', md: '24px' },
                    color: '#003352',
                } },
                React.createElement("p", null,
                    t('payment.hint.P1'),
                    ' ',
                    React.createElement("a", { href: "mailto:" + configContactEmail, style: { color: '#1976D2', textDecoration: 'underline' } }, configContactEmail)),
                React.createElement("p", null, "\u00A0"),
                React.createElement("p", null, t('payment.hint.P2'))))),
        method.gateways && watchPaymentMethod === 'card_redirect' && (React.createElement(Collapse, { in: true },
            React.createElement(Divider, { sx: { my: 2 } }),
            React.createElement(FormControl, { component: "fieldset", name: "gatewayId" },
                React.createElement(Typography, { sx: { fontWeight: 500, fontSize: '12px' } }, t('payment.selectProvider')),
                (errors === null || errors === void 0 ? void 0 : errors.gatewayId) && (React.createElement(ErrorHelperText, { sx: { mt: 2 } }, t('payment.selectOption'))),
                React.createElement(Stack, { direction: { xs: 'column', sm: 'row' }, justifyContent: "flex-start", alignItems: "center" }, method.gateways
                    .filter(function (iss) { var _a; return (_a = iss.currencies) === null || _a === void 0 ? void 0 : _a.some(function (c) { return c.code === watchCurrency; }); })
                    .map(function (iss, index) { return (React.createElement(Controller, { key: index, control: control, rules: { required: true }, name: "gatewayId", render: function (_a) {
                        var onChange = _a.field.onChange, fieldState = _a.fieldState;
                        return (React.createElement(React.Fragment, null,
                            React.createElement(Stack, { key: index, role: "button", sx: __assign({ m: 2, ml: { md: 0 }, p: 2, border: '1px solid', borderColor: iss.id === watchGatewayId
                                        ? 'primary.main'
                                        : '#bdbdbd', cursor: 'pointer', boxShadow: iss.id === watchGatewayId
                                        ? 'none'
                                        : '0px 2px 10px rgba(25, 1, 52, 0.12)', background: iss.id === watchGatewayId
                                        ? 'linear-gradient(0deg, rgba(25, 119, 210, 0.07), rgba(25, 119, 210, 0.07))'
                                        : 'unset', borderRadius: 1, textAlign: 'center', fontSize: 14, alignItems: 'center', justifyContent: 'center', overflow: 'hidden', width: '100%', maxWidth: { md: '250px' } }, (fieldState.error && {
                                    borderColor: 'error.main',
                                })), onClick: function () { return handleGateway(iss.id, onChange); } }, iss.imageUrl ? (React.createElement(Stack, { justifyContent: "center", alignItems: "center" },
                                React.createElement(Box, { sx: {
                                        height: { xs: '28px', sm: '38px' },
                                    }, component: "img", src: iss.imageUrl }))) : (iss.name))));
                    } })); }))),
            method.description && (React.createElement(React.Fragment, null,
                React.createElement(Stack, null, method.description
                    .filter(function (desc) {
                    return desc.currencyCode === watchCurrency;
                })
                    .map(function (desc) { return (React.createElement(Typography, { variant: "body2", color: "text.secondary" },
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: desc.body } }))); }))))))));
};
export default PaymentMethod;
