import React, { useState, useEffect } from 'react';
import { flightClasses, activeFlightClassSelector, setFlightClass, } from '../../../redux/slices/flightSearch.slice';
import { useSelector, useDispatch } from 'react-redux';
import useTranslation from '../../../hooks/useTranslation';
import { FlightClassToggle, FlightClassList, FlightClassRoot, FlightClassMenuAnchor, FlightClassItem, } from './elements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
var FlightClassSelect = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var dispatch = useDispatch();
    var t = useTranslation();
    useEffect(function () {
        if (isOpen) {
            var callback_1 = function () {
                setIsOpen(false);
                document.removeEventListener('click', callback_1);
            };
            document.addEventListener('click', callback_1);
            return function () {
                document.removeEventListener('click', callback_1);
            };
        }
    }, [isOpen]);
    var activeFlightClass = useSelector(activeFlightClassSelector);
    return (React.createElement(FlightClassRoot, { onClick: function (e) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        } },
        React.createElement(FlightClassToggle, { isOpen: isOpen, onClick: function () { return setIsOpen(!isOpen); } },
            t('flightClass.title'),
            ": ",
            t("flightClass." + activeFlightClass),
            ' ',
            React.createElement(FontAwesomeIcon, { size: "xs", icon: faChevronDown })),
        React.createElement(FlightClassMenuAnchor, null, isOpen ? (React.createElement(FlightClassList, null, flightClasses.map(function (fc) { return (React.createElement(FlightClassItem, { key: fc, onClick: function () {
                dispatch(setFlightClass(fc));
                setIsOpen(false);
            } }, t("flightClass." + fc))); }))) : null)));
};
export default FlightClassSelect;
