var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addDays, format, parseISO, startOfToday, startOfTomorrow, } from 'date-fns';
import { ensureNoSharedAirports, ensureUniqueAirports } from './helpers';
import { CabinClassType } from 'app/generated/graphql';
export var searchTypes = ['oneWay', 'twoWay', 'multiple'];
export var flightClasses = [
    'economy',
    'businessEconomy',
    'business',
];
export var passengerAges = ['adult', 'child', 'infant'];
export var internalDateFormat = 'yyyy-MM-dd';
var getLatestFlightIntervalDate = function (date) {
    return parseISO(Array.isArray(date) ? date[1] : date);
};
var initialState = {
    type: 'twoWay',
    flightClass: 'economy',
    passengerCount: {
        adult: 1,
        child: 0,
        infant: 0,
    },
    focusReturnDate: false,
    stayDuration: null,
    flights: [
        {
            departureAirportOptions: [],
            arrivalAirportOptions: [],
            date: format(startOfTomorrow(), internalDateFormat),
        },
        {
            departureAirportOptions: [],
            arrivalAirportOptions: [],
            date: format(addDays(startOfTomorrow(), 3), internalDateFormat),
        },
        null,
    ],
    nearbyPlaces: null,
    placesHint: false,
};
var _a = createSlice({
    name: 'flightSearch',
    initialState: initialState,
    reducers: {
        setInitialState: function (state, action) {
            return __assign({}, action.payload);
        },
        setSearchType: function (state, action) {
            var type = action.payload;
            state.type = type;
            state.stayDuration = null;
            switch (type) {
                case 'oneWay': {
                    state.flights[1] = null;
                    state.flights[2] = null;
                    break;
                }
                case 'twoWay': {
                    state.flights[1] = {
                        departureAirportOptions: state.flights[0].arrivalAirportOptions,
                        arrivalAirportOptions: state.flights[0].departureAirportOptions,
                        date: format(addDays(getLatestFlightIntervalDate(state.flights[0].date), 3), internalDateFormat),
                    };
                    state.flights[2] = null;
                    break;
                }
                case 'multiple': {
                    state.flights[1] = {
                        departureAirportOptions: state.flights[0].arrivalAirportOptions,
                        arrivalAirportOptions: [],
                        date: format(addDays(getLatestFlightIntervalDate(state.flights[0].date), 3), internalDateFormat),
                    };
                    state.flights[2] = null;
                    break;
                }
            }
        },
        addThirdFlight: function (state) {
            state.flights[2] = {
                departureAirportOptions: state.flights[1].arrivalAirportOptions,
                arrivalAirportOptions: [],
                date: format(addDays(getLatestFlightIntervalDate(state.flights[1].date), 3), internalDateFormat),
            };
        },
        setFlightClass: function (state, action) {
            state.flightClass = action.payload;
        },
        setInitialPassengerCount: function (state, action) {
            state.passengerCount = action.payload;
        },
        updatePassengerCount: function (state, action) {
            var _a = action.payload, age = _a.age, direction = _a.direction;
            var maxTotalPassengerCount = 18;
            var maxPassengerCount = 9;
            var count = state.passengerCount[age];
            if (count === 1 && direction === 'down' && age === 'adult') {
                return;
            }
            if (count === maxPassengerCount &&
                direction === 'up' &&
                (age === 'adult' || age === 'infant')) {
                return;
            }
            if (age === 'infant' && direction === 'up') {
                if (state.passengerCount.infant >= state.passengerCount.adult) {
                    return;
                }
            }
            if (direction === 'up' &&
                (age === 'adult' || age === 'child') &&
                state.passengerCount.adult + state.passengerCount.child >=
                    maxPassengerCount) {
                return;
            }
            if (count === 0 && direction === 'down') {
                return;
            }
            state.passengerCount[age] = direction === 'up' ? count + 1 : count - 1;
            if (age === 'adult' &&
                direction === 'down' &&
                state.passengerCount.infant > state.passengerCount.adult) {
                state.passengerCount.infant = state.passengerCount.adult;
            }
        },
        setDepartureAirports: function (state, action) {
            var _a = action.payload, flightIndex = _a.flightIndex, airports = _a.airports;
            state.flights[flightIndex].departureAirportOptions =
                ensureUniqueAirports(airports);
            state.flights[flightIndex].arrivalAirportOptions =
                ensureNoSharedAirports(airports, state.flights[flightIndex].arrivalAirportOptions);
        },
        setArrivalAirports: function (state, action) {
            var _a = action.payload, flightIndex = _a.flightIndex, airports = _a.airports;
            state.flights[flightIndex].arrivalAirportOptions =
                ensureUniqueAirports(airports);
            state.flights[flightIndex].departureAirportOptions =
                ensureNoSharedAirports(airports, state.flights[flightIndex].departureAirportOptions);
        },
        reverseFlightDirection: function (state, action) {
            var flightIndex = action.payload.flightIndex;
            var flight = state.flights[flightIndex];
            if (flight) {
                var arrivalAirportOptions = flight.arrivalAirportOptions, departureAirportOptions = flight.departureAirportOptions;
                flight.arrivalAirportOptions = __spreadArray([], departureAirportOptions, true);
                flight.departureAirportOptions = __spreadArray([], arrivalAirportOptions, true);
            }
        },
        replayFlightSearch: function (state, action) {
            return action.payload;
        },
        setFlightDate: function (state, action) {
            var _a = action.payload, date = _a.date, flightIndex = _a.flightIndex;
            state.flights[flightIndex].date = date;
            var payloadDate = Array.isArray(date)
                ? [parseISO(date[0]), parseISO(date[1])]
                : [parseISO(date), parseISO(date)];
            for (var i = 0; i <= flightIndex - 1; i++) {
                if (!state.flights[i]) {
                    break;
                }
                var currentDate = state.flights[i].date;
                if (Array.isArray(currentDate)) {
                    var startDate = parseISO(currentDate[0]);
                    var endDate = parseISO(currentDate[1]);
                    if (payloadDate[0] < startDate) {
                        startDate = payloadDate[0];
                    }
                    if (payloadDate[0] < endDate) {
                        endDate = payloadDate[0];
                    }
                    if (startDate === endDate) {
                        state.flights[i].date = format(startDate, internalDateFormat);
                    }
                    else {
                        state.flights[i].date = [
                            format(startDate, internalDateFormat),
                            format(endDate, internalDateFormat),
                        ];
                    }
                    if (state.stayDuration && flightIndex === 1) {
                        state.stayDuration = null;
                    }
                }
                else {
                    var date_1 = parseISO(currentDate);
                    if (payloadDate[0] < date_1) {
                        state.flights[i].date = format(payloadDate[0], internalDateFormat);
                    }
                    if (state.stayDuration && flightIndex === 1) {
                        state.stayDuration = null;
                    }
                }
            }
            for (var i = flightIndex + 1; i <= 2; i++) {
                if (!state.flights[i]) {
                    break;
                }
                var previousFlight = state.flights[i - 1];
                var previousLatestDate = getLatestFlightIntervalDate(previousFlight.date);
                var currentDate = state.flights[i].date;
                if (Array.isArray(currentDate)) {
                    var startDate = parseISO(currentDate[0]);
                    var endDate = parseISO(currentDate[1]);
                    if (previousLatestDate > startDate) {
                        startDate = previousLatestDate;
                    }
                    if (previousLatestDate > endDate) {
                        endDate = previousLatestDate;
                    }
                    if (startDate === endDate) {
                        state.flights[i].date = format(startDate, internalDateFormat);
                    }
                    else {
                        state.flights[i].date = [
                            format(startDate, internalDateFormat),
                            format(endDate, internalDateFormat),
                        ];
                    }
                    if (state.stayDuration && flightIndex === 1) {
                        state.stayDuration = null;
                    }
                }
                else {
                    var date_2 = parseISO(currentDate);
                    if (date_2 < previousLatestDate) {
                        state.flights[i].date = format(previousLatestDate, internalDateFormat);
                    }
                    if (state.stayDuration && flightIndex === 1) {
                        state.stayDuration = null;
                    }
                }
            }
        },
        setStayDuration: function (state, action) {
            state.stayDuration = action.payload;
        },
        deleteFlight: function (state, action) {
            var flights = state.flights.filter(function (_, i) { return i !== action.payload.flightIndex; });
            flights[1] = flights[1] || null;
            flights[2] = flights[2] || null;
            state.flights = flights;
            if (!flights[1]) {
                state.type = 'oneWay';
            }
        },
        setFlightError: function (state, action) {
            if (state.flights[action.payload.flightIndex].errors) {
                state.flights[action.payload.flightIndex].errors.push(action.payload.error);
            }
            else {
                state.flights[action.payload.flightIndex].errors = [
                    action.payload.error,
                ];
            }
        },
        clearFlightError: function (state, action) {
            state.flights[action.payload.flightIndex].errors = undefined;
        },
        toggleReturnDateFocus: function (state, action) {
            state.focusReturnDate = action.payload;
        },
        setNearbyPlaces: function (state, action) {
            state.nearbyPlaces = action.payload;
        },
    },
}), actions = _a.actions, reducer = _a.reducer;
export default reducer;
export var setSearchType = actions.setSearchType, setDepartureAirports = actions.setDepartureAirports, setArrivalAirports = actions.setArrivalAirports, setFlightClass = actions.setFlightClass, setInitialPassengerCount = actions.setInitialPassengerCount, updatePassengerCount = actions.updatePassengerCount, reverseFlightDirection = actions.reverseFlightDirection, replayFlightSearch = actions.replayFlightSearch, addThirdFlight = actions.addThirdFlight, setFlightDate = actions.setFlightDate, setInitialState = actions.setInitialState, setStayDuration = actions.setStayDuration, deleteFlight = actions.deleteFlight, toggleReturnDateFocus = actions.toggleReturnDateFocus, setFlightError = actions.setFlightError, clearFlightError = actions.clearFlightError, setNearbyPlaces = actions.setNearbyPlaces;
export var flightSearchStateSelector = function (state) {
    return state.flightSearch;
};
export var activeSearchTypeSelector = createSelector([flightSearchStateSelector], function (flightSearchState) { return flightSearchState.type; });
export var activeFlightClassSelector = createSelector([flightSearchStateSelector], function (flightSearchState) { return flightSearchState.flightClass; });
export var passengerCountStateSelector = createSelector([flightSearchStateSelector], function (flightSearchState) { return flightSearchState.passengerCount; });
export var totalPassengerCountSelector = createSelector([passengerCountStateSelector], function (passengerCountState) {
    return passengerCountState.child +
        passengerCountState.adult +
        passengerCountState.infant;
});
export var formFlightsSelector = createSelector([flightSearchStateSelector], function (flightSearchState) { return flightSearchState.flights; });
export var stayDurationSelector = createSelector([flightSearchStateSelector], function (flightSearchState) { return flightSearchState.stayDuration; });
export var flightByIndexSelector = createSelector([
    flightSearchStateSelector,
    function (_, flightIndex) { return flightIndex; },
], function (flightSearchState, flightIndex) { return flightSearchState.flights[flightIndex]; });
export var flightsSelector = createSelector([flightSearchStateSelector], function (flightSearchState) { return flightSearchState.flights; });
export var otherFlightValuesSelector = createSelector([
    flightsSelector,
    activeSearchTypeSelector,
    function (_, flightIndex) { return flightIndex; },
], function (flights, activeSearchType, flightIndex) {
    switch (activeSearchType) {
        case 'oneWay':
            return [];
        case 'twoWay': {
            var otherIndex = 1 - flightIndex;
            return [
                {
                    type: otherIndex === 0 ? 'departure' : 'arrival',
                    value: flights[otherIndex].date,
                    index: otherIndex,
                },
            ];
        }
        case 'multiple': {
            return flights
                .map(function (flight, i) {
                return flight && i !== flightIndex
                    ? {
                        index: i,
                        type: 'other',
                        value: flight.date,
                    }
                    : null;
            })
                .filter(function (x) { return x !== null; });
        }
    }
});
var flightClassToCabinClass = {
    economy: CabinClassType.Economy,
    business: CabinClassType.Business,
    businessEconomy: CabinClassType.PremiumEconomy,
};
export var flightSearchCalendarInputSelector = createSelector([
    flightSearchStateSelector,
    activeFlightClassSelector,
    passengerCountStateSelector,
    function (_, flightIndex) { return flightIndex; },
], function (flightSearchState, flightClass, passengerCountState, flightIndex) {
    var flight = flightSearchState.flights[flightIndex];
    if (!flight) {
        return {
            source: [''],
            destination: [''],
        };
    }
    return {
        source: flight.departureAirportOptions.map(function (dao) { return dao.code; }),
        destination: flight.arrivalAirportOptions.map(function (aao) { return aao.code; }),
    };
});
export var flightMinDateSelector = function (flightIndex) {
    return createSelector([flightSearchStateSelector], function (flightSearchState) {
        var flight = flightSearchState.flights[flightIndex - 1];
        // if (flight) {
        //   return getLatestFlightIntervalDate(flight.date);
        // }
        return startOfToday();
    });
};
export var shouldFocusReturnDateSelector = createSelector([flightSearchStateSelector], function (flightSearchState) { return flightSearchState.focusReturnDate; });
export var nearbyPlacesSelector = createSelector([flightSearchStateSelector], function (flightSearchState) { return flightSearchState.nearbyPlaces; });
