var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var Cached = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M1.19043 2.04605V5.76034M1.19043 5.76034H4.90472M1.19043 5.76034L4.06281 3.06129C4.72813 2.39563 5.55123 1.90937 6.45531 1.64786C7.35939 1.38636 8.31498 1.35813 9.23292 1.56583C10.1509 1.77352 11.0012 2.21037 11.7047 2.83559C12.4081 3.46082 12.9417 4.25406 13.2557 5.14129M14.8095 11.9508V8.23653M14.8095 8.23653H11.0952M14.8095 8.23653L11.9371 10.9356C11.2718 11.6012 10.4487 12.0875 9.54459 12.349C8.64052 12.6105 7.68492 12.6387 6.76699 12.431C5.84905 12.2233 4.99869 11.7865 4.29524 11.1613C3.5918 10.536 3.0582 9.7428 2.74424 8.85557", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default Cached;
