var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var PassengerCountRoot = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 142px;\n  border-radius: 0.25rem;\n  height: 47px;\n  font-size: 14px;\n  line-height: 15.4px;\n  border: 1px solid ", ";\n\n  ", " {\n    border: none;\n    height: 31px;\n    border-radius: 31px;\n    margin-bottom: 1em;\n    background: #fff;\n    width: 100%;\n    margin-top: 10px;\n  }\n"], ["\n  width: 142px;\n  border-radius: 0.25rem;\n  height: 47px;\n  font-size: 14px;\n  line-height: 15.4px;\n  border: 1px solid ", ";\n\n  ", " {\n    border: none;\n    height: 31px;\n    border-radius: 31px;\n    margin-bottom: 1em;\n    background: #fff;\n    width: 100%;\n    margin-top: 10px;\n  }\n"])), function (p) { return p.theme.palette.text.disabled; }, function (p) { return p.theme.breakpoints.down('sm'); });
export var PassengerCountToggle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  cursor: pointer;\n  height: 100%;\n  width: 100%;\n  padding-left: 1em;\n  display: flex;\n  justify-content: space-between;\n\n  > div > .fa-chevron-down {\n    transition: transform 0.4s;\n    color: black;\n    margin-left: 6px;\n    ", "\n  }\n\n  ", " {\n    border-radius: 31px;\n    width: 100%;\n    justify-content: center;\n  }\n"], ["\n  align-items: center;\n  cursor: pointer;\n  height: 100%;\n  width: 100%;\n  padding-left: 1em;\n  display: flex;\n  justify-content: space-between;\n\n  > div > .fa-chevron-down {\n    transition: transform 0.4s;\n    color: black;\n    margin-left: 6px;\n    ", "\n  }\n\n  ", " {\n    border-radius: 31px;\n    width: 100%;\n    justify-content: center;\n  }\n"])), function (props) { return (props.isOpen ? 'transform: rotate(-180deg);' : ''); }, function (p) { return p.theme.breakpoints.down('sm'); });
export var PassengerCountList = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  background: #fff;\n  right: -15px;\n  top: 17px;\n  padding: 4px 0;\n  border: 1px solid #1976d2;\n\n  box-shadow: 0 4px 10px rgba(1, 21, 52, 0.16);\n  border-radius: 4px;\n\n  z-index: 2;\n\n  ", " {\n    width: 100%;\n    height: 100%;\n    border: none;\n    box-shadow: none;\n    position: unset;\n    left: 0;\n    right: auto;\n  }\n"], ["\n  position: absolute;\n  background: #fff;\n  right: -15px;\n  top: 17px;\n  padding: 4px 0;\n  border: 1px solid #1976d2;\n\n  box-shadow: 0 4px 10px rgba(1, 21, 52, 0.16);\n  border-radius: 4px;\n\n  z-index: 2;\n\n  ", " {\n    width: 100%;\n    height: 100%;\n    border: none;\n    box-shadow: none;\n    position: unset;\n    left: 0;\n    right: auto;\n  }\n"])), function (p) { return p.theme.breakpoints.down('sm'); });
export var PassengerCountMenuAnchor = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var PassengerCountItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 8px 16px;\n  width: 240px;\n\n  &:not(:last-child) {\n    border-bottom: 1px solid rgba(0, 82, 255, 0.08);\n  }\n\n  ", " {\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 8px 16px;\n  width: 240px;\n\n  &:not(:last-child) {\n    border-bottom: 1px solid rgba(0, 82, 255, 0.08);\n  }\n\n  ", " {\n    width: 100%;\n  }\n"])), function (p) { return p.theme.breakpoints.down('sm'); });
export var PassengerAgesDescription = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  line-height: 120%;\n  font-size: 12px;\n  color: #888;\n  margin: 4px 0 0 0;\n"], ["\n  line-height: 120%;\n  font-size: 12px;\n  color: #888;\n  margin: 4px 0 0 0;\n"])));
export var PassengerCounterButtons = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 80px;\n  justify-content: space-between;\n\n  button {\n    border-radius: 0.1rem;\n    font-size: 12px;\n    width: 24px;\n    height: 24px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: ", ";\n    color: #fff;\n    &:disabled {\n      background: #e0e0e0;\n      cursor: not-allowed !important;\n    }\n    @media screen and (max-width: 768px) {\n      background: ", ";\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  width: 80px;\n  justify-content: space-between;\n\n  button {\n    border-radius: 0.1rem;\n    font-size: 12px;\n    width: 24px;\n    height: 24px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: ", ";\n    color: #fff;\n    &:disabled {\n      background: #e0e0e0;\n      cursor: not-allowed !important;\n    }\n    @media screen and (max-width: 768px) {\n      background: ", ";\n    }\n  }\n"])), function (p) { return p.theme.palette.primary.main; }, function (p) { return p.theme.palette.primary.dark; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
