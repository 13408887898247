import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Masonry from '@mui/lab/Masonry';
import img1 from '../../assets/illustrations/our-advantages1.svg';
import img2 from '../../assets/illustrations/our-advantages2.svg';
import img3 from '../../assets/illustrations/our-advantages3.svg';
import img4 from '../../assets/illustrations/our-advantages4.svg';
import img5 from '../../assets/illustrations/our-advantages5.svg';
import img6 from '../../assets/illustrations/our-advantages6.svg';
import useTranslation from 'app/hooks/useTranslation';
import { configSiteName } from 'app/util/siteConfig';
var OurAdvantages = function () {
    var t = useTranslation();
    var data = [
        {
            img: img1,
            title: t('ourAdvantages.list1.title'),
            desc: t('ourAdvantages.list1.desc', { configSiteName: configSiteName }),
        },
        {
            img: img2,
            title: t('ourAdvantages.list2.title'),
            desc: t('ourAdvantages.list2.desc'),
        },
        {
            img: img3,
            title: t('ourAdvantages.list3.title'),
            desc: t('ourAdvantages.list3.desc', { configSiteName: configSiteName }),
        },
        {
            img: img4,
            title: t('ourAdvantages.list4.title'),
            desc: t('ourAdvantages.list4.desc', { configSiteName: configSiteName }),
        },
        {
            img: img5,
            title: t('ourAdvantages.list5.title'),
            desc: t('ourAdvantages.list5.desc', { configSiteName: configSiteName }),
        },
        {
            img: img6,
            title: t('ourAdvantages.list6.title'),
            desc: t('ourAdvantages.list6.desc', { configSiteName: configSiteName }),
        },
    ];
    return (React.createElement(Box, { sx: {
            padding: {
                xs: '40px 16px',
                md: '80px 16px',
            },
        } },
        React.createElement(Typography, { variant: "h2", sx: {
                margin: '0 0 8px 0',
                textAlign: 'center',
                color: function (theme) { return theme.palette.primary.dark; },
            } }, t('ourAdvantages.title')),
        React.createElement(Typography, { sx: { fontSize: '16px', color: '#828282', textAlign: 'center' } }, t('ourAdvantages.subtitle', { configSiteName: configSiteName })),
        React.createElement(Masonry, { columns: { xs: 1, md: 2, lg: 3 }, spacing: 0, sx: {
                margin: {
                    xs: '24px 0 0 0',
                    md: '48px 0 0 0',
                },
            } }, data.map(function (item, key) { return (React.createElement(Box, { sx: {
                padding: {
                    xs: '8px 0',
                    md: '12px',
                },
                '&:nth-of-type(2)': {
                    padding: {
                        xs: '8px 0',
                        md: '80px 12px 12px',
                    },
                },
            }, key: key },
            React.createElement(Box, { sx: {
                    background: '#FFFFFF',
                    boxShadow: '0px 8px 32px rgba(0, 85, 104, 0.1)',
                    borderRadius: '32px',
                    padding: '48px 32px',
                } },
                React.createElement(Box, { sx: {
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        margin: '0 0 16px 0',
                    } },
                    React.createElement(Box, { component: 'img', src: item.img, alt: configSiteName, sx: { width: { xs: '56px', md: '62px' } } }),
                    React.createElement(Typography, { sx: {
                            fontWeight: 600,
                            fontSize: { xs: '18px', md: '20px' },
                            lineHeight: '120%',
                            color: 'primary.dark',
                        } }, item.title)),
                React.createElement(Typography, { sx: { fontSize: '14px', lineHeight: '160%' } }, item.desc)))); }))));
};
export default OurAdvantages;
