import { addSeconds, formatDuration, intervalToDuration } from 'date-fns';
var getDuration = function (duration, locale) {
    var durationInterval = intervalToDuration({
        start: new Date(),
        end: addSeconds(new Date(), duration),
    });
    if (durationInterval.days) {
        durationInterval.hours = (durationInterval === null || durationInterval === void 0 ? void 0 : durationInterval.hours) ? (durationInterval === null || durationInterval === void 0 ? void 0 : durationInterval.hours) + (durationInterval.days * 24) : durationInterval.days * 24;
        durationInterval.days = 0;
    }
    ;
    return formatDuration(durationInterval, { locale: locale, format: ['hours', 'minutes'] });
};
export default getDuration;
