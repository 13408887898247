var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Card, Grid, MenuItem, Stack, TextField, Typography, } from '@material-ui/core';
import { Box } from '@material-ui/system';
import Autocomplete from '@mui/material/Autocomplete';
import useTranslation from 'app/hooks/useTranslation';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Check from '../../assets/icons/Check';
import Close from '../../assets/icons/Close';
import Stroller from '../../assets/icons/Stroller';
import LatinLettersField from '../fields/LatinLettersField';
import { MaskedDateField } from '../fields/MaskedDayField';
import Baggage from './Baggage';
import SavedPassengers from './SavedPassengers';
var Passenger = function (_a) {
    var passenger = _a.passenger, metadata = _a.metadata, index = _a.index, savedPassengers = _a.savedPassengers;
    var _b = useFormContext(), control = _b.control, setValue = _b.setValue;
    var countries = metadata.countries;
    var infantsConditions = metadata.infantsConditions;
    var t = useTranslation();
    var genderOptions = __spreadArray([
        { text: '', identifier: '' }
    ], passenger.gender.option, true);
    return (React.createElement(Card, { sx: {
            mx: { xs: 2, md: 'unset' },
            mt: 2,
            alignSelf: 'center',
            width: '100%',
            maxWidth: { xs: '95vw', md: 'unset' },
            p: 2,
        }, className: "passenger" },
        React.createElement(Stack, { direction: "row", spacing: 1, sx: { py: 1 }, alignItems: "flex-end" },
            React.createElement(Typography, { variant: "h5", sx: { color: function (theme) { return theme.palette.primary.dark; } } }, t('common.passenger') + " " + (index + 1)),
            React.createElement(Typography, { variant: "body2", color: "text.secondary", sx: { padding: '0 0 1px 0' } }, t("passengerType." + passenger.passengerType.value))),
        savedPassengers && savedPassengers.length > 0 && (React.createElement(SavedPassengers, { passenger: passenger, savedPassengers: savedPassengers, index: index })),
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, container: true, spacing: 2 },
                React.createElement(Grid, { md: 2, xs: 4, item: true },
                    React.createElement(Controller, { name: "form.passengers." + index + "." + passenger.gender.name, control: control, rules: {
                            required: t('formErrors.requiredField'),
                        }, render: function (_a) {
                            var _b, _c, _d, _e;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(TextField, __assign({}, field, { disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "gender", variant: "outlined", label: t("passengerForm." + ((_d = passenger.gender) === null || _d === void 0 ? void 0 : _d.label.toLowerCase().split(' ').join('_'))), value: ((_e = passenger.gender.option.find(function (option) { return option.identifier === field.value; })) === null || _e === void 0 ? void 0 : _e.identifier) || '', select: true }), genderOptions.map(function (option, i) { return (React.createElement(MenuItem, { disabled: i === 0, value: option.identifier }, option.text)); })));
                        } })),
                React.createElement(Grid, { md: 5, xs: 8, item: true },
                    React.createElement(Controller, { name: "form.passengers." + index + "." + passenger.surname.name, rules: {
                            required: t('formErrors.requiredField'),
                        }, control: control, render: function (_a) {
                            var _b, _c, _d;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(LatinLettersField, __assign({ label: t("passengerForm." + ((_b = passenger.surname) === null || _b === void 0 ? void 0 : _b.label.toLowerCase().split(' ').join('_'))) }, field, { fullWidth: true, disabled: formState.isSubmitting, error: !!((_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message), helperText: (_d = fieldState.error) === null || _d === void 0 ? void 0 : _d.message, placeholder: t('passengerForm.surnamePlaceholder'), inputProps: {
                                    autoComplete: 'family-name',
                                } })));
                        } })),
                React.createElement(Grid, { md: 5, xs: 12, item: true },
                    React.createElement(Controller, { name: "form.passengers." + index + "." + passenger.name.name, rules: {
                            required: t('formErrors.requiredField'),
                        }, control: control, render: function (_a) {
                            var _b, _c, _d;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(LatinLettersField, __assign({ label: t("passengerForm." + ((_b = passenger.name) === null || _b === void 0 ? void 0 : _b.label.toLowerCase().split(' ').join('_'))) }, field, { fullWidth: true, disabled: formState.isSubmitting, error: !!((_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message), helperText: (_d = fieldState.error) === null || _d === void 0 ? void 0 : _d.message, placeholder: t('passengerForm.namePlaceholder'), inputProps: {
                                    autoComplete: 'given-name',
                                } })));
                        } }))),
            React.createElement(Grid, { item: true, container: true, spacing: 2, sx: { mt: 0 } },
                passenger.travelDocument && passenger.travelDocument.nationality && (React.createElement(Grid, { md: 6, xs: 12, item: true },
                    React.createElement(Controller, { name: "form.passengers." + index + ".travelDocument." + passenger.travelDocument.nationality.name, rules: {
                            required: t('formErrors.requiredField'),
                        }, control: control, render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(Autocomplete, __assign({}, field, { disabled: formState.isSubmitting, getOptionLabel: function (option) { return (option ? option.text : ''); }, value: (countries === null || countries === void 0 ? void 0 : countries.find(function (c) { return (c === null || c === void 0 ? void 0 : c.identifier) === field.value; })) ||
                                    null, onChange: function (e, value) {
                                    return field.onChange(value ? value.identifier : '');
                                }, id: "country-select", options: countries, autoHighlight: true, renderOption: function (props, option) { return (React.createElement(Box, __assign({ component: "li" }, props), (option === null || option === void 0 ? void 0 : option.text) || '')); }, renderInput: function (params) {
                                    var _a, _b;
                                    return (React.createElement(TextField, __assign({ error: !!((_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, variant: "outlined" }, params, { label: t("passengerForm." + passenger
                                            .travelDocument.nationality.label.toLowerCase()
                                            .split(' ')
                                            .join('_')), inputProps: __assign({}, params.inputProps) })));
                                } })));
                        } }))),
                passenger.dateOfBirth && (React.createElement(Grid, { md: 6, xs: 12, item: true },
                    React.createElement(Controller, { rules: {
                            required: t('formErrors.requiredField'),
                            minLength: {
                                value: 10,
                                message: t('formErrors.fullDate'),
                            },
                        }, name: "form.passengers." + index + "." + passenger.dateOfBirth.name, control: control, render: function (_a) {
                            var _b, _c, _d, _e, _f;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(MaskedDateField, __assign({ label: t("passengerForm." + ((_b = passenger.dateOfBirth) === null || _b === void 0 ? void 0 : _b.label.toLowerCase().split(' ').join('_'))) }, field, { reverseYears: true, minYear: (_c = passenger.dateOfBirth) === null || _c === void 0 ? void 0 : _c.range.start, maxYear: (_d = passenger.dateOfBirth) === null || _d === void 0 ? void 0 : _d.range.end, disabled: formState.isSubmitting, error: !!((_e = fieldState.error) === null || _e === void 0 ? void 0 : _e.message), helperText: (_f = fieldState.error) === null || _f === void 0 ? void 0 : _f.message })));
                        } })))),
            React.createElement(Grid, { item: true, container: true, spacing: 2, sx: { mt: 0 } },
                passenger.travelDocument && passenger.travelDocument.documentNumber && (React.createElement(Grid, { md: 6, xs: 12, item: true },
                    React.createElement(Controller, { name: "form.passengers." + index + ".travelDocument." + passenger.travelDocument.documentNumber.name, rules: {
                            required: t('formErrors.requiredField'),
                        }, control: control, render: function (_a) {
                            var _b, _c, _d;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(TextField, __assign({}, field, { error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), disabled: formState.isSubmitting, helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, label: t("passengerForm." + ((_d = passenger.travelDocument) === null || _d === void 0 ? void 0 : _d.documentNumber.label.toLowerCase().split(' ').join('_'))), placeholder: t('ex. RT123456') })));
                        } }))),
                passenger.travelDocument && passenger.travelDocument.documentExpire && (React.createElement(Grid, { md: 6, xs: 12, item: true },
                    React.createElement(Controller, { name: "form.passengers." + index + ".travelDocument." + passenger.travelDocument.documentExpire.name, rules: {
                            required: t('formErrors.requiredField'),
                            minLength: {
                                value: 10,
                                message: t('formErrors.fullDate'),
                            },
                        }, control: control, render: function (_a) {
                            var _b, _c, _d, _e, _f;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(MaskedDateField, __assign({ label: t("passengerForm." + ((_b = passenger.travelDocument) === null || _b === void 0 ? void 0 : _b.documentExpire.label.toLowerCase().split(' ').join('_'))) }, field, { minYear: (_c = passenger.travelDocument) === null || _c === void 0 ? void 0 : _c.documentExpire.range.start, maxYear: (_d = passenger.travelDocument) === null || _d === void 0 ? void 0 : _d.documentExpire.range.end, disabled: formState.isSubmitting, error: !!((_e = fieldState.error) === null || _e === void 0 ? void 0 : _e.message), helperText: (_f = fieldState.error) === null || _f === void 0 ? void 0 : _f.message })));
                        } })))),
            passenger.selectServices && (React.createElement(Baggage, { selectServices: passenger.selectServices, index: index })),
            passenger.passengerType.value === 'infant' && (React.createElement(Stack, { sx: {
                    backgroundColor: 'rgba(129, 198, 228, 0.08)',
                    border: '1px solid',
                    borderColor: 'primary.main',
                    width: '100%',
                    borderRadius: 1,
                    mt: 2,
                    p: 2,
                } },
                React.createElement(Stack, null,
                    React.createElement(Stack, { direction: "row", alignItems: "center" },
                        React.createElement(Stroller, null),
                        React.createElement(Typography, { sx: { pl: 1 }, variant: "body2" }, t('booking.infantConditions'))),
                    React.createElement(Stack, { sx: { pt: 1 }, direction: "row", alignItems: "center" },
                        (infantsConditions === null || infantsConditions === void 0 ? void 0 : infantsConditions.trolley) ? (React.createElement(Check, { viewBox: "-4 -4 22 19" })) : (React.createElement(Close, { stroke: "red", viewBox: "-4 -4 19 19" })),
                        ' ',
                        React.createElement(Typography, { sx: { pl: 1 }, variant: "subtitle1" }, t('booking.trolley'))),
                    React.createElement(Stack, { sx: { pt: 1 }, direction: "row", alignItems: "center" },
                        React.createElement(Check, { viewBox: "-4 -4 22 19" }),
                        React.createElement(Typography, { sx: { pl: 1 }, variant: "subtitle1" }, t('booking.adultsArms')))))))));
};
export default Passenger;
