var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Stack, useTheme } from '@material-ui/core';
var ErrorHelperText = function (props) {
    var children = props.children, _a = props.showIcon, showIcon = _a === void 0 ? true : _a, othersProps = __rest(props, ["children", "showIcon"]);
    var theme = useTheme();
    return (React.createElement(Stack, __assign({ alignItems: "center", direction: "row" }, othersProps),
        showIcon && (React.createElement("svg", { style: {
                display: 'inline-block',
                width: '16px',
                height: '16px',
                verticalAlign: 'middle',
                fill: 'currentcolor',
                color: theme.palette.error.main,
                margin: '0px 4px 0px 0px',
            }, viewBox: "0 0 24 24", preserveAspectRatio: "xMidYMid meet" },
            React.createElement("path", { d: "M12 2C9.3 2 6.8 3.1 4.9 5 3 6.9 2 9.5 2 12.2c0 5.4 4.4 9.8 9.8 9.8h.2c5.6-.1 10-4.6 10-10.2C22 6.4 17.6 2 12.2 2H12zm-.8 5.58c0-.5.4-.8.8-.8.5 0 .8.4.8.8v5c0 .5-.4.8-.8.8-.5 0-.8-.3-.8-.8v-5zm.8 9.65a1.3 1.3 0 110-2.6 1.3 1.3 0 010 2.6z" }))),
        React.createElement(Stack, { sx: { color: 'error.main', fontSize: 'fontSize' } }, children)));
};
export default ErrorHelperText;
