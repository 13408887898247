var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var Backpack = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_15390_64164)" },
            React.createElement("path", { d: "M14.5106 4.5155V4.89874L14.8807 4.99832C16.0433 5.31119 16.9168 6.39302 16.9168 7.7V17.6C16.9168 18.2447 16.4056 18.75 15.8127 18.75H6.18766C5.5947 18.75 5.0835 18.2447 5.0835 17.6V7.7C5.0835 6.39302 5.95706 5.31119 7.11968 4.99832L7.48975 4.89874V4.5155V3.95313C7.48975 3.5648 7.80455 3.25 8.19287 3.25C8.5812 3.25 8.896 3.5648 8.896 3.95312V4.4V4.9H9.396H12.6043H13.1043V4.4V3.95312C13.1043 3.5648 13.4191 3.25 13.8075 3.25C14.1958 3.25 14.5106 3.5648 14.5106 3.95313V4.5155Z", stroke: "#002171" }),
            React.createElement("path", { d: "M7.3335 12.833H14.2085V14.6663", stroke: "#002171", strokeLinecap: "round" }),
            React.createElement("path", { d: "M14.2085 16.5L7.3335 16.5L7.3335 14.6667", stroke: "#002171", strokeLinecap: "round" }),
            React.createElement("path", { d: "M9.1665 6.41699H12.8332", stroke: "#002171", strokeLinecap: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_15390_64164" },
                React.createElement("rect", { width: "22", height: "22", fill: "white" })))))); };
export default Backpack;
