import React from 'react';
import FlightSearchFormDesktop from './FlightSearchFormDesktop';
import FlightSearchFormMobile from './FlightSearchFormMobile/FlightSearchFormMobile';
import { useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { activeFlightClassSelector, activeSearchTypeSelector, formFlightsSelector, passengerCountStateSelector, stayDurationSelector, } from 'app/redux/slices/flightSearch.slice';
import { useLocaleId } from 'app/hooks/useTranslation';
var FlightSearchForm = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var passengers = _a.passengers, searchIdRef = _a.searchIdRef, compactForm = _a.compactForm;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var flights = useSelector(formFlightsSelector);
    var stayDuration = useSelector(stayDurationSelector);
    var activeSearchType = useSelector(activeSearchTypeSelector);
    var activeFlightClass = useSelector(activeFlightClassSelector);
    var passengerCount = useSelector(passengerCountStateSelector);
    var locale = useLocaleId();
    var departureValues = (_b = flights[0].departureAirportOptions) === null || _b === void 0 ? void 0 : _b.map(function (o) { return o.code; }).filter(Boolean).join(',');
    var arrivalValues = (_c = flights[0].arrivalAirportOptions) === null || _c === void 0 ? void 0 : _c.map(function (o) { return o.code; }).filter(Boolean).join(',');
    var departureDates = Array.isArray(flights[0].date)
        ? flights[0].date.join(',')
        : flights[0].date;
    var arrivalDates = stayDuration
        ? "d" + stayDuration
        : flights[1]
            ? Array.isArray(flights === null || flights === void 0 ? void 0 : flights[1].date)
                ? flights === null || flights === void 0 ? void 0 : flights[1].date.join(',')
                : flights === null || flights === void 0 ? void 0 : flights[1].date
            : '-';
    var multipleUrl = '';
    if (activeSearchType === 'multiple') {
        var flight1 = ((_d = flights[0].departureAirportOptions) === null || _d === void 0 ? void 0 : _d.map(function (o) { return o.code; }).filter(Boolean).join(',')) + "_" + ((_e = flights[0].arrivalAirportOptions) === null || _e === void 0 ? void 0 : _e.map(function (o) { return o.code; }).filter(Boolean).join(',')) + "_" + (Array.isArray(flights[0].date)
            ? flights[0].date.join(',')
            : flights[0].date);
        var flight2 = flights[1]
            ? ((_f = flights[1].departureAirportOptions) === null || _f === void 0 ? void 0 : _f.map(function (o) { return o.code; }).filter(Boolean).join(',')) + "_" + ((_g = flights[1].arrivalAirportOptions) === null || _g === void 0 ? void 0 : _g.map(function (o) { return o.code; }).filter(Boolean).join(',')) + "_" + (Array.isArray(flights[1].date)
                ? flights[1].date.join(',')
                : flights[1].date)
            : null;
        var flight3 = flights[2]
            ? ((_h = flights[2].departureAirportOptions) === null || _h === void 0 ? void 0 : _h.map(function (o) { return o.code; }).filter(Boolean).join(',')) + "_" + ((_j = flights[2].arrivalAirportOptions) === null || _j === void 0 ? void 0 : _j.map(function (o) { return o.code; }).filter(Boolean).join(',')) + "_" + (Array.isArray(flights[2].date)
                ? flights[2].date.join(',')
                : flights[2].date)
            : null;
        multipleUrl = "/" + locale + "/multiple/" + flight1 + "/" + flight2 + "/" + (flight3 ? flight3 : '-') + "/?passengers=" + passengerCount.adult + "_" + passengerCount.child + "_" + passengerCount.infant + "&cabinclass=" + activeFlightClass;
    }
    var searchUrl = "/" + locale + "/search/" + departureValues + "/" + arrivalValues + "/" + departureDates + "/" + arrivalDates + "/?passengers=" + passengerCount.adult + "_" + passengerCount.child + "_" + passengerCount.infant + "&cabinclass=" + activeFlightClass;
    var totalPassengerNumber = passengers && Object.values(passengers).reduce(function (a, b) { return a + b; });
    var isServer = typeof window === 'undefined';
    if (isServer) {
        return null;
    }
    if (isMobile) {
        return (React.createElement(FlightSearchFormMobile, { searchIdRef: searchIdRef, searchUrl: activeSearchType === 'multiple' ? multipleUrl : searchUrl, compactForm: compactForm, totalPassengerNumber: totalPassengerNumber }));
    }
    return (React.createElement(FlightSearchFormDesktop, { searchUrl: activeSearchType === 'multiple' ? multipleUrl : searchUrl, searchIdRef: searchIdRef }));
};
export default FlightSearchForm;
