import { Card, Collapse, Divider, Grid, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import { ChevronRight, Info } from '@material-ui/icons';
import { Box } from '@material-ui/system';
import getCurrencySymbol from 'app/util/getCurrencySymbol';
import { parseISO } from 'date-fns';
import React, { useState } from 'react';
import Backpack from '../../assets/icons/Backpack';
import Briefcase from '../../assets/icons/Briefcase';
import Check from '../../assets/icons/Check';
import Close from '../../assets/icons/Close';
import Stroller from '../../assets/icons/Stroller';
import Trolley from '../../assets/icons/Trolley';
import useTranslation, { useDateFormat } from '../../hooks/useTranslation';
var BaggageOption = function (_a) {
    var _b;
    var option = _a.option;
    var t = useTranslation();
    var iconMap = {
        personal_item: React.createElement(Backpack, null),
        cabin_bag: React.createElement(Trolley, null),
        hold_baggage: React.createElement(Briefcase, null),
        no_baggage: React.createElement(Close, { stroke: "red", viewBox: "-4 -4 19 19" }),
    };
    return (React.createElement(Stack, { direction: "row", justifyContent: "space-between", sx: {
            position: 'relative',
            py: 1,
            width: '100%',
        } },
        React.createElement(Stack, { sx: { width: { xs: '100%', md: '65%' } } }, option.baggage.map(function (baggage, index) {
            var _a;
            return (React.createElement(React.Fragment, null, baggage.type === 'priority' ? (React.createElement(Stack, { alignItems: "flex-end", sx: { color: 'text.secondary' }, direction: "row" },
                React.createElement(Info, { sx: { width: 18, height: 18 } }),
                React.createElement(Typography, { sx: { pl: 1 }, variant: "subtitle1" },
                    baggage.text,
                    ' ', (_a = baggage.airlines) === null || _a === void 0 ? void 0 :
                    _a.map(function (a, key) { var _a; return ((_a = baggage.airlines) === null || _a === void 0 ? void 0 : _a.length) - 1 === key ? a : a + ", "; })))) : (React.createElement(Stack, { justifyContent: "space-between", key: index, sx: {
                    flexDirection: { sm: 'column', md: 'row' },
                } },
                React.createElement(Stack, { alignItems: "center", direction: "row" },
                    iconMap[baggage.type],
                    React.createElement(Typography, { variant: "body2" }, baggage.pieces
                        ? baggage.pieces > 1 && baggage.pieces + " x "
                        : ''),
                    React.createElement(Typography, { variant: "body2" }, baggage.text)),
                baggage.dimension && (React.createElement(Stack, null,
                    React.createElement(Typography, { variant: "body2", color: "text.disabled" }, baggage.dimension.length + " x " + baggage.dimension.width + " x " + baggage.dimension.height + " cm",
                        baggage.weight > 0
                            ? ", " + baggage.weight + " " + baggage.unit
                            : '')))))));
        })),
        React.createElement(Stack, { sx: {
                position: { xs: 'absolute', md: 'initial' },
                top: '8px',
                right: '0',
            } },
            React.createElement(Typography, { variant: "h6", fontSize: '14px', sx: { color: 'primary.main' } }, (option === null || option === void 0 ? void 0 : option.price.amount) === '0' ? (t('baggage.included')) : (React.createElement(React.Fragment, null,
                getCurrencySymbol((_b = option === null || option === void 0 ? void 0 : option.price.currency) === null || _b === void 0 ? void 0 : _b.code), option === null || option === void 0 ? void 0 :
                option.price.amount))))));
};
var OrderPassenger = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var passenger = _a.passenger, index = _a.index, bookingStatus = _a.bookingStatus;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var format = useDateFormat();
    var _m = useState(index === 1 || false), isOpen = _m[0], setIsOpen = _m[1];
    var t = useTranslation();
    var statusTextMap = {
        NORMAL: t('ticketStatus.NORMAL'),
        BOOKING_IS_PAID_WAIT_SEND_TICKET: t('ticketStatus.BOOKING_IS_PAID_WAIT_SEND_TICKET'),
        BOOKING_IS_CANCELLED: t('ticketStatus.BOOKING_IS_CANCELLED'),
        BOOKING_IS_CANCELED_BY_CLIENT: t('ticketStatus.BOOKING_IS_CANCELED_BY_CLIENT'),
        BOOKING_PENDING_BY_PAYMENT_GATEWAY: t('ticketStatus.BOOKING_PENDING_BY_PAYMENT_GATEWAY'),
        BOOKING_CAN_NOT_ISSUE: t('ticketStatus.BOOKING_CAN_NOT_ISSUE'),
        BOOKING_ISSUED: t('ticketStatus.BOOKING_ISSUED'),
    };
    return (React.createElement(Card, { sx: {
            width: '100%',
            mx: { xs: 2, md: 'unset' },
            mt: 2,
            alignSelf: 'center',
            p: 2,
        } }, isMobile ? (React.createElement(Stack, { onClick: function () { return setIsOpen(!isOpen); } },
        React.createElement(Stack, { direction: "row", spacing: 1, sx: { py: 1 }, alignItems: "flex-end" },
            React.createElement(Typography, { sx: { color: 'black' }, variant: "h5" }, t('common.passenger') + " " + index),
            React.createElement(Typography, { variant: "body2", color: "text.secondary", sx: { padding: '0 0 1px 0' } }, t("passengerType." + passenger.type))),
        React.createElement(Grid, { sx: { py: 1 }, container: true },
            React.createElement(Grid, { xs: 12, md: 4, item: true, container: true, direction: "column" },
                React.createElement(Grid, { item: true },
                    React.createElement(Stack, { direction: "row" },
                        React.createElement(Stack, { direction: "row", sx: {
                                textAlign: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                mr: 1,
                                width: 26,
                                height: 26,
                                fontSize: 14,
                                color: 'common.white',
                                borderRadius: '50%',
                                backgroundColor: 'primary.main',
                            } },
                            passenger.surname[0],
                            passenger.name[0]),
                        React.createElement(Typography, { sx: { fontWeight: 700, color: 'primary.dark' } },
                            passenger.surname,
                            " ",
                            passenger.name),
                        React.createElement(Typography, { color: "text.secondary", sx: { fontWeight: 700, ml: 1 } }, passenger.title),
                        React.createElement(ChevronRight, { sx: {
                                position: 'absolute',
                                right: 16,
                                transform: isOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
                            } }))))),
        React.createElement(Collapse, { in: isOpen },
            React.createElement(Divider, { variant: "fullWidth" }),
            React.createElement(Grid, { sx: { py: 1 }, container: true },
                React.createElement(Grid, { xs: 12, md: 3, item: true, container: true, direction: "row", justifyContent: "space-between" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { color: "text.secondary", variant: "body2" }, t('passengerForm.dateOfBirth'))),
                    passenger.dateOfBirth && (React.createElement(Grid, { item: true },
                        React.createElement(Typography, { sx: { fontWeight: 700, color: 'primary.dark' } }, format(parseISO(passenger.dateOfBirth), 'dd.MM.yyyy'))))),
                passenger.document && (React.createElement(React.Fragment, null,
                    passenger.document.number && (React.createElement(Grid, { xs: 12, md: 3, item: true, container: true, direction: "row", justifyContent: "space-between", sx: { my: 0.5 } },
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { color: "text.secondary", variant: "body2" }, t('passengerForm.documentNumber'))),
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { sx: { fontWeight: 700, color: 'primary.dark' } }, (_b = passenger.document) === null || _b === void 0 ? void 0 : _b.number)))),
                    passenger.document.countryOfIssue && (React.createElement(Grid, { xs: 12, md: 3, item: true, container: true, direction: "row", justifyContent: "space-between", sx: { my: 0.5 } },
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { color: "text.secondary", variant: "body2" }, t('passengerForm.country'))),
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { sx: { fontWeight: 700, color: 'primary.dark' } }, (_c = passenger.document) === null || _c === void 0 ? void 0 : _c.countryOfIssue.name)))),
                    passenger.document.issuedDate && (React.createElement(Grid, { xs: 12, md: 3, item: true, container: true, direction: "row", justifyContent: "space-between", sx: { my: 0.5 } },
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { color: "text.secondary", variant: "body2" }, t('passengerForm.issuedDate'))),
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { sx: { fontWeight: 700, color: 'primary.dark' } }, format(parseISO((_d = passenger.document) === null || _d === void 0 ? void 0 : _d.issuedDate), 'dd.MM.yyyy'))))),
                    passenger.document.expireDate && (React.createElement(Grid, { xs: 12, md: 3, item: true, container: true, justifyContent: "space-between", sx: { my: 0.5 } },
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { color: "text.secondary", variant: "body2" }, t('passengerForm.expirationDate'))),
                        React.createElement(Grid, { item: true },
                            React.createElement(Typography, { sx: { fontWeight: 700, color: 'primary.dark' } }, format(parseISO((_e = passenger.document) === null || _e === void 0 ? void 0 : _e.expireDate), 'dd.MM.yyyy')))))))),
            React.createElement(Grid, { xs: 12, md: 4, sx: {
                    my: 0.5,
                    backgroundColor: '#E3F2FD',
                    borderRadius: 1,
                    p: 1,
                }, item: true, container: true, direction: "column" },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { color: "black", variant: "body2" }, t('orderPassenger.ticketNumber'))),
                React.createElement(Grid, { item: true }, passenger.ticketNumber ? (passenger.ticketNumber.map(function (tn) { return (React.createElement(Typography, { color: "text.secondary", sx: { fontWeight: 700 } }, tn)); })) : (React.createElement(Typography, { color: "text.secondary", sx: { fontWeight: 700 } }, statusTextMap[bookingStatus])))),
            passenger.electronicMiscellaneousDocument ? (React.createElement(Grid, { xs: 12, md: 4, sx: {
                    my: 0.5,
                    backgroundColor: '#1c72c7',
                    borderRadius: 1,
                    p: 1,
                }, item: true, container: true, direction: "column" },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { color: "black", variant: "body2" }, t('orderPassenger.baggageNumber'))),
                React.createElement(Grid, { item: true }, passenger.electronicMiscellaneousDocument.map(function (emd) { return (React.createElement(Typography, { color: "text.secondary", sx: { fontWeight: 700 } },
                    emd,
                    ' ')); })))) : null,
            passenger.services && (React.createElement(React.Fragment, null,
                React.createElement(Divider, { sx: { my: 2 }, variant: "fullWidth" }),
                React.createElement(Typography, { color: "black", sx: { fontWeight: 600 } }, t('order.services')),
                passenger.services.map(function (s, i) {
                    var _a, _b, _c, _d;
                    return (React.createElement(Stack, { key: i },
                        s && s.route && (React.createElement(Typography, { color: "primary.main", sx: { my: 1, fontWeight: 600 } }, s.route &&
                            s.route.map(function (routeItem, index) { return (React.createElement(React.Fragment, null,
                                routeItem,
                                index !== s.route.length - 1 && ' - ')); }))),
                        (s === null || s === void 0 ? void 0 : s.handLuggage) && (React.createElement(Stack, { justifyContent: "space-between", sx: {
                                border: '1px solid #BDBDBD',
                                borderRadius: 1,
                                px: 2,
                                mt: 2,
                            } }, (_b = (_a = s === null || s === void 0 ? void 0 : s.handLuggage) === null || _a === void 0 ? void 0 : _a.option) === null || _b === void 0 ? void 0 : _b.map(function (option) { return (React.createElement(BaggageOption, { option: option })); }))),
                        (s === null || s === void 0 ? void 0 : s.holdLuggage) && (React.createElement(Stack, { justifyContent: "space-between", sx: {
                                border: '1px solid #BDBDBD',
                                borderRadius: 1,
                                px: 2,
                                mt: 2,
                            } }, (_d = (_c = s === null || s === void 0 ? void 0 : s.holdLuggage) === null || _c === void 0 ? void 0 : _c.option) === null || _d === void 0 ? void 0 : _d.map(function (option) { return (React.createElement(BaggageOption, { option: option })); })))));
                }),
                passenger.type === 'infant' && (React.createElement(Stack, { sx: {
                        backgroundColor: 'rgba(129, 198, 228, 0.08)',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        width: '100%',
                        borderRadius: 1,
                        mt: 2,
                        p: 2,
                    } },
                    React.createElement(Stack, null,
                        React.createElement(Stack, { direction: "row", alignItems: "center" },
                            React.createElement(Stroller, null),
                            React.createElement(Typography, { sx: { pl: 1 }, variant: "body2" }, t('booking.infantConditions'))),
                        React.createElement(Stack, { sx: { pt: 1 }, direction: "row", alignItems: "center" },
                            ((_f = passenger.infantsConditions) === null || _f === void 0 ? void 0 : _f.trolley) ? (React.createElement(Check, { viewBox: "-4 -4 22 19" })) : (React.createElement(Close, { stroke: "red", viewBox: "-4 -4 19 19" })),
                            ' ',
                            React.createElement(Typography, { sx: { pl: 1 }, variant: "subtitle1" }, t('booking.trolley'))),
                        React.createElement(Stack, { sx: { pt: 1 }, direction: "row", alignItems: "center" },
                            React.createElement(Check, { viewBox: "-4 -4 22 19" }),
                            React.createElement(Typography, { sx: { pl: 1 }, variant: "subtitle1" }, t('booking.adultsArms'))))))))))) : (React.createElement(React.Fragment, null,
        React.createElement(Stack, { direction: "row", spacing: 1, sx: { py: 1 }, alignItems: "flex-end" },
            React.createElement(Typography, { variant: "h5" }, t('common.passenger') + " " + index),
            React.createElement(Typography, { variant: "body2", color: "text.secondary", sx: { padding: '0 0 1px 0' } }, t("passengerType." + passenger.type))),
        React.createElement(Grid, { sx: { py: 1 }, container: true },
            React.createElement(Grid, { xs: 12, md: 12, item: true, container: true, direction: "column" },
                React.createElement(Typography, { variant: "body2" }, t('orderPassenger.passengerData')),
                React.createElement(Stack, { direction: "row" },
                    React.createElement(Stack, { direction: "row", sx: {
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            mr: 1,
                            width: 26,
                            height: 26,
                            fontSize: 14,
                            color: 'common.white',
                            borderRadius: '50%',
                            backgroundColor: 'primary.main',
                        } },
                        passenger.surname[0],
                        passenger.name[0]),
                    React.createElement(Typography, { sx: { fontWeight: 700, color: 'primary.dark' } },
                        passenger.surname,
                        " ",
                        passenger.name),
                    React.createElement(Typography, { color: "text.secondary", sx: { fontWeight: 700, ml: 1 } }, passenger.title))),
            React.createElement(Divider, { variant: "fullWidth", sx: { my: 1 } })),
        React.createElement(Divider, { variant: "fullWidth" }),
        React.createElement(Grid, { sx: { py: 1 }, container: true },
            React.createElement(Grid, { xs: 12, md: 3, item: true, container: true, direction: "column" },
                React.createElement(Grid, { item: true },
                    React.createElement(Typography, { variant: "body2" }, t('passengerForm.dateOfBirth'))),
                passenger.dateOfBirth && (React.createElement(Grid, { item: true },
                    React.createElement(Typography, { sx: { fontWeight: 700, color: 'primary.dark' } }, format(parseISO(passenger.dateOfBirth), 'dd.MM.yyyy'))))),
            passenger.document && (React.createElement(React.Fragment, null,
                passenger.document.number && (React.createElement(Grid, { xs: 12, md: 3, item: true, container: true, direction: "column" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body2" }, t('passengerForm.documentNumber'))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { sx: { fontWeight: 700, color: 'primary.dark' } }, (_g = passenger.document) === null || _g === void 0 ? void 0 : _g.number)))),
                passenger.document.countryOfIssue && (React.createElement(Grid, { xs: 12, md: 2, item: true, container: true, direction: "column" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body2" }, t('passengerForm.country'))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { sx: { fontWeight: 700, color: 'primary.dark' } }, (_h = passenger.document) === null || _h === void 0 ? void 0 : _h.countryOfIssue.name)))),
                passenger.document.issuedDate && (React.createElement(Grid, { xs: 12, md: 2, item: true, container: true, direction: "column" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body2" }, t('passengerForm.issuedDate'))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { sx: { fontWeight: 700, color: 'primary.dark' } }, format(parseISO((_j = passenger.document) === null || _j === void 0 ? void 0 : _j.issuedDate), 'dd.MM.yyyy'))))),
                passenger.document.expireDate && (React.createElement(Grid, { xs: 12, md: 2, item: true, container: true, direction: "column" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body2" }, t('passengerForm.expirationDate'))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { sx: { fontWeight: 700, color: 'primary.dark' } }, format(parseISO((_k = passenger.document) === null || _k === void 0 ? void 0 : _k.expireDate), 'dd.MM.yyyy')))))))),
        React.createElement(Stack, { direction: "row", sx: { width: '100%' }, spacing: 2 },
            React.createElement(Stack, { sx: {
                    backgroundColor: '#E3F2FD',
                    borderRadius: 1,
                    p: 1,
                    width: '50%',
                } },
                React.createElement(Box, null,
                    React.createElement(Typography, { color: "black", variant: "body2" }, t('orderPassenger.ticketNumber'))),
                React.createElement(Box, null, passenger.ticketNumber ? (passenger.ticketNumber.map(function (tn) { return (React.createElement(Typography, { color: "text.secondary", sx: { fontWeight: 700 } }, tn)); })) : (React.createElement(Typography, { color: "text.secondary", sx: { fontWeight: 700 } }, statusTextMap[bookingStatus])))),
            passenger.electronicMiscellaneousDocument ? (React.createElement(Stack, { sx: {
                    backgroundColor: '#1c72c7',
                    borderRadius: 1,
                    p: 1,
                    width: '50%',
                } },
                React.createElement(Box, null,
                    React.createElement(Typography, { color: "black", variant: "body2" }, t('orderPassenger.baggageNumber'))),
                React.createElement(Box, null, passenger.electronicMiscellaneousDocument.map(function (emd) { return (React.createElement(Typography, { color: "text.secondary", sx: { fontWeight: 700 } },
                    emd,
                    ' ')); })))) : null),
        passenger.services && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "body1" }, t('order.services')),
            passenger.services.map(function (s) {
                var _a, _b, _c, _d;
                return (React.createElement(Stack, null,
                    s && s.route && (React.createElement(Typography, { color: "primary.main", sx: { my: 1, fontWeight: 600 } }, s.route &&
                        s.route.map(function (routeItem, index) { return (React.createElement(React.Fragment, null,
                            routeItem,
                            index !== s.route.length - 1 && ' - ')); }))),
                    (s === null || s === void 0 ? void 0 : s.handLuggage) && (React.createElement(Stack, { justifyContent: "space-between", sx: {
                            border: '1px solid #BDBDBD',
                            borderRadius: 1,
                            px: 2,
                            mt: 2,
                        } }, (_b = (_a = s === null || s === void 0 ? void 0 : s.handLuggage) === null || _a === void 0 ? void 0 : _a.option) === null || _b === void 0 ? void 0 : _b.map(function (option) {
                        return React.createElement(BaggageOption, { option: option });
                    }))),
                    (s === null || s === void 0 ? void 0 : s.holdLuggage) && (React.createElement(Stack, { justifyContent: "space-between", sx: {
                            border: '1px solid #BDBDBD',
                            borderRadius: 1,
                            px: 2,
                            mt: 2,
                        } }, (_d = (_c = s === null || s === void 0 ? void 0 : s.holdLuggage) === null || _c === void 0 ? void 0 : _c.option) === null || _d === void 0 ? void 0 : _d.map(function (option) { return (React.createElement(BaggageOption, { option: option })); })))));
            }),
            passenger.type === 'infant' && (React.createElement(Stack, { sx: {
                    backgroundColor: 'rgba(129, 198, 228, 0.08)',
                    border: '1px solid',
                    borderColor: 'primary.main',
                    width: '100%',
                    borderRadius: 1,
                    mt: 2,
                    p: 2,
                } },
                React.createElement(Stack, null,
                    React.createElement(Stack, { direction: "row", alignItems: "center" },
                        React.createElement(Stroller, null),
                        React.createElement(Typography, { sx: { pl: 1 }, variant: "body2" }, t('booking.infantConditions'))),
                    React.createElement(Stack, { sx: { pt: 1 }, direction: "row", alignItems: "center" },
                        ((_l = passenger.infantsConditions) === null || _l === void 0 ? void 0 : _l.trolley) ? (React.createElement(Check, { viewBox: "-4 -4 22 19" })) : (React.createElement(Close, { stroke: "red", viewBox: "-4 -4 19 19" })),
                        ' ',
                        React.createElement(Typography, { sx: { pl: 1 }, variant: "subtitle1" }, t('booking.trolley'))),
                    React.createElement(Stack, { sx: { pt: 1 }, direction: "row", alignItems: "center" },
                        React.createElement(Check, { viewBox: "-4 -4 22 19" }),
                        React.createElement(Typography, { sx: { pl: 1 }, variant: "subtitle1" }, t('booking.adultsArms'))))))))))));
};
export default OrderPassenger;
