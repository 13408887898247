import { Stack, Typography } from '@material-ui/core';
import { Collapse } from '@mui/material';
import React, { useState } from 'react';
import { Info } from '@material-ui/icons';
var HowPay = function (_a) {
    var howPay = _a.howPay;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    return (React.createElement(Stack, { onClick: function () { return setIsOpen(!isOpen); }, sx: {
            p: 2,
            cursor: 'pointer',
            my: 2,
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'primary.main',
            '& li': { listStyleType: 'decimal', my: 2 },
            '& li>span': { width: '100%', display: 'flex', fontWeight: 600 },
        } },
        React.createElement(Stack, { direction: "row", alignItems: "center" },
            React.createElement(Info, { sx: { width: 18, height: 18 } }),
            React.createElement(Typography, { sx: { fontWeight: 600, pl: 1 } }, howPay.title)),
        React.createElement(Collapse, { in: isOpen },
            React.createElement("div", { dangerouslySetInnerHTML: { __html: howPay.body } }))));
};
export default HowPay;
