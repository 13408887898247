var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var FlightClassToggle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 15px;\n  cursor: pointer;\n  display: inline-block;\n  /* > .fa-chevron-down {\n    transition: transform 0.4s;\n    ", "\n  } */\n"], ["\n  margin-bottom: 15px;\n  cursor: pointer;\n  display: inline-block;\n  /* > .fa-chevron-down {\n    transition: transform 0.4s;\n    ", "\n  } */\n"])), function (props) { return (props.isOpen ? 'transform: rotate(-180deg);' : ''); });
export var FlightClassList = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  background: #fff;\n  left: -12px;\n  top: 0;\n\n  &:before {\n    content: '';\n    width: 0;\n    height: 0;\n    position: absolute;\n    left: 15px;\n    top: 87px;\n    border-top: 10px solid #fff;\n    border-left: 10px solid transparent;\n    border-right: 10px solid transparent;\n  }\n"], ["\n  position: absolute;\n  background: #fff;\n  left: -12px;\n  top: 0;\n\n  &:before {\n    content: '';\n    width: 0;\n    height: 0;\n    position: absolute;\n    left: 15px;\n    top: 87px;\n    border-top: 10px solid #fff;\n    border-left: 10px solid transparent;\n    border-right: 10px solid transparent;\n  }\n"])));
export var FlightClassRoot = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var FlightClassMenuAnchor = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var FlightClassItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 3px 20px;\n  font-weight: 400;\n  line-height: 1.42857143;\n  cursor: pointer;\n  white-space: nowrap;\n\n  &:hover {\n    color: #262626;\n    cursor: 'pointer';\n    background-color: #f5f5f5;\n  }\n"], ["\n  padding: 3px 20px;\n  font-weight: 400;\n  line-height: 1.42857143;\n  cursor: pointer;\n  white-space: nowrap;\n\n  &:hover {\n    color: #262626;\n    cursor: 'pointer';\n    background-color: #f5f5f5;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
