var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, Button, Grid, Stack, TextField } from '@material-ui/core';
import get from 'lodash.get';
import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import useTranslation from 'app/hooks/useTranslation';
import { useCheckRecoveryPasswordTokenQuery, useRecoveryPasswordMutation, } from 'app/generated/graphql';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AuthContext } from 'app/hooks/useAuth';
function PasswordRecovery() {
    var t = useTranslation();
    var params = useParams();
    var checkRecoveryToken = useCheckRecoveryPasswordTokenQuery({
        variables: { token: params.token },
    });
    var checkResult = get(checkRecoveryToken.data, 'checkRecoveryPasswordToken');
    var defaultValues = {
        token: params.token,
        password: '',
        confirm_password: '',
    };
    var passwordMethods = useForm({ defaultValues: defaultValues });
    var context = useContext(AuthContext);
    var _a = useRecoveryPasswordMutation({
        onCompleted: function (_a) {
            var _b;
            var recoveryPassword = _a.recoveryPassword;
            if ((recoveryPassword === null || recoveryPassword === void 0 ? void 0 : recoveryPassword.__typename) === 'AuthPayload') {
                context.login(recoveryPassword);
                history.replace('/');
            }
            if ((recoveryPassword === null || recoveryPassword === void 0 ? void 0 : recoveryPassword.__typename) === 'ValidationErrors') {
                (_b = recoveryPassword.fields) === null || _b === void 0 ? void 0 : _b.forEach(function (f) {
                    //@ts-ignore
                    passwordMethods.setError(f.path, {
                        type: 'manual',
                        message: f === null || f === void 0 ? void 0 : f.messages[0],
                    });
                });
            }
        },
    }), recoveryPassword = _a[0], recoveryPasswordData = _a[1];
    var customPasswordChangeSubmit = function (e) {
        e.preventDefault();
        passwordMethods.clearErrors();
        var values = passwordMethods.getValues();
        recoveryPassword({
            variables: {
                input: {
                    token: values.token,
                    password: values.password,
                    confirm_password: values.confirm_password,
                },
            },
        });
    };
    var passwordForm = (React.createElement(FormProvider, __assign({}, passwordMethods),
        React.createElement("form", { onSubmit: customPasswordChangeSubmit },
            React.createElement(Stack, { sx: { mt: 2 } },
                React.createElement(Grid, { container: true, item: true, md: 3, xs: 12, spacing: 2, direction: "column" },
                    React.createElement("input", __assign({}, passwordMethods.register('token'), { type: "hidden" })),
                    React.createElement(Grid, { item: true, md: 3, xs: 12 },
                        React.createElement(Controller, { name: "password", control: passwordMethods.control, rules: {
                                required: '',
                            }, render: function (_a) {
                                var _b, _c;
                                var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                return (React.createElement(TextField, __assign({}, field, { type: "password", error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "password", variant: "outlined", label: t('common.new_password') })));
                            } })),
                    React.createElement(Grid, { item: true, md: 3, xs: 12 },
                        React.createElement(Controller, { name: "confirm_password", control: passwordMethods.control, rules: {
                                required: '',
                            }, render: function (_a) {
                                var _b, _c;
                                var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                return (React.createElement(TextField, __assign({}, field, { type: "password", error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "confirm_password", variant: "outlined", label: t('common.confirm_password') })));
                            } })),
                    React.createElement(Grid, { item: true },
                        React.createElement(Button, { type: "submit", variant: "contained", sx: {
                                width: { lg: 250, xs: 'unset' },
                                height: '48px',
                                alignSelf: 'flex-start',
                            } }, t('common.change'))))))));
    var history = useHistory();
    useEffect(function () {
        if ((checkResult === null || checkResult === void 0 ? void 0 : checkResult.__typename) === 'CheckRecoveryPasswordToken') {
            if (checkResult.status === 'INVALID') {
                history.replace('/');
            }
        }
    }, [checkResult]);
    return (React.createElement(Stack, { justifyContent: "center", alignItems: "center" },
        React.createElement(Box, { sx: {
                width: '100%',
                maxWidth: 1150,
                position: 'relative',
                display: 'flex',
                px: { xs: 2, md: 'unset' },
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                background: 'linear-gradient(180deg, rgba(230, 238, 255, 0.1) 0%, rgba(0, 82, 255, 0.008) 100%), #FFFFFF',
            } }, (checkResult === null || checkResult === void 0 ? void 0 : checkResult.__typename) === 'CheckRecoveryPasswordToken' && (React.createElement(React.Fragment, null, checkResult.status === 'VALID' && React.createElement(React.Fragment, null, passwordForm))))));
}
export default PasswordRecovery;
