var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var SwapHoriz = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M2.42864 11.1428V7.14283M2.42864 4.85712V0.857117M7.00007 11.1428V5.99997M7.00007 3.71426V0.857117M11.5715 11.1428V8.28569M11.5715 5.99997V0.857117M0.714355 7.14283H4.14293M5.28578 3.71426H8.71435M9.85721 8.28569H13.2858", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default SwapHoriz;
