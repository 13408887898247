import { Divider, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { parseISO } from 'date-fns';
import get from 'lodash.get';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserBookingsQuery } from '../../generated/graphql';
import useTranslation, { useDateFormat, useLocaleId, } from '../../hooks/useTranslation';
var ProfileBookings = function () {
    var defaultValues = {};
    var _a = useUserBookingsQuery({
        notifyOnNetworkStatusChange: true,
    }), data = _a.data, loading = _a.loading, networkStatus = _a.networkStatus, fetchMore = _a.fetchMore;
    var history = useHistory();
    var userBookingsActive = get(data, 'user.bookings.active');
    var userBookingsHistory = get(data, 'user.bookings.history');
    var hasNextPageActive = get(data, 'user.bookings.active.pageInfo.hasNextPage');
    var hasNextPageHistory = get(data, 'user.bookings.history.pageInfo.hasNextPage');
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var format = useDateFormat();
    var t = useTranslation();
    var locale = useLocaleId();
    var handleBookingClick = function (code, person) {
        history.push("/" + locale + "/rezervare/" + code + "/" + person);
    };
    var bgColorMap = {
        UNPAID: '#FFD600',
        PAID: '#009624',
        PAID_PARTIAL: '#FFD600',
        REFUND: '#FF9A9A',
        REFUND_PARTIAL: '#FF9A9A',
        CANCELED: '#E62F2F',
        PENDING: '#FFD600',
        COMPLETED: '#00C853',
    };
    return (React.createElement(Stack, { sx: { p: 2 } },
        React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "flex-end", sx: { mt: 2 } },
            React.createElement(Typography, { variant: "h4" }, t('common.active_bookings'))),
        React.createElement(Divider, { sx: { my: 2 }, variant: "fullWidth" }),
        userBookingsActive && userBookingsActive.edges.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement(TableContainer, null, isMobile ? (React.createElement(Table, { "aria-label": "simple table" },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { align: "left" },
                            t('route'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" },
                            t('common.status'),
                            "\u00A0"))),
                React.createElement(TableBody, null, userBookingsActive.edges.map(function (e) { return (React.createElement(TableRow, { sx: { cursor: 'pointer' }, key: e.node.id, onClick: function () {
                        return handleBookingClick(e.node.code, e.node.person);
                    } },
                    React.createElement(TableCell, null,
                        React.createElement(Stack, null, e.node.routes.map(function (r) { return (React.createElement(Stack, null,
                            React.createElement(Box, null, r.route))); })),
                        e.node.routes.map(function (r, i) { return (React.createElement(Stack, { key: i, direction: "row", sx: { display: 'inline' } },
                            React.createElement(Typography, { variant: "caption", sx: {
                                    color: 'primary.main',
                                    display: 'inline',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                } },
                                format(parseISO(r.departureDate), 'dd MMM'),
                                r.returnDate && (React.createElement(React.Fragment, null,
                                    ' ',
                                    "- ",
                                    format(parseISO(r.returnDate), 'dd MMM'))),
                                ', '),
                            React.createElement(Typography, { variant: "caption", sx: {
                                    color: 'text.secondary',
                                    display: 'inline',
                                } },
                                t('common.passengers'),
                                ":",
                                ' ',
                                e.node.numberOfPassengers,
                                ', '),
                            React.createElement(Typography, { variant: "caption", sx: { display: 'inline' } }, e.node.bookingCode))); })),
                    React.createElement(TableCell, null,
                        React.createElement(Typography, { sx: {
                                background: bgColorMap[e.node.status],
                                color: 'common.white',
                                p: 2,
                                width: 'fit-content',
                            } }, t("profile.bookingStatus." + e.node.status))))); })))) : (React.createElement(Table, { sx: { minWidth: 650 }, "aria-label": "simple table" },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, t('common.flight_date')),
                        React.createElement(TableCell, { align: "left" },
                            t('common.route'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" },
                            t('common.status'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" },
                            t('common.reservation_code'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" }, t('common.created')))),
                React.createElement(TableBody, null, userBookingsActive.edges.map(function (e) { return (React.createElement(TableRow, { sx: { cursor: 'pointer' }, key: e.node.id, onClick: function () {
                        return handleBookingClick(e.node.code, e.node.person);
                    } },
                    React.createElement(TableCell, null, e.node.routes.map(function (r, i) { return (React.createElement(Stack, { key: i },
                        React.createElement(Box, { sx: {
                                p: 1,
                                border: '1px solid #1976D2',
                                color: 'primary.main',
                                display: 'flex',
                                borderRadius: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            } },
                            format(parseISO(r.departureDate), 'dd MMM'),
                            r.returnDate && (React.createElement(React.Fragment, null,
                                ' ',
                                "- ",
                                format(parseISO(r.returnDate), 'dd MMM')))))); })),
                    React.createElement(TableCell, { align: "left" }, e.node.routes.map(function (r) { return (React.createElement(Stack, null,
                        React.createElement(Box, null, r.route),
                        React.createElement(Box, { sx: { color: '#bdbdbd' } },
                            t('common.passengers'),
                            ":",
                            ' ',
                            e.node.numberOfPassengers))); })),
                    React.createElement(TableCell, null,
                        React.createElement(Typography, { sx: {
                                background: bgColorMap[e.node.status],
                                color: 'common.white',
                                p: 2,
                                width: 'fit-content',
                            } }, t("profile.bookingStatus." + e.node.status))),
                    React.createElement(TableCell, { align: "left" }, e.node.bookingCode),
                    React.createElement(TableCell, { align: "left" }, format(parseISO(e.node.createdAt), 'dd MMM yyyy')))); }))))))) : (t('common.no_data_on_active_bookings')),
        React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "flex-end", sx: { mt: 2 } },
            React.createElement(Typography, { variant: "h4" }, t('common.booking_history'))),
        React.createElement(Divider, { sx: { my: 2 }, variant: "fullWidth" }),
        userBookingsHistory && userBookingsHistory.edges.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement(TableContainer, null, isMobile ? (React.createElement(Table, { "aria-label": "simple table" },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { align: "left" },
                            t('common.route'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" },
                            t('common.status'),
                            "\u00A0"))),
                React.createElement(TableBody, null, userBookingsActive.edges.map(function (e) { return (React.createElement(TableRow, { sx: { cursor: 'pointer' }, key: e.node.id, onClick: function () {
                        return handleBookingClick(e.node.code, e.node.person);
                    } },
                    React.createElement(TableCell, null,
                        React.createElement(Stack, null, e.node.routes.map(function (r) { return (React.createElement(Stack, null,
                            React.createElement(Box, null, r.route))); })),
                        e.node.routes.map(function (r, i) { return (React.createElement(Stack, { key: i, direction: "row", sx: { display: 'inline' } },
                            React.createElement(Typography, { variant: "caption", sx: {
                                    color: 'primary.main',
                                    display: 'inline',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                } },
                                format(parseISO(r.departureDate), 'dd MMM'),
                                r.returnDate && (React.createElement(React.Fragment, null,
                                    ' ',
                                    "- ",
                                    format(parseISO(r.returnDate), 'dd MMM'))),
                                ', '),
                            React.createElement(Typography, { variant: "caption", sx: {
                                    color: 'text.secondary',
                                    display: 'inline',
                                } },
                                t('common.passengers'),
                                ":",
                                ' ',
                                e.node.numberOfPassengers,
                                ', '),
                            React.createElement(Typography, { variant: "caption", sx: { display: 'inline' } }, e.node.bookingCode))); })),
                    React.createElement(TableCell, null,
                        React.createElement(Typography, { sx: {
                                background: bgColorMap[e.node.status],
                                color: 'common.white',
                                p: 2,
                                width: 'fit-content',
                            } }, t("profile.bookingStatus." + e.node.status))))); })))) : (React.createElement(Table, { sx: { minWidth: 650 }, "aria-label": "simple table" },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, t('common.flight_date')),
                        React.createElement(TableCell, { align: "left" },
                            t('common.route'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" },
                            t('common.status'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" },
                            t('common.reservation_code'),
                            "\u00A0"),
                        React.createElement(TableCell, { align: "left" }, t('common.created')))),
                React.createElement(TableBody, null, userBookingsHistory.edges.map(function (e) { return (React.createElement(TableRow, { key: e.node.id, sx: { cursor: 'pointer' }, onClick: function () {
                        return handleBookingClick(e.node.code, e.node.person);
                    } },
                    React.createElement(TableCell, null, e.node.routes.map(function (r, i) { return (React.createElement(Stack, { key: i },
                        React.createElement(Box, { sx: {
                                p: 1,
                                border: '1px solid #1976D2',
                                color: 'primary.main',
                                display: 'flex',
                                borderRadius: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                my: 1,
                            } },
                            format(parseISO(r.departureDate), 'dd MMM'),
                            r.returnDate && (React.createElement(React.Fragment, null,
                                ' ',
                                "- ",
                                format(parseISO(r.returnDate), 'dd MMM')))))); })),
                    React.createElement(TableCell, { align: "left" }, e.node.routes.map(function (r) { return (React.createElement(Stack, null,
                        React.createElement(Box, null, r.route),
                        React.createElement(Box, { sx: { color: '#bdbdbd' } },
                            t('common.passengers'),
                            ":",
                            ' ',
                            e.node.numberOfPassengers))); })),
                    React.createElement(TableCell, null,
                        React.createElement(Typography, { sx: {
                                background: bgColorMap[e.node.status],
                                color: 'common.white',
                                p: 2,
                                width: 'fit-content',
                            } }, t("profile.bookingStatus." + e.node.status))),
                    React.createElement(TableCell, { align: "left" }, e.node.bookingCode),
                    React.createElement(TableCell, { align: "left" }, format(parseISO(e.node.createdAt), 'dd MMM yyyy')))); }))))))) : (t('common.no_archive_booking_data')),
        React.createElement(Divider, { sx: { my: 2 }, variant: "fullWidth" })));
};
export default ProfileBookings;
