import { Card, Stack, Typography, useMediaQuery, Button, } from '@material-ui/core';
import { ArrowDownward } from '@material-ui/icons';
import useTranslation from 'app/hooks/useTranslation';
import React, { useMemo, useState } from 'react';
var Summary = function (_a) {
    var passengers = _a.passengers, passengersPrice = _a.passengersPrice, handLuggage = _a.handLuggage, holdLuggage = _a.holdLuggage, priceDetails = _a.priceDetails, appliedBonusAmount = _a.appliedBonusAmount;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _b = useState(false), expanded = _b[0], setExpanded = _b[1];
    var t = useTranslation();
    var handLuggageSum = useMemo(function () { return handLuggage === null || handLuggage === void 0 ? void 0 : handLuggage.reduce(function (a, b) { return a + b; }, 0); }, [handLuggage]) || 0;
    var holdLuggageSum = useMemo(function () { return holdLuggage === null || holdLuggage === void 0 ? void 0 : holdLuggage.reduce(function (a, b) { return a + b; }, 0); }, [holdLuggage]) || 0;
    var totalPrice = passengersPrice + handLuggageSum + holdLuggageSum;
    var passengersDetails = priceDetails.filter(function (item) {
        return item.type == 'adults' ||
            item.type == 'children' ||
            item.type == 'infants' ||
            item.type == 'service_fee' ||
            item.type == 'online_checkin';
    });
    return isMobile ? (React.createElement(Stack, { sx: {
            position: 'fixed',
            bottom: 15,
            right: expanded ? 'unset' : '2em',
            left: expanded ? '50%' : 'unset',
            transform: expanded ? 'translateX(-50%)' : 'unset',
            height: 'auto',
        } },
        React.createElement(Stack, { sx: {
                backgroundColor: 'text.primary',
                color: 'white',
                borderRadius: '4px',
            } }, expanded ? (React.createElement(Stack, { sx: {
                width: '95vw',
                p: 2,
            } },
            React.createElement(Button, { onClick: function () { return setExpanded(false); }, sx: { mt: -4 } },
                React.createElement(ArrowDownward, { sx: {
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        backgroundColor: 'text.primary',
                        color: 'common.white',
                        p: 0.5,
                    } })),
            passengersDetails.map(function (passenger, key) { return (React.createElement(Stack, { sx: { mt: 1 }, color: "common.white", direction: "row", justifyContent: "space-between", key: key },
                React.createElement(Stack, { direction: "row" },
                    React.createElement(Typography, { variant: "subtitle1" },
                        passenger.num > 0 ? passenger.num + " x " : null,
                        ' ',
                        t("summary." + passenger.type),
                        "\u00A0")),
                React.createElement(Stack, null,
                    React.createElement(Typography, { variant: "subtitle1" }, passenger.total.amount > 0
                        ? "\u20AC" + passenger.total.amount
                        : t('summary.free'))))); }),
            handLuggageSum > 0 && (React.createElement(Stack, { sx: { mt: 1 }, direction: "row", justifyContent: "space-between" },
                React.createElement(Typography, { variant: "subtitle1" }, t('summary.hand_baggage')),
                React.createElement(Typography, { variant: "subtitle1" },
                    "\u20AC",
                    handLuggageSum))),
            holdLuggageSum > 0 && (React.createElement(Stack, { sx: { mt: 1 }, direction: "row", justifyContent: "space-between" },
                React.createElement(Typography, { variant: "subtitle1" }, t('summary.hold_baggage')),
                React.createElement(Typography, { variant: "subtitle1" },
                    "\u20AC",
                    holdLuggageSum))),
            appliedBonusAmount && (React.createElement(Stack, { sx: { mt: 1 }, color: "common.white", direction: "row", justifyContent: "space-between" },
                React.createElement(Stack, { direction: "row" },
                    React.createElement(Typography, { variant: "subtitle1" },
                        t("summary.discount"),
                        "\u00A0")),
                React.createElement(Stack, null,
                    React.createElement(Typography, { variant: "subtitle1" }, "-\u20AC" + appliedBonusAmount)))),
            React.createElement(Stack, { sx: { mt: 2 }, direction: "row", justifyContent: "space-between" },
                React.createElement(Typography, { variant: "body2", sx: { color: function (theme) { return theme.palette.primary.dark; } } },
                    React.createElement(Stack, null, t('booking.totalPrice')),
                    React.createElement(Stack, null,
                        "\u20AC",
                        totalPrice.toFixed(2)))))) : (React.createElement(Stack, { sx: {
                width: '100%',
                p: 2,
            }, onClick: function () { return setExpanded(true); } },
            "\u20AC",
            appliedBonusAmount
                ? (totalPrice - appliedBonusAmount).toFixed(2)
                : totalPrice.toFixed(2)))))) : (React.createElement(Stack, { sx: { flex: 1 } },
        React.createElement(Card, { sx: {
                width: 270,
                py: 3,
                px: 2,
                mt: 2,
                ml: '1.875rem',
                position: 'sticky',
                top: 0,
                mb: '33rem',
            } },
            React.createElement(React.Fragment, null,
                passengersDetails.map(function (passenger, key) { return (React.createElement(Stack, { sx: { mt: 1 }, color: "#0D47A1", direction: "row", justifyContent: "space-between", key: key },
                    React.createElement(Stack, { direction: "row" },
                        React.createElement(Typography, { variant: "subtitle1" },
                            passenger.num > 0 ? passenger.num + " x" : null,
                            ' ',
                            t("summary." + passenger.type),
                            "\u00A0")),
                    React.createElement(Stack, null,
                        React.createElement(Typography, { variant: "subtitle1" }, passenger.total.amount > 0
                            ? "\u20AC" + passenger.total.amount
                            : t('summary.free'))))); }),
                handLuggageSum > 0 && (React.createElement(Stack, { sx: { mt: 1 }, direction: "row", justifyContent: "space-between", color: "#0D47A1" },
                    React.createElement(Stack, null,
                        React.createElement(Typography, { variant: "subtitle1" }, t('summary.hand_baggage'))),
                    React.createElement(Stack, null,
                        React.createElement(Typography, { variant: "subtitle1" },
                            "\u20AC",
                            handLuggageSum.toFixed(2))))),
                holdLuggageSum > 0 && (React.createElement(Stack, { sx: { mt: 1 }, direction: "row", justifyContent: "space-between", color: "#0D47A1" },
                    React.createElement(Stack, null,
                        React.createElement(Typography, { variant: "subtitle1" }, t('summary.hold_baggage'))),
                    React.createElement(Stack, null,
                        React.createElement(Typography, { variant: "subtitle1" },
                            "\u20AC",
                            holdLuggageSum.toFixed(2))))),
                appliedBonusAmount && (React.createElement(Stack, { sx: { mt: 1 }, direction: "row", justifyContent: "space-between", color: "#0D47A1" },
                    React.createElement(Stack, { direction: "row" },
                        React.createElement(Typography, { variant: "subtitle1" },
                            t("summary.discount"),
                            "\u00A0")),
                    React.createElement(Stack, null,
                        React.createElement(Typography, { variant: "subtitle1" }, "-\u20AC" + appliedBonusAmount)))),
                React.createElement(Typography, { fontWeight: "700" },
                    React.createElement(Stack, { sx: {
                            mt: 2,
                            pt: 2,
                            borderTop: function (theme) { return "1px solid " + theme.palette.primary.dark; },
                            color: function (theme) { return theme.palette.primary.dark; },
                        }, direction: "row", justifyContent: "space-between" },
                        React.createElement(Stack, null, t('booking.totalPrice')),
                        React.createElement(Stack, null,
                            "\u20AC",
                            appliedBonusAmount
                                ? (totalPrice - appliedBonusAmount).toFixed(2)
                                : totalPrice.toFixed(2))))))));
};
export default Summary;
