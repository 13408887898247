import { Alert, AlertTitle, Box, Button, Card, Container, Dialog, Divider, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { parseISO } from 'date-fns';
import useTranslation, { useDateFormat, useLocaleId, } from 'app/hooks/useTranslation';
import { useAcceptBookingPriceChangeMutation, useBookingInfoQuery, useCheckBookingStatusLazyQuery, } from 'app/generated/graphql';
import FlightCard from 'app/components/FlightCard/FlightCard';
import OrderPassenger from 'app/components/Order/OrderPassenger';
import OrderSummary from 'app/components/Order/OrderSummary';
import OrderStatus from 'app/components/Order/OrderStatus';
import CheckIn from 'app/components/Order/CheckIn';
import BookingAlert from 'app/components/Alert';
import Voucher from 'app/components/Order/Voucher';
import { Payment } from 'app/components/Order/Payment';
import NotFoundComponent from 'app/components/NotFoundComponent';
import Layout from 'app/components/Layout';
import PageSkeleton from 'app/components/ui/PageSkeleton';
import { FormatListBulleted } from '@material-ui/icons';
import getCurrencySymbol from 'app/util/getCurrencySymbol';
import ArrowForward from 'app/assets/icons/ArrowForward';
import PaymentSummary from 'app/components/Order/Payment/PaymentSummary';
function Order() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var params = useParams();
    var locale = useLocaleId();
    var name = params === null || params === void 0 ? void 0 : params.name;
    var code = params === null || params === void 0 ? void 0 : params.code;
    var pageData = useBookingInfoQuery({
        variables: { code: code, name: name },
        fetchPolicy: 'network-only',
    });
    useEffect(function () {
        locale && pageData.refetch && pageData.refetch();
    }, [locale]);
    var t = useTranslation();
    var loading = pageData.loading;
    var _k = useState(false), isDialogShown = _k[0], setIsDialogShown = _k[1];
    var result = get(pageData.data, 'bookingInfo');
    var _l = useState(result), bookingInfo = _l[0], setBookingInfo = _l[1];
    var _m = useState(false), showChangePriceAlert = _m[0], setShowChangePriceAlert = _m[1];
    var _o = useState(false), showChangeBookingDataAlert = _o[0], setShowChangeBookingDataAlert = _o[1];
    var _p = useState(false), isActive = _p[0], setIsActive = _p[1];
    var _q = useState(0), numQueries = _q[0], setNumQueries = _q[1];
    var _r = useCheckBookingStatusLazyQuery({
        fetchPolicy: 'network-only',
    }), checkBookingStatusQuery = _r[0], checkBookingStatus = _r[1];
    var checkStatus = get(checkBookingStatus.data, 'checkBookingStatus');
    var _s = useState(''), bookingUUid = _s[0], setBookingUUid = _s[1];
    useEffect(function () {
        if (result && result.__typename === 'BookingInfoResult') {
            setBookingInfo(result);
            setBookingUUid(result.booking.id);
        }
    }, [result]);
    useEffect(function () {
        if (typeof document === 'undefined') {
            return;
        }
        var isActiveInterval = setInterval(function () {
            if (document.visibilityState === 'visible') {
                setIsActive(true);
                if (numQueries < 1) {
                    if ((result === null || result === void 0 ? void 0 : result.__typename) === 'BookingInfoResult') {
                        if (result.booking.status === 'NORMAL' ||
                            result.booking.status === 'PRICE_CHANGED' ||
                            result.booking.status === 'PRICE_CHANGED_WHEN_CREATED_BOOKING') {
                            checkBookingStatusQuery({
                                variables: { bookingUuid: result.booking.id },
                            });
                            setNumQueries(function (prevNumQueries) { return prevNumQueries + 1; });
                        }
                    }
                }
            }
            else {
                setNumQueries(0);
            }
        }, 1000 * 60 * 1);
        return function () { return clearInterval(isActiveInterval); };
    }, [numQueries, result]);
    useEffect(function () {
        if (checkStatus && checkStatus.__typename === 'CheckBookingResult') {
            if (checkStatus.status === 'NORMAL') {
                setShowChangePriceAlert(false);
                setShowChangeBookingDataAlert(false);
            }
            else if (checkStatus.status === 'PRICE_CHANGE') {
                setNumQueries(1);
                setShowChangePriceAlert(true);
            }
            else if (checkStatus.status === 'TKT_TIME_CHANGE' ||
                checkStatus.status === 'CANCELLED') {
                setNumQueries(1);
                setShowChangeBookingDataAlert(true);
            }
        }
    }, [checkStatus]);
    var history = useHistory();
    var handleDeclinePriceChange = function () {
        history.replace('/');
    };
    var _t = useAcceptBookingPriceChangeMutation({
        variables: { bookingUuid: bookingUUid },
        onCompleted: function (_a) {
            var acceptBookingPriceChange = _a.acceptBookingPriceChange;
            if ((acceptBookingPriceChange === null || acceptBookingPriceChange === void 0 ? void 0 : acceptBookingPriceChange.__typename) ===
                'AcceptBookingPriceChangeResult') {
                setShowChangePriceAlert(false);
                pageData.refetch && pageData.refetch();
                setNumQueries(0);
            }
        },
    }), acceptPriceChange = _t[0], acceptPriceChangeResult = _t[1];
    var handleAcceptPriceChangeClick = function () {
        acceptPriceChange();
    };
    var handleDownloadCheckinClick = function () {
        var _a;
        (_a = document
            .querySelector('.Checkin')) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'start', behavior: 'smooth' });
    };
    var handleToPayClick = function () {
        var _a;
        (_a = document.querySelector('.Payment')) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
    };
    useEffect(function () {
        if ((bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.__typename) === 'BookingInfoResult' &&
            bookingInfo.payments) {
            setTimeout(function () {
                var _a;
                return (_a = document
                    .querySelector('.Payment')) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    }, [bookingInfo]);
    useEffect(function () {
        if (typeof document === 'undefined') {
            return;
        }
        var expiredSessionTimeout = setTimeout(function () {
            if (bookingInfo &&
                bookingInfo.__typename === 'BookingInfoResult' &&
                bookingInfo.booking.status === 'NORMAL') {
                setIsDialogShown(true);
            }
        }, 1000 * 60 * 15);
        return function () { return clearTimeout(expiredSessionTimeout); };
    }, [bookingInfo]);
    var format = useDateFormat();
    return (React.createElement(Layout, null,
        React.createElement(Stack, { justifyContent: "center", alignItems: "center", sx: { minHeight: 500 } },
            loading ? (React.createElement(PageSkeleton, null)) : (React.createElement(Stack, { sx: { minHeight: 500, width: '100%', alignItems: 'center' } },
                (result === null || result === void 0 ? void 0 : result.__typename) === 'BookingNotFound' && (React.createElement(Stack, { alignItems: "center", sx: {
                        width: '100%',
                        p: 3,
                        mb: 4,
                    } },
                    React.createElement(NotFoundComponent, null))),
                (bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.__typename) === 'BookingInfoResult' && (React.createElement(Stack, { alignItems: "center", sx: {
                        width: '100%',
                        backgroundColor: {
                            xs: 'primary.dark',
                            md: 'rgba(227, 242, 253, 1)',
                        },
                        color: {
                            xs: 'primary.contrastText',
                            md: '#374145',
                        },
                        p: { xs: 2, md: 3 },
                        mb: 4,
                    } },
                    React.createElement(Container, null,
                        React.createElement(Stack, { direction: isMobile ? 'column' : 'row', justifyContent: "space-between", sx: { width: '100%' } },
                            React.createElement(Stack, { sx: { width: '100%' } },
                                React.createElement(Stack, { direction: "row" },
                                    React.createElement(Typography, { sx: {
                                            fontWeight: 400,
                                            fontSize: { xs: '16px', md: '22px' },
                                        }, variant: "h3" },
                                        t('order.bookingConfirmation'),
                                        " -"),
                                    React.createElement(Typography, { variant: "h3", sx: {
                                            pl: 1,
                                            fontWeight: 400,
                                            fontSize: { xs: '16px', md: '22px' },
                                            color: {
                                                xs: 'primary.contrastText',
                                                md: 'primary.main',
                                            },
                                        } }, bookingInfo.booking.reservationNumber)),
                                React.createElement(Typography, { fontSize: { xs: '12px', md: '16px' } }, format(parseISO(bookingInfo.booking.dateCreated), 'dd.MM.yyyy, HH:mm:ss'))),
                            !isMobile && (React.createElement(Stack, { spacing: 2, sx: { width: { sm: '100%', md: '270px' } } },
                                bookingInfo.booking.electronicTicket && (React.createElement(Button, { sx: {
                                        width: '100%',
                                        '> a': {
                                            textDecoration: 'none',
                                            color: 'common.white',
                                            width: '100%',
                                        },
                                    }, variant: "contained" },
                                    React.createElement("a", { href: bookingInfo.booking.electronicTicket, target: "_blank", download: true, rel: "noreferrer" }, t('order.printTicket')))),
                                bookingInfo.checkin &&
                                    bookingInfo.booking.electronicTicket && (React.createElement(Button, { onClick: handleDownloadCheckinClick, sx: {
                                        width: '100%',
                                        backgroundColor: '#4BC25F',
                                    }, variant: "contained" }, t('order.downloadBoardingPass'))))))))),
                React.createElement(Container, null,
                    React.createElement(Box, { sx: {
                            width: '100%',
                            position: 'relative',
                            display: 'flex',
                            px: { xs: 2, md: 'unset' },
                            alignItems: 'center',
                            flexDirection: 'column',
                        } },
                        (bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.__typename) === 'BookingInfoResult' && (React.createElement(Stack, { sx: { width: '100%' }, direction: "row" },
                            React.createElement(Stack, { sx: { width: '100%', maxWidth: 850 } },
                                React.createElement(OrderStatus, { booking: bookingInfo.booking, setBookingInfo: setBookingInfo, handleDownloadCheckinClick: handleDownloadCheckinClick }),
                                React.createElement(Stack, { sx: { mx: { xs: -2, md: 0 } } },
                                    React.createElement(FlightCard, { noPrice: true, noBaggageDetails: true, open: !isMobile, itinerary: bookingInfo.itinerary })),
                                bookingInfo.alert &&
                                    bookingInfo.alert.map(function (a, i) { return (
                                    //@ts-ignore
                                    React.createElement(BookingAlert, { key: i, alert: a })); }), (_a = bookingInfo.passengers) === null || _a === void 0 ? void 0 :
                                _a.map(function (passenger, index) { return (React.createElement(OrderPassenger, { key: index, bookingStatus: bookingInfo.booking.status, passenger: passenger, index: index + 1 })); }),
                                bookingInfo.checkin && (React.createElement(CheckIn, { code: code, name: name, checkin: bookingInfo.checkin, bookingId: bookingInfo.booking.id })),
                                bookingInfo.payments && (React.createElement(Payment, { bookingId: bookingInfo.booking.id, payments: bookingInfo.payments, price: bookingInfo.price, timeLimit: bookingInfo.booking.timeLimit, showVoucher: !bookingInfo.booking.applyVoucher, promoFareWarning: bookingInfo.booking.promoFareWarning, refetch: function () { return pageData.refetch(); } })),
                                isMobile && !bookingInfo.payments && (React.createElement(Card, { sx: {
                                        my: 2,
                                        width: '100%',
                                        p: 2,
                                    } },
                                    React.createElement(Stack, null,
                                        React.createElement(Stack, { direction: "row", alignItems: "center" },
                                            React.createElement(FormatListBulleted, null),
                                            React.createElement(Typography, { variant: "h5", sx: { pl: 1 } }, t('common.summary'))),
                                        React.createElement(Typography, { sx: { my: 2 } },
                                            t('payment.flightFees'),
                                            ":"), (_b = bookingInfo.price.details) === null || _b === void 0 ? void 0 :
                                        _b.map(function (p) {
                                            var _a, _b;
                                            return (React.createElement(Stack, { sx: { my: 1 } },
                                                React.createElement(Stack, { justifyContent: "space-between", direction: "row" },
                                                    React.createElement(Box, null, "" + ((p === null || p === void 0 ? void 0 : p.num) === 0 ? '' : (p === null || p === void 0 ? void 0 : p.num) + " x ") + t("summary." + (p === null || p === void 0 ? void 0 : p.type))),
                                                    React.createElement(Box, null, ((_a = p === null || p === void 0 ? void 0 : p.total) === null || _a === void 0 ? void 0 : _a.amount) === '0'
                                                        ? t('summary.free')
                                                        : "\u20AC" + ((_b = p === null || p === void 0 ? void 0 : p.total) === null || _b === void 0 ? void 0 : _b.amount)))));
                                        }),
                                        React.createElement(Divider, { sx: { my: 1 } }),
                                        React.createElement(Stack, { direction: "row", alignItems: "flex-end", justifyContent: "flex-end" },
                                            React.createElement(PaymentSummary, null),
                                            React.createElement(Typography, { variant: "body1", sx: { textTransform: 'capitalize' } },
                                                t('flightCard.flightDuration'),
                                                ":"),
                                            React.createElement(Typography, { variant: "h4", sx: { pl: 1, lineHeight: '24px' } },
                                                getCurrencySymbol((_d = (_c = bookingInfo.price.total) === null || _c === void 0 ? void 0 : _c.currency) === null || _d === void 0 ? void 0 : _d.code),
                                                ' ', (_e = bookingInfo.price.total) === null || _e === void 0 ? void 0 :
                                                _e.amount)))))),
                            showChangePriceAlert &&
                                checkStatus &&
                                checkStatus.__typename === 'CheckBookingResult' && (React.createElement(Dialog, { open: showChangePriceAlert, onClose: function () { } },
                                React.createElement(Alert, { sx: {
                                        mt: 4,
                                        py: 2,
                                        px: '1.75rem',
                                    }, severity: "warning" },
                                    checkStatus.alerts &&
                                        ((_f = checkStatus.alerts[0]) === null || _f === void 0 ? void 0 : _f.title) && (React.createElement(AlertTitle, null, checkStatus.alerts[0].title)),
                                    checkStatus.alerts &&
                                        checkStatus.alerts.length > 0 && (React.createElement(Typography, { variant: "body2", color: "#1c72c7" }, (_g = checkStatus.alerts[0]) === null || _g === void 0 ? void 0 : _g.body)),
                                    acceptPriceChangeResult.loading ? (React.createElement(Stack, { justifyContent: "center", alignItems: "center" },
                                        React.createElement(CircularProgress, null))) : (React.createElement(Stack, { direction: "row", justifyContent: "center", alignItems: "center", spacing: 2 },
                                        React.createElement(Button, { variant: "contained", color: "error", onClick: function () { return handleDeclinePriceChange(); } }, t('common.cancel')),
                                        React.createElement(Button, { onClick: function () { return handleAcceptPriceChangeClick(); }, color: "primary", variant: "contained" }, t('common.accept'))))))),
                            showChangeBookingDataAlert &&
                                checkStatus &&
                                checkStatus.__typename === 'CheckBookingResult' && (React.createElement(Dialog, { open: showChangeBookingDataAlert, onClose: function () { } },
                                React.createElement(Alert, { sx: {
                                        mt: 4,
                                        py: 2,
                                        px: '1.75rem',
                                    }, severity: "warning" },
                                    checkStatus.alerts &&
                                        ((_h = checkStatus.alerts[0]) === null || _h === void 0 ? void 0 : _h.title) && (React.createElement(AlertTitle, null, checkStatus.alerts[0].title)),
                                    checkStatus.alerts &&
                                        checkStatus.alerts.length > 0 && (React.createElement(Typography, { variant: "body2", color: "#1c72c7" }, (_j = checkStatus.alerts[0]) === null || _j === void 0 ? void 0 : _j.body)),
                                    React.createElement(Stack, { direction: "row" },
                                        React.createElement(Button, { onClick: function () {
                                                pageData.refetch();
                                                setShowChangeBookingDataAlert(false);
                                            } }, "OK"))))),
                            !isMobile && (React.createElement(Stack, { sx: {
                                    flex: 1,
                                } },
                                React.createElement(Stack, { sx: {
                                        ml: '1.875rem',
                                        position: 'sticky',
                                        top: 0,
                                        mb: '33rem',
                                    } },
                                    React.createElement(OrderSummary, { price: bookingInfo.price }),
                                    !bookingInfo.booking.applyVoucher && (React.createElement(Voucher, { bookingId: bookingInfo.booking.id })),
                                    ' ',
                                    bookingInfo.booking.status === 'NORMAL' &&
                                        bookingInfo.payments && (React.createElement(Button, { variant: "contained", color: "error", sx: { height: 40 }, onClick: handleToPayClick },
                                        React.createElement(Box, { sx: {
                                                display: 'flex',
                                                alignItems: 'center',
                                            } },
                                            React.createElement(ArrowForward, { viewBox: "-9 -7 24 24" }),
                                            React.createElement(Box, { sx: { maxWidth: 'calc(100% - 24px)' } }, t('order.payButton')))))))))),
                        React.createElement(React.Fragment, null, (result === null || result === void 0 ? void 0 : result.__typename) === 'AppError' && (React.createElement(Stack, null, result.message))))))),
            React.createElement(Dialog, { open: isDialogShown, onClose: function () { } },
                React.createElement(Alert, { sx: {
                        py: 2,
                        px: '1.75rem',
                        '& .MuiAlert-icon': {
                            color: 'primary.main',
                        },
                    }, severity: "info" },
                    React.createElement(AlertTitle, { sx: { fontSize: '16px' } }, t('common.session_timed_out')),
                    React.createElement(Typography, { variant: "body1" }, t('refresh.msg')),
                    React.createElement(Button, { variant: "contained", onClick: function () { return window.location.reload(); }, sx: { margin: '8px 0 0 0' } }, t('common.refresh')))))));
}
export default Order;
