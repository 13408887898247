import React from 'react';
import { rangePickerModes } from '../RangePicker/types';
import { PickerModeSelectOption, ApplyButton, } from './elements';
import { Hidden, Stack, Typography, useMediaQuery } from '@material-ui/core';
import useTranslation from '../../../../hooks/useTranslation';
import { ChevronRight } from '@material-ui/icons';
var PickerModeSelect = function (_a) {
    var value = _a.value, onChange = _a.onChange, onConfirm = _a.onConfirm, showDurationOption = _a.showDurationOption;
    var t = useTranslation();
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    return (React.createElement(Stack, { sx: {
            width: { xs: '100%' },
            '>*': { flexBasis: '25%' },
            px: { xs: 2, md: 'unset' },
        }, direction: "row", spacing: isMobile ? 2 : '' },
        rangePickerModes
            .filter(function (rpm) { return (showDurationOption ? true : rpm !== 'duration'); })
            .map(function (rpm) { return (React.createElement(PickerModeSelectOption, { key: rpm, selected: rpm === value, onClick: function () { return rpm !== value && onChange(rpm); } },
            React.createElement(Typography, { variant: "body2" }, t("rpm." + rpm)))); }),
        value !== 'single' && (React.createElement(Hidden, { smDown: true },
            React.createElement(ApplyButton, { onClick: function () {
                    onConfirm && onConfirm();
                } },
                React.createElement(ChevronRight, { viewBox: "0 -3 32 32" }),
                " ",
                t('common.apply'))))));
};
export default PickerModeSelect;
