export var ensureUniqueAirports = function (airports) {
    return airports.reduce(function (acc, curr) {
        if (!acc.find(function (a) { return a.code === curr.code; })) {
            acc.push(curr);
        }
        return acc;
    }, []);
};
export var ensureNoSharedAirports = function (referenceAirports, inputAirports) {
    return inputAirports.filter(function (inputAirport) {
        return !referenceAirports.find(function (referenceAirport) { return inputAirport.code === referenceAirport.code; });
    });
};
