import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography, useMediaQuery, useTheme, SwipeableDrawer, Tabs, Tab, } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { useGetFareRulesLazyQuery } from 'app/generated/graphql';
import get from 'lodash.get';
import React, { useRef, useState } from 'react';
import useTranslation from '../../hooks/useTranslation';
import FareRulesIcon from '../../assets/icons/FareRulesIcon';
export var FareRules = function (_a) {
    var offerId = _a.offerId, asLink = _a.asLink;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var anchorEl = useRef(null);
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('md'); });
    var t = useTranslation();
    var _c = useGetFareRulesLazyQuery({
        variables: { offerId: offerId },
        fetchPolicy: 'no-cache',
    }), fareRulesQuery = _c[0], _d = _c[1], loading = _d.loading, data = _d.data, error = _d.error;
    var parts = get(data === null || data === void 0 ? void 0 : data.fareRules, 'parts');
    var _e = useState(0), value = _e[0], setValue = _e[1];
    var theme = useTheme();
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    var TabData = function () {
        return (React.createElement(React.Fragment, null,
            loading && (React.createElement(Stack, { justifyContent: "center", alignItems: "center" },
                React.createElement(CircularProgress, null))),
            data && (React.createElement(Box, { onClick: function (e) { return e.stopPropagation(); } },
                React.createElement(Tabs, { value: value, onChange: handleChange, sx: { overflow: 'initial', minHeight: 'initial' } }, parts.map(function (part) {
                    return part.route.map(function (route, routeIndex) { return (React.createElement(Tab, { key: routeIndex, sx: {
                            flex: '1',
                            width: 'initial',
                            maxWidth: 'initial',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                        }, label: "" + route.join('-') })); });
                })),
                parts.map(function (part, index) {
                    return part.route.map(function (route, routeIndex) {
                        var _a;
                        return (React.createElement(Box, { role: "tabpanel", hidden: value !== index, sx: { padding: '16px' } }, (_a = part.rules) === null || _a === void 0 ? void 0 : _a.map(function (rule) { return (React.createElement(React.Fragment, null,
                            React.createElement(DialogTitle, { sx: { padding: '0 0 8px' } }, rule.name),
                            React.createElement(DialogContent, { sx: { padding: '0' } },
                                React.createElement(Box, { sx: {
                                        overflow: 'auto',
                                        margin: '0 0 8px',
                                        color: 'text.secondary',
                                    } }, rule.details.map(function (detail) { return (React.createElement(Typography, null, detail)); }))))); })));
                    });
                })))));
    };
    return (React.createElement(React.Fragment, null, isMobile ? (React.createElement(React.Fragment, null,
        asLink ? (React.createElement(Box, { sx: {
                display: 'inline',
                color: '#1976d2',
                textDecoration: 'underline',
            }, onClick: function () {
                setOpen(true);
                fareRulesQuery();
            } }, t('flightCard.fareRules'))) : (React.createElement(Button, { sx: { flex: 1, padding: '5px 6px' }, variant: "outlined", onClick: function () {
                setOpen(true);
                fareRulesQuery();
            } },
            React.createElement(Box, { mr: 0.5 },
                React.createElement(FareRulesIcon, { viewBox: "0 0 19 20", stroke: "#1976D2" })),
            t('flightCard.fareRules'))),
        React.createElement(SwipeableDrawer, { anchor: "bottom", open: open, onOpen: function () { return setOpen(true); }, onClose: function () { return setOpen(false); } },
            React.createElement(Stack, { sx: { height: 'calc(100vh - 100px)', pb: 0.5 } },
                React.createElement(Stack, { justifyContent: "space-between", alignItems: "center", sx: {
                        backgroundColor: 'primary.dark',
                    } },
                    React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", sx: { width: '100%', padding: '16px 16px' } },
                        React.createElement(Typography, { sx: {
                                maxWidth: 'calc(100% - 48px)',
                                fontWeight: 600,
                                fontSize: '1em',
                                color: 'common.white',
                            } }, t('fareRules.fareRules')),
                        React.createElement(Clear, { onClick: function () { return setOpen(false); }, sx: { color: 'common.white' } })),
                    React.createElement(Box, { sx: {
                            width: '100%',
                            backgroundColor: 'common.white',
                            height: '35px',
                            borderRadius: '12px 12px 0 0',
                            display: 'flex',
                            justifyContent: 'center',
                        } },
                        React.createElement("div", { style: {
                                width: '30px',
                                height: '4px',
                                backgroundColor: 'grey',
                                marginTop: '8px',
                                borderRadius: 21,
                            } }))),
                React.createElement(Box, { sx: { flex: '1', overflow: 'auto' } },
                    React.createElement(TabData, null)))))) : (React.createElement(React.Fragment, null,
        asLink ? (React.createElement(Box, { sx: {
                display: 'inline',
                color: '#1976d2',
                textDecoration: 'underline',
            } }, t('flightCard.fareRules'))) : (React.createElement(Box, { ref: anchorEl, sx: { display: 'inline', ml: '0px!important' }, onClick: function (e) {
                e.stopPropagation();
                !open && setOpen(true);
                fareRulesQuery();
            } },
            React.createElement(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '13px',
                    cursor: 'pointer',
                    color: function (theme) { return theme.palette.primary.dark; },
                } },
                React.createElement(FareRulesIcon, { viewBox: "0 0 19 20", stroke: "currentColor" }),
                t('flightCard.fareRules')))),
        React.createElement(Dialog, { fullScreen: isMobile, open: open, onClose: function () { return setOpen(false); }, onBackdropClick: function (e) {
                e.stopPropagation();
                setOpen(false);
            } },
            React.createElement(Stack, { direction: "row", sx: {
                    background: '#F3FAFD',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid rgb(227, 242, 253)',
                }, onClick: function (e) { return e.stopPropagation(); } },
                React.createElement(Typography, { sx: { fontWeight: 600, my: 2, mx: 3 } }, t('fareRules.fareRules')),
                React.createElement(IconButton, { "aria-label": "close", onClick: function () { return setOpen(false); }, sx: {
                        color: function (theme) { return theme.palette.grey[500]; },
                    } },
                    React.createElement(Clear, null))),
            React.createElement(TabData, null))))));
};
