import { CircularProgress, Stack, Typography, Box, Button, Collapse, Link, useMediaQuery, } from '@material-ui/core';
import get from 'lodash.get';
import React, { useState } from 'react';
import { useGetBaggageInfoQuery } from '../../generated/graphql';
import InfoOutline from '../../assets/icons/InfoOutline';
import { Info } from '@material-ui/icons';
import Backpack from '../../assets/icons/Backpack';
import Trolley from '../../assets/icons/Trolley';
import Briefcase from '../../assets/icons/Briefcase';
import Close from '../../assets/icons/Close';
import useTranslation, { useLocaleId } from '../../hooks/useTranslation';
import getCurrencySymbol from 'app/util/getCurrencySymbol';
export var BaggageInfo = function (_a) {
    var offerId = _a.offerId;
    var _b = useGetBaggageInfoQuery({
        variables: { offerId: offerId },
        fetchPolicy: 'no-cache',
    }), loading = _b.loading, data = _b.data, error = _b.error;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState(null), anchorEl = _d[0], setAnchorEl = _d[1];
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };
    var t = useTranslation();
    var locale = useLocaleId();
    var iconMap = {
        personal_item: React.createElement(Backpack, null),
        cabin_bag: React.createElement(Trolley, null),
        hold_baggage: React.createElement(Briefcase, null),
        forbidden: React.createElement(Close, { viewBox: "-4 -4 19 19", stroke: "red" }),
    };
    var parts = get(data === null || data === void 0 ? void 0 : data.baggageInfo, 'parts');
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    return (React.createElement(React.Fragment, null,
        loading && (React.createElement(Stack, { justifyContent: "center", alignItems: "center" },
            React.createElement(CircularProgress, null))),
        React.createElement(Stack, { sx: {
                padding: { xs: '10px 16px', md: '16px 0' },
                width: { xs: '100%', md: '530px' },
            }, onClick: function (e) { return e.stopPropagation(); } }, parts === null || parts === void 0 ? void 0 : parts.map(function (part, index) { return (React.createElement(Stack, { key: index, sx: { margin: '0 0 16px' } },
            React.createElement(Typography, { color: "text.primary", variant: "body1" }, part.route.map(function (route) { return (React.createElement(React.Fragment, null, route.map(function (r, i) { return (React.createElement(React.Fragment, null, r + " " + (i !== route.length - 1 ? " - " : ''))); }))); })),
            React.createElement(Stack, { color: "text.primary", direction: "column", mt: 1, justifyContent: "space-between" },
                part.includedBaggage ? (React.createElement(React.Fragment, null,
                    part.includedBaggage.handBaggage && (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { variant: "body2" }, t('baggageInfo.handBaggage')),
                        React.createElement(Stack, null, part.includedBaggage.handBaggage.map(function (option, index) {
                            var _a;
                            return (React.createElement(Stack, { key: index }, (_a = option === null || option === void 0 ? void 0 : option.baggage) === null || _a === void 0 ? void 0 : _a.map(function (baggage, index) { return (React.createElement(React.Fragment, null, baggage.type === 'priority' ? (React.createElement(Stack, { alignItems: "flex-end", sx: { color: 'text.secondary' }, direction: "row" },
                                React.createElement(Info, { sx: { width: 18, height: 18 } }),
                                React.createElement(Typography, { sx: { pl: 1 }, variant: "subtitle1" },
                                    baggage.text,
                                    ' ',
                                    baggage.airlines.map(function (a) { return a; })))) : (React.createElement(Stack, { justifyContent: "space-between", sx: {
                                    flexDirection: 'row',
                                } },
                                isMobile ? (React.createElement(Stack, null,
                                    React.createElement(Stack, { alignItems: "center", direction: "row" },
                                        iconMap[baggage.type],
                                        React.createElement(Typography, { variant: "body2" }, baggage.pieces
                                            ? baggage.pieces > 1 &&
                                                baggage.pieces + " x "
                                            : ''),
                                        React.createElement(Typography, { variant: "body2" },
                                            "\u00A0",
                                            baggage.text)),
                                    baggage.dimension && (React.createElement(Stack, null,
                                        React.createElement(Typography, { variant: "body2", color: "text.disabled" }, baggage.dimension.length + " x " + baggage.dimension.width + " x " + baggage.dimension.height + " cm",
                                            baggage.weight > 0
                                                ? ", " + baggage.weight + " " + baggage.unit
                                                : ''))))) : (React.createElement(React.Fragment, null,
                                    React.createElement(Stack, { alignItems: "center", direction: "row" },
                                        iconMap[baggage.type],
                                        React.createElement(Typography, { variant: "body2" }, baggage.pieces
                                            ? baggage.pieces > 1 &&
                                                baggage.pieces + " x "
                                            : ''),
                                        React.createElement(Typography, { variant: "body2" },
                                            "\u00A0",
                                            baggage.text)),
                                    baggage.dimension && (React.createElement(Stack, null,
                                        React.createElement(Typography, { variant: "body2", color: "text.disabled" }, baggage.dimension.length + " x " + baggage.dimension.width + " x " + baggage.dimension.height + " cm",
                                            baggage.weight > 0
                                                ? ", " + baggage.weight + " " + baggage.unit
                                                : ''))))),
                                option.price && (React.createElement(Typography, { variant: "h5", color: "primary.main" },
                                    getCurrencySymbol(option.price.currency.code),
                                    option.price.amount)))))); })));
                        })))),
                    part.includedBaggage.holdBaggage && (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { variant: "body2" }, t('baggageInfo.holdBaggage')),
                        React.createElement(Stack, null, part.includedBaggage.holdBaggage.map(function (option, index) {
                            var _a;
                            return (React.createElement(Stack, { key: index }, (_a = option === null || option === void 0 ? void 0 : option.baggage) === null || _a === void 0 ? void 0 : _a.map(function (baggage) { return (React.createElement(React.Fragment, null, baggage.type === 'priority' ? (React.createElement(Stack, { alignItems: "flex-end", sx: { color: 'text.secondary' }, direction: "row" },
                                React.createElement(Info, { sx: { width: 18, height: 18 } }),
                                React.createElement(Typography, { sx: { pl: 1 }, variant: "subtitle1" },
                                    baggage.text,
                                    ' ',
                                    baggage.airlines.map(function (a) { return a; })))) : (React.createElement(Stack, { justifyContent: "space-between", sx: {
                                    flexDirection: 'row',
                                } },
                                isMobile ? (React.createElement(Stack, null,
                                    React.createElement(Stack, { alignItems: "center", direction: "row" },
                                        iconMap[baggage.type],
                                        React.createElement(Typography, { variant: "body2" }, baggage.pieces
                                            ? baggage.pieces > 1 &&
                                                baggage.pieces + " x "
                                            : ''),
                                        React.createElement(Typography, { variant: "body2" },
                                            "\u00A0",
                                            baggage.text)),
                                    baggage.dimension && (React.createElement(Stack, null,
                                        React.createElement(Typography, { variant: "body2", color: "text.disabled" }, baggage.dimension.length + " x " + baggage.dimension.width + " x " + baggage.dimension.height + " cm",
                                            baggage.weight > 0
                                                ? ", " + baggage.weight + " " + baggage.unit
                                                : ''))))) : (React.createElement(React.Fragment, null,
                                    React.createElement(Stack, { alignItems: "center", direction: "row" },
                                        iconMap[baggage.type],
                                        React.createElement(Typography, { variant: "body2" }, baggage.pieces
                                            ? baggage.pieces > 1 &&
                                                baggage.pieces + " x "
                                            : ''),
                                        React.createElement(Typography, { variant: "body2" },
                                            "\u00A0",
                                            baggage.text)),
                                    baggage.dimension && (React.createElement(Stack, null,
                                        React.createElement(Typography, { variant: "body2", color: "text.disabled" }, baggage.dimension.length + " x " + baggage.dimension.width + " x " + baggage.dimension.height + " cm",
                                            baggage.weight > 0
                                                ? ", " + baggage.weight + " " + baggage.unit
                                                : ''))))),
                                option.price && (React.createElement(Typography, { variant: "h5", color: "primary.main" },
                                    getCurrencySymbol(option.price.currency.code),
                                    option.price.amount)))))); })));
                        })))))) : (React.createElement(Typography, null, t('common.information_is_missing'))),
                part.forbiddenBaggage && (React.createElement(React.Fragment, null,
                    part.forbiddenBaggage.handBaggage && (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { variant: "body2" }, t('baggageInfo.handBaggage')),
                        React.createElement(Stack, null, part.forbiddenBaggage.handBaggage.map(function (option, index) {
                            var _a;
                            return (React.createElement(Stack, { key: index }, (_a = option === null || option === void 0 ? void 0 : option.baggage) === null || _a === void 0 ? void 0 : _a.map(function (baggage, index) { return (React.createElement(React.Fragment, null,
                                React.createElement(Stack, { justifyContent: "space-between", sx: {
                                        flexDirection: {
                                            sm: 'column',
                                            md: 'row',
                                        },
                                    } },
                                    React.createElement(Stack, { alignItems: "center", direction: "row" },
                                        iconMap[baggage.type],
                                        React.createElement(Typography, { variant: "body2" }, baggage.text))))); })));
                        })))),
                    part.forbiddenBaggage.holdBaggage && (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { variant: "body2" }, t('baggageInfo.holdBaggage')),
                        React.createElement(Stack, null, part.forbiddenBaggage.holdBaggage.map(function (option, index) {
                            var _a;
                            return (React.createElement(Stack, { key: index }, (_a = option === null || option === void 0 ? void 0 : option.baggage) === null || _a === void 0 ? void 0 : _a.map(function (baggage) { return (React.createElement(React.Fragment, null,
                                React.createElement(Stack, { justifyContent: "space-between", sx: {
                                        flexDirection: {
                                            sm: 'column',
                                            md: 'row',
                                        },
                                    } },
                                    React.createElement(Stack, { alignItems: "center", direction: "row" },
                                        iconMap[baggage.type],
                                        React.createElement(Typography, { variant: "body2" },
                                            t('baggageInfo.forbidden'),
                                            ' ',
                                            React.createElement(Link, { href: "/" + locale + "/forbiddenBaggage" }, t('importantBaggageInfo.viewDetails'))))))); })));
                        }))))))),
            part.extraBaggage && (React.createElement(React.Fragment, null,
                React.createElement(Button, { onClick: handleClick, sx: { justifyContent: 'flex-start', pl: 0 } },
                    React.createElement(Box, { sx: { color: '#1976D2', textDecoration: 'underline' } }, t('baggageInfo.additionalBaggage'))),
                React.createElement(Collapse, { in: open },
                    React.createElement(Stack, { justifyContent: "space-between" },
                        part.extraBaggage.handBaggage && (React.createElement(React.Fragment, null,
                            React.createElement(Typography, { variant: "body2" },
                                t('baggageInfo.handBaggage'),
                                " (",
                                t('common.per_passenger'),
                                ")"),
                            React.createElement(Stack, null, part.extraBaggage.handBaggage.map(function (option, index) {
                                var _a;
                                return (React.createElement(Stack, { key: index }, (_a = option === null || option === void 0 ? void 0 : option.baggage) === null || _a === void 0 ? void 0 : _a.map(function (baggage, index) { return (React.createElement(React.Fragment, null, baggage.type === 'priority' ? (React.createElement(Stack, { alignItems: "flex-end", sx: { color: 'text.secondary' }, direction: "row" },
                                    React.createElement(Info, { sx: { width: 18, height: 18 } }),
                                    React.createElement(Typography, { sx: { pl: 1 }, variant: "subtitle1" },
                                        baggage.text,
                                        ' ',
                                        baggage.airlines.map(function (a) { return a; })))) : (React.createElement(Stack, { justifyContent: "space-between", sx: {
                                        flexDirection: 'row',
                                    } },
                                    isMobile ? (React.createElement(Stack, null,
                                        React.createElement(Stack, { alignItems: "center", direction: "row" },
                                            iconMap[baggage.type],
                                            React.createElement(Typography, { variant: "body2" }, baggage.pieces
                                                ? baggage.pieces > 1 &&
                                                    baggage.pieces + " x "
                                                : ''),
                                            React.createElement(Typography, { variant: "body2" },
                                                "\u00A0",
                                                baggage.text)),
                                        baggage.dimension && (React.createElement(Stack, null,
                                            React.createElement(Typography, { variant: "body2", color: "text.disabled" }, baggage.dimension.length + " x " + baggage.dimension.width + " x " + baggage.dimension.height + " cm",
                                                baggage.weight > 0
                                                    ? ", " + baggage.weight + " " + baggage.unit
                                                    : ''))))) : (React.createElement(React.Fragment, null,
                                        React.createElement(Stack, { alignItems: "center", direction: "row" },
                                            iconMap[baggage.type],
                                            React.createElement(Typography, { variant: "body2" }, baggage.pieces
                                                ? baggage.pieces > 1 &&
                                                    baggage.pieces + " x "
                                                : ''),
                                            React.createElement(Typography, { variant: "body2" },
                                                "\u00A0",
                                                baggage.text)),
                                        baggage.dimension && (React.createElement(Stack, null,
                                            React.createElement(Typography, { variant: "body2", color: "text.disabled" }, baggage.dimension.length + " x " + baggage.dimension.width + " x " + baggage.dimension.height + " cm",
                                                baggage.weight > 0
                                                    ? ", " + baggage.weight + " " + baggage.unit
                                                    : ''))))),
                                    option.price && (React.createElement(Typography, { variant: "h5", color: "primary.main" },
                                        getCurrencySymbol(option.price.currency.code),
                                        option.price.amount)))))); })));
                            })))),
                        part.extraBaggage.holdBaggage && (React.createElement(React.Fragment, null,
                            React.createElement(Typography, { variant: "body2" },
                                t('baggageInfo.holdBaggage'),
                                " (",
                                t('common.per_passenger'),
                                ")"),
                            React.createElement(Stack, null, part.extraBaggage.holdBaggage.map(function (option, index) {
                                var _a;
                                return (React.createElement(Stack, { key: index }, (_a = option === null || option === void 0 ? void 0 : option.baggage) === null || _a === void 0 ? void 0 : _a.map(function (baggage) { return (React.createElement(React.Fragment, null, baggage.type === 'priority' ? (React.createElement(Stack, { alignItems: "flex-end", sx: { color: 'text.secondary' }, direction: "row" },
                                    React.createElement(Info, { sx: { width: 18, height: 18 } }),
                                    React.createElement(Typography, { sx: { pl: 1 }, variant: "subtitle1" },
                                        baggage.text,
                                        ' ',
                                        baggage.airlines.map(function (a) { return a; })))) : (React.createElement(Stack, { justifyContent: "space-between", sx: {
                                        flexDirection: 'row',
                                    } },
                                    isMobile ? (React.createElement(Stack, null,
                                        React.createElement(Stack, { alignItems: "center", direction: "row" },
                                            iconMap[baggage.type],
                                            React.createElement(Typography, { variant: "body2" }, baggage.pieces
                                                ? baggage.pieces > 1 &&
                                                    baggage.pieces + " x "
                                                : ''),
                                            React.createElement(Typography, { variant: "body2" },
                                                "\u00A0",
                                                baggage.text)),
                                        baggage.dimension && (React.createElement(Stack, null,
                                            React.createElement(Typography, { variant: "body2", color: "text.disabled" }, baggage.dimension.length + " x " + baggage.dimension.width + " x " + baggage.dimension.height + " cm",
                                                baggage.weight > 0
                                                    ? ", " + baggage.weight + " " + baggage.unit
                                                    : ''))))) : (React.createElement(React.Fragment, null,
                                        React.createElement(Stack, { alignItems: "center", direction: "row" },
                                            iconMap[baggage.type],
                                            React.createElement(Typography, { variant: "body2" }, baggage.pieces
                                                ? baggage.pieces > 1 &&
                                                    baggage.pieces + " x "
                                                : ''),
                                            React.createElement(Typography, { variant: "body2" },
                                                "\u00A0",
                                                baggage.text)),
                                        baggage.dimension && (React.createElement(Stack, null,
                                            React.createElement(Typography, { variant: "body2", color: "text.disabled" }, baggage.dimension.length + " x " + baggage.dimension.width + " x " + baggage.dimension.height + " cm",
                                                baggage.weight > 0
                                                    ? ", " + baggage.weight + " " + baggage.unit
                                                    : ''))))),
                                    option.price && (React.createElement(Typography, { variant: "h5", color: "primary.main" },
                                        getCurrencySymbol(option.price.currency.code),
                                        option.price.amount)))))); })));
                            })))),
                        React.createElement(Stack, { direction: "row", pt: 3, pr: 10 },
                            React.createElement(InfoOutline, null),
                            React.createElement(Typography, { color: "text.primary", pl: 1, variant: "body2" }, t('baggageInfo.priceWarning'))))))))); }))));
};
