var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var CheckinRe = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.83596 6.79123C8.03802 6.79123 8.20182 6.9523 8.20182 7.15099V8.66195C8.20182 9.09108 8.48061 9.5066 8.82636 9.84659L11.0211 12.0048C11.0703 12.0532 11.1048 12.1147 11.1199 12.1815L11.8516 15.4192C11.8955 15.6132 11.7711 15.8054 11.5739 15.8485C11.3766 15.8916 11.1812 15.7693 11.1373 15.5753L10.4288 12.4398L8.30897 10.3554C7.92302 9.97583 7.47011 9.38405 7.47011 8.66195V7.15099C7.47011 6.9523 7.63391 6.79123 7.83596 6.79123Z", fill: "#002171" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.45405 10.7468C9.6292 10.8212 9.71193 11.0258 9.63883 11.2039L7.81755 15.6421C7.74445 15.8203 7.54321 15.9044 7.36807 15.8301C7.19292 15.7557 7.11019 15.5511 7.18328 15.373L9.00457 10.9347C9.07766 10.7566 9.2789 10.6725 9.45405 10.7468Z", fill: "#002171" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.17571 9.53186C8.36109 9.59776 8.45664 9.79787 8.38912 9.97881L6.28144 15.6274C6.21393 15.8083 6.00891 15.9016 5.82353 15.8357C5.63815 15.7698 5.5426 15.5697 5.61011 15.3887L7.71779 9.74017C7.78531 9.55923 7.99032 9.46596 8.17571 9.53186Z", fill: "#002171" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.78385 1.77468C7.78385 0.885395 8.5687 0.142822 9.50861 0.142822C10.4485 0.142822 11.2334 0.885395 11.2334 1.77468V2.13731C11.2334 3.0266 10.4485 3.76917 9.50861 3.76917C8.5687 3.76917 7.78385 3.0266 7.78385 2.13731V1.77468ZM9.50861 0.868092C8.99206 0.868092 8.55041 1.28595 8.55041 1.77468V2.13731C8.55041 2.62604 8.99206 3.0439 9.50861 3.0439C10.0252 3.0439 10.4668 2.62604 10.4668 2.13731V1.77468C10.4668 1.28595 10.0252 0.868092 9.50861 0.868092Z", fill: "#002171" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.0747 6.61947C10.2165 6.46181 10.4641 6.44477 10.6277 6.5814L12.9796 8.54567C13.1432 8.6823 13.1609 8.92087 13.0191 9.07852C12.8773 9.23617 12.6298 9.25321 12.4662 9.11658L10.1142 7.15232C9.95061 7.01568 9.93293 6.77712 10.0747 6.61947Z", fill: "#002171" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.41156 4.18258C9.75565 4.29076 10.099 4.42369 10.3562 4.63709C10.6362 4.86938 10.7942 5.17944 10.7942 5.58068V9.01066L12.6057 11.3987C12.6379 11.4412 12.6598 11.4899 12.6697 11.5413L13.4223 15.4392C13.4595 15.6317 13.3239 15.8162 13.1194 15.8512C12.9149 15.8862 12.719 15.7585 12.6818 15.566L11.9443 11.7461L10.1117 9.33018C10.0661 9.27009 10.0416 9.19808 10.0416 9.12422V5.58068C10.0416 5.37952 9.9738 5.26436 9.85869 5.16887C9.7217 5.05523 9.50204 4.95802 9.16997 4.85379L9.1624 4.85133C8.9666 4.78548 8.67229 4.75984 8.39201 4.79754C8.09646 4.8373 7.92236 4.93174 7.86228 5.00952C7.80967 5.07765 7.70601 5.25689 7.56891 5.5202C7.43741 5.77273 7.28877 6.07556 7.14835 6.36867C7.00814 6.66132 6.87715 6.94215 6.78115 7.14997C6.73317 7.25383 6.69399 7.33933 6.66682 7.39881L6.65481 7.42517V9.37227C6.65481 9.56797 6.48633 9.72662 6.2785 9.72662C6.07067 9.72662 5.90219 9.56797 5.90219 9.37227V7.35245C5.90219 7.3047 5.91244 7.25744 5.93232 7.21351L6.2785 7.35245C5.93232 7.21351 5.93231 7.21353 5.93232 7.21351L5.94361 7.18864L5.97543 7.11882C6.00295 7.05856 6.04255 6.97214 6.091 6.86726C6.18786 6.65761 6.32028 6.37369 6.46231 6.07723C6.60412 5.78122 6.75652 5.47058 6.89315 5.20819C7.02417 4.95657 7.1557 4.71945 7.25361 4.59268C7.49458 4.28068 7.92257 4.14479 8.28557 4.09596C8.66231 4.04528 9.08114 4.07236 9.41156 4.18258Z", fill: "#002171" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.65807 8.9584C6.83564 9.06456 6.89581 9.29845 6.79244 9.48082L5.34149 12.0407C5.23812 12.2231 5.01037 12.2848 4.83279 12.1787C4.65521 12.0725 4.59505 11.8386 4.69842 11.6563L6.14937 9.0964C6.25274 8.91403 6.48049 8.85225 6.65807 8.9584Z", fill: "#002171" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.37267 10.5166L5.09139 11.4661C5.17573 11.5127 5.23733 11.5897 5.26261 11.68C5.28788 11.7703 5.27476 11.8666 5.22613 11.9476L3.22431 15.2823V15.5049C3.22431 15.6994 3.06034 15.8571 2.85808 15.8571C2.65582 15.8571 2.49185 15.6994 2.49185 15.5049V15.427L0.954375 14.5776L0.948379 14.5742C0.620933 14.3852 0.447236 13.9691 0.67427 13.6011L2.35748 10.7859L2.36093 10.7802C2.55738 10.4653 2.99009 10.2983 3.37267 10.5166ZM2.70775 14.7339L1.3234 13.9691C1.31483 13.9639 1.30997 13.9589 1.30733 13.9554L1.3078 13.9546L2.9901 11.1409C2.99554 11.1327 3.00074 11.128 3.00433 11.1254L3.00526 11.126L4.40829 11.9011L2.70775 14.7339Z", fill: "#002171" }))); };
export default CheckinRe;
