var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Typography, useMediaQuery } from '@material-ui/core';
import gql from 'graphql-tag';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useItineraryPricesCalendarItemsQuery } from '../../generated/graphql';
import { otherFlightValuesSelector, flightByIndexSelector, flightMinDateSelector, setFlightDate, flightSearchCalendarInputSelector, } from '../../redux/slices/flightSearch.slice';
import Datepicker from '../ui/chrono/Datepicker';
import CalendarToday from '../../assets/icons/CalendarToday';
import useTranslation from '../../hooks/useTranslation';
var ITIRENARY_CALENDAR_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query itineraryPricesCalendarItems($search: SearchCalendarInput!) {\n    itineraryPricesCalendar(search: $search) {\n      ... on ItineraryPricesCalendar {\n        calendar {\n          date\n          price {\n            formattedValue\n          }\n          direct\n        }\n      }\n      ... on AppError {\n        message\n        code\n      }\n    }\n  }\n"], ["\n  query itineraryPricesCalendarItems($search: SearchCalendarInput!) {\n    itineraryPricesCalendar(search: $search) {\n      ... on ItineraryPricesCalendar {\n        calendar {\n          date\n          price {\n            formattedValue\n          }\n          direct\n        }\n      }\n      ... on AppError {\n        message\n        code\n      }\n    }\n  }\n"])));
var FlightDatepicker = function (_a) {
    var flightIndex = _a.flightIndex, alignDatepicker = _a.alignDatepicker, needsUpdateConfirmation = _a.needsUpdateConfirmation;
    var dispatch = useDispatch();
    var flight = useSelector(function (state) {
        return flightByIndexSelector(state, flightIndex);
    });
    var calendarItemsSearchInput = useSelector(function (state) {
        return flightSearchCalendarInputSelector(state, flightIndex);
    });
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var calendarItemsResult = useItineraryPricesCalendarItemsQuery({
        variables: {
            search: calendarItemsSearchInput,
        },
    });
    var t = useTranslation();
    var groupedCalendarItems = useMemo(function () {
        var _a;
        if (!calendarItemsResult.data) {
            return {};
        }
        var itineraryPricesCalendar = calendarItemsResult.data.itineraryPricesCalendar;
        if (!itineraryPricesCalendar ||
            (itineraryPricesCalendar === null || itineraryPricesCalendar === void 0 ? void 0 : itineraryPricesCalendar.__typename) === 'AppError') {
            return {};
        }
        if (itineraryPricesCalendar.__typename === 'ItineraryPricesCalendar') {
            return (_a = itineraryPricesCalendar.calendar) === null || _a === void 0 ? void 0 : _a.reduce(function (acc, curr) {
                //@ts-ignore
                acc[curr === null || curr === void 0 ? void 0 : curr.date] = curr;
                return acc;
            }, {});
        }
        return {};
    }, [calendarItemsResult.data]);
    var otherValues = useSelector(function (state) {
        return otherFlightValuesSelector(state, flightIndex);
    });
    var _b = useState((flight === null || flight === void 0 ? void 0 : flight.date) || null), intermediateDate = _b[0], setIntermediateDate = _b[1];
    var handleChange = function (date) {
        if (needsUpdateConfirmation) {
            setIntermediateDate(date);
        }
        else {
            date && dispatch(setFlightDate({ date: date, flightIndex: flightIndex }));
        }
    };
    var handleConfirm = function () {
        intermediateDate &&
            dispatch(setFlightDate({ date: intermediateDate, flightIndex: flightIndex }));
    };
    useEffect(function () {
        setIntermediateDate((flight === null || flight === void 0 ? void 0 : flight.date) || null);
    }, [flight]);
    var flightMinDate = useSelector(flightMinDateSelector(flightIndex));
    if (!flight) {
        return null;
    }
    return (React.createElement(Datepicker, { alignCalendars: alignDatepicker, value: flight.date, index: flightIndex, otherValues: otherValues, startAdornment: isMobile && (React.createElement(Typography, { sx: { mr: 1, fontSize: '14px' } }, t('searchForm.label.departureDate'))), endAdornment: isMobile && React.createElement(CalendarToday, { viewBox: "-4 0 21 21" }), groupedCalendarItems: groupedCalendarItems, minDate: flightMinDate, onChange: handleChange, onConfirm: handleConfirm, needsUpdateConfirmation: needsUpdateConfirmation }));
};
export default FlightDatepicker;
var templateObject_1;
