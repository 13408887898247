export var configSiteName = 'Flugo.ro';
export var configSiteUrl = 'www.flugo.ro';
export var configPhone = ' +40311004444'; //for hrefs
export var configPhoneMask = ' +40 31 100 4444'; //for templates
export var configContactEmail = 'info@flugo.ro';
export var configLegalName = 'Wise Travel Solutions SRL';
export var configLegalAddress = 'Registrul Comerțului J40/14659/2012 | RO31007807 Centrul de Afaceri Muntenia, mun. București, strada Splaiul Unirii 16, camera 801';
export var configUtmObjKey = 'flugo-utm';
export var configGdpr = 'flugo_gdpr';
export var configJwtTokenKey = 'flugo-jwt';
export var configLocale = 'flugo-locale';
export var configBillingFormDataCustomer = 'newflugobilling-form-data-customer';
export var configFacebookUrl = 'https://www.facebook.com/BileteAvionFlugo';
export var configInstagramUrl = 'https://www.instagram.com/flugo.ro/';
