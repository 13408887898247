var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
export var CarouselContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 1258px;\n  border-radius: 32px;\n  background: linear-gradient(\n    350.6deg,\n    rgba(0, 0, 0, 0.3) 17.86%,\n    rgba(0, 0, 0, 0.18) 65.57%,\n    rgba(0, 0, 0, 0) 100%\n  );\n  .slick-slide {\n    z-index: -1;\n    &.slick-active {\n      z-index: 1;\n    }\n  }\n"], ["\n  width: 100%;\n  max-width: 1258px;\n  border-radius: 32px;\n  background: linear-gradient(\n    350.6deg,\n    rgba(0, 0, 0, 0.3) 17.86%,\n    rgba(0, 0, 0, 0.18) 65.57%,\n    rgba(0, 0, 0, 0) 100%\n  );\n  .slick-slide {\n    z-index: -1;\n    &.slick-active {\n      z-index: 1;\n    }\n  }\n"])));
export var CarouselItem = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  min-height: 478px;\n  width: 100%;\n  display: block !important;\n"], ["\n  position: relative;\n  min-height: 478px;\n  width: 100%;\n  display: block !important;\n"])));
export var CarouselImage = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  border-radius: 32px;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  width: 100%;\n  height: 100%;\n  background: no-repeat center top scroll;\n  background-size: cover;\n"], ["\n  position: absolute;\n  border-radius: 32px;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  width: 100%;\n  height: 100%;\n  background: no-repeat center top scroll;\n  background-size: cover;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
