var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ListItem } from '@material-ui/core';
export var AirportSearchResultContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n  max-width: 80%;\n"], ["\n  display: flex;\n  align-items: center;\n  overflow: hidden;\n  max-width: 80%;\n"])));
export var SelectedAirport = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  line-height: 28px;\n  color: #fff;\n  padding: 4px 12px;\n  background-color: rgba(79, 133, 195, 0.7);\n  display: flex;\n  white-space: nowrap;\n  & + & {\n    margin-left: 4px;\n  }\n"], ["\n  line-height: 28px;\n  color: #fff;\n  padding: 4px 12px;\n  background-color: rgba(79, 133, 195, 0.7);\n  display: flex;\n  white-space: nowrap;\n  & + & {\n    margin-left: 4px;\n  }\n"])));
export var AirportSelectInputWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-sizing: border-box;\n  ", " {\n    width: 255px;\n    ", ";\n    ", ";\n    border: 1px solid ", ";\n    ", "\n  }\n  ", " {\n    width: 343px;\n  }\n  ", " {\n    width: 100%;\n    /* border: 1px solid ", "; */\n    ", "\n    box-shadow: 0px 2px 10px 0px #1901341F;\n\n    ", ";\n  }\n  display: flex;\n  align-items: center;\n  padding: 3px;\n  height: 48px;\n  background: white;\n  border-radius: 0.25rem;\n  > input {\n    flex: 1;\n    outline: none;\n    border: 1px solid white;\n    min-width: 10%;\n    height: 100%;\n  }\n"], ["\n  box-sizing: border-box;\n  ", " {\n    width: 255px;\n    ", ";\n    ", ";\n    border: 1px solid ", ";\n    ", "\n  }\n  ", " {\n    width: 343px;\n  }\n  ", " {\n    width: 100%;\n    /* border: 1px solid ", "; */\n    ", "\n    box-shadow: 0px 2px 10px 0px #1901341F;\n\n    ", ";\n  }\n  display: flex;\n  align-items: center;\n  padding: 3px;\n  height: 48px;\n  background: white;\n  border-radius: 0.25rem;\n  > input {\n    flex: 1;\n    outline: none;\n    border: 1px solid white;\n    min-width: 10%;\n    height: 100%;\n  }\n"])), function (p) { return p.theme.breakpoints.up('lg'); }, function (p) { return p.wide && "width: 346px"; }, function (p) { return p.open && "width: 512px"; }, function (p) { return p.theme.palette.text.disabled; }, function (p) { return p.flightError && "border: 1px solid red;"; }, function (p) { return p.theme.breakpoints.down('md'); }, function (p) { return p.theme.breakpoints.down('sm'); }, function (p) { return p.theme.palette.text.primary; }, function (p) { return p.flightError && "border: 1px solid red;"; }, function (p) {
    return p.open &&
        "width: calc(100% - 16px); \n       margin: 8px;\n        box-shadow: none; \n        border: 1px solid " + p.theme.palette.text.primary + ";";
});
export var RemoveAirport = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 28px;\n  height: 28px;\n  background-color: #4f84c3;\n  align-items: center;\n  justify-content: center;\n  margin-left: 8px;\n  cursor: pointer;\n"], ["\n  display: flex;\n  width: 28px;\n  height: 28px;\n  background-color: #4f84c3;\n  align-items: center;\n  justify-content: center;\n  margin-left: 8px;\n  cursor: pointer;\n"])));
export var AirportOptionsContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  background: white;\n  min-width: 255px;\n  box-sizing: border-box;\n  z-index: 1;\n  ", "\n"], ["\n  position: absolute;\n  background: white;\n  min-width: 255px;\n  box-sizing: border-box;\n  z-index: 1;\n  ", "\n"])), function (p) { return (p.isOpen ? "border: 1px solid #c9c9c9;" : ''); });
export var AirportSelectItem = styled(ListItem)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  height: 48px;\n  &:hover {\n    background-color: rgb(227, 242, 253);\n  }\n\n  ", "\n  position: relative;\n  cursor: pointer;\n  white-space: nowrap;\n  ", " {\n    white-space: pre-wrap;\n    height: unset;\n  }\n  ", "\n"], ["\n  display: flex;\n  justify-content: space-between;\n  height: 48px;\n  &:hover {\n    background-color: rgb(227, 242, 253);\n  }\n\n  ", "\n  position: relative;\n  cursor: pointer;\n  white-space: nowrap;\n  ", " {\n    white-space: pre-wrap;\n    height: unset;\n  }\n  ", "\n"])), function (p) { return (p.highlighted ? "background-color: #bde4ff;" : ''); }, function (p) { return p.theme.breakpoints.down('sm'); }, function (p) {
    return p.isMain
        ? css(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""]))) : css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n          padding-left: 2em;\n        "], ["\n          padding-left: 2em;\n        "])));
});
export var AirportSelectItemCountry = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: #999;\n  font-size: 12px;\n"], ["\n  color: #999;\n  font-size: 12px;\n"])));
export var AirportSelectItemCode = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin: 0 15px 0 42px;\n  color: ", ";\n  /* font-family: monospace; */\n  letter-spacing: 1.453px;\n"], ["\n  margin: 0 15px 0 42px;\n  color: ", ";\n  /* font-family: monospace; */\n  letter-spacing: 1.453px;\n"])), function (p) { return p.theme.palette.primary.main; });
export var AirportSelectLoading = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 5px;\n  color: #444;\n"], ["\n  padding: 5px;\n  color: #444;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
