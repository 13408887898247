import { IconButton, Stack, Typography } from '@material-ui/core';
import { TrainOutlined } from '@material-ui/icons';
import React from 'react';
import useTranslation from '../../../hooks/useTranslation';
import { AirportSelectItem, } from './elements';
import ControlPointInput from '../../../assets/icons/ControlPointInput';
import AirplanemodeActiveOutline from '../../../assets/icons/AirplanemodeActiveOutline';
import Cancel from '../../../assets/icons/Cancel';
import Language from '../../../assets/icons/Language';
import LocationCity from '../../../assets/icons/LocationCity';
import ReactCountryFlag from 'react-country-flag';
var AirportOptionItem = function (_a) {
    var code = _a.code, name = _a.name, isMain = _a.isMain, country = _a.country, type = _a.type, city = _a.city, included = _a.included, clickHandler = _a.clickHandler, closeDropdown = _a.closeDropdown, purgeInputValue = _a.purgeInputValue;
    var getIcon = {
        Station: React.createElement(AirplanemodeActiveOutline, { viewBox: "0 0 20 20" }),
        AIRPORT: React.createElement(AirplanemodeActiveOutline, { viewBox: "0 0 21 21" }),
        TRAIN: React.createElement(TrainOutlined, { sx: { color: 'primary.dark' } }),
        City: React.createElement(LocationCity, { viewBox: "0 -1 23 23" }),
        anywhere: React.createElement(Language, { stroke: "#002171", viewBox: "0 -1 16 16" }),
    };
    var t = useTranslation();
    if (included) {
        return (React.createElement(AirportSelectItem, { onClick: function () {
                if (clickHandler) {
                    clickHandler();
                }
                if (closeDropdown) {
                    closeDropdown();
                }
                if (purgeInputValue) {
                    purgeInputValue();
                }
            }, isMain: isMain, key: code },
            React.createElement(Stack, null,
                React.createElement(Stack, { direction: "row", alignItems: "center", sx: { color: 'primary.dark' } },
                    React.createElement(Stack, { alignItems: "center" },
                        React.createElement(Stack, { alignItems: "flex-start", justifyContent: "center" },
                            React.createElement(Stack, { sx: { display: 'inline' }, direction: "row" },
                                React.createElement(Typography, { sx: { fontWeight: 700, display: 'inline' } },
                                    name,
                                    "\u00A0")))))),
            React.createElement(IconButton, { onClick: function (e) {
                    e.stopPropagation();
                    if (clickHandler) {
                        clickHandler();
                    }
                } },
                React.createElement(Cancel, { viewBox: "-1 -2 20 20", sx: { stroke: '#002171' } }))));
    }
    return (React.createElement(AirportSelectItem, { onClick: function () {
            if (clickHandler) {
                clickHandler();
            }
            if (closeDropdown) {
                closeDropdown();
            }
            if (purgeInputValue) {
                purgeInputValue();
            }
        }, isMain: isMain, key: code },
        React.createElement(Stack, null,
            React.createElement(Stack, { direction: "row", alignItems: "center", sx: { color: 'primary.dark' } },
                type !== 'Country' ? (
                //@ts-ignore
                React.createElement(IconButton, null, getIcon[type])) : (React.createElement(Stack, { sx: {
                        marginRight: '0.8em',
                        marginLeft: '0.5em',
                        borderRadius: '3px',
                        height: '1.2em',
                        overflow: 'hidden',
                        border: '0.5px solid',
                        borderColor: 'primary.main',
                    } },
                    React.createElement(ReactCountryFlag, { style: {
                            width: '1.5em',
                            height: '1.5em',
                        }, countryCode: code, svg: true }))),
                type === 'anywhere' && (React.createElement(Stack, { alignItems: "center" },
                    React.createElement(Stack, { alignItems: "flex-start", justifyContent: "center" },
                        React.createElement(Stack, { direction: "row" },
                            React.createElement(Typography, { sx: { fontWeight: 700 } }, name))))),
                type === 'City' && (React.createElement(Stack, { alignItems: "center" },
                    React.createElement(Stack, { alignItems: "flex-start", justifyContent: "center" },
                        React.createElement(Stack, { sx: { display: 'inline' }, direction: "row" },
                            React.createElement(Typography, { sx: { fontWeight: 700, display: 'inline' } },
                                name,
                                ",\u00A0"),
                            React.createElement(Typography, { sx: { display: 'inline' } }, t('common.allAirports')))),
                    country && (React.createElement(Stack, { sx: { alignSelf: 'flex-start' } }, country.name)))),
                type === 'Country' && (React.createElement(Stack, { alignItems: "center" },
                    React.createElement(Stack, { alignItems: "flex-start", justifyContent: "center" },
                        React.createElement(Stack, { sx: { display: 'inline' }, direction: "row" },
                            React.createElement(Typography, { sx: { fontWeight: 700, display: 'inline' } },
                                name,
                                ",\u00A0"),
                            React.createElement(Typography, { sx: { display: 'inline' } }, code))))),
                (type === 'Station' || type === 'AIRPORT' || type === 'TRAIN') && (React.createElement(Stack, { alignItems: "center" },
                    React.createElement(Stack, { alignItems: "flex-start", justifyContent: "center" },
                        React.createElement(Stack, { sx: { display: 'inline' }, direction: "row" },
                            React.createElement(Typography, { sx: { display: 'inline' } },
                                city && (React.createElement(Typography, { sx: {
                                        display: 'inline',
                                        fontWeight: 700,
                                    } },
                                    city.name,
                                    ",\u00A0")),
                                name,
                                "\u00A0"),
                            React.createElement(Typography, { sx: { display: 'inline' } },
                                "(",
                                code,
                                ")"))))))),
        included ? (React.createElement(IconButton, { onClick: function (e) {
                e.stopPropagation();
                if (clickHandler) {
                    clickHandler();
                }
            } },
            React.createElement(Cancel, { viewBox: "-1 -2 20 20", sx: { stroke: '#002171' } }))) : (React.createElement(IconButton, { onClick: function (e) {
                e.stopPropagation();
                if (clickHandler) {
                    clickHandler();
                }
                if (purgeInputValue) {
                    purgeInputValue();
                }
            } },
            React.createElement(ControlPointInput, { viewBox: "0 -2 18 18" })))));
};
export default AirportOptionItem;
