import React, { useContext, useEffect, useState } from 'react';
import { Divider, Drawer, IconButton, Stack, Typography, Box, useMediaQuery, } from '@material-ui/core';
import whitelogo from './newLogo.svg';
import logo from './site-logo.svg';
import rightArrowIcon from './right-arrow-list.svg';
import Menu from '../../assets/icons/Menu';
import AccountCircle from './icons/acount-cricle.svg';
import Clear from '../../assets/icons/Clear';
import { AuthContext } from 'app/hooks/useAuth';
import User from '../User/User';
import useTranslation, { useLocaleId } from '../../hooks/useTranslation';
import { ticketMenuItems } from './DesktopMenu';
import { configSiteName, configPhone, configPhoneMask, configFacebookUrl, configInstagramUrl, } from 'app/util/siteConfig';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { IconSCTwo } from '../FooterSection/styles';
import instagramIcon from '../FooterSection/icons/socials/Icon2.svg';
import facebookIcon from '../FooterSection/icons/socials/Icon3.svg';
import LocaleMenu from './LocaleMenu';
var MobileMenu = function (_a) {
    var paletteMode = _a.paletteMode, _b = _a.navVisible, navVisible = _b === void 0 ? false : _b;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _c = useState(false), menuDrawerVisible = _c[0], setMenuDrawerVisible = _c[1];
    var _d = useState(false), userDrawerVisible = _d[0], setUserDrawerVisible = _d[1];
    var t = useTranslation();
    var locale = useLocaleId();
    var ServicesMenuItems = [
        {
            label: t('footer.groupOffer'),
            link: "/" + locale + "/group-offer",
        },
        {
            label: t('footer.connectingFlight'),
            link: "/" + locale + "/conexiuni-inteligente",
        },
        {
            label: t('menuButtons.warranty'),
            link: "/" + locale + "/guarantee",
        },
    ];
    var LeftMenuItems = [
        {
            item: configSiteName,
            children: [
                {
                    label: t('menuButtons.about'),
                    href: "/" + locale + "/despre-noi",
                },
                {
                    label: t('menuButtons.terms'),
                    href: "/" + locale + "/termeni-si-conditii",
                },
                {
                    label: t('menuButtons.contacts'),
                    href: "/" + locale + "/contacte",
                },
                {
                    label: t('menuButtons.privacy'),
                    href: "/" + locale + "/politica-de-confidentialitate",
                },
                {
                    label: t('footer.iata'),
                    href: "/" + locale + "/iata-terms-and-conditions",
                },
                {
                    label: t('footer.cookies'),
                    href: "/" + locale + "/de-ce-cookie",
                },
            ],
            state: true,
        },
        {
            item: t('footer.more'),
            children: [
                {
                    label: t('footer.bonus'),
                    href: "/" + locale + "/program-de-bonus",
                },
                {
                    label: t('footer.faq'),
                    href: "/" + locale + "/intrebari-frecvente",
                },
                {
                    label: t('footer.specialNeeds'),
                    href: "/" + locale + "/special-needs-rules",
                },
                {
                    label: t('footer.whatsCheckin'),
                    href: "/" + locale + "/check-in",
                },
                {
                    label: t('footer.checkin'),
                    href: "/" + locale + "/check-in-online",
                },
                {
                    label: t('footer.blog'),
                    href: "/" + locale + "/blog",
                },
                {
                    label: t('menuButtons.career'),
                    href: "/" + locale + "/cariera",
                },
            ],
            state: true,
        },
        {
            item: t('footer.utilInfo'),
            children: [
                {
                    label: t('footer.countries'),
                    href: "/" + locale + "/bilete-avion-catre-tari",
                },
                {
                    label: t('footer.cities'),
                    href: "/" + locale + "/bilete-avion",
                },
                {
                    label: t('filters.airports'),
                    href: "/" + locale + "/aeroporturi",
                },
                {
                    label: t('filters.airlines'),
                    href: "/" + locale + "/companii-aeriene",
                },
                {
                    label: t('footer.planes'),
                    href: "/" + locale + "/wiki/aeronave",
                },
                {
                    label: t('footer.alliances'),
                    href: "/" + locale + "/wiki/aliante",
                },
                {
                    label: t('footer.safety_assessment_criteria'),
                    href: "/" + locale + "/criterii-de-evaluare-a-sigurantei-pentru-companii-aeriene",
                },
                {
                    label: t('footer.evaluation_criteria'),
                    href: "/" + locale + "/criterii-de-evaluare-a-serviciilor-complete-oferite-de-transportator",
                },
            ],
            state: true,
        },
    ];
    var context = useContext(AuthContext);
    var _e = useState(false), isAuthenticated = _e[0], setIsAutheticated = _e[1];
    useEffect(function () {
        if (context.authData.user) {
            setIsAutheticated(true);
        }
        else {
            setIsAutheticated(false);
        }
    }, [context.authData.user]);
    var closeDrawer = function () { return setUserDrawerVisible(false); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { direction: "row", width: "100%", py: 2, sx: { alignItems: 'center', justifyContent: 'space-between' } },
            React.createElement(IconButton, { onClick: function () { return setMenuDrawerVisible(true); }, sx: { p: 0 } },
                React.createElement(Menu, { viewBox: "-4 -5 24 24" })),
            React.createElement("a", { href: "/", style: { display: 'flex' } },
                React.createElement("img", { src: isMobile ? whitelogo : logo, title: configSiteName + " - " + t('home.compare.prices'), alt: configSiteName })),
            React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', gap: '4px' } },
                React.createElement(LocaleMenu, { paletteMode: paletteMode }),
                React.createElement(IconButton, { onClick: function () { return setUserDrawerVisible(true); }, sx: {
                        width: '26px',
                        height: '26px',
                    } },
                    React.createElement("img", { src: AccountCircle })))),
        React.createElement(Drawer, { anchor: 'left', open: menuDrawerVisible, onClose: function () { return setMenuDrawerVisible(false); }, PaperProps: {
                sx: {
                    backgroundColor: '#002171',
                    color: 'common.white',
                    right: '60px',
                },
            }, sx: {
                zIndex: 1300,
            } },
            React.createElement(Stack, { sx: { p: 3 }, direction: "row", justifyContent: "space-between", alignItems: "center" },
                React.createElement("img", { src: whitelogo, style: { height: 36 }, alt: configSiteName }),
                React.createElement(Clear, { viewBox: "-2 -2 16 16", onClick: function () { return setMenuDrawerVisible(false); } })),
            React.createElement(Divider, { variant: "fullWidth", style: { background: '#D3DAE4' } }),
            React.createElement(Stack, { direction: "column", sx: { pl: 2, py: 3 } },
                React.createElement(Typography, { variant: "h6", sx: { pl: 1, pb: 1 } }, t('footer.services')),
                ticketMenuItems.map(function (_a, key) {
                    var label = _a.label, link = _a.link;
                    return (React.createElement(Stack, { direction: "row", gap: "7px", alignItems: "center", fontSize: "14px", sx: { pl: 3, pt: '5px', pr: '10px' }, key: key },
                        React.createElement("img", { src: rightArrowIcon, style: { height: 21 } }),
                        React.createElement("a", { href: link, style: { textDecoration: 'none', color: '#fff' } }, t(label))));
                }),
                ServicesMenuItems.map(function (_a, key) {
                    var label = _a.label, link = _a.link;
                    return (React.createElement(Stack, { direction: "row", gap: "7px", alignItems: "center", fontSize: "14px", sx: { pl: 3, pt: '5px', pr: '10px' }, key: key },
                        React.createElement("img", { src: rightArrowIcon, style: { height: 21 } }),
                        React.createElement("a", { href: link, style: { textDecoration: 'none', color: '#fff' } }, label)));
                })),
            React.createElement(Divider, { variant: "fullWidth", style: { background: '#D3DAE4' } }),
            React.createElement(Stack, null, LeftMenuItems.map(function (elm, key) { return (React.createElement(React.Fragment, { key: key },
                React.createElement(Stack, { direction: "column", sx: { pl: 2, py: 3 } },
                    React.createElement(Typography, { variant: "h6", sx: { pl: 1, pb: 1 } }, elm.item),
                    elm.children.map(function (child, key) { return (React.createElement(Stack, { direction: "row", gap: "7px", alignItems: "center", fontSize: "14px", sx: { pl: 3, pt: '5px', pr: '10px' }, key: key },
                        React.createElement("img", { src: rightArrowIcon, style: { height: 21 } }),
                        React.createElement("a", { href: child.href, style: { textDecoration: 'none', color: '#fff' } }, child.label))); })),
                React.createElement(Divider, { variant: "fullWidth", style: { background: '#D3DAE4' } }))); })),
            React.createElement(Stack, { direction: "row", justifyContent: "center", alignItems: "center", spacing: 2, sx: { py: 3 } },
                React.createElement(LocalPhoneIcon, { sx: { fontSize: 24 } }),
                React.createElement("a", { href: "tel:" + configPhone, style: { fontSize: 20, color: '#fff', textDecoration: 'none' } }, configPhoneMask)),
            React.createElement(Divider, { variant: "fullWidth", style: { background: '#D3DAE4' } }),
            React.createElement(Stack, { direction: "row", gap: "12px", justifyContent: "center", alignItems: "center", sx: { py: 3 } },
                React.createElement("a", { href: configFacebookUrl, target: "_blank", rel: "noreferrer" },
                    React.createElement(IconSCTwo, { style: { height: 32 }, src: facebookIcon })),
                React.createElement("a", { href: configInstagramUrl, target: "_blank", rel: "noreferrer" },
                    React.createElement(IconSCTwo, { style: { height: 32 }, src: instagramIcon })))),
        React.createElement(Drawer, { anchor: 'right', sx: { zIndex: 1300 }, open: userDrawerVisible, onClose: function () { return setUserDrawerVisible(false); } },
            React.createElement(User, { isAuthenticated: isAuthenticated, mobileOpen: userDrawerVisible, closeMobileDrawer: closeDrawer }))));
};
export default MobileMenu;
