var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var FareRulesIcon = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { xmlns: "http://www.w3.org/2000/svg", d: "M10.857 3.21436H5.42843C5.0685 3.21436 4.7233 3.35734 4.46879 3.61185C4.21427 3.86637 4.07129 4.21156 4.07129 4.5715V15.4286C4.07129 15.7886 4.21427 16.1338 4.46879 16.3883C4.7233 16.6428 5.0685 16.7858 5.42843 16.7858H13.5713C13.9312 16.7858 14.2764 16.6428 14.5309 16.3883C14.7854 16.1338 14.9284 15.7886 14.9284 15.4286V7.28578M10.857 3.21436L14.9284 7.28578M10.857 3.21436V7.28578H14.9284M12.2141 10.6786H6.78557M12.2141 13.3929H6.78557M8.14272 7.96436H6.78557", strokeLinecap: "round", strokeLinejoin: "round", fill: "#fff" }))); };
export default FareRulesIcon;
