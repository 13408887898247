var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var Language = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M12.7142 6.99993C12.7142 10.1558 10.1558 12.7142 6.99993 12.7142M12.7142 6.99993C12.7142 3.84402 10.1558 1.28564 6.99993 1.28564M12.7142 6.99993H1.28564M6.99993 12.7142C3.84402 12.7142 1.28564 10.1558 1.28564 6.99993M6.99993 12.7142C8.42923 11.1494 9.2415 9.11877 9.28564 6.99993C9.2415 4.88109 8.42923 2.85042 6.99993 1.28564M6.99993 12.7142C5.57063 11.1494 4.75836 9.11877 4.71422 6.99993C4.75836 4.88109 5.57063 2.85042 6.99993 1.28564M1.28564 6.99993C1.28564 3.84402 3.84402 1.28564 6.99993 1.28564", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default Language;
