import { Card, CircularProgress, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Box } from '@material-ui/system';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { useActivateCheckinSegmentMutation, usePassengerTravelDocumentFormLazyQuery, useRefetchCheckinLazyQuery, } from 'app/generated/graphql';
import useTranslation, { useDateFormat, useLocaleId, } from 'app/hooks/useTranslation';
import CheckInForm from './CheckInForm';
var CheckInStatus = function (_a) {
    var _b;
    var pass = _a.pass, refetch = _a.refetch;
    var status = (_b = pass.values) === null || _b === void 0 ? void 0 : _b.status;
    var format = useDateFormat();
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var t = useTranslation();
    var _c = useActivateCheckinSegmentMutation({
        variables: { segmentId: pass.segmentId },
        onCompleted: function (_a) {
            var activateCheckinSegment = _a.activateCheckinSegment;
            if ((activateCheckinSegment === null || activateCheckinSegment === void 0 ? void 0 : activateCheckinSegment.__typename) === 'SegmentBoardingPassDetails') {
                refetch();
            }
        },
    }), activateCheckinResult = _c[0], activateResult = _c[1];
    switch (status) {
        case 'FREE_CHECK_IN':
            return (React.createElement(Stack, { sx: {
                    borderRadius: 1,
                    height: '48px',
                    my: 1,
                    width: { xs: '100%', md: '279px' },
                    p: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#4BC25F',
                    border: '1px solid',
                    borderColor: '#4BC25F',
                    fontSize: '14px',
                    lineHeight: '18px',
                    textAlign: 'center',
                } }, t('checkin.status.FREE_CHECK_IN')));
        case 'NOT_REQUIRED_CHECK_IN':
            return (React.createElement(Stack, { direction: "row", onClick: function () { return activateCheckinResult(); }, sx: {
                    borderRadius: 1,
                    height: '48px',
                    my: 1,
                    width: { xs: '100%', md: '279px' },
                    p: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    backgroundColor: '#1c72c7',
                    fontSize: '14px',
                    lineHeight: '18px',
                    textAlign: 'center',
                } },
                React.createElement(ChevronRight, null),
                " ",
                t('checkin.status.NOT_REQUIRED_CHECK_IN')));
        case 'FLIGHT_IS_PERFORMED':
            return (React.createElement(Stack, { sx: {
                    borderRadius: 1,
                    height: '48px',
                    my: 1,
                    width: { xs: '100%', md: '279px' },
                    p: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#4BC25F',
                    border: '1px solid',
                    borderColor: '#4BC25F',
                    fontSize: '14px',
                    lineHeight: '18px',
                    textAlign: 'center',
                } }, t('checkin.status.FLIGHT_IS_PERFORMED')));
        case 'WILL_BE_AVAILABLE':
            return (React.createElement(Stack, { sx: {
                    borderRadius: 1,
                    my: 1,
                    height: '48px',
                    width: { xs: '100%', md: '279px' },
                    p: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#FFE597',
                    fontSize: '14px',
                    lineHeight: '18px',
                    textAlign: 'center',
                } },
                t('checkin.status.WILL_BE_AVAILABLE'),
                ' ',
                React.createElement(Typography, { fontSize: "14px", fontWeight: "bold", lineHeight: "18px" }, format(new Date(pass.values.value), 'dd MMMM'))));
        case 'DOWNLOAD_BOARDING_PASS':
            return (React.createElement(Stack, { role: "button", sx: {
                    borderRadius: 1,
                    my: 1,
                    width: { xs: '100%', md: '279px' },
                    height: '48px',
                    p: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'common.white',
                    cursor: 'pointer',
                    backgroundColor: 'primary.main',
                    '> a': { textDecoration: 'none', color: 'common.white' },
                    fontSize: '14px',
                    lineHeight: '18px',
                    textAlign: 'center',
                } },
                React.createElement("a", { href: pass.values.value, target: "_blank", download: true, rel: "noreferrer" }, t('checkin.status.DOWNLOAD_BOARDING_PASS'))));
        default:
            return null;
    }
};
var CheckIn = function (_a) {
    var _b, _c;
    var checkin = _a.checkin, bookingId = _a.bookingId, code = _a.code, name = _a.name;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _d = useState(checkin), newCheckin = _d[0], setNewCheckin = _d[1];
    var t = useTranslation();
    var _e = usePassengerTravelDocumentFormLazyQuery({
        variables: { bookingId: bookingId },
    }), getPassengerForm = _e[0], passengerFormResult = _e[1];
    var locale = useLocaleId();
    var result = get(passengerFormResult.data, 'passengersTravelDocumentForm');
    var handleAddDetailsClick = function () {
        var _a;
        (_a = document
            .querySelector('.CheckinForm')) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
    };
    var _f = useRefetchCheckinLazyQuery({
        variables: { code: code, name: name },
        onCompleted: function (_a) {
            var bookingInfo = _a.bookingInfo;
            if ((bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.__typename) === 'BookingInfoResult' &&
                bookingInfo.checkin) {
                setNewCheckin(bookingInfo.checkin);
            }
        },
        fetchPolicy: 'network-only',
    }), refetchCheckinQuery = _f[0], refetchCheckinResult = _f[1];
    useEffect(function () {
        refetchCheckinQuery();
    }, [locale]);
    var showFormCondition = (_b = newCheckin.boardingPass) === null || _b === void 0 ? void 0 : _b.some(function (pass) { var _a; return ((_a = pass === null || pass === void 0 ? void 0 : pass.values) === null || _a === void 0 ? void 0 : _a.status) === 'NEED_COMPLETE_TRAVEL_DOCUMENT'; });
    useEffect(function () {
        if (showFormCondition) {
            getPassengerForm();
        }
    }, [newCheckin.boardingPass]);
    return (React.createElement(Stack, { className: "Checkin" },
        passengerFormResult.called && showFormCondition && (React.createElement(Card, { sx: {
                my: 2,
                width: '100%',
                p: 2,
            }, className: "CheckinForm" },
            React.createElement(Typography, null, t('checkin.title')),
            passengerFormResult.loading ? (React.createElement(CircularProgress, null)) : (React.createElement(React.Fragment, null, (result === null || result === void 0 ? void 0 : result.__typename) === 'TravelDocumentForm' && (React.createElement(React.Fragment, null,
                ' ',
                result.info,
                React.createElement(CheckInForm, { bookingId: bookingId, result: result, refetch: refetchCheckinQuery }))))))),
        React.createElement(Card, { sx: {
                my: 2,
                width: '100%',
                p: 2,
            } },
            React.createElement(Typography, { sx: { fontWeight: 600, my: 1, color: 'primary.dark' } }, newCheckin.title), (_c = newCheckin.info) === null || _c === void 0 ? void 0 :
            _c.map(function (item) { return (React.createElement(Typography, { sx: { color: 'text.secondary', my: 1, lineHeight: '18px' } }, item)); }),
            newCheckin.boardingPass &&
                newCheckin.boardingPass.map(function (pass, index) {
                    var _a, _b, _c, _d, _e, _f, _g;
                    var sumCount = newCheckin.boardingPass.length / 2;
                    var isOneWay = newCheckin.boardingPass &&
                        ((_a = newCheckin.boardingPass[0].sourceCity) === null || _a === void 0 ? void 0 : _a.name) ===
                            ((_c = (_b = newCheckin.boardingPass[newCheckin.boardingPass.length - 1]) === null || _b === void 0 ? void 0 : _b.destinationCity) === null || _c === void 0 ? void 0 : _c.name);
                    return (React.createElement(React.Fragment, null,
                        index === 0 && (React.createElement(Typography, { sx: { fontWeight: '600', my: 2, color: 'primary.dark' } }, t("flightPartType.OUT"))),
                        index === sumCount && isOneWay && (React.createElement(Typography, { sx: { fontWeight: '600', my: 2, color: 'primary.dark' } }, t("flightPartType.IN"))),
                        React.createElement(Stack, { key: index, sx: {
                                flexDirection: { xs: 'column', md: 'row' },
                                border: '1px solid rgb(227, 242, 253)',
                                borderRadius: 1,
                                p: 1,
                                height: { xs: 'auto', md: '64px' },
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 1,
                            } },
                            React.createElement(Box, { sx: { maxWidth: 150 }, component: "img", src: "/assets/carrierlogos/" + ((_d = pass === null || pass === void 0 ? void 0 : pass.carrier) === null || _d === void 0 ? void 0 : _d.code) + ".png" }),
                            React.createElement(Typography, { sx: {
                                    color: 'primary.dark',
                                    margin: { xs: '16px 0 4px 0', md: '0' },
                                } }, ((_e = pass === null || pass === void 0 ? void 0 : pass.sourceCity) === null || _e === void 0 ? void 0 : _e.name) + " - " + ((_f = pass === null || pass === void 0 ? void 0 : pass.destinationCity) === null || _f === void 0 ? void 0 : _f.name)),
                            pass && (React.createElement(React.Fragment, null, ((_g = pass.values) === null || _g === void 0 ? void 0 : _g.status) ===
                                'NEED_COMPLETE_TRAVEL_DOCUMENT' ? (React.createElement(React.Fragment, null,
                                React.createElement(Stack, { role: "button", onClick: function () { return handleAddDetailsClick(); }, sx: {
                                        borderRadius: 1,
                                        my: 1,
                                        minWidth: 280,
                                        width: { xs: '100%', md: 'unset' },
                                        p: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#E62F2F',
                                        cursor: 'pointer',
                                        border: '1px solid',
                                        borderColor: '#E62F2F',
                                        fontSize: '14px',
                                    } }, t('checkin.status.NEED_COMPLETE_TRAVEL_DOCUMENT')))) : (React.createElement(CheckInStatus, { pass: pass, refetch: refetchCheckinQuery })))))));
                }))));
};
export default CheckIn;
