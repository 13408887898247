var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, Grid, TextField, } from '@material-ui/core';
import LatinLettersField from 'app/components/fields/LatinLettersField';
import useTranslation from 'app/hooks/useTranslation';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import BankCard from './BankCard';
var CardForm = function () {
    var methods = useFormContext();
    var t = useTranslation();
    var handleNumericInputChange = function (event, maxLength) {
        event.stopPropagation();
        var _a = event.target, name = _a.name, value = _a.value;
        var truncatedValue = value.replace(/\D/g, '').slice(0, maxLength);
        methods.setValue(name, truncatedValue, { shouldValidate: true });
    };
    var watchCardValues = methods.watch('card', undefined);
    var _a = useState(false), isCvvFocused = _a[0], setIsCvvFocused = _a[1];
    return (React.createElement(Box, { sx: {
            margin: {
                xs: '0 0 24px 0',
                md: '0 0 30px 0',
            },
        } },
        React.createElement(Grid, { container: true, spacing: 2, direction: { xs: 'column-reverse', md: 'row' } },
            React.createElement(Grid, { container: true, item: true, xs: 12, md: 6, sx: { alignItems: 'center' } },
                React.createElement(Grid, { container: true, item: true, spacing: 2 },
                    React.createElement(Grid, { md: 12, xs: 12, item: true },
                        React.createElement(Controller, { name: "card.cardNumber", control: methods.control, rules: {
                                required: t('formErrors.requiredField'),
                            }, render: function (_a) {
                                var _b, _c;
                                var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                return (React.createElement(TextField, __assign({}, field, { onChange: function (event) { return handleNumericInputChange(event, 16); }, value: field.value || '', disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "cardNumber", variant: "outlined", label: t('bankcard.placeholder.number'), inputProps: {
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        maxLength: 16,
                                    } })));
                            } })),
                    React.createElement(Grid, { md: 12, xs: 12, item: true },
                        React.createElement(Controller, { name: "card.cardHolder", control: methods.control, rules: {
                                required: t('formErrors.requiredField'),
                            }, render: function (_a) {
                                var _b, _c;
                                var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                return (React.createElement(LatinLettersField, __assign({}, field, { noPropagation: true, value: field.value, disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "cardHolder", variant: "outlined", label: t('bankcard.placeholder.cardholder') })));
                            } })),
                    React.createElement(Grid, { xs: 12, md: 4, item: true },
                        React.createElement(Controller, { name: "card.cardExpireMonth", control: methods.control, rules: {
                                required: t('formErrors.requiredField'),
                            }, render: function (_a) {
                                var _b, _c;
                                var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                return (React.createElement(TextField, __assign({}, field, { onChange: function (event) { return handleNumericInputChange(event, 2); }, value: field.value || '', disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "cardExpireMonth", variant: "outlined", label: t('bankcard.placeholder.expire_month'), placeholder: t('bankcard.placeholder.month'), inputProps: {
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        maxLength: 2,
                                    } })));
                            } })),
                    React.createElement(Grid, { xs: 12, md: 4, item: true },
                        React.createElement(Controller, { name: "card.cardExpireYear", control: methods.control, rules: {
                                required: t('formErrors.requiredField'),
                            }, render: function (_a) {
                                var _b, _c;
                                var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                return (React.createElement(TextField, __assign({}, field, { onChange: function (event) { return handleNumericInputChange(event, 2); }, value: field.value || '', disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "cardExpireYear", variant: "outlined", label: t('bankcard.placeholder.expire_year'), placeholder: t('bankcard.placeholder.year'), inputProps: {
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        maxLength: 2,
                                    } })));
                            } })),
                    React.createElement(Grid, { xs: 12, md: 4, item: true },
                        React.createElement(Controller, { name: "card.cardCvv", control: methods.control, rules: {
                                required: t('formErrors.requiredField'),
                            }, render: function (_a) {
                                var _b, _c;
                                var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                return (React.createElement(TextField, __assign({}, field, { onFocus: function () { return setIsCvvFocused(true); }, onBlur: function () {
                                        setIsCvvFocused(false);
                                    }, onChange: function (event) { return handleNumericInputChange(event, 4); }, value: field.value || '', disabled: formState.isSubmitting, error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, id: "cardCvv", variant: "outlined", label: t('bankcard.placeholder.cvv'), inputProps: {
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        maxLength: 4,
                                    } })));
                            } })))),
            React.createElement(Grid, { container: true, item: true, xs: 12, md: 6 },
                React.createElement(BankCard, { watchCardValues: watchCardValues, reversed: isCvvFocused })))));
};
export default CardForm;
