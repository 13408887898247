var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Alert, AlertTitle, Button, Dialog, Typography, Stack, useMediaQuery, } from '@material-ui/core';
import styled from '@emotion/styled';
import useTranslation from 'app/hooks/useTranslation';
import React, { useEffect, useState } from 'react';
import InfoOutline from '../assets/icons/InfoOutline';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
var BookingAlert = function (_a) {
    var alert = _a.alert;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _b = useState(false), isExpanded = _b[0], setIsExpanded = _b[1];
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var t = useTranslation();
    useEffect(function () {
        setIsOpen(true);
    }, []);
    var alertSeverityMap = {
        ERROR: 'error',
        WARNING: 'warning',
        INFO: 'info',
        SUCCESS: 'success',
    };
    var AlertTextBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: #002171;\n    display: -webkit-box;\n    -webkit-line-clamp: ", ";\n    -webkit-box-orient: vertical;\n  "], ["\n    color: #002171;\n    display: -webkit-box;\n    -webkit-line-clamp: ", ";\n    -webkit-box-orient: vertical;\n  "])), isExpanded ? 'auto' : '4');
    return isMobile ? (React.createElement(Stack, { sx: {
            position: 'relative',
            alignItems: 'center',
            backgroundColor: '#E3F2FD',
            margin: '32px 16px 0',
        }, onClick: function () { return setIsExpanded(!isExpanded); } },
        React.createElement(Alert, { icon: React.createElement(InfoOutline, null), sx: {
                padding: '16px 16px 0 16px',
                height: isExpanded ? 'unset' : '135px',
                backgroundColor: 'transparent',
                overflow: isExpanded ? 'unset' : 'hidden',
                '& .MuiAlert-icon': {
                    display: 'none',
                },
            }, severity: "info" },
            React.createElement(Stack, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '6px',
                    margin: '0 0 6px 0',
                } },
                React.createElement(InfoOutline, null),
                React.createElement(Typography, { sx: { fontSize: '16px', color: 'primary.dark' } }, t('bonus.simple.warning'))),
            React.createElement(AlertTextBox, null,
                React.createElement("div", { dangerouslySetInnerHTML: { __html: alert.body } }))),
        React.createElement(Button, { sx: {
                width: '100%',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '4px',
                padding: '8px 16px 16px',
                textAlign: 'left',
                color: 'primary.dark',
            }, variant: "text" },
            isExpanded ? t('flightCard.less') : t('flightCard.show.more'),
            React.createElement(ArrowDownwardIcon, { sx: {
                    fill: '#002171',
                    width: '16px',
                    transform: isExpanded ? 'scale(1, -1)' : 'scale(1, 1)',
                } })))) : alert.modal ? (React.createElement(Dialog, { open: isOpen, onClose: function () { } },
        React.createElement(Alert, { sx: {
                mt: 4,
                py: 2,
                px: '1.75rem',
            }, severity: alertSeverityMap[alert.type] },
            alert.title && React.createElement(AlertTitle, null, alert.title),
            React.createElement(Typography, { variant: "body2", color: "#1c72c7" }, alert.body),
            React.createElement(Button, { onClick: function () { return setIsOpen(false); } }, "OK")))) : (React.createElement(Alert, { sx: {
            mt: 4,
            py: 2,
            px: '1.75rem',
        }, severity: alertSeverityMap[alert.type] },
        alert.title && React.createElement(AlertTitle, null, alert.title),
        React.createElement(Typography, { variant: "body2", color: "#1c72c7" }, alert.body)));
};
export default BookingAlert;
var templateObject_1;
