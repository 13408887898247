var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { PristineButton } from '../../ui/buttons';
export var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 1075px;\n  box-sizing: border-box;\n  position: relative;\n  padding: 15px;\n  padding-top: 15px;\n  @media (min-width: 1240px) {\n    width: 1235px;\n  }\n\n  ", " {\n    width: 750px;\n  }\n\n  ", " {\n    width: 100%;\n  }\n"], ["\n  width: 1075px;\n  box-sizing: border-box;\n  position: relative;\n  padding: 15px;\n  padding-top: 15px;\n  @media (min-width: 1240px) {\n    width: 1235px;\n  }\n\n  ", " {\n    width: 750px;\n  }\n\n  ", " {\n    width: 100%;\n  }\n"])), function (p) { return p.theme.breakpoints.down('md'); }, function (p) { return p.theme.breakpoints.down('sm'); });
export var FlightRowContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-bottom: 15px;\n"], ["\n  display: flex;\n  align-items: center;\n  margin-bottom: 15px;\n"])));
export var FlightRows = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var FlightFormMain = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
export var FlightFormBottom = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
export var AddFlightButton = styled(PristineButton)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  width: 28px;\n  height: 28px;\n  align-self: center;\n  justify-content: center;\n  cursor: pointer;\n  align-items: flex-start;\n  color: ", ";\n\n  ", " {\n    width: unset;\n    margin: 10px 0;\n    border-radius: 63px;\n    align-self: center;\n    height: 36px;\n    border: 1px solid #fff;\n    padding: 8px 16px;\n    background-color: transparent;\n    color: #fff;\n  }\n"], ["\n  display: flex;\n  width: 28px;\n  height: 28px;\n  align-self: center;\n  justify-content: center;\n  cursor: pointer;\n  align-items: flex-start;\n  color: ", ";\n\n  ", " {\n    width: unset;\n    margin: 10px 0;\n    border-radius: 63px;\n    align-self: center;\n    height: 36px;\n    border: 1px solid #fff;\n    padding: 8px 16px;\n    background-color: transparent;\n    color: #fff;\n  }\n"])), function (p) { return p.theme.palette.text.primary; }, function (p) { return p.theme.breakpoints.down('sm'); });
export var City = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 16px;\n  color: #fff;\n  {(p)=>p.theme.breakpoints.down('sm')}{\n    overflow-x: hidden;\n    white-space: nowrap;\n    flex-wrap: nowrap;\n  }\n"], ["\n  font-size: 16px;\n  color: #fff;\n  {(p)=>p.theme.breakpoints.down('sm')}{\n    overflow-x: hidden;\n    white-space: nowrap;\n    flex-wrap: nowrap;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
