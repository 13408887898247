var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Card, Stack, TextField, Typography, Box, useMediaQuery, } from '@material-ui/core';
import useTranslation from '../../hooks/useTranslation';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useApplyVoucherMutation } from '../../generated/graphql';
import Alert from '../Alert';
import ArrowForward from 'app/assets/icons/ArrowForward';
var Voucher = function (_a) {
    var bookingId = _a.bookingId;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var defaultValues = {
        bookingUuid: bookingId,
        voucher: '',
    };
    var _b = useState(true), showVoucher = _b[0], setShowVoucher = _b[1];
    var _c = useApplyVoucherMutation({
        onCompleted: function (_a) {
            var applyVoucher = _a.applyVoucher;
            if ((applyVoucher === null || applyVoucher === void 0 ? void 0 : applyVoucher.__typename) === 'BookingAlert') {
                setAlert(applyVoucher);
                setTimeout(function () { return setAlert(null); }, 3000);
            }
            if ((applyVoucher === null || applyVoucher === void 0 ? void 0 : applyVoucher.__typename) === 'BookingInfoResult') {
                setTimeout(function () { return setShowVoucher(false); }, 1000);
            }
        },
    }), applyVoucherMutation = _c[0], applyVoucherData = _c[1];
    var t = useTranslation();
    var methods = useForm({ defaultValues: defaultValues });
    var _d = useState(null), alert = _d[0], setAlert = _d[1];
    var onSubmit = function (e) {
        e.preventDefault();
        applyVoucherMutation({
            variables: {
                bookingUuid: bookingId,
                voucher: methods.getValues('voucher'),
            },
        });
    };
    return (React.createElement(Stack, { sx: {
            my: 2,
        } }, showVoucher && (React.createElement(Card, { sx: {
            width: '100%',
            py: 3,
            px: 2,
        } },
        React.createElement("form", { onSubmit: onSubmit },
            React.createElement(Typography, { variant: "body1", sx: { color: 'primary.dark' } }, t('voucher.title')),
            React.createElement(Controller, { name: "voucher", control: methods.control, rules: {
                    required: '',
                    minLength: 1,
                }, render: function (_a) {
                    var _b, _c;
                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                    return (React.createElement(TextField, __assign({}, field, { error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), disabled: formState.isSubmitting, helperText: !!((_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message), sx: { width: '100%', mt: 1 }, label: t('voucher.label') })));
                } }),
            React.createElement(Button, { type: "submit", sx: { height: '40px', mt: 2 }, variant: "contained", fullWidth: true },
                React.createElement(Box, { sx: {
                        display: 'flex',
                        alignItems: 'center',
                    } },
                    React.createElement(ArrowForward, { viewBox: "-9 -7 24 24" }),
                    React.createElement(Box, { sx: { maxWidth: 'calc(100% - 24px)' } }, t('common.apply')))),
            alert && React.createElement(Alert, { alert: alert }))))));
};
export default Voucher;
