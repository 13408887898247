var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import SvgIcon from '@mui/material/SvgIcon';
var ArrowForward = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M0.857178 9.28578L5.14289 5.00007L0.857178 0.714355", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default ArrowForward;
