var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var ExpandMoreFilters = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M1.42871 1.46436L5.50014 5.53578L9.57157 1.46436", stroke: "#374145", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default ExpandMoreFilters;
