var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
export var Box = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: ", ";\n  align-items: ", ";\n  justify-content: ", ";\n  flex-wrap: ", ";\n  ", "\n  ", "\n"], ["\n  display: flex;\n  flex-direction: ", ";\n  align-items: ", ";\n  justify-content: ", ";\n  flex-wrap: ", ";\n  ", "\n  ", "\n"])), function (p) { return p.direction || 'row'; }, function (p) { return p.align || 'start'; }, function (p) { return p.justify || 'normal'; }, function (p) { return p.wrap || 'nowrap'; }, function (p) { return (p.flex ? "flex: " + p.flex + ";" : ''); }, function (p) {
    return p.renderFlexAfter
        ? "\n  &:after {\n    content: '';\n    margin: auto;\n  }\n  "
        : '';
});
var templateObject_1;
