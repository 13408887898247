var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Box, ClickAwayListener, Fade, Paper, Popper, Stack, IconButton, Button, useMediaQuery, Typography, SwipeableDrawer, } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Clear } from '@material-ui/icons';
import { BaggageInfo } from './BaggageInfo';
import Close from '../../assets/icons/Close';
import BaggageInfoIcon from '../../assets/icons/BaggageInfoIcon';
import useTranslation from '../../hooks/useTranslation';
export var BaggageDetails = function (_a) {
    var offerId = _a.offerId;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var anchorEl = useRef(null);
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('md'); });
    var t = useTranslation();
    return isMobile ? (React.createElement(React.Fragment, null,
        React.createElement(Button, { sx: {
                flex: 1,
                padding: '5px 6px',
            }, variant: "outlined", onClick: function () { return setOpen(true); } },
            React.createElement(Box, { mr: 0.5 },
                React.createElement(BaggageInfoIcon, { viewBox: "0 0 19 20", stroke: "currentColor" })),
            React.createElement(Box, { mb: -0.125 }, t('flightCard.baggageDetails'))),
        React.createElement(SwipeableDrawer, { anchor: "bottom", open: open, onOpen: function () { return setOpen(true); }, onClose: function () { return setOpen(false); } },
            React.createElement(Stack, { sx: { height: 'calc(100vh - 100px)' } },
                React.createElement(Stack, { justifyContent: "space-between", alignItems: "center", sx: {
                        backgroundColor: 'primary.dark',
                    } },
                    React.createElement(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", sx: { width: '100%', padding: '16px 16px' } },
                        React.createElement(Typography, { sx: {
                                maxWidth: 'calc(100% - 48px)',
                                fontWeight: 600,
                                fontSize: '1em',
                                color: 'common.white',
                            } }, t('flightCard.baggageDetails')),
                        React.createElement(Clear, { onClick: function () { return setOpen(false); }, sx: { color: 'common.white' } })),
                    React.createElement(Box, { sx: {
                            width: '100%',
                            backgroundColor: 'common.white',
                            height: '35px',
                            borderRadius: '12px 12px 0 0',
                            display: 'flex',
                            justifyContent: 'center',
                        } },
                        React.createElement("div", { style: {
                                width: '30px',
                                height: '4px',
                                backgroundColor: 'grey',
                                marginTop: '8px',
                                borderRadius: 21,
                            } }))),
                React.createElement(Box, { sx: { flex: '1', overflow: 'auto' } },
                    React.createElement(BaggageInfo, { offerId: offerId })))))) : (React.createElement(React.Fragment, null,
        React.createElement(Box, { ref: anchorEl, sx: { pr: 1.5 }, onClick: function (e) {
                e.stopPropagation();
                !open && setOpen(true);
            } },
            React.createElement(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '13px',
                    cursor: 'pointer',
                    color: function (theme) { return theme.palette.primary.dark; },
                } },
                React.createElement(BaggageInfoIcon, { viewBox: "0 0 19 22", stroke: "currentColor" }),
                t('flightCard.baggageDetails'))),
        React.createElement(Popper, { open: open, anchorEl: anchorEl.current, placement: "bottom-start", transition: true }, function (_a) {
            var TransitionProps = _a.TransitionProps;
            return (React.createElement(ClickAwayListener, { onClickAway: function () { return setOpen(false); } },
                React.createElement(Fade, __assign({}, TransitionProps, { timeout: 150 }),
                    React.createElement(Paper, { elevation: 3, sx: { p: 1, pl: 2, mt: 0.5 } },
                        React.createElement(IconButton, { sx: { position: 'absolute', right: '15px' }, onClick: function (e) {
                                e.stopPropagation();
                                setOpen(false);
                            } },
                            React.createElement(Close, { viewBox: "-4 -4 19 19", stroke: "#1c72c7" })),
                        React.createElement(Stack, { direction: "row", spacing: 2, alignItems: "center" },
                            React.createElement(BaggageInfo, { offerId: offerId }))))));
        })));
};
