import { Card, Divider, Stack, useMediaQuery } from '@material-ui/core';
import { Box } from '@material-ui/system';
import useTranslation from 'app/hooks/useTranslation';
import React from 'react';
var OrderSummary = function (_a) {
    var _b, _c;
    var price = _a.price;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var t = useTranslation();
    return isMobile ? (React.createElement(Stack, { sx: {} })) : (React.createElement(Card, { sx: {
            width: '100%',
            py: 3,
            px: 2,
            color: 'primary.dark',
        } }, (_b = price.details) === null || _b === void 0 ? void 0 :
        _b.map(function (p) {
            var _a, _b, _c, _d;
            return (React.createElement(Stack, null, (p === null || p === void 0 ? void 0 : p.type) === 'promo' ? (React.createElement(Stack, { justifyContent: "space-between", direction: "row" },
                React.createElement(Box, null, "" + t("summary." + (p === null || p === void 0 ? void 0 : p.type))),
                React.createElement(Box, null,
                    "-\u20AC", (_b = (_a = p === null || p === void 0 ? void 0 : p.total) === null || _a === void 0 ? void 0 : _a.amount) === null || _b === void 0 ? void 0 :
                    _b.replace('-', '')))) : (React.createElement(Stack, { justifyContent: "space-between", direction: "row" },
                React.createElement(Box, null, "" + ((p === null || p === void 0 ? void 0 : p.num) === 0 ? '' : (p === null || p === void 0 ? void 0 : p.num) + " x ") + t("summary." + (p === null || p === void 0 ? void 0 : p.type))),
                React.createElement(Box, null, ((_c = p === null || p === void 0 ? void 0 : p.total) === null || _c === void 0 ? void 0 : _c.amount) === '0' ? (React.createElement(Box, { sx: { fontWeight: 700 } }, t('summary.free'))) : ("\u20AC" + ((_d = p === null || p === void 0 ? void 0 : p.total) === null || _d === void 0 ? void 0 : _d.amount)))))));
        }),
        React.createElement(Divider, { variant: "fullWidth", sx: { my: 1, borderColor: 'primary.dark' } }),
        React.createElement(Stack, { justifyContent: "space-between", direction: "row", sx: { fontWeight: '700' } },
            React.createElement(Box, null, t('booking.totalPrice')),
            React.createElement(Box, null,
                "\u20AC", (_c = price.total) === null || _c === void 0 ? void 0 :
                _c.amount))));
};
export default OrderSummary;
