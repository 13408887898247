var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Autocomplete, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, Stack, TextField, Typography, useMediaQuery, } from '@material-ui/core';
import { Box } from '@material-ui/system';
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useUpdatePassengerTravelDocumentMutation, useValidatePassengerTravelDocumentMutation, } from 'app/generated/graphql';
import get from 'lodash.get';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { MaskedDateField } from '../fields/MaskedDayField';
import useTranslation, { useDateFormat } from 'app/hooks/useTranslation';
import { parseISO } from 'date-fns';
var CheckInForm = function (_a) {
    var _b, _c;
    var bookingId = _a.bookingId, result = _a.result, refetch = _a.refetch;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _d = useState(false), isOpen = _d[0], setIsOpen = _d[1];
    var format = useDateFormat();
    var t = useTranslation();
    var defaultValues = useMemo(function () {
        var documents = [];
        if ((result === null || result === void 0 ? void 0 : result.__typename) === 'TravelDocumentForm') {
            //@ts-ignore
            documents = result.travelDocuments;
        }
        var formDocuments = documents &&
            documents.map(function (document) {
                var _a, _b;
                return __assign(__assign(__assign(__assign({}, (document.passengerId && {
                    passengerId: document.passengerId.value,
                })), { dateOfBirth: (_b = (_a = document.dateOfBirth) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.split('-').reverse().join('.') }), (document.nationality && { nationality: '' })), (document.document && {
                    document: {
                        countryOfIssue: '',
                        number: '',
                        issueDate: '',
                        expireDate: '',
                    },
                }));
            });
        return {
            bookingId: bookingId,
            travelDocuments: formDocuments,
        };
    }, [result]);
    var methods = useForm({
        defaultValues: defaultValues,
        criteriaMode: 'firstError',
        shouldFocusError: true,
    });
    var errorField = document.querySelector('.Mui-error');
    useEffect(function () {
        if (errorField) {
            errorField.scrollIntoView({ behavior: 'smooth' });
        }
    }, [errorField]);
    var _e = useValidatePassengerTravelDocumentMutation({
        onCompleted: function (_a) {
            var _b;
            var validatePassengerTravelDocument = _a.validatePassengerTravelDocument;
            if ((validatePassengerTravelDocument === null || validatePassengerTravelDocument === void 0 ? void 0 : validatePassengerTravelDocument.__typename) ===
                'PassengerTravelDocumentValidationResult') {
                if (validatePassengerTravelDocument.travelDocumentsInput) {
                    setIsOpen(true);
                }
            }
            else if ((validatePassengerTravelDocument === null || validatePassengerTravelDocument === void 0 ? void 0 : validatePassengerTravelDocument.__typename) === 'ValidationErrors') {
                (_b = document
                    .querySelector('.CheckinForm')) === null || _b === void 0 ? void 0 : _b.scrollIntoView({ behavior: 'smooth' });
                // @ts-ignore
                validatePassengerTravelDocument === null || validatePassengerTravelDocument === void 0 ? void 0 : validatePassengerTravelDocument.fields.forEach(function (doc) {
                    // @ts-ignore
                    methods.setError("travelDocuments." + doc.path, {
                        type: 'manual',
                        message: doc === null || doc === void 0 ? void 0 : doc.messages[0],
                    });
                });
            }
        },
    }), validateCheckIn = _e[0], validateResult = _e[1];
    var onSubmit = function (formValues) {
        var values = __assign(__assign({}, formValues), { documents: formValues.travelDocuments.map(function (doc) {
                var documentCopy = _.cloneDeep(doc);
                documentCopy.passengerId = doc.passengerId;
                if (doc.dateOfBirth) {
                    documentCopy.dateOfBirth = doc.dateOfBirth
                        .split('.')
                        .reverse()
                        .join('-');
                }
                if (doc.document && doc.document.issueDate) {
                    documentCopy.document.issueDate = doc.document.issueDate
                        .split('.')
                        .reverse()
                        .join('-');
                }
                if (doc.document && doc.document.expireDate) {
                    documentCopy.document.expireDate = doc.document.expireDate
                        .split('.')
                        .reverse()
                        .join('-');
                }
                if (doc.nationality && doc.document) {
                    documentCopy.document.countryOfIssue = doc.nationality;
                }
                return documentCopy;
            }) });
        validateCheckIn({
            variables: {
                bookingId: values.bookingId,
                documents: values.documents,
            },
        });
    };
    var _f = useUpdatePassengerTravelDocumentMutation(), submitCheckin = _f[0], submitCheckinResult = _f[1];
    var customValidationSubmit = function (e) {
        e.preventDefault();
        return methods.handleSubmit(onSubmit)(e);
    };
    var validationResponse = get(validateResult.data, 'validatePassengerTravelDocument');
    var updateCheckinResponse = get(submitCheckinResult.data, 'updatePassengerTravelDocument');
    var handleUpdateSubmit = function () {
        if (validationResponse &&
            (validationResponse === null || validationResponse === void 0 ? void 0 : validationResponse.__typename) ===
                'PassengerTravelDocumentValidationResult' &&
            validationResponse.travelDocumentsInput !== undefined) {
            var values = validationResponse.travelDocumentsInput.map(function (doc) {
                var documentCopy = {
                    passengerId: doc.passengerId,
                    dateOfBirth: doc.dateOfBirth,
                    nationality: doc.nationality.code,
                    document: {
                        number: doc.document.number,
                        issueDate: doc.document.issueDate,
                        expireDate: doc.document.expireDate,
                        countryOfIssue: doc.document.countryOfIssue.code,
                    },
                };
                documentCopy.passengerId = doc.passengerId;
                return documentCopy;
            });
            return submitCheckin({
                variables: {
                    bookingId: bookingId,
                    documents: values,
                },
            });
        }
    };
    return (React.createElement(Stack, null, (result === null || result === void 0 ? void 0 : result.__typename) === 'TravelDocumentForm' && (React.createElement(FormProvider, __assign({}, methods),
        React.createElement(Dialog, { fullScreen: isMobile, open: isOpen },
            React.createElement(DialogTitle, { sx: {
                    fontSize: 14,
                } },
                (validationResponse === null || validationResponse === void 0 ? void 0 : validationResponse.__typename) ===
                    'PassengerTravelDocumentValidationResult' &&
                    !updateCheckinResponse &&
                    t('checkin.validation'),
                (updateCheckinResponse === null || updateCheckinResponse === void 0 ? void 0 : updateCheckinResponse.__typename) === 'CheckinInfoResult' && (React.createElement(React.Fragment, null, updateCheckinResponse.title))),
            React.createElement(DialogContent, null,
                React.createElement(Stack, { sx: { p: 2 } },
                    (validationResponse === null || validationResponse === void 0 ? void 0 : validationResponse.__typename) ===
                        'PassengerTravelDocumentValidationResult' &&
                        validationResponse.travelDocumentsInput &&
                        !updateCheckinResponse && (React.createElement(Stack, null, (_b = validationResponse.travelDocumentsInput) === null || _b === void 0 ? void 0 :
                        _b.map(function (pas, index) {
                            var _a;
                            return (React.createElement(Stack, { key: index, spacing: 1.5 },
                                index !== 0 && (React.createElement(Divider, { variant: "fullWidth", sx: { my: 1 } })),
                                React.createElement(Stack, { direction: "row" },
                                    React.createElement(Stack, { flex: 1 },
                                        React.createElement(Typography, { variant: "body2" },
                                            t('checkInConfirmation.name'),
                                            ":")),
                                    React.createElement(Stack, { flex: 2 },
                                        React.createElement(Typography, { fontWeight: "bold" }, (pas === null || pas === void 0 ? void 0 : pas.name) + " " + (pas === null || pas === void 0 ? void 0 : pas.surname) + ", " + (pas === null || pas === void 0 ? void 0 : pas.title)),
                                        ' ')),
                                React.createElement(Stack, { direction: "row" },
                                    React.createElement(Stack, { flex: 1 },
                                        React.createElement(Typography, { variant: "body2" },
                                            t('passengerForm.date_of_birth'),
                                            ":")),
                                    ' ',
                                    React.createElement(Stack, { flex: 2 },
                                        React.createElement(Typography, { fontWeight: "bold" },
                                            ' ',
                                            format(parseISO(pas === null || pas === void 0 ? void 0 : pas.dateOfBirth), 'dd.MM.yyyy')),
                                        ' ')),
                                (pas === null || pas === void 0 ? void 0 : pas.document) && (React.createElement(React.Fragment, null,
                                    React.createElement(Stack, { direction: "row" },
                                        React.createElement(Stack, { flex: 1 },
                                            React.createElement(Typography, { variant: "body2" },
                                                t('checkInConfirmation.documentNumber'),
                                                ":")),
                                        ' ',
                                        React.createElement(Stack, { flex: 2 },
                                            React.createElement(Typography, { fontWeight: "bold" }, pas.document.number))),
                                    React.createElement(Stack, { direction: "row" },
                                        React.createElement(Stack, { flex: 1 },
                                            React.createElement(Typography, { variant: "body2" },
                                                t('checkInConfirmation.country'),
                                                ":")),
                                        ' ',
                                        React.createElement(Stack, { flex: 2 },
                                            React.createElement(Typography, { fontWeight: "bold" }, (_a = pas.document.countryOfIssue) === null || _a === void 0 ? void 0 : _a.name))),
                                    React.createElement(Stack, { direction: "row" },
                                        ' ',
                                        React.createElement(Stack, { flex: 1 },
                                            React.createElement(Typography, { variant: "body2" },
                                                t('checkInConfirmation.issueDate'),
                                                ":")),
                                        ' ',
                                        React.createElement(Stack, { flex: 2 },
                                            React.createElement(Typography, { fontWeight: "bold" },
                                                ' ',
                                                format(parseISO(pas.document.issueDate), 'dd.MM.yyyy')),
                                            ' ')),
                                    React.createElement(Stack, { direction: "row" },
                                        React.createElement(Stack, { flex: 1 },
                                            React.createElement(Typography, { variant: "body2" },
                                                t('checkInConfirmation.expireDate'),
                                                ":")),
                                        ' ',
                                        React.createElement(Stack, { flex: 2 },
                                            React.createElement(Typography, { fontWeight: "bold" },
                                                ' ',
                                                format(parseISO(pas.document.expireDate), 'dd.MM.yyyy'))))))));
                        }),
                        React.createElement(Stack, { sx: { my: 1, fontSize: 12 } },
                            React.createElement("div", { dangerouslySetInnerHTML: {
                                    __html: validationResponse.info,
                                } })),
                        React.createElement(Stack, { sx: { width: '100%' }, spacing: 1, justifyContent: isMobile ? 'center' : 'flex-end', direction: "row" },
                            React.createElement(Button, { variant: "contained", onClick: function () { return setIsOpen(false); }, color: "error", sx: { width: 145, height: 48 } }, t('common.cancel')),
                            React.createElement(Button, { variant: "contained", onClick: function () { return handleUpdateSubmit(); }, sx: { width: 145, height: 48 } }, t('common.save'))))),
                    (updateCheckinResponse === null || updateCheckinResponse === void 0 ? void 0 : updateCheckinResponse.__typename) === 'CheckinInfoResult' && (React.createElement(Stack, null,
                        updateCheckinResponse.info &&
                            updateCheckinResponse.info.map(function (inf) { return (React.createElement("div", { dangerouslySetInnerHTML: {
                                    __html: inf,
                                } })); }),
                        React.createElement(Stack, { spacing: 2, sx: { py: 2 } }, (_c = updateCheckinResponse.available) === null || _c === void 0 ? void 0 : _c.map(function (av, i) {
                            var _a;
                            return (React.createElement(Stack, { key: i, direction: "row", justifyContent: "space-between" },
                                React.createElement(Box, { key: i, sx: { mr: 1, maxWidth: 25 }, component: "img", src: "/assets/carrierlogos_compact/" + ((_a = av === null || av === void 0 ? void 0 : av.carrier) === null || _a === void 0 ? void 0 : _a.code) + ".png" }),
                                React.createElement("div", { dangerouslySetInnerHTML: {
                                        __html: av === null || av === void 0 ? void 0 : av.info,
                                    } })));
                        })),
                        React.createElement(Stack, { sx: { width: '100%' }, direction: "row" },
                            React.createElement(Button, { variant: "contained", onClick: function () {
                                    setIsOpen(false);
                                    refetch();
                                } }, "OK"))))))),
        React.createElement("form", { onSubmit: customValidationSubmit },
            result.travelDocuments.map(function (val, index) { return (React.createElement(React.Fragment, null, val && (React.createElement(React.Fragment, null,
                React.createElement(Divider, { variant: "fullWidth", sx: { my: 2 } }),
                React.createElement(Stack, { direction: "row", sx: { mb: 2 } },
                    React.createElement(Typography, null, val === null || val === void 0 ? void 0 :
                        val.name,
                        " ", val === null || val === void 0 ? void 0 :
                        val.surname),
                    ", ",
                    React.createElement(Typography, { sx: { pl: 0.5 } }, val === null || val === void 0 ? void 0 : val.title),
                    ' '),
                React.createElement("input", __assign({}, methods.register("travelDocuments." + index + ".passengerId"), { value: val.passengerId.value, type: "hidden" })),
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, md: 6, xs: 12 },
                        React.createElement(Controller, { rules: {
                                required: '',
                                minLength: {
                                    value: 10,
                                    message: t('formErrors.fullDate'),
                                },
                            }, name: "travelDocuments." + index + ".dateOfBirth", control: methods.control, render: function (_a) {
                                var _b, _c, _d, _e;
                                var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                return (React.createElement(MaskedDateField, __assign({ label: t('passengerForm.dateOfBirth') }, field, { reverseYears: true, minYear: (_b = val.dateOfBirth) === null || _b === void 0 ? void 0 : _b.range.start, maxYear: (_c = val.dateOfBirth) === null || _c === void 0 ? void 0 : _c.range.end, disabled: formState.isSubmitting, error: !!((_d = fieldState.error) === null || _d === void 0 ? void 0 : _d.message), helperText: (_e = fieldState.error) === null || _e === void 0 ? void 0 : _e.message })));
                            } })),
                    React.createElement(Grid, { item: true, md: 6, xs: 12 }, val.nationality && (React.createElement(Controller, { name: "travelDocuments." + index + ".nationality", rules: {
                            required: '',
                        }, control: methods.control, render: function (_a) {
                            var _b, _c;
                            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                            return (React.createElement(Autocomplete, __assign({}, field, { disabled: formState.isSubmitting, getOptionLabel: function (option) {
                                    return option ? option.text : '';
                                }, value: ((_b = result.metadata) === null || _b === void 0 ? void 0 : _b.countries.find(function (c) { return (c === null || c === void 0 ? void 0 : c.identifier) === field.value; })) || null, onChange: function (e, value) {
                                    return field.onChange(value ? value.identifier : '');
                                }, id: "country-select", options: (_c = result.metadata) === null || _c === void 0 ? void 0 : _c.countries, autoHighlight: true, renderOption: function (props, option) { return (React.createElement(Box, __assign({ component: "li" }, props), (option === null || option === void 0 ? void 0 : option.text) || '')); }, renderInput: function (params) {
                                    var _a, _b;
                                    return (React.createElement(TextField, __assign({ error: !!((_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message), helperText: (_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message, variant: "outlined" }, params, { label: t('passengerForm.country'), inputProps: __assign({}, params.inputProps) })));
                                } })));
                        } }))),
                    val.document && (React.createElement(Grid, { item: true, container: true, spacing: 2 },
                        val.document.number && (React.createElement(Grid, { md: 6, xs: 12, item: true },
                            React.createElement(Controller, { name: "travelDocuments." + index + ".document.number", rules: {
                                    required: '',
                                }, control: methods.control, render: function (_a) {
                                    var _b, _c;
                                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                    return (React.createElement(TextField, __assign({}, field, { error: !!((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message), disabled: formState.isSubmitting, helperText: (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message, sx: { width: '100%' }, label: t('passengerForm.documentNumber') })));
                                } }))),
                        val.document.issueDate && (React.createElement(Grid, { md: 3, xs: 12, item: true },
                            React.createElement(Controller, { name: "travelDocuments." + index + ".document.issueDate", rules: {
                                    required: '',
                                    minLength: {
                                        value: 10,
                                        message: t('formErrors.fullDate'),
                                    },
                                }, control: methods.control, render: function (_a) {
                                    var _b, _c, _d, _e;
                                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                    return (React.createElement(MaskedDateField, __assign({ label: t('passengerForm.issuedDate') }, field, { reverseYears: true, minYear: (_b = val.document) === null || _b === void 0 ? void 0 : _b.issueDate.range.start, maxYear: (_c = val.document) === null || _c === void 0 ? void 0 : _c.issueDate.range.end, disabled: formState.isSubmitting, error: !!((_d = fieldState.error) === null || _d === void 0 ? void 0 : _d.message), helperText: (_e = fieldState.error) === null || _e === void 0 ? void 0 : _e.message })));
                                } }))),
                        val.document.expireDate && (React.createElement(Grid, { item: true, md: 3, xs: 12 },
                            React.createElement(Controller, { name: "travelDocuments." + index + ".document.expireDate", rules: {
                                    required: '',
                                    minLength: {
                                        value: 10,
                                        message: t('formErrors.fullDate'),
                                    },
                                }, control: methods.control, render: function (_a) {
                                    var _b, _c, _d, _e;
                                    var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
                                    return (React.createElement(MaskedDateField, __assign({ label: t('passengerForm.expirationDate') }, field, { minYear: (_b = val.document) === null || _b === void 0 ? void 0 : _b.expireDate.range.start, maxYear: (_c = val.document) === null || _c === void 0 ? void 0 : _c.expireDate.range.end, disabled: formState.isSubmitting, error: !!((_d = fieldState.error) === null || _d === void 0 ? void 0 : _d.message), helperText: (_e = fieldState.error) === null || _e === void 0 ? void 0 : _e.message })));
                                } })))))))))); }),
            React.createElement(Stack, { sx: { my: 1 } },
                React.createElement(Button, { type: "submit", variant: "contained", sx: {
                        width: { lg: 250, xs: 'unset' },
                        height: '48px',
                        alignSelf: 'flex-end',
                    } },
                    t('checkInConfirmation.send'),
                    !!methods.formState.isSubmitting && React.createElement(CircularProgress, null))))))));
};
export default CheckInForm;
