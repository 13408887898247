var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var AccountCircle = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("circle", { fill: "none", cx: "13", cy: "13", r: "12.5", stroke: "#374145" }),
    React.createElement("path", { fill: "none", d: "M17.9526 18.5714V17.3334C17.9526 16.6766 17.6917 16.0468 17.2274 15.5824C16.763 15.118 16.1331 14.8572 15.4764 14.8572H10.524C9.86732 14.8572 9.23749 15.118 8.77311 15.5824C8.30874 16.0468 8.04785 16.6766 8.04785 17.3334V18.5714M15.4764 9.90478C15.4764 11.2723 14.3678 12.381 13.0002 12.381C11.6327 12.381 10.524 11.2723 10.524 9.90478C10.524 8.53722 11.6327 7.42859 13.0002 7.42859C14.3678 7.42859 15.4764 8.53722 15.4764 9.90478Z", stroke: "#374145", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default AccountCircle;
