var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import SvgIcon from '@mui/material/SvgIcon';
var AccessTime = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M6.99986 3.14272V6.99986L9.57129 8.28558M13.4284 6.99986C13.4284 10.5503 10.5503 13.4284 6.99986 13.4284C3.44946 13.4284 0.571289 10.5503 0.571289 6.99986C0.571289 3.44946 3.44946 0.571289 6.99986 0.571289C10.5503 0.571289 13.4284 3.44946 13.4284 6.99986Z", stroke: "#828282", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default AccessTime;
