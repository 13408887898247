import { createSlice, createSelector } from '@reduxjs/toolkit';
var initialState = {
    searches: [],
};
var _a = createSlice({
    name: 'searchHistory',
    initialState: initialState,
    reducers: {
        addSearchHistoryItem: function (state, action) {
            state.searches.push(action.payload);
        },
        removeSearchHistoryItem: function (state, action) {
            state.searches.splice(action.payload, 1);
        },
    },
}), actions = _a.actions, reducer = _a.reducer;
export default reducer;
export var addSearchHistoryItem = actions.addSearchHistoryItem, removeSearchHistoryItem = actions.removeSearchHistoryItem;
var searchHistoryStateSelector = function (state) { return state.searchHistory; };
export var searchHistoryItemsSelector = createSelector([searchHistoryStateSelector], function (searchHistoryState) { return searchHistoryState.searches; });
