var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import SvgIcon from '@mui/material/SvgIcon';
var Luggage = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.75256 6.91228C6.93441 6.91228 7.08183 7.05724 7.08183 7.23606V8.59593C7.08183 8.98214 7.33275 9.35611 7.64392 9.6621L9.61923 11.6045C9.66351 11.648 9.69454 11.7034 9.70813 11.7635L10.3667 14.6775C10.4061 14.852 10.2942 15.025 10.1167 15.0638C9.93914 15.1026 9.76326 14.9925 9.72381 14.818L9.08607 11.996L7.17827 10.12C6.83091 9.77842 6.4233 9.24581 6.4233 8.59593V7.23606C6.4233 7.05724 6.57071 6.91228 6.75256 6.91228Z", fill: "#EB5757" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.20884 10.4723C8.36647 10.5392 8.44093 10.7234 8.37515 10.8837L6.73599 14.8781C6.6702 15.0384 6.48909 15.1141 6.33146 15.0472C6.17382 14.9803 6.09936 14.7961 6.16515 14.6358L7.80431 10.6414C7.87009 10.4811 8.05121 10.4054 8.20884 10.4723Z", fill: "#EB5757" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.05833 9.37885C7.22517 9.43816 7.31117 9.61825 7.25041 9.7811L5.3535 14.8648C5.29273 15.0277 5.10822 15.1116 4.94137 15.0523C4.77453 14.993 4.68853 14.8129 4.7493 14.65L6.64621 9.56632C6.70697 9.40347 6.89149 9.31954 7.05833 9.37885Z", fill: "#EB5757" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.70566 2.39738C6.70566 1.59703 7.41203 0.928711 8.25795 0.928711C9.10387 0.928711 9.81024 1.59703 9.81024 2.39738V2.72375C9.81024 3.52411 9.10387 4.19243 8.25795 4.19243C7.41203 4.19243 6.70566 3.52411 6.70566 2.72375V2.39738ZM8.25795 1.58145C7.79305 1.58145 7.39557 1.95753 7.39557 2.39738V2.72375C7.39557 3.16361 7.79305 3.53968 8.25795 3.53968C8.72285 3.53968 9.12033 3.16361 9.12033 2.72375V2.39738C9.12033 1.95753 8.72285 1.58145 8.25795 1.58145Z", fill: "#EB5757" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.76744 6.75769C8.89505 6.6158 9.11786 6.60046 9.26509 6.72343L11.3818 8.49127C11.5291 8.61424 11.545 8.82895 11.4174 8.97084C11.2898 9.11273 11.067 9.12806 10.9197 9.00509L8.80299 7.23726C8.65575 7.11429 8.63983 6.89958 8.76744 6.75769Z", fill: "#EB5757" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.1706 4.56449C8.48028 4.66185 8.78926 4.78149 9.02077 4.97355C9.27279 5.18261 9.41498 5.46166 9.41498 5.82278V8.90977L11.0453 11.059C11.0743 11.0973 11.094 11.1411 11.1029 11.1873L11.7803 14.6955C11.8137 14.8687 11.6917 15.0348 11.5076 15.0663C11.3236 15.0978 11.1473 14.9828 11.1138 14.8096L10.45 11.3717L8.80071 9.19734C8.75968 9.14325 8.73762 9.07844 8.73762 9.01197V5.82278C8.73762 5.64174 8.67661 5.53809 8.57302 5.45216C8.44972 5.34988 8.25203 5.26239 7.95317 5.16858L7.94636 5.16637C7.77014 5.1071 7.50526 5.08402 7.253 5.11796C6.98701 5.15374 6.83032 5.23873 6.77625 5.30874C6.72889 5.37005 6.6356 5.53137 6.51221 5.76835C6.39387 5.99563 6.26009 6.26818 6.13371 6.53197C6.00753 6.79536 5.88963 7.04811 5.80323 7.23514C5.76005 7.32861 5.72478 7.40557 5.70034 7.45909L5.68952 7.48283V9.23521C5.68952 9.41135 5.53789 9.55413 5.35084 9.55413C5.1638 9.55413 5.01217 9.41135 5.01217 9.23521V7.41738C5.01217 7.3744 5.02139 7.33186 5.03929 7.29233L5.35084 7.41738C5.03929 7.29233 5.03928 7.29235 5.03929 7.29233L5.04944 7.26995L5.07808 7.2071C5.10285 7.15287 5.13849 7.0751 5.1821 6.98071C5.26927 6.79202 5.38845 6.53649 5.51627 6.26968C5.64391 6.00327 5.78107 5.72369 5.90403 5.48754C6.02194 5.26109 6.14033 5.04768 6.22845 4.93358C6.44532 4.65278 6.83051 4.53048 7.15721 4.48653C7.49628 4.44092 7.87322 4.4653 8.1706 4.56449Z", fill: "#EB5757" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.69246 8.86273C5.85228 8.95827 5.90642 9.16877 5.81339 9.3329L4.50753 11.6368C4.4145 11.8009 4.20953 11.8565 4.04971 11.761C3.88989 11.6655 3.83574 11.455 3.92877 11.2908L5.23463 8.98693C5.32766 8.8228 5.53264 8.76719 5.69246 8.86273Z", fill: "#EB5757" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.7356 10.2651L4.28244 11.1197C4.35835 11.1616 4.41379 11.2309 4.43654 11.3122C4.45929 11.3934 4.44748 11.4801 4.40371 11.553L2.60207 14.5542V14.7546C2.60207 14.9296 2.4545 15.0716 2.27247 15.0716C2.09043 15.0716 1.94286 14.9296 1.94286 14.7546V14.6845L0.559133 13.92L0.553736 13.9169C0.259035 13.7469 0.102708 13.3723 0.307038 13.0412L1.82193 10.5075L1.82503 10.5024C2.00184 10.219 2.39127 10.0686 2.7356 10.2651ZM2.13717 14.0607L0.891254 13.3723C0.88354 13.3676 0.879168 13.3631 0.876788 13.36L0.877214 13.3594L2.39129 10.827C2.39618 10.8196 2.40086 10.8153 2.40409 10.8131L2.40493 10.8135L3.66766 11.5112L2.13717 14.0607Z", fill: "#EB5757" }))); };
export default Luggage;
