import React, { useContext, useEffect, useState } from 'react';
import { Link as MUILink, Stack, Typography, } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useTranslation from '../../hooks/useTranslation';
import User from '../User/User';
import LocaleMenu from './LocaleMenu';
import { AuthContext } from 'app/hooks/useAuth';
import logo from './site-logo.svg';
import whiteLogo from './newLogo.svg';
export var ticketMenuItems = [
    {
        label: 'menuButtons.tickets',
        link: '/',
        iconSrc: require('../../assets/icons/Airport.svg'),
    },
    // {
    //   label: 'menuButtons.hotels',
    //   link: 'https://hoteluri.zbor24.ro/?sid=6c2659dc-b71d-4790-86cf-f3a0b543a8f1',
    //   iconSrc: require('../../assets/icons/Hotels.svg'),
    // },
    // {
    //   label: 'menuButtons.transfer',
    //   link: 'https://zbor24.gettransfer.com',
    //   iconSrc: require('../../assets/icons/Transfers.svg'),
    // },
    // {
    //   label: 'menuButtons.charter',
    //   link: '',
    //   iconSrc: require('../../assets/icons/Charter.svg'),
    // },
    {
        label: 'menuButtons.insurance',
        link: 'https://www.asigurari.ro',
        iconSrc: require('../../assets/icons/Insurance.svg'),
    },
];
// const languages: Record<SiteLocale, string> = {
//   ro: 'Romana',
//   en: 'English',
// } as const;
// const LocaleMenu: React.FC<LocaleMenuProps> = ({ paletteMode }) => {
//   const t = useTranslation();
//   const [anchorEl, setAnchorEl] = useState(null);
//   const { locale, updateLocale } = React.useContext(LocaleContext);
//   function handleClick(event: any) {
//     if (anchorEl !== event.currentTarget) {
//       setAnchorEl(event.currentTarget);
//     }
//   }
//   function handleClose() {
//     setAnchorEl(null);
//   }
//   return (
//     <>
//       <Typography
//         color={paletteMode === 'dark' ? 'common.white' : 'text.primary'}
//         display="flex"
//         alignItems="center"
//         onClick={handleClick}
//         onMouseOver={handleClick}
//       >
//         <Language stroke="#1c72c7" viewBox="-5 -5 24 24" />
//         {languages[locale]}
//       </Typography>
//       <Menu
//         PaperProps={{
//           style: {
//             width: 95,
//           },
//         }}
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleClose}
//         MenuListProps={{ onMouseLeave: handleClose }}
//       >
//         {Object.entries(languages).map(([code, text]) => (
//           <MenuItem
//             key={code}
//             onClick={() => {
//               updateLocale(code as SiteLocale);
//               handleClose();
//             }}
//           >
//             <Typography color="text.primary">{text}</Typography>
//           </MenuItem>
//         ))}
//       </Menu>
//     </>
//   );
// };
var DesktopMenu = function (_a) {
    var paletteMode = _a.paletteMode, _b = _a.navigationType, navigationType = _b === void 0 ? 'links' : _b;
    var t = useTranslation();
    var context = useContext(AuthContext);
    var _c = useState(false), isAuthenticated = _c[0], setIsAutheticated = _c[1];
    useEffect(function () {
        if (context.authData.user) {
            setIsAutheticated(true);
        }
        else {
            setIsAutheticated(false);
        }
    }, [context.authData.user]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { spacing: 3, direction: "row", sx: {
                alignItems: 'center',
                py: 2,
            } },
            React.createElement("a", { href: "/" },
                React.createElement("img", { src: paletteMode === 'dark' ? whiteLogo : logo, title: t('home.compare.prices') })),
            navigationType === 'links' && (React.createElement(Stack, { direction: "row", gap: 4 }, ticketMenuItems.map(function (_a, key) {
                var label = _a.label, link = _a.link;
                return (React.createElement(Typography, { color: "text.primary", style: { cursor: 'pointer' }, key: key }, link.length > 0 ? (link === '/' ? (React.createElement(Link, { to: link, style: { textDecoration: 'none', color: '#4F4F4F' } }, t(label))) : (React.createElement("a", { href: link, target: "_blank", rel: "noreferrer", style: { textDecoration: 'none', color: '#4F4F4F' } }, t(label)))) : (t(label))));
            }))),
            React.createElement("div", { style: { flex: 1 } }),
            React.createElement(LocaleMenu, { paletteMode: paletteMode }),
            React.createElement(Typography, { component: MUILink, href: "/ro/contacte", sx: {
                    textDecorationLine: 'none',
                    color: paletteMode === 'dark' ? '#fff' : 'primary.dark',
                } }, t('menuButtons.contacts')),
            React.createElement(User, { paletteMode: paletteMode, isAuthenticated: isAuthenticated }))));
};
export default DesktopMenu;
