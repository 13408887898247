var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { CarouselContainer, CarouselImage, CarouselItem } from './elements';
import Slider from '@ant-design/react-slick';
import { useLocaleId } from 'app/hooks/useTranslation';
var Carousel = function () {
    var locale = useLocaleId();
    var sliderSettings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        fade: true,
        speed: 3000,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
    };
    return (React.createElement(CarouselContainer, null,
        React.createElement(Slider, __assign({}, sliderSettings),
            React.createElement("div", null,
                React.createElement(CarouselItem, { to: "/" + locale + "/contacte" },
                    React.createElement(CarouselImage, { style: {
                            backgroundImage: "url(\"/assets/banners/home-2.jpeg\")",
                        } }))),
            React.createElement("div", null,
                React.createElement(CarouselItem, { to: "/" + locale + "/program-de-bonus" },
                    React.createElement(CarouselImage, { style: {
                            backgroundImage: "url(\"/assets/banners/home-1.jpeg\")",
                        } }))),
            React.createElement("div", null,
                React.createElement(CarouselItem, { to: "/" + locale + "/check-in-online" },
                    React.createElement(CarouselImage, { style: {
                            backgroundImage: "url(\"/assets/banners/home-3.jpeg\")",
                        } }))))));
};
export default Carousel;
