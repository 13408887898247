var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useMemo } from 'react';
import RangePicker from './RangePicker/RangePicker';
import { internalDateFormat, } from '../../../redux/slices/flightSearch.slice';
import { startOfToday, addYears, startOfDay, parseISO } from 'date-fns';
import { useDateFormat } from '../../../hooks/useTranslation';
var Datepicker = function (_a) {
    var value = _a.value, _b = _a.index, index = _b === void 0 ? 0 : _b, otherValues = _a.otherValues, groupedCalendarItems = _a.groupedCalendarItems, onChange = _a.onChange, minDate = _a.minDate, maxDate = _a.maxDate, rest = __rest(_a, ["value", "index", "otherValues", "groupedCalendarItems", "onChange", "minDate", "maxDate"]);
    var format = useDateFormat();
    var flightDateValue = useMemo(function () {
        return Array.isArray(value)
            ? [parseISO(value[0]), parseISO(value[1])]
            : [parseISO(value || ''), parseISO(value || '')];
    }, [value]);
    var handleChange = useCallback(function (value) {
        onChange(value
            ? value[0].valueOf() === value[1].valueOf()
                ? format(value[0], internalDateFormat)
                : [
                    format(value[0], internalDateFormat),
                    format(value[1], internalDateFormat),
                ]
            : null);
    }, []);
    var resolvedMinDate = useMemo(function () { return minDate || startOfToday(); }, [minDate]);
    var resolvedMaxDate = useMemo(function () { return maxDate || startOfDay(addYears(new Date(), 1)); }, [maxDate]);
    return (React.createElement(RangePicker, __assign({}, rest, { minDate: resolvedMinDate, maxDate: resolvedMaxDate, groupedCalendarItems: groupedCalendarItems, value: flightDateValue, otherValues: otherValues, onChange: handleChange, singleDatePick: !Array.isArray(value) })));
};
export default Datepicker;
