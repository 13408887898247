var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { PristineButton } from '../../buttons';
export var MonthTable = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 1px solid rgb(227, 242, 253);\n  & + & {\n    border-left: 1px solid rgb(227, 242, 253);\n  }\n  ", " {\n    padding: 8px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 8px;\n    margin-top: 8px;\n    border: none;\n    & + & {\n      border: none;\n    }\n  }\n"], ["\n  border-bottom: 1px solid rgb(227, 242, 253);\n  & + & {\n    border-left: 1px solid rgb(227, 242, 253);\n  }\n  ", " {\n    padding: 8px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 8px;\n    margin-top: 8px;\n    border: none;\n    & + & {\n      border: none;\n    }\n  }\n"])), function (p) { return p.theme.breakpoints.down('sm'); });
export var MonthAndYear = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 1em;\n  height: 30px;\n  color: ", ";\n  border-bottom: 1px solid rgb(227, 242, 253);\n  font-weight: 500;\n  position: relative;\n  padding: 12px 24px 12px 16px;\n  width: 382px;\n  height: 48px;\n  border-radius: 0px;\n  ", " {\n    padding: 8px 16px;\n    height: 37px;\n    background: #f2f2f2;\n    margin-bottom: 8px;\n    max-width: 30vw;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);\n    border-radius: 87px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 1em;\n  height: 30px;\n  color: ", ";\n  border-bottom: 1px solid rgb(227, 242, 253);\n  font-weight: 500;\n  position: relative;\n  padding: 12px 24px 12px 16px;\n  width: 382px;\n  height: 48px;\n  border-radius: 0px;\n  ", " {\n    padding: 8px 16px;\n    height: 37px;\n    background: #f2f2f2;\n    margin-bottom: 8px;\n    max-width: 30vw;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);\n    border-radius: 87px;\n  }\n"])), function (p) { return p.theme.palette.text.primary; }, function (p) { return p.theme.breakpoints.down('sm'); });
export var MonthNavigationWrapper = styled(PristineButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  color: ", ";\n  align-items: center;\n  justify-content: center;\n  height: 30px;\n  width: 30px;\n  cursor: pointer;\n  position: absolute;\n  ", "\n"], ["\n  display: flex;\n  color: ", ";\n  align-items: center;\n  justify-content: center;\n  height: 30px;\n  width: 30px;\n  cursor: pointer;\n  position: absolute;\n  ", "\n"])), function (p) { return p.theme.palette.primary.main; }, function (p) { return (p.isPrev ? 'left: 12px;' : 'right: 12px;'); });
export var WeekDayNamesContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
export var WeekDayName = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 50px;\n  height: 50px;\n  font-size: 14px;\n  line-height: 150%;\n  text-align: center;\n  color: ", ";\n  ", " {\n    width: 14vw;\n    height: 10vw;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 50px;\n  height: 50px;\n  font-size: 14px;\n  line-height: 150%;\n  text-align: center;\n  color: ", ";\n  ", " {\n    width: 14vw;\n    height: 10vw;\n  }\n"])), function (p) { return p.theme.palette.text.primary; }, function (p) { return p.theme.breakpoints.down('sm'); });
var rangeTypeColorMap = {
    other: {
        border: '#1c72c7',
        background: '#1c72c7',
    },
    departure: {
        border: '#1976D2',
        background: '#1c72c7',
    },
    arrival: { border: '#1976D2', background: '#1976D2' },
};
export var MonthDayRoot = styled(PristineButton)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  width: 50px;\n  height: 50px;\n  margin: 5px 0;\n  margin-right: -1px;\n  cursor: ", ";\n  color: ", ";\n  font-size: 13px;\n  font-weight: 500;\n  font-family: 'Onest';\n  ", "\n  ", ";\n  ", ";\n\n  ", "\n  ", "\n\n", "\n    ", "\n  ", ";\n  ", "\n  ", " {\n    width: 14vw;\n    height: 13vw;\n  }\n"], ["\n  display: flex;\n  position: relative;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  width: 50px;\n  height: 50px;\n  margin: 5px 0;\n  margin-right: -1px;\n  cursor: ", ";\n  color: ", ";\n  font-size: 13px;\n  font-weight: 500;\n  font-family: 'Onest';\n  ", "\n  ", ";\n  ", ";\n\n  ", "\n  ", "\n\n", "\n    ", "\n  ", ";\n  ", "\n  ", " {\n    width: 14vw;\n    height: 13vw;\n  }\n"])), function (props) { return (props.disabled ? 'not-allowed' : 'pointer'); }, function (props) {
    return props.isInMonthBounds && !props.disabled
        ? props.theme.palette.text.primary
        : '#ccc';
}, function (p) {
    return p.selected &&
        p.isInMonthBounds &&
        "\n    background: " + p.theme.palette.primary.main + ";\n    border-radius: 4px;\n    color: #fff;\n    ";
}, function (p) { return p.hidden && "display:none;"; }, function (p) {
    var _a;
    return ((_a = p.rangeProperties) === null || _a === void 0 ? void 0 : _a.type) &&
        "border-top: 1px solid " + rangeTypeColorMap[p.rangeProperties.type].border + "; \n    color: #fff;\n    border-bottom: 1px solid " + rangeTypeColorMap[p.rangeProperties.type].border + ";\n    background-color: " + rangeTypeColorMap[p.rangeProperties.type].background + ";\n    ";
}, function (p) {
    var _a, _b;
    return ((_a = p.rangeProperties) === null || _a === void 0 ? void 0 : _a.type) &&
        ((_b = p.rangeProperties) === null || _b === void 0 ? void 0 : _b.isRangeStart) &&
        "border-left: 1px solid " + rangeTypeColorMap[p.rangeProperties.type].border + ";\n    border-top-left-radius: 4px;\n    border-bottom-left-radius: 4px;\n    &:before {\n      content: \"" + p.rangeProperties.label + "\";\n      position: absolute;\n      top: 0;\n      z-index:2;\n      left: 50%;\n      transform: translate(-50%, -50%);\n      font-size: 9px;\n      padding: 0 1em;\n      color: " + rangeTypeColorMap[p.rangeProperties.type].border + ";\n      background-color: white;\n      border: 1px solid " + rangeTypeColorMap[p.rangeProperties.type].border + ";\n    }\n    ";
}, function (p) {
    var _a, _b;
    return ((_a = p.rangeProperties) === null || _a === void 0 ? void 0 : _a.type) &&
        ((_b = p.rangeProperties) === null || _b === void 0 ? void 0 : _b.isRangeEnd) &&
        "border-right: 1px solid " + rangeTypeColorMap[p.rangeProperties.type].border + ";\n    border-top-right-radius: 4px;\n    border-bottom-right-radius: 4px;\n";
}, function (p) {
    var _a, _b;
    return ((_a = p.rangeProperties) === null || _a === void 0 ? void 0 : _a.type) &&
        ((_b = p.rangeProperties) === null || _b === void 0 ? void 0 : _b.isMidRangeStartOfWeek) &&
        "border-left: 1px dashed " + rangeTypeColorMap[p.rangeProperties.type].border + ";";
}, function (p) {
    var _a, _b;
    return ((_a = p.rangeProperties) === null || _a === void 0 ? void 0 : _a.type) &&
        ((_b = p.rangeProperties) === null || _b === void 0 ? void 0 : _b.isMidRangeEndOfWeek) &&
        "border-right: 1px dashed " + rangeTypeColorMap[p.rangeProperties.type].border + ";";
}, function (p) { return !p.isInMonthBounds && "visibility: hidden;"; }, function (p) {
    return p.highlighted &&
        p.isInMonthBounds &&
        !p.disabled &&
        "\n    background: #1976D2;\n    color: #fff;\n    ";
}, function (p) { return p.theme.breakpoints.down('sm'); });
export var DirectFlight = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 0px 3px;\n  position: absolute;\n  width: 40px;\n  height: 14px;\n  left: 5px;\n  top: 43px;\n  color: ", ";\n  background: ", ";\n  /* border: 1px solid #1976D2; */\n  box-sizing: border-box;\n  border-radius: 2px;\n  font-size: 10px;\n  z-index: 1;\n  ", " {\n    left: 9px;\n  }\n"], ["\n  padding: 0px 3px;\n  position: absolute;\n  width: 40px;\n  height: 14px;\n  left: 5px;\n  top: 43px;\n  color: ", ";\n  background: ", ";\n  /* border: 1px solid #1976D2; */\n  box-sizing: border-box;\n  border-radius: 2px;\n  font-size: 10px;\n  z-index: 1;\n  ", " {\n    left: 9px;\n  }\n"])), function (p) { return p.theme.palette.primary.main; }, function (p) { return p.theme.palette.primary.light; }, function (p) { return p.theme.breakpoints.down('sm'); });
export var Price = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  position: absolute;\n  top: 31px;\n  font-size: 10px;\n  white-space: nowrap;\n"], ["\n  color: ", ";\n  position: absolute;\n  top: 31px;\n  font-size: 10px;\n  white-space: nowrap;\n"])), function (p) { return p.theme.palette.text.secondary; });
export var WeekRow = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
