import React from 'react';
import { ButtonSC, DialogContentWrapper, } from 'app/components/CookieBanner/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Switch, } from '@mui/material';
import { styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';
import { ArrowRight } from '@material-ui/icons';
import useTranslation from 'app/hooks/useTranslation';
var style = {
    width: 574,
    zIndex: 101,
    boxShadow: 24,
    maxWidth: 600,
    p: 4,
};
var items = [
    {
        dataKey: 'infrastructure',
        title: 'cookie.infrastructure.title',
        content: 'cookie.infrastructure.text',
        disabled: true,
    },
    {
        dataKey: 'base',
        title: 'cookie.base.title',
        content: 'cookie.base.text',
        disabled: true,
    },
    {
        dataKey: 'security',
        title: 'cookie.security.title',
        content: 'cookie.security.text',
        disabled: true,
    },
    {
        dataKey: 'market',
        title: 'cookie.market.title',
        content: 'cookie.market.text',
    },
    {
        dataKey: 'stats',
        title: 'cookie.stats.title',
        content: 'cookie.stats.text',
    },
];
var StyledDialog = styled(Dialog)(function () { return ({
    "& .MuiPaper-root[role='dialog']": {
        height: 'calc(100% - 64px)',
    },
    "&.mobile-cookie-dialog .MuiPaper-root[role='dialog']": {
        margin: '0 !important',
        height: '100% !important',
        maxHeight: '100% !important',
        width: '100% !important',
        maxWidth: '100% !important',
    },
    "&.mobile-cookie-dialog .MuiPaper-root[role='dialog'] .dialog-confirm-paper": {
        width: 'unset !important',
    },
}); });
export var CookieDialog = function (_a) {
    var isOpen = _a.isOpen, onSave = _a.onSave, onClose = _a.onClose, openState = _a.openState, check = _a.check, toggle = _a.toggle, className = _a.className;
    var t = useTranslation();
    return (React.createElement(StyledDialog, { className: className, open: isOpen, onClose: onClose },
        React.createElement(DialogContentWrapper, null,
            React.createElement(Paper, { classes: { rounded: 'true' }, className: "dialog-confirm-paper", sx: style },
                React.createElement(DialogTitle, { id: "scroll-dialog-title" },
                    t('cookie.title'),
                    React.createElement(IconButton, { "aria-label": "close", onClick: onClose, sx: {
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#444',
                        } },
                        React.createElement(CloseIcon, null))),
                React.createElement(DialogContent, null,
                    React.createElement("div", { className: "text-content" }, t('cookie.text2')),
                    React.createElement("ul", null, items.map(function (_a) {
                        var dataKey = _a.dataKey, title = _a.title, content = _a.content, disabled = _a.disabled;
                        return (React.createElement(LiItem, { key: dataKey, dataKey: dataKey, title: t(title), content: t(content), disabled: disabled, opened: openState[dataKey].opened, checked: openState[dataKey].checked, onCheck: check, onClick: toggle }));
                    }))),
                React.createElement(DialogActions, null,
                    React.createElement(ButtonSC, { onClick: function () { return onSave(); } }, t('cookie.save')))))));
};
var LiItem = function (_a) {
    var dataKey = _a.dataKey, title = _a.title, content = _a.content, onCheck = _a.onCheck, onClick = _a.onClick, opened = _a.opened, checked = _a.checked, disabled = _a.disabled;
    var handleClick = function () {
        onClick(dataKey);
    };
    var handleChange = function (e) {
        e.stopPropagation();
        onCheck(dataKey);
    };
    return (React.createElement("li", null,
        React.createElement("div", { className: "wrapper", onClick: handleClick, "data-key": dataKey },
            React.createElement("div", { className: "left" },
                React.createElement("div", { style: { display: 'flex' }, className: classnames({
                        opened: opened,
                    }) },
                    title,
                    React.createElement("div", { className: "ico" },
                        React.createElement(ArrowRight, null))),
                React.createElement("div", { className: "content" },
                    React.createElement("p", null, content))),
            React.createElement(Switch, { disabled: disabled, onChange: handleChange, checked: checked }))));
};
