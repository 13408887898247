import React from 'react';
import { Box, Container } from '@material-ui/core';
import Layout from 'app/components/Layout';
import useTranslation from 'app/hooks/useTranslation';
function ForbiddenBaggage() {
    var t = useTranslation();
    return (React.createElement(Layout, null,
        React.createElement(Container, null,
            React.createElement(Box, { sx: {
                    mt: 2,
                    px: 2,
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                } },
                React.createElement("div", { dangerouslySetInnerHTML: {
                        __html: t('forbiddenBaggagePage'),
                    } })))));
}
export default ForbiddenBaggage;
