var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var BaggageInfoIcon = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { xmlns: "http://www.w3.org/2000/svg", d: "M12.2144 16.1071V5.24997C12.2144 4.89003 12.0714 4.54483 11.8169 4.29032C11.5623 4.03581 11.2171 3.89282 10.8572 3.89282H8.14293C7.78299 3.89282 7.4378 4.03581 7.18328 4.29032C6.92877 4.54483 6.78578 4.89003 6.78578 5.24997V16.1071M4.0715 6.60711H14.9286C15.6782 6.60711 16.2858 7.21472 16.2858 7.96425V14.75C16.2858 15.4995 15.6782 16.1071 14.9286 16.1071H4.0715C3.32197 16.1071 2.71436 15.4995 2.71436 14.75V7.96425C2.71436 7.21472 3.32197 6.60711 4.0715 6.60711Z", strokeLinecap: "round", strokeLinejoin: "round", fill: "#fff" }))); };
export default BaggageInfoIcon;
