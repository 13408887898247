import { configureStore, } from '@reduxjs/toolkit';
import rootReducer from './reducers';
// const persistConfig = {
//   key: 'root',
//   storage,
// };
// const persistedReducer = persistReducer(persistConfig, rootReducer);
export var createStore = function (preloadedState) {
    var store = configureStore({
        reducer: rootReducer,
        // preloadedState,
        // middleware: getDefaultMiddleware({
        //   serializableCheck: false,
        // }),
    });
    // let persistor = persistStore(store);
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        // module.hot.accept('./reducers', () => {
        //   const nextRootReducer = require('./reducers').default;
        //   store.replaceReducer(nextRootReducer);
        // });
    }
    return { store: store };
};
