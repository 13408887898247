import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
var IniterarySkeleton = function () {
    return (React.createElement(Stack, { spacing: "4px", width: 1 / 6, sx: {
            backgroundColor: 'white',
            alignItems: 'center',
            padding: '8px',
            boxShadow: '0px 2px 10px rgba(25, 1, 52, 0.12)',
            borderRadius: '4px',
        } },
        React.createElement(Skeleton, { variant: "text", width: '80%', height: 12, sx: { borderRadius: 0 } }),
        React.createElement(Skeleton, { variant: "text", width: '80%', height: 12, sx: { borderRadius: 0 } }),
        React.createElement(Skeleton, { variant: "text", width: '60%', height: 17, sx: { borderRadius: 0 } })));
};
export default function RecommendedItinerariesSkeleton() {
    return (React.createElement(Stack, { direction: 'row', spacing: 1.25 }, new Array(6).fill(0).map(function (el) {
        return React.createElement(IniterarySkeleton, null);
    })));
}
