var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
export var _PristineButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: transparent;\n  box-shadow: 0px 0px 0px transparent;\n  border: 0px solid transparent;\n  text-shadow: 0px 0px 0px transparent;\n  outline: none;\n  cursor: pointer;\n"], ["\n  background: transparent;\n  box-shadow: 0px 0px 0px transparent;\n  border: 0px solid transparent;\n  text-shadow: 0px 0px 0px transparent;\n  outline: none;\n  cursor: pointer;\n"])));
export var PristineButton = function (props) { return React.createElement(_PristineButton, __assign({}, props, { type: props.type || 'button' })); };
export var PrimaryButton = styled(PristineButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: #fff;\n  background: ", ";\n  border: 1px solid #2e6da4;\n  height: 34px;\n  padding: 0 12px;\n  font-weight: 400;\n  &:hover {\n    box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.1);\n  }\n  &:active {\n    background-color: #204d74;\n    border-color: #122b40;\n  }\n"], ["\n  color: #fff;\n  background: ", ";\n  border: 1px solid #2e6da4;\n  height: 34px;\n  padding: 0 12px;\n  font-weight: 400;\n  &:hover {\n    box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.1);\n  }\n  &:active {\n    background-color: #204d74;\n    border-color: #122b40;\n  }\n"])), function (p) { return p.theme.palette.primary.main; });
var templateObject_1, templateObject_2;
