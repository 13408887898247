var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import get from 'lodash.get';
import React from 'react';
import { useUserLazyQuery, } from '../generated/graphql';
import { configJwtTokenKey } from 'app/util/siteConfig';
export var JWT_TOKEN_KEY = configJwtTokenKey;
var defaultState = { token: '', user: null };
export var AuthContext = React.createContext({
    authData: defaultState,
    token: '',
    login: function (newAuthData) { },
    logout: function () { },
    setUser: function (newUser) { },
    loading: true,
});
export var AuthProvider = function (_a) {
    var children = _a.children;
    var _b = React.useState(defaultState), authData = _b[0], setAuthData = _b[1];
    var _c = React.useState(defaultState.token), token = _c[0], setToken = _c[1];
    var _d = React.useState(true), loading = _d[0], setIsLoading = _d[1];
    var _e = useUserLazyQuery({
        onCompleted: function (_a) {
            var user = _a.user;
            setIsLoading(false);
        },
    }), getUser = _e[0], getUserResult = _e[1];
    var userByToken = get(getUserResult.data, 'user');
    React.useEffect(function () {
        var savedToken = localStorage.getItem(JWT_TOKEN_KEY) || defaultState.token;
        setToken(savedToken);
    }, []);
    var login = React.useCallback(function (newData) {
        localStorage.setItem(JWT_TOKEN_KEY, newData.token);
        setToken(newData.token);
        setAuthData(newData);
    }, []);
    React.useEffect(function () {
        if (!authData.user && token) {
            getUser();
            if ((userByToken === null || userByToken === void 0 ? void 0 : userByToken.__typename) === 'User') {
                login({ token: token, user: userByToken });
            }
        }
    }, [authData.user, token, userByToken]);
    React.useEffect(function () {
        !token && setIsLoading(false);
    }, [token]);
    var logout = React.useCallback(function () {
        localStorage.setItem(JWT_TOKEN_KEY, defaultState.token);
        setAuthData(defaultState);
        setToken(defaultState.token);
    }, []);
    var setUser = React.useCallback(function (newUser) {
        setAuthData(__assign(__assign({}, authData), { user: newUser }));
    }, []);
    var value = React.useMemo(function () {
        return { authData: authData, token: token, login: login, logout: logout, setUser: setUser, loading: loading };
    }, [authData, token, login, logout, setUser, loading]);
    return React.createElement(AuthContext.Provider, { value: value }, children);
};
