var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var Check = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("svg", { width: "14", height: "11", viewBox: "0 0 14 11", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M13.2858 1.28613L4.64293 9.92899L0.714355 6.00042", stroke: "#27AE60", strokeLinecap: "round", strokeLinejoin: "round" })))); };
export default Check;
