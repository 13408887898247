export const runtimeConfig =
  typeof window !== 'undefined'
    ? {
        // client
        apiUrl: window._APP_.config.apiUrl,
      }
    : {
        // server
        apiUrl: process.env.API_ENDPOINT,
      };
