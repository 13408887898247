var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Home from 'app/pages/Home';
import SearchResults from 'app/pages/Results';
import SearchResultsMultiple from 'app/pages/ResultsMultiple';
import Booking from 'app/pages/Booking';
import Order from 'app/pages/Order';
import Profile from 'app/pages/Profile';
import PasswordRecovery from 'app/pages/PasswordRecovery';
import ForbiddenBaggage from 'app/pages/ForbiddenBaggage';
import NotFound from 'app/pages/NotFound';
import { asyncComponent } from '@jaredpalmer/after';
import Page from 'app/pages/Page';
var routes = [
    {
        path: '/:locale?',
        exact: true,
        chunkName: 'home',
        component: Home,
    },
    {
        path: '/:locale?/despre-noi',
        exact: true,
        chunkName: 'about',
        component: asyncComponent({
            loader: function () { return import('app/pages/About'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'about',
        }),
    },
    {
        path: '/:locale/search/:departureValues/:returnValues/:departureDate/:returnDate/',
        chunkName: 'search',
        component: SearchResults,
    },
    {
        path: '/:locale/multiple/:flight1/:flight2/:flight3/',
        chunkName: 'multipleSearch',
        component: SearchResultsMultiple,
    },
    {
        path: '/:locale/booking/:id',
        chunkName: 'booking',
        component: Booking,
    },
    {
        path: '/:locale?/account/',
        chunkName: 'account',
        component: Profile,
    },
    {
        path: '/recovery/:token',
        chunkName: 'passwordRecovery',
        component: PasswordRecovery,
    },
    {
        path: '/:locale?/order/:code/:name',
        chunkName: 'order',
        component: Order,
    },
    {
        path: '/:locale/rezervare/:code/:name',
        chunkName: 'rezervare',
        component: Order,
    },
    {
        path: '/:locale?/forbiddenBaggage',
        chunkName: 'forbiddenBaggage',
        component: ForbiddenBaggage,
    },
    {
        path: '/:locale?/group-offer',
        exact: true,
        chunkName: 'group-offer',
        component: asyncComponent({
            loader: function () { return import('app/pages/GroupOffer'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'group-offer',
        }),
    },
    {
        path: '/:locale?/check-in',
        exact: true,
        chunkName: 'check-in',
        component: asyncComponent({
            loader: function () { return import('app/pages/CheckIn'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'check-in',
        }),
    },
    {
        path: '/:locale?/check-in-online',
        exact: true,
        chunkName: 'check-in-online',
        component: asyncComponent({
            loader: function () { return import('app/pages/CheckInOnline'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'check-in-online',
        }),
    },
    {
        path: '/:locale?/blog/post/:id',
        exact: true,
        chunkName: 'blogInnerPage',
        component: asyncComponent({
            loader: function () { return import('app/pages/BlogInnerPage'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'blogInnerPage',
        }),
    },
    {
        path: '/:locale?/blog',
        exact: true,
        chunkName: 'blog',
        component: asyncComponent({
            loader: function () { return import('app/pages/Blog'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'blog',
        }),
    },
    {
        path: '/:locale?/contacte',
        exact: true,
        chunkName: 'contacts',
        component: asyncComponent({
            loader: function () { return import('app/pages/Contacts'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'contacts',
        }),
    },
    {
        path: '/:locale?/program-de-bonus',
        exact: true,
        chunkName: 'bonus-program',
        component: asyncComponent({
            loader: function () { return import('app/pages/BonusProgram'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'bonus-program',
        }),
    },
    {
        path: '/:locale?/intrebari-frecvente',
        exact: true,
        chunkName: 'faq',
        component: asyncComponent({
            loader: function () { return import('app/pages/FAQ'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'faq',
        }),
    },
    {
        path: '/:locale?/iata-terms-and-conditions',
        exact: true,
        chunkName: 'iata-terms-and-conditions',
        component: function (props) { return React.createElement(Page, __assign({ slug: "iata" }, props)); },
    },
    {
        path: '/:locale?/special-needs-rules',
        exact: true,
        chunkName: 'special-needs-rules',
        component: function (props) { return React.createElement(Page, __assign({ slug: "special-needs" }, props)); },
    },
    {
        path: '/:locale?/politica-de-confidentialitate',
        exact: true,
        chunkName: 'privacy',
        component: function (props) { return React.createElement(Page, __assign({ slug: "privacy-policy" }, props)); },
    },
    {
        path: '/:locale?/de-ce-cookie',
        exact: true,
        chunkName: 'cookie-policy',
        component: function (props) { return React.createElement(Page, __assign({ slug: "cookie-policy" }, props)); },
    },
    {
        path: '/:locale?/terms',
        exact: true,
        chunkName: 'terms',
        component: function (props) { return React.createElement(Page, __assign({ slug: "terms-and-conditions" }, props)); },
    },
    {
        path: '/:locale?/cariera',
        exact: true,
        chunkName: 'career',
        component: function (props) { return React.createElement(Page, __assign({ slug: "career" }, props)); },
    },
    {
        path: '/:locale?/conexiuni-inteligente',
        exact: true,
        chunkName: 'smart-connections',
        component: asyncComponent({
            loader: function () { return import('app/pages/SmartConnections'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'smart-connections',
        }),
    },
    {
        path: '/:locale?/guarantee',
        exact: true,
        chunkName: 'guarantee',
        component: asyncComponent({
            loader: function () { return import('app/pages/Guarantee'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'guarantee',
        }),
    },
    {
        path: '/:locale?/termeni-si-conditii',
        exact: true,
        chunkName: 'terms-and-conditions',
        component: function (props) { return React.createElement(Page, __assign({ slug: "terms-and-conditions" }, props)); },
    },
    {
        path: '/:locale?/wiki/aeronave',
        exact: true,
        chunkName: 'airplanes',
        component: asyncComponent({
            loader: function () { return import('app/pages/Airplanes'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'airplanes',
        }),
    },
    {
        path: '/:locale?/wiki/aeronava/:id',
        exact: true,
        chunkName: 'airplane',
        component: asyncComponent({
            loader: function () { return import('app/pages/Airplane'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
        }),
    },
    {
        path: '/:locale?/wiki/aliante',
        exact: true,
        chunkName: 'aliante',
        component: asyncComponent({
            loader: function () { return import('app/pages/Alliances'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'aliante',
        }),
    },
    {
        path: '/:locale?/wiki/alianta/:id',
        exact: true,
        chunkName: 'alianta',
        component: asyncComponent({
            loader: function () { return import('app/pages/Alliance'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'alianta',
        }),
    },
    {
        path: '/:locale?/companii-aeriene',
        exact: true,
        chunkName: 'airlines',
        component: asyncComponent({
            loader: function () { return import('app/pages/Airlines'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'airlines',
        }),
    },
    {
        path: '/:locale?/companii-aeriene/:id',
        exact: true,
        chunkName: 'airline',
        component: asyncComponent({
            loader: function () { return import('app/pages/Airline'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'airline',
        }),
    },
    {
        path: '/:locale?/bilete-avion',
        exact: true,
        chunkName: 'cities',
        component: asyncComponent({
            loader: function () { return import('app/pages/Cities'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'cities',
        }),
    },
    {
        path: '/:locale?/bilete-avion/:id',
        exact: true,
        chunkName: 'city',
        component: asyncComponent({
            loader: function () { return import('app/pages/City'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'city',
        }),
    },
    {
        path: '/:locale?/bilete-avion/:idfrom/:idto',
        exact: true,
        chunkName: 'city',
        component: asyncComponent({
            loader: function () { return import('app/pages/FromToCity'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'city',
        }),
    },
    {
        path: '/:locale?/bilete-avion-catre-tari',
        exact: true,
        chunkName: 'countries',
        component: asyncComponent({
            loader: function () { return import('app/pages/Countries'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'countries',
        }),
    },
    {
        path: '/:locale?/bilete-avion-catre-tari/:id',
        exact: true,
        chunkName: 'country',
        component: asyncComponent({
            loader: function () { return import('app/pages/Country'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'country',
        }),
    },
    {
        path: '/:locale?/aeroporturi',
        exact: true,
        chunkName: 'airports',
        component: asyncComponent({
            loader: function () { return import('app/pages/Airports'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'airports',
        }),
    },
    {
        path: '/:locale?/aeroporturi/:id',
        exact: true,
        chunkName: 'airport',
        component: asyncComponent({
            loader: function () { return import('app/pages/Airport'); },
            Placeholder: function () { return React.createElement("div", null, "...LOADING..."); },
            chunkName: 'airport',
        }),
    },
    {
        path: '/:locale?/criterii-de-evaluare-a-sigurantei-pentru-companii-aeriene',
        exact: true,
        chunkName: 'criterii-de-evaluare-a-sigurantei-pentru-companii-aeriene',
        component: function (props) { return (React.createElement(Page, __assign({ slug: "criterii-de-evaluare-a-sigurantei-pentru-companii-aeriene" }, props))); },
    },
    {
        path: '/:locale?/criterii-de-evaluare-a-serviciilor-complete-oferite-de-transportator',
        exact: true,
        chunkName: 'criterii-de-evaluare-a-serviciilor-complete-oferite-de-transportator',
        component: function (props) { return (React.createElement(Page, __assign({ slug: "criterii-de-evaluare-a-serviciilor-complete-oferite-de-transportator" }, props))); },
    },
    { component: NotFound },
];
export default routes;
