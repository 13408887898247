var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import SvgIcon from '@mui/material/SvgIcon';
var Cancel = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("path", { fill: "none", d: "M10.1426 5.85718L5.85693 10.1429M5.85693 5.85718L10.1426 10.1429M15.1426 8.00003C15.1426 11.9449 11.9447 15.1429 7.99979 15.1429C4.0549 15.1429 0.856934 11.9449 0.856934 8.00003C0.856934 4.05514 4.0549 0.857178 7.99979 0.857178C11.9447 0.857178 15.1426 4.05514 15.1426 8.00003Z", strokeLinecap: "round", strokeLinejoin: "round" }))); };
export default Cancel;
