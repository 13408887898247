var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSearchHistoryItem } from '../../../redux/slices/searchHistory.slice';
import { flightSearchStateSelector, setFlightError, } from '../../../redux/slices/flightSearch.slice';
import useTranslation from '../../../hooks/useTranslation';
import { Button, Typography, useMediaQuery } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import Search from '../../../assets/icons/Search';
import { DelimitedArrayParam, DelimitedNumericArrayParam, StringParam, useQueryParams, withDefault, } from 'use-query-params';
import { FlightType, ItinerariesSortByInput } from '../../../generated/graphql';
var SearchButton = function (_a) {
    var searchUrl = _a.searchUrl, searchIdRef = _a.searchIdRef, setCompact = _a.setCompact;
    var dispatch = useDispatch();
    var t = useTranslation();
    var flightSearchState = useSelector(flightSearchStateSelector);
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _b = useQueryParams({
        passengers: withDefault(DelimitedNumericArrayParam, [1]),
        airlines: withDefault(DelimitedArrayParam, []),
        airports: withDefault(DelimitedArrayParam, []),
        price: withDefault(DelimitedArrayParam, ['0', '100000']),
        flightType: withDefault(DelimitedArrayParam, [
            FlightType.Lowcost,
            FlightType.Regular,
        ]),
        sortBy: withDefault(StringParam, ItinerariesSortByInput.Recommendation),
    }), queryParams = _b[0], setQueryParams = _b[1];
    var history = useHistory();
    var checkFlightSearchState = function (e) {
        flightSearchState.flights.forEach(function (flight, index) {
            if ((flight === null || flight === void 0 ? void 0 : flight.departureAirportOptions.length) === 0) {
                e.preventDefault();
                dispatch(setFlightError({
                    error: {
                        field: 'departure',
                        text: t('formErrors.provideDeparture'),
                    },
                    flightIndex: index,
                }));
            }
            if ((flight === null || flight === void 0 ? void 0 : flight.arrivalAirportOptions.length) === 0) {
                e.preventDefault();
                dispatch(setFlightError({
                    error: {
                        field: 'arrival',
                        text: t('formErrors.provideReturn'),
                    },
                    flightIndex: index,
                }));
            }
        });
        if (flightSearchState.flights
            .map(function (f) { var _a; return (_a = f === null || f === void 0 ? void 0 : f.errors) === null || _a === void 0 ? void 0 : _a.map(function (err) { return err.text; }); })
            .filter(Boolean)
            .flat().length === 0) {
            // history.push(searchUrl);
        }
    };
    var handleClick = function (e) {
        dispatch(addSearchHistoryItem(flightSearchState));
        checkFlightSearchState(e);
        setCompact && setCompact();
        setQueryParams(__assign(__assign({}, queryParams), { airlines: undefined, price: undefined, flightType: undefined, sortBy: undefined, airports: undefined }));
        if (searchIdRef) {
            searchIdRef.current = null;
        }
    };
    return (React.createElement(Button, { component: Link, to: searchUrl, onClick: handleClick, variant: "contained", sx: {
            color: '#fff',
            backgroundColor: '#E62F2F',
            height: '3rem',
            padding: '6px 18px',
            borderRadius: { lg: '50%' },
            alignSelf: { lg: 'flex-end', sx: 'center' },
            minWidth: '3rem',
            '&:hover': {
                transform: { lg: 'scale(1.1)', xs: 'none' },
                background: '#E62F2F',
                boxShadow: 'none',
            },
            width: { lg: '3rem', xs: '100%' },
            marginTop: { xs: '1rem' },
            transition: 'all 0.2s ease-in-out',
            boxShadow: 'none',
        } },
        React.createElement(Search, { viewBox: "0 -2 24 24", style: __assign({}, (isMobile
                ? {
                    marginLeft: '-1.2em',
                }
                : { position: 'relative', top: '1px', left: '3px' })) }),
        React.createElement(Typography, { sx: { ml: { xs: 1, lg: 0 }, textTransform: 'uppercase' }, fontSize: "1.125rem" }, isMobile && t('searchButton.title'))));
};
export default SearchButton;
