var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
var Briefcase = function (props) { return (React.createElement(SvgIcon, __assign({}, props),
    React.createElement("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M14.1668 5.49967V5.99967H14.6668H18.3335C19.0749 5.99967 19.6668 6.59165 19.6668 7.33301V17.4163C19.6668 18.1577 19.0749 18.7497 18.3335 18.7497H3.66683C2.92554 18.7497 2.33361 18.1578 2.3335 17.4165C2.3335 17.4165 2.3335 17.4164 2.3335 17.4163L2.34266 7.33346V7.33301C2.34266 6.58837 2.92874 5.99967 3.66683 5.99967H7.3335H7.8335V5.49967V3.66634C7.8335 2.92498 8.42547 2.33301 9.16683 2.33301H12.8335C13.5749 2.33301 14.1668 2.92498 14.1668 3.66634V5.49967Z", stroke: "#002171" }),
        React.createElement("path", { d: "M14.6665 5.95801H7.7915", stroke: "#002171" }),
        React.createElement("path", { d: "M5.5 5.95801V18.7913", stroke: "#002171" }),
        React.createElement("path", { d: "M16.5 5.95801V18.7913", stroke: "#002171" })))); };
export default Briefcase;
