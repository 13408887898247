var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Avatar, Stack, Typography } from '@material-ui/core';
import Cancel from 'app/assets/icons/Cancel';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useTranslation from '../../hooks/useTranslation';
var SavedPassengers = function (_a) {
    var savedPassengers = _a.savedPassengers, passenger = _a.passenger, index = _a.index;
    var t = useTranslation();
    var setValue = useFormContext().setValue;
    var _b = useState(null), selectedIndex = _b[0], setSelectedIndex = _b[1];
    var handleSavedPassengerClick = function (savedPassenger, passIndex) {
        var pass = __assign(__assign({ id: passenger.id.value, type: passenger.passengerType.value, surname: savedPassenger.node.surname, name: savedPassenger.node.name, gender: savedPassenger.node.gender.toLowerCase() }, (passenger.dateOfBirth && {
            dateOfBirth: savedPassenger.node.dateOfBirth
                .split('-')
                .reverse()
                .join('.'),
        })), (passenger.travelDocument && {
            travelDocument: {
                nationality: savedPassenger.node.nationality.code,
                countryOfIssue: savedPassenger.node.nationality.code,
                documentNumber: savedPassenger.node.document.number,
                documentExpire: savedPassenger.node.document.expireDate
                    .split('-')
                    .reverse()
                    .join('.'),
            },
        }));
        setValue("form.passengers." + index, pass);
        setSelectedIndex(passIndex);
    };
    return (React.createElement(Stack, { sx: { mb: 2 } },
        React.createElement(Typography, { py: 1 }, t('form.savedPassengersTitle')),
        React.createElement(Stack, { direction: "row", sx: { overflow: 'auto' } }, savedPassengers.map(function (pass, passIndex) { return (React.createElement(Stack, { onClick: function () { return handleSavedPassengerClick(pass, passIndex); }, direction: "row", alignItems: "center", sx: {
                borderRadius: '36px',
                flexShrink: 0,
                py: 1,
                pl: 0.5,
                pr: 1,
                mr: 1,
                border: '1px solid',
                borderColor: 'text.disabled',
                cursor: 'pointer',
                backgroundColor: passIndex === selectedIndex ? 'primary.main' : 'common.white',
                color: passIndex === selectedIndex ? 'common.white' : 'text.primary',
            } },
            React.createElement(Avatar, { sx: {
                    height: 25,
                    width: 25,
                    mr: 1,
                    fontSize: '12px',
                    backgroundColor: passIndex === selectedIndex ? 'common.white' : 'primary.main',
                    color: passIndex === selectedIndex ? 'text.primary' : 'common.white',
                } }, pass.node.surname.charAt(0) + pass.node.name.charAt(0)),
            pass.node.surname,
            " ",
            pass.node.name,
            passIndex === selectedIndex && (React.createElement(Cancel, { onClick: function (e) {
                    e.stopPropagation();
                    setSelectedIndex(null);
                }, viewBox: "-1 -2 20 20", sx: {
                    ml: 0.5,
                    stroke: 'white',
                    cursor: 'pointer',
                } })))); }))));
};
export default SavedPassengers;
