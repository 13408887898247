var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Grid, Stack, Typography, Box, useMediaQuery, } from '@material-ui/core';
import React from 'react';
import useTranslation from 'app/hooks/useTranslation';
import { ItinerariesSortByInput, } from '../../generated/graphql';
import get from 'lodash.get';
import getCurrencySymbol from 'app/util/getCurrencySymbol';
export var Sorter = function (_a) {
    var sortBy = _a.sortBy, onChange = _a.onChange, values = _a.values, sorterPrices = _a.sorterPrices, isLoading = _a.isLoading;
    var t = useTranslation();
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var cheaper = get(sorterPrices, 'cheaper');
    var fast = get(sorterPrices, 'fast');
    var recommendation = get(sorterPrices, 'recommendation');
    var sortPricesMap = {
        cheaper: cheaper,
        fast: fast,
        recommendation: recommendation,
    };
    return (React.createElement(Grid, { container: true, border: '1px solid rgb(227, 242, 253)', width: isMobile ? 'calc(100% - 32px)' : '100%', mx: isMobile ? 2 : 0 }, Object.values(ItinerariesSortByInput).map(function (sort, index) {
        var _a, _b, _c;
        var activeItem = sort === sortBy;
        return (React.createElement(Grid, { item: true, md: 4, xs: 4, onClick: activeItem
                ? function () { }
                : function (e) { return onChange(__assign(__assign({}, values), { sortBy: sort })); }, borderBottom: activeItem ? '3px solid #1976D2' : '3px solid transparent', sx: {
                cursor: 'pointer',
                background: '#fff',
            }, key: index },
            React.createElement(Stack, { direction: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'center', color: activeItem ? 'primary.main' : 'primary.dark', gap: 0.5, py: 1, borderLeft: '1px solid rgb(227, 242, 253)', lineHeight: '16px', fontSize: '14px', sx: {
                    backgroundColor: {
                        xs: activeItem ? 'primary.light' : 'transparent',
                        md: 'transparent',
                    },
                    transition: '0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                } },
                React.createElement(Box, { sx: {
                        color: {
                            xs: !activeItem ? 'text.secondary' : 'primary.main',
                            md: activeItem ? 'primary.main' : 'primary.dark',
                        },
                    } }, t("sorter." + sort.toLowerCase())),
                !isLoading && (React.createElement(Typography, { lineHeight: '16px', fontSize: '14px' },
                    sorterPrices && !isMobile && t('filters.from') + " ",
                    sorterPrices &&
                        "" + (sorterPrices &&
                            getCurrencySymbol((_b = (_a = sortPricesMap[sort.toLowerCase()]) === null || _a === void 0 ? void 0 : _a.currency) === null || _b === void 0 ? void 0 : _b.code)) + ((_c = sortPricesMap[sort.toLowerCase()]) === null || _c === void 0 ? void 0 : _c.amount) + " \n                ")))));
    })));
};
