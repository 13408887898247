var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import NotFoundComponent from 'app/components/NotFoundComponent';
import Layout from 'app/components/Layout';
var NotFoundParentComponent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  /* padding-top: 50px; */\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  /* ", " {\n    padding-top: 100px;\n  } */\n\n  .row {\n    display: flex;\n  }\n\n  .panel .btn-square > svg {\n    width: 45px;\n    height: 45px;\n  }\n"], ["\n  /* padding-top: 50px; */\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  /* ", " {\n    padding-top: 100px;\n  } */\n\n  .row {\n    display: flex;\n  }\n\n  .panel .btn-square > svg {\n    width: 45px;\n    height: 45px;\n  }\n"])), function (p) { return p.theme.breakpoints.down('md'); });
function NotFound() {
    return (React.createElement(Layout, null,
        React.createElement(NotFoundParentComponent, null,
            React.createElement(Box, { sx: {
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    background: 'linear-gradient(180deg, rgba(230, 238, 255, 0.1) 0%, rgba(0, 82, 255, 0.008) 100%), #FFFFFF',
                } },
                React.createElement(NotFoundComponent, null)))));
}
export default NotFound;
var templateObject_1;
