var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
/**
 *
 * LoginSocialFacebook
 *
 */
import React, { forwardRef, memo, useCallback, useImperativeHandle, useLayoutEffect, useState, } from 'react';
var SDK_URL = 'https://connect.facebook.net/en_EN/sdk.js';
var SCRIPT_ID = 'facebook-jssdk';
var isServer = typeof window === 'undefined';
var _window = !isServer && window;
// eslint-disable-next-line
var LoginSocialFacebook = forwardRef(function (_a, ref) {
    var appId = _a.appId, _b = _a.scope, scope = _b === void 0 ? 'email,public_profile' : _b, _c = _a.state, state = _c === void 0 ? true : _c, _d = _a.xfbml, xfbml = _d === void 0 ? true : _d, _e = _a.cookie, cookie = _e === void 0 ? true : _e, _f = _a.version, version = _f === void 0 ? 'v2.7' : _f, _g = _a.language, language = _g === void 0 ? 'en_EN' : _g, _h = _a.auth_type, auth_type = _h === void 0 ? '' : _h, className = _a.className, onLoginStart = _a.onLoginStart, onLogoutSuccess = _a.onLogoutSuccess, onReject = _a.onReject, onResolve = _a.onResolve, redirect_uri = _a.redirect_uri, _j = _a.fieldsProfile, fieldsProfile = _j === void 0 ? 'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender' : _j, _k = _a.response_type, response_type = _k === void 0 ? 'code' : _k, _l = _a.return_scopes, return_scopes = _l === void 0 ? true : _l, children = _a.children;
    var _m = useState(false), isLogged = _m[0], setIsLogged = _m[1];
    var _o = useState(false), isSdkLoaded = _o[0], setIsSdkLoaded = _o[1];
    var _p = useState(false), isProcessing = _p[0], setIsProcessing = _p[1];
    useLayoutEffect(function () {
        !isServer && !isSdkLoaded && load();
        // eslint-disable-next-line
    }, [isSdkLoaded]);
    var insertSDKScript = useCallback(function (document, cb) {
        var fbScriptTag = document.createElement('script');
        fbScriptTag.id = SCRIPT_ID;
        fbScriptTag.src = SDK_URL;
        var scriptNode = document.getElementsByTagName('script')[0];
        scriptNode &&
            scriptNode.parentNode &&
            scriptNode.parentNode.insertBefore(fbScriptTag, scriptNode);
        cb();
    }, []);
    var checkIsExistsSDKScript = useCallback(function () {
        return !!document.getElementById(SCRIPT_ID);
    }, []);
    var initFbSDK = useCallback(function (config, document) {
        var _window = window;
        _window.fbAsyncInit = function () {
            _window.FB && _window.FB.init(__assign({}, config));
            setIsSdkLoaded(true);
            var fbRoot = document.getElementById('fb-root');
            if (!fbRoot) {
                fbRoot = document.createElement('div');
                fbRoot.id = 'fb-root';
                document.body.appendChild(fbRoot);
            }
        };
    }, []);
    var getMe = useCallback(function (authResponse) {
        _window.FB.api('/me', { locale: language, fields: fieldsProfile }, function (me) {
            setIsLogged(true);
            setIsProcessing(false);
            onResolve({
                provider: 'facebook',
                data: __assign(__assign({}, authResponse), me),
            });
        });
    }, [fieldsProfile, language, onResolve]);
    var handleResponse = useCallback(function (response) {
        if (response.authResponse) {
            getMe(response.authResponse);
        }
        else {
            onReject(response);
            setIsProcessing(false);
        }
    }, [getMe, onReject]);
    var load = useCallback(function () {
        if (checkIsExistsSDKScript()) {
            setIsSdkLoaded(true);
        }
        else {
            insertSDKScript(document, function () {
                initFbSDK({
                    appId: appId,
                    xfbml: xfbml,
                    version: version,
                    state: state,
                    cookie: cookie,
                    redirect_uri: redirect_uri,
                    response_type: response_type,
                }, document);
            });
        }
    }, [
        state,
        appId,
        xfbml,
        cookie,
        version,
        initFbSDK,
        redirect_uri,
        response_type,
        insertSDKScript,
        checkIsExistsSDKScript,
    ]);
    var loginFB = useCallback(function () {
        if (isProcessing || !isSdkLoaded)
            return;
        setIsProcessing(true);
        if (!_window.FB) {
            setIsProcessing(false);
            load();
            onReject("Fb isn't loaded!");
        }
        else {
            onLoginStart && onLoginStart();
            _window.FB.login(handleResponse, {
                scope: scope,
                return_scopes: return_scopes,
                auth_type: auth_type,
            });
        }
    }, [
        isProcessing,
        isSdkLoaded,
        load,
        onReject,
        onLoginStart,
        handleResponse,
        scope,
        return_scopes,
        auth_type,
    ]);
    useImperativeHandle(ref, function () { return ({
        onLogout: function () {
            if (isLogged) {
                console.log('🚀 ~ file: index.tsx ~ line 202 ~ useImperativeHandle ~ isLogged', isLogged);
                setIsLogged(false);
                onLogoutSuccess && onLogoutSuccess();
                _window.FB.logout();
            }
            else {
                console.log('You must login before logout.');
            }
        },
    }); });
    return (React.createElement("div", { className: className, style: { width: '100%' }, onClick: loginFB }, children));
});
export default memo(LoginSocialFacebook);
