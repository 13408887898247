var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import FlightSearchForm from 'app/components/FlightSearchForm/SearchForm/FlightSearchForm';
import Carousel from 'app/components/Carousel/Carousel';
import WelcomeSection from 'app/components/WelcomeSection/WelcomeSection';
import DesktopMenu from 'app/components/Menu/DesktopMenu';
import MobileMenu from 'app/components/Menu/MobileMenu';
import { Box, Container, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import Layout from 'app/components/Layout';
import ArrowDown from 'app/assets/icons/arrow-down.svg';
import SpecialOffers from 'app/components/SpecialOffers/SpecialOffers';
import TravelAndSave from 'app/components/Home/TravelAndSave';
import OurAdvantages from 'app/components/Home/OurAdvantages';
import { StringParam, useQueryParams } from 'use-query-params';
import { AnalyticsContext } from 'app/hooks/useAnalytics';
import useTranslation from 'app/hooks/useTranslation';
import { configSiteName } from 'app/util/siteConfig';
function Home() {
    var t = useTranslation();
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var scrollBottomBtnRef = useRef(null);
    var _a = useQueryParams({
        utm_source: StringParam,
        utm_medium: StringParam,
        utm_campaign: StringParam,
        utm_term: StringParam,
    }), queryParams = _a[0], setQueryParams = _a[1];
    var utmContext = useContext(AnalyticsContext);
    useEffect(function () {
        utmContext.setAnalyticsContext({
            ga: utmContext.utmData.ga,
            utmCampaign: queryParams.utm_campaign || undefined,
            utmMedium: queryParams.utm_medium || undefined,
            utmTerm: queryParams.utm_term || undefined,
            utmSource: queryParams.utm_source || undefined,
        });
    }, [queryParams]);
    var scrollBottom = function () {
        scrollBottomBtnRef.current !== null &&
            window.scrollTo({
                behavior: 'smooth',
                top: scrollBottomBtnRef.current.offsetTop,
            });
    };
    var Header = function () {
        return (React.createElement(Box, { sx: { background: { xs: '#002171', md: 'transparent' } } },
            React.createElement(Container, { disableGutters: !isMobile },
                isMobile ? (React.createElement(MobileMenu, { navVisible: true })) : (React.createElement(DesktopMenu, { paletteMode: "white", navigationType: "buttons" })),
                isMobile && (React.createElement(Stack, { justifyContent: "center", sx: { my: 2, textAlign: 'center' } },
                    React.createElement(Typography, { variant: "h3", sx: { color: { xs: '#fff', md: '#000' } } }, t('home.compare.prices'))))),
            React.createElement(Box, { sx: {
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    pb: { md: '150px' },
                } },
                !isMobile ? React.createElement(Carousel, null) : null,
                React.createElement(Container, { sx: __assign({ width: '100%', mt: 2 }, (!isMobile && {
                        position: 'absolute',
                        bottom: '52px',
                    })) },
                    React.createElement(FlightSearchForm, null),
                    isMobile && (React.createElement(Stack, { sx: { mt: 4, mb: 3, alignItems: 'center' }, ref: scrollBottomBtnRef, onClick: scrollBottom },
                        React.createElement("img", { src: ArrowDown, style: { width: '36px', height: '36px' } })))))));
    };
    return (React.createElement(Layout, { HeaderComponent: React.createElement(Header, null) },
        React.createElement(Helmet, null,
            React.createElement("title", null, t('meta.home.title', { configSiteName: configSiteName })),
            React.createElement("meta", { name: "description", content: t('meta.home.desc', { configSiteName: configSiteName }) })),
        React.createElement(Container, null,
            React.createElement(SpecialOffers, null),
            React.createElement(TravelAndSave, null),
            React.createElement(WelcomeSection, null),
            React.createElement(OurAdvantages, null))));
}
export default Home;
