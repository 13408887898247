import { Avatar, Button, Menu, Stack, Typography, useMediaQuery, } from '@material-ui/core';
import get from 'lodash.get';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'app/hooks/useAuth';
import useTranslation, { useLocaleId } from 'app/hooks/useTranslation';
import Login from './Login';
import Close from 'app/assets/icons/Close';
var User = function (_a) {
    var _b, _c;
    var isAuthenticated = _a.isAuthenticated, mobileOpen = _a.mobileOpen, closeMobileDrawer = _a.closeMobileDrawer, paletteMode = _a.paletteMode;
    var _d = useState(false), isOpen = _d[0], setIsOpen = _d[1];
    var _e = useState(mobileOpen || false), isMobileOpen = _e[0], setIsMobileOpen = _e[1];
    var _f = useState(null), anchorEl = _f[0], setAnchorEl = _f[1];
    var desktopMenuOpen = Boolean(anchorEl);
    var locale = useLocaleId();
    var handleDesktopAvatarClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleDesktopMenuClose = function () {
        setAnchorEl(null);
    };
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var t = useTranslation();
    var history = useHistory();
    var handleAvatarClick = function () {
        history.push("/" + locale + "/account");
    };
    var userContext = useContext(AuthContext);
    var userFirstName = get((_b = userContext.authData.user) === null || _b === void 0 ? void 0 : _b.profile, 'firstName');
    var userLasttName = get((_c = userContext.authData.user) === null || _c === void 0 ? void 0 : _c.profile, 'lastName');
    var userEmail = get(userContext.authData.user, 'email');
    var usernameBreakdown = (React.createElement(Stack, null, userFirstName && userLasttName ? (React.createElement(Stack, null,
        React.createElement(Typography, { variant: "body1", color: "common.white" },
            userFirstName,
            " ",
            userLasttName),
        React.createElement(Typography, { variant: "body2", color: "common.white" }, userEmail))) : (React.createElement(Typography, { variant: "body1", color: "common.white" }, userEmail))));
    var avatarBreakdown = userFirstName && userLasttName
        ? userFirstName.charAt(0) + userLasttName.charAt(0)
        : userEmail === null || userEmail === void 0 ? void 0 : userEmail.substring(0, 2);
    var userMenu = (React.createElement(Menu, { sx: { px: 3, py: 2 }, anchorEl: anchorEl, id: "account-menu", open: desktopMenuOpen, onClose: handleDesktopMenuClose, onClick: handleDesktopMenuClose, transformOrigin: { horizontal: 'right', vertical: 'top' }, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } },
        React.createElement(Stack, { sx: { p: 2 } },
            React.createElement(Typography, { variant: "body1" }, t('login.bookings')),
            React.createElement(Button, { variant: "text", sx: { color: 'primary.main', justifyContent: 'flex-start' }, onClick: function () {
                    history.push("/" + locale + "/account/?tab=flights");
                } }, t('login.myFlights')),
            React.createElement(Typography, { variant: "body1" }, t('login.accountInfo')),
            React.createElement(Button, { variant: "text", sx: { color: 'primary.main', justifyContent: 'flex-start' }, onClick: function () {
                    history.push("/" + locale + "/account/?tab=profile");
                } }, t('login.profile')),
            React.createElement(Button, { variant: "text", sx: { color: 'primary.main', justifyContent: 'flex-start' }, onClick: function () {
                    history.push("/" + locale + "/account/?tab=bonuses");
                } }, t('login.bonuses')),
            React.createElement(Button, { variant: "text", sx: { color: 'primary.main', justifyContent: 'flex-start' }, onClick: function () {
                    history.push("/" + locale + "/account/?tab=passengers");
                } }, t('login.passengers')),
            React.createElement(Button, { variant: "contained", sx: { height: 32 }, onClick: function () {
                    userContext.logout();
                    setIsOpen(false);
                } }, t('login.logout')))));
    return isAuthenticated ? (isMobile ? (React.createElement(Stack, { sx: { width: '70vw' } },
        React.createElement(Stack, { direction: "row", spacing: 2, alignItems: "center", sx: { p: 2, backgroundColor: 'text.primary' } },
            React.createElement(Avatar, { onClick: function () { return handleAvatarClick(); }, sx: { cursor: 'pointer', backgroundColor: 'primary.main' } }, avatarBreakdown),
            usernameBreakdown,
            React.createElement(Stack, { sx: { position: 'absolute', right: 10 }, onClick: closeMobileDrawer },
                React.createElement(Close, { stroke: "white" }))),
        React.createElement(Stack, { spacing: 2, sx: { px: 3, py: 2 } },
            React.createElement(Typography, { variant: "body1" }, t('login.bookings')),
            React.createElement(Button, { variant: "text", sx: { color: 'primary.main', justifyContent: 'flex-start' }, onClick: function () {
                    history.push("/" + locale + "/account/?tab=flights");
                    closeMobileDrawer && closeMobileDrawer();
                } }, t('login.myFlights')),
            React.createElement(Typography, { variant: "body1" }, t('login.accountInfo')),
            React.createElement(Button, { variant: "text", sx: { color: 'primary.main', justifyContent: 'flex-start' }, onClick: function () {
                    history.push("/" + locale + "/account/?tab=profile");
                    closeMobileDrawer && closeMobileDrawer();
                } }, t('login.profile')),
            React.createElement(Button, { variant: "text", sx: { color: 'primary.main', justifyContent: 'flex-start' }, onClick: function () {
                    history.push("/" + locale + "/account/?tab=bonuses");
                    closeMobileDrawer && closeMobileDrawer();
                } }, t('login.bonuses')),
            React.createElement(Button, { variant: "text", sx: { color: 'primary.main', justifyContent: 'flex-start' }, onClick: function () {
                    history.push("/" + locale + "/account/?tab=passengers");
                    closeMobileDrawer && closeMobileDrawer();
                } }, t('login.passengers')),
            React.createElement(Button, { variant: "contained", sx: { height: 48 }, onClick: function () {
                    userContext.logout();
                    setIsOpen(false);
                } }, t('login.logout'))))) : (React.createElement(React.Fragment, null,
        React.createElement(Button, { color: paletteMode === 'dark' ? 'primary' : 'secondary', variant: "outlined", sx: {
                p: '6px 16px',
                borderRadius: '52px',
                height: '33px',
                gap: '10px',
            }, onClick: function () { return history.push("/" + locale + "/account/?tab=flights"); } }, t('login.myFlights')),
        React.createElement(Stack, { onClick: handleDesktopAvatarClick, "aria-controls": desktopMenuOpen ? 'account-menu' : undefined, "aria-haspopup": "true", "aria-expanded": desktopMenuOpen ? 'true' : undefined },
            React.createElement(Stack, { direction: "row", spacing: 2, alignItems: "center" },
                React.createElement(Avatar, { sx: { cursor: 'pointer', backgroundColor: 'primary.main' } }, avatarBreakdown))),
        userMenu))) : isMobile ? (React.createElement(React.Fragment, null,
        React.createElement(Login, { open: isMobileOpen, onClose: function () {
                setIsMobileOpen(false);
                closeMobileDrawer && closeMobileDrawer();
            } }))) : (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "outlined", sx: {
                p: '6px 16px',
                borderRadius: '52px',
                height: '33px',
                gap: '10px',
                color: paletteMode === 'dark' ? 'primary' : 'primary.dark',
                border: '1px solid',
            }, onClick: function () { return setIsOpen(true); } }, t('login.login')),
        React.createElement(Login, { open: isOpen, onClose: function () { return setIsOpen(false); } })));
};
export default User;
