import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Container, useMediaQuery } from '@material-ui/core';
import FlightCardSkeleton from '../FlightCard/FlightCardSkeleton';
import { Box } from '@material-ui/system';
export default function PageSkeleton() {
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    return (React.createElement(Stack, { sx: { width: '100%' } }, isMobile ? (React.createElement(Stack, null,
        React.createElement(Stack, { sx: {
                width: '100%',
                backgroundColor: 'primary.dark',
                p: 3,
                mb: 4,
            }, spacing: 1, color: "background.default" },
            React.createElement(Skeleton, { variant: "rectangular", width: '100%', height: '20px', sx: { backgroundColor: 'rgba(255, 255, 255, 0.4)' } }),
            React.createElement(Skeleton, { variant: "rectangular", width: '100%', height: '12px', sx: { backgroundColor: 'rgba(255, 255, 255, 0.4)' } })),
        React.createElement(Stack, null,
            React.createElement(FlightCardSkeleton, null),
            React.createElement(Stack, { spacing: 3, sx: { my: 4, px: 2 } }, new Array(6).fill(0).map(function (el, key) { return (React.createElement(Stack, { sx: {
                    width: '100%',
                    height: '200px',
                    boxShadow: '0px 2px 10px rgba(25, 1, 52, 0.12)',
                    borderRadius: '4px',
                    px: 3,
                    py: 2,
                }, key: key },
                React.createElement(Skeleton, { variant: "rectangular", width: '100%', height: '100%' }))); }))))) : (React.createElement(Stack, { className: "container", sx: { width: '100%' } },
        React.createElement(Stack, { alignItems: "center", sx: {
                width: '100%',
                mb: 4,
            } },
            React.createElement(Stack, { sx: {
                    width: '100%',
                    height: '80px',
                    alignItems: 'center',
                    backgroundColor: 'rgba(227, 242, 253, 1)',
                }, direction: "row", justifyContent: "center" },
                React.createElement(Container, null,
                    React.createElement(Stack, { justifyContent: "space-between", direction: "row" },
                        React.createElement(Skeleton, { variant: "rectangular", width: 600, height: 40 }),
                        React.createElement(Skeleton, { variant: "rectangular", width: 180, height: 40 })),
                    React.createElement(Skeleton, { variant: "rectangular", width: '100%', height: '100%' }))),
            React.createElement(Container, null,
                React.createElement(Box, { sx: {
                        width: '100%',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    } },
                    React.createElement(Stack, { direction: "row", sx: { width: '100%', mt: 3 } },
                        React.createElement(Stack, { sx: { maxWidth: 850, width: '100%' } },
                            React.createElement(FlightCardSkeleton, { noPrice: true }),
                            React.createElement(Stack, { spacing: 3, sx: { mt: 4 } }, new Array(6).fill(0).map(function (el, key) { return (React.createElement(Stack, { sx: {
                                    width: '100%',
                                    height: '350px',
                                    boxShadow: '0px 2px 10px rgba(25, 1, 52, 0.12)',
                                    borderRadius: '4px',
                                    px: 3,
                                    py: 2,
                                }, key: key },
                                React.createElement(Skeleton, { variant: "rectangular", width: '100%', height: '100%' }))); }))),
                        React.createElement(Stack, { sx: {
                                flex: 1,
                                pl: 4,
                            } },
                            React.createElement(Stack, { sx: {
                                    width: '270px',
                                    height: '160px',
                                    boxShadow: '0px 2px 10px rgba(25, 1, 52, 0.12)',
                                    borderRadius: '4px',
                                    mt: 2,
                                    px: 3,
                                    py: 2,
                                } },
                                React.createElement(Skeleton, { variant: "rectangular", width: '100%', height: '100%' })))))))))));
}
