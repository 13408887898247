var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Box, Button, Card, Container, Stack, Tab, Tabs, useMediaQuery, } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { capitalize } from 'lodash';
import { AuthContext } from 'app/hooks/useAuth';
import useTranslation from 'app/hooks/useTranslation';
import ProfileForm from 'app/components/UserProfile/ProfileForm';
import ProfilePassengers from 'app/components/UserProfile/ProfilePassengers';
import ProfileBookings from 'app/components/UserProfile/ProfileBookings';
import ProfileBonuses from 'app/components/UserProfile/ProfileBonuses';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import Layout from 'app/components/Layout';
import styled from '@emotion/styled';
function Profile() {
    var _a, _b;
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var history = useHistory();
    var context = useContext(AuthContext);
    useEffect(function () {
        if (!context.token && !context.loading) {
            history.replace('/');
        }
        if (!context.authData.user && !context.loading && !context.token) {
            history.replace('/');
        }
    }, [context.authData.user, context.loading, context.token]);
    var userProfile = context.authData.user
        ? context.authData.user.profile
        : null;
    var handleLogoutClick = context.logout;
    var tabsValues = ['Profile', 'Flights', 'Passengers', 'Bonuses'];
    var _c = useState(tabsValues.indexOf('Profile')), activeTab = _c[0], setActiveTab = _c[1];
    var _d = useQueryParams({
        tab: withDefault(StringParam, 'profile'),
    }), queryParams = _d[0], setQueryParams = _d[1];
    var t = useTranslation();
    var StyledCard = styled(Card)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", " {\n      filter: none;\n\n      &:hover {\n        box-shadow: none;\n      }\n    }\n  "], ["\n    ", " {\n      filter: none;\n\n      &:hover {\n        box-shadow: none;\n      }\n    }\n  "])), function (p) { return p.theme.breakpoints.down('sm'); });
    useEffect(function () {
        tabsValues.includes(capitalize(queryParams.tab))
            ? setActiveTab(tabsValues.indexOf(capitalize(queryParams.tab)))
            : setActiveTab(0);
    }, [queryParams.tab]);
    var tabContent = {
        Profile: (React.createElement(ProfileForm, { profile: userProfile, email: ((_b = (_a = context.authData) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.email) || '' })),
        Passengers: React.createElement(ProfilePassengers, null),
        Flights: React.createElement(ProfileBookings, null),
        Bonuses: React.createElement(ProfileBonuses, null),
    };
    var handleTabChange = function (index) {
        setQueryParams({ tab: tabsValues[index].toLowerCase() });
    };
    return (React.createElement(Layout, null,
        React.createElement(Container, null,
            React.createElement(Box, { sx: {
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    px: { xs: 0, md: 'unset' },
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    mt: { md: 4 },
                } },
                React.createElement(StyledCard, { sx: {
                        width: '100%',
                        mx: { xs: 2, md: 'unset' },
                        alignSelf: 'center',
                        p: 2,
                    } },
                    React.createElement(Stack, { direction: "row", justifyContent: "space-between", sx: {
                            borderBottom: '1px solid #bdbdbd',
                            boxSizing: 'content-box',
                        } },
                        React.createElement(Tabs, { value: activeTab, variant: "fullWidth", onChange: function (e, index) { return handleTabChange(index); } }, tabsValues.map(function (val, index) { return (React.createElement(Tab, { key: index, label: t("profile." + val.toLowerCase()) })); })),
                        !isMobile && (React.createElement(Button, { onClick: function () { return handleLogoutClick(); } }, t('login.logout')))),
                    tabContent[tabsValues[activeTab]])))));
}
export default Profile;
var templateObject_1;
